import { executeDataVerification, toastMessage, generateColumnGrouping, checkHeihlight, getColumnStyle, isEditable } from "layouts/linelist/XL_Utils";
import { colGrpTYReport } from "layouts/linelist/colGrpVariable";
import { compareAndHighLightDataChanges, GetAutoChanges } from "layouts/linelist/getChangesComman";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import CustomAgGrid from "../CustomAgGrid";
import { setHighlightByTblName } from "layouts/linelist/getChangesComman";
import { xlRound } from "layouts/linelist/XL_Calculation";

const tbl_name = "xl_faherty_ty_report";
const rng_name = "TY_Report_Rng";
const TYReportTab = forwardRef(({ setCurrentGridApi }, ref) => {
  const dispatch = useDispatch();
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const FormulaCalcColLst = useSelector((s) => s.formulaCalcColLst[0][tbl_name]);
  const TYReport = useSelector((s) => s.TYReportData);
  const TYReportCache = useSelector((s) => s.TYReportDataSSN);
  const varHighlightCell = useSelector((s) => s.TYReportHighlistCell);
  const varColDefs = useSelector((s) => s.TYReportColDef || []);
  const [TYReportColDef, setTYReportColDef] = useState([]);
  const columnGrpPref = useSelector((s) => s.TYReportGrpColPref);
  const columnPref = useSelector((s) => s.TYReportColPref);
  const varSorting = useSelector((s) => s.TYReportSorting || []);
  const varFilters = useSelector((s) => s.TYReportFilters || {});
  const highlightCells = useRef([]);
  const cellValueChanged = useRef([]);
  const [isGridReady, setIsGridReady] = useState(false);
  const [autoChangeState, setAutoChangeState] = useState(false);
  const [autoGetChangeResult, setAutoGetChangeResult] = useState({});
  const [bottomRowData, setBottomRowData] = useState([]);
  const footerRowCalCols = ["Style Activity", "Margin Metrics - AIR", "Margin Metrics - Reg AUR", "Margin Metrics - AUR", "Margin Metrics - AUC", "Margin Metrics - IMU%", "Margin Metrics - Reg GM%", "Margin Metrics - GM%", "Margin Metrics - Reg Disc Rate", "Margin Metrics - Disc Rate"]
  const setResetFilter = (s) => {
    gridApi.current.applyColumnState({ state: s === "reset" ? null : varSorting });
    gridApi.current.setFilterModel(s === "reset" ? null : varFilters);
  };
  useImperativeHandle(ref, () => ({
    validateSendData: () => {
      if (!TYReport) return false;
      const res = executeDataVerification(
        null,
        tbl_name,
        highlightCells.current,
        gridApi.current,
        gridColumnApi.current
      );
      if (res.status === false) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        return res;
      }
      cellValueChanged.current = [];
      removeHeihlight();
      return { status: true, colDef: varColDefs };
    },
    getChangesFunc: async (masterData, metaData) => {
      const res = await compareAndHighLightDataChanges(
        masterData,
        rng_name,
        tbl_name,
        varColDefs,
        gridApi.current,
        cellValueChanged.current,
        highlightCells.current,
        metaData
      );
      if (res.status) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        dispatch({ type: "TY_REPORT_DATA", data: res.data });
        gridApi.current.refreshCells({ force: true });
      } else {
        toastMessage(res.msg, "warning");
      }
      return res;
    },
    getAutoChanges: (data, downloadType) => {
      setResetFilter("reset");
      const res = GetAutoChanges(
        data,
        highlightCells.current,
        gridApi.current,
        cellValueChanged.current,
        tbl_name,
        downloadType
      );
      setAutoChangeState(true);
      setAutoGetChangeResult(res);
    },
  }));
  const removeHeihlight = () => {
    highlightCells.current = [];
    if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true });
  };
  const onGridReady = (params) => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;
    setCurrentGridApi(params);
    calculateAllFormulas();

    setIsGridReady(true);
  };
  const onSortChanged = (event) => {
    const colState = gridApi.current.getColumnState();
    const savedSortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch({ type: "SET_TY_REPORT_SORTING", data: savedSortState });
  };
  const onFilterChanged = (event) => {
    const savedFilterModel = gridApi.current.getFilterModel();
    dispatch({ type: "SET_TY_REPORT_FILTERS", data: savedFilterModel });
    calcBottomRow();
  };
  const resteAutoChangeState = () => {
    setResetFilter("set");
    setAutoChangeState(false);
    setAutoGetChangeResult({});
  };
  const setCellValueChanged = (data) => {
    cellValueChanged.current = data;
  };
  useEffect(() => {
    // console.log("TYReportData -> ", TYReportData);
    // console.log("varColDefs -> ", varColDefs);
    if (varColDefs && varColDefs.length > 0) {
        let temp = varColDefs;
        temp = temp.map((col) => {
            let mpCol = col
            mpCol.suppressColumnsToolPanel = col.hide;
            mpCol.suppressFiltersToolPanel = col.hide;

            // console.log("mpCol -> ", mpCol);
            if (mpCol.field.includes('CC Count - ')) {
                mpCol.headerName = mpCol.headerName.replace('CC Count - ', '');
            } else if (mpCol.field.includes('FP Weeks - ')) {
                mpCol.headerName = mpCol.headerName.replace('FP Weeks - ', '');
            } else if (mpCol.field.includes('Margin Metrics - ')) {
                mpCol.headerName = mpCol.headerName.replace('Margin Metrics - ', '');
            } else if (mpCol.field.includes('Total Available Cost - ')) {
                mpCol.headerName = mpCol.headerName.replace('Total Available Cost - ', '');
            } else if (mpCol.field.includes('Total Available Units - ')) {
                mpCol.headerName = mpCol.headerName.replace('Total Available Units - ', '');
            } else if (mpCol.field.includes('BOP Cost - ')) {
                mpCol.headerName = mpCol.headerName.replace('BOP Cost - ', '');
            } else if (mpCol.field.includes('BOP Units - ')) {
                mpCol.headerName = mpCol.headerName.replace('BOP Units - ', '');
            } else if (mpCol.field.includes('EOP Cost - ')) {
                mpCol.headerName = mpCol.headerName.replace('EOP Cost - ', '');
            } else if (mpCol.field.includes('EOP Units - ')) {
              mpCol.headerName = mpCol.headerName.replace('EOP Units - ', '');
            } else if (mpCol.field.includes('Receipt Retail - ')) {
              mpCol.headerName = mpCol.headerName.replace('Receipt Retail - ', '');
            } else if (mpCol.field.includes('Receipt Cost - ')) {
              mpCol.headerName = mpCol.headerName.replace('Receipt Cost - ', '');
            } else if (mpCol.field.includes('Receipt Units - ')) {
              mpCol.headerName = mpCol.headerName.replace('Receipt Units - ', '');
            } else if (mpCol.field.includes('Retail Sales AIR - ')) {
              mpCol.headerName = mpCol.headerName.replace('Retail Sales AIR - ', '');
            } else if (mpCol.field.includes('Retail Reg Sales AIR - ')) {
              mpCol.headerName = mpCol.headerName.replace('Retail Reg Sales AIR - ', '');
            } else if (mpCol.field.includes('Retail MD Sales AIR - ')) {
              mpCol.headerName = mpCol.headerName.replace('Retail MD Sales AIR - ', '');
            } else if (mpCol.field.includes('Total Retail Sales $ - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total Retail Sales $ - ', '');
            } else if (mpCol.field.includes('Total Retail Reg Sales $ - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total Retail Reg Sales $ - ', '');
            } else if (mpCol.field.includes('Total Retail MD Sales $ - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total Retail MD Sales $ - ', '');
            } else if (mpCol.field.includes('Total Margin Sales $ - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total Margin Sales $ - ', '');
            } else if (mpCol.field.includes('Total Reg Margin Sales $ - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total Reg Margin Sales $ - ', '');
            } else if (mpCol.field.includes('Total MD Margin Sales $ - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total MD Margin Sales $ - ', '');
            } else if (mpCol.field.includes('Total Sales Cost - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total Sales Cost - ', '');
            } else if (mpCol.field.includes('Total FP Sales Cost - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total FP Sales Cost - ', '');
            } else if (mpCol.field.includes('Total MD Sales Cost - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total MD Sales Cost - ', '');
            } else if (mpCol.field.includes('Total Sales Units - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total Sales Units - ', '');
            } else if (mpCol.field.includes('Total FP Sales Units - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total FP Sales Units - ', '');
            } else if (mpCol.field.includes('Total MD Sales Units - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total MD Sales Units - ', '');
            } else if (mpCol.field.includes('Total Reg Sales U/Week - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total Reg Sales U/Week - ', '');
            } else if (mpCol.field.includes('Total ST % - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total ST % - ', '');
            } else if (mpCol.field.includes('Reg ST % - ')) {
              mpCol.headerName = mpCol.headerName.replace('Reg ST % - ', '');
            } else if (mpCol.field.includes('APS - ')) {
              mpCol.headerName = mpCol.headerName.replace('APS - ', '');
            } else if (mpCol.field.includes('Attribute - ')) {
              mpCol.headerName = mpCol.headerName.replace('Attribute - ', '');
            }

            mpCol.cellStyle = params => { return funCellStyle(params, col) }
            return mpCol;
        });
        // console.log("temp -> ", temp);
        setTYReportColDef(generateColumnGrouping(colGrpTYReport, temp));
    }
  }, [TYReport]);
  useEffect(() => {
      highlightCells.current = varHighlightCell;
      if (isGridReady) gridApi.current.refreshCells({ force: true })
  }, [varHighlightCell])
  const funCellStyle = (params, col) => {
    if (isGridReady && gridColumnApi?.current?.isPivotMode()) return;
    if (!params?.node?.data && !params?.node?.data?.row_id) return;
    const value = params.value ? params.value : "";
    let bgColor = "";
    let bdrColor = "";
    let txtDeco = "";
    let fontColor = "#000000";
    let boldTxt = "normal";
    let borderBottom = "";
    let fontStyle = "normal";
    const customCode = getColumnStyle(varColDefs, value, params, col);
    if (customCode.status) {
        bgColor = customCode.bgColor;
        bdrColor = customCode.bdrColor;
        txtDeco = customCode.txtDeco;
        fontColor = customCode.fontColor;
        boldTxt = customCode.boldTxt;
        borderBottom = customCode.borderBottom;
        fontStyle = customCode.fontStyle;
    } else {
        const editable = isEditable(params, col.field, col.editable, col.insert_editable);
        if (editable) bgColor = "#FFFFFF";
        else bgColor = "#F0F0F0";
        if (col.type === "dropdown") bgColor = "#FDFCEC";
    }
    // let key = tbl_name + "|" + col.headerName.trim() + "|" + params.node.data.row_id
    let key = tbl_name + "|" + col.field.trim() + "|" + params.node.data.row_id
    let obj = checkHeihlight(key, highlightCells.current)
    if (obj.status === true) {
        if (obj.colorCode === 1) bgColor = "cyan";
        else if (obj.colorCode === 2) bgColor = "#d5a7f2";
        else if (obj.colorCode === 3) bdrColor = "#2196f3";
        else if (obj.colorCode === 4) bdrColor = "#9B6155";
        else if (obj.colorCode === 5) bgColor = "#CAE2F9";
    }
    if (params.node.rowPinned === "bottom" && params.node.rowIndex === 0) { bgColor = "#C7F9CC"; boldTxt = "bold"; txtDeco = "" }
    if (params.node.rowPinned === "bottom" && params.node.rowIndex === 1) { bgColor = "#FBF8CC"; boldTxt = "bold"; txtDeco = "" }
    if (bdrColor !== "")
        return { backgroundColor: bgColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor, borderColor: bdrColor, fontStyle: fontStyle }
    else
        return { backgroundColor: bgColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor, borderBottomColor: borderBottom, fontStyle: fontStyle }
  };  
  useEffect(() => {
    if (isGridReady) {
      if (varSorting !== null && varSorting !== undefined) {
        gridApi.current.applyColumnState({ state: varSorting });
      }
      if (varFilters !== null && varFilters !== undefined) {
        gridApi.current.setFilterModel(varFilters);
      }
    }
  }, [isGridReady]);
  useEffect(() => {
    if (TYReport && TYReport.length > 0 && isGridReady) {
      if (isGridReady) {
        calculateAllFormulas();
        calcBottomRow();
      }
    }
  }, [TYReport, isGridReady]);
  const fxcalc = (colnm) => {
    let retval = false;
    if (FormulaCalcColLst.includes(colnm)) retval = true;
    return retval;
  };
  const calculateAllFormulas = () => {
    if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
      gridApi.current.forEachNode((event) => { calculateReactFormula(event) });
      gridApi.current.refreshCells({ force: true });
    }
    dispatch({ type: "SET_PLEASE_WAIT", data: false });
  };
  const calculateReactFormula = (event) => {
    if (!event.data) return "";
    fxcalc("CC Count - Retail") ? event.data["CC Count - Retail"] = calculateCCCountRetail(event) : null;
    fxcalc("CC Count - Online") ? event.data["CC Count - Online"] = calculateCCCountOnline(event) : null;
    fxcalc("Total Available Cost - Retail") ? event.data["Total Available Cost - Retail"] = calculateTotalAvailableCostRetail(event) : null;
    fxcalc("Total Available Cost - Online") ? event.data["Total Available Cost - Online"] = calculateTotalAvailableCostOnline(event) : null;
    fxcalc("Total Available Cost - Omni") ? event.data["Total Available Cost - Omni"] = calculateTotalAvailableCostOmni(event) : null;
    fxcalc("Total Available Units - Omni") ? event.data["Total Available Units - Omni"] = calculateTotalAvailableUnitsOmni(event) : null;
    fxcalc("BOP Cost - Retail") ? event.data["BOP Cost - Retail"] = calculateBOPCostRetail(event) : null;
    fxcalc("BOP Cost - Online") ? event.data["BOP Cost - Online"] = calculateBOPCostOnline(event) : null;
    fxcalc("BOP Cost - Omni") ? event.data["BOP Cost - Omni"] = calculateBOPCostOmni(event) : null;
    fxcalc("BOP Units - Omni") ? event.data["BOP Units - Omni"] = calculateBOPUnitsOmni(event) : null;
    fxcalc("BOP Units - Retail") ? event.data["BOP Units - Retail"] = calculateBOPUnitsRetail(event) : null;
    fxcalc("BOP Units - Online") ? event.data["BOP Units - Online"] = calculateBOPUnitsOnline(event) : null;    
    fxcalc("Receipt Units - Omni") ? event.data["Receipt Units - Omni"] = calculateReceiptUnitsOmni(event) : null;  
    fxcalc("Receipt Retail - Retail") ? event.data["Receipt Retail - Retail"] = calculateReceiptRetailRetail(event) : null;
    fxcalc("Receipt Retail - Online") ? event.data["Receipt Retail - Online"] = calculateReceiptRetailOnline(event) : null;
    fxcalc("Receipt Retail - Omni") ? event.data["Receipt Retail - Omni"] = calculateReceiptRetailOmni(event) : null;   
    fxcalc("Receipt Cost - Retail") ? event.data["Receipt Cost - Retail"] = calculateReceiptCostRetail(event) : null;
    fxcalc("Receipt Cost - Online") ? event.data["Receipt Cost - Online"] = calculateReceiptCostOnline(event) : null;
    fxcalc("Receipt Cost - Omni") ? event.data["Receipt Cost - Omni"] = calculateReceiptCostOmni(event) : null;    
    fxcalc("Retail Reg Sales AIR - Retail") ? event.data["Retail Reg Sales AIR - Retail"] = calculateRetailRegSalesAIRRetail(event) : null;
    fxcalc("Retail Reg Sales AIR - Online") ? event.data["Retail Reg Sales AIR - Online"] = calculateRetailRegSalesAIROnline(event) : null;
    fxcalc("Retail Reg Sales AIR - Omni") ? event.data["Retail Reg Sales AIR - Omni"] = calculateRetailRegSalesAIROmni(event) : null;    
    fxcalc("Retail MD Sales AIR - Retail") ? event.data["Retail MD Sales AIR - Retail"] = calculateRetailMDSalesAIRRetail(event) : null;
    fxcalc("Retail MD Sales AIR - Online") ? event.data["Retail MD Sales AIR - Online"] = calculateRetailMDSalesAIROnline(event) : null;
    fxcalc("Retail MD Sales AIR - Omni") ? event.data["Retail MD Sales AIR - Omni"] = calculateRetailMDSalesAIROmni(event) : null;    
    fxcalc("Retail Sales AIR - Retail") ? event.data["Retail Sales AIR - Retail"] = calculateRetailSalesAIRRetail(event) : null;
    fxcalc("Retail Sales AIR - Online") ? event.data["Retail Sales AIR - Online"] = calculateRetailSalesAIROnline(event) : null;
    fxcalc("Retail Sales AIR - Omni") ? event.data["Retail Sales AIR - Omni"] = calculateRetailSalesAIROmni(event) : null;
    fxcalc("Total Retail Sales $ - Retail") ? event.data["Total Retail Sales $ - Retail"] = calculateTotalRetailSales$Retail(event) : null;
    fxcalc("Total Retail Sales $ - Online") ? event.data["Total Retail Sales $ - Online"] = calculateTotalRetailSales$Online(event) : null;
    fxcalc("Total Retail Sales $ - Omni") ? event.data["Total Retail Sales $ - Omni"] = calculateTotalRetailSales$Omni(event) : null;    
    fxcalc("Total Retail Reg Sales $ - Omni") ? event.data["Total Retail Reg Sales $ - Omni"] = calculateTotalRetailRegSales$Omni(event) : null;    
    fxcalc("APS - Online") ? event.data["APS - Online"] = parseFloat(event.data["Total Reg Sales U/Week - Online"] || 0) : null;
    fxcalc("APS - Retail") ? event.data["APS - Retail"] = calculateAPSRetail(event) : null;
    fxcalc("Reg ST % - Online") ? event.data["Reg ST % - Online"] = calculateRegStperOnline(event) : null;
    fxcalc("Reg ST % - Retail") ? event.data["Reg ST % - Retail"] = calculateRegStperRetail(event) : null;
    fxcalc("Reg ST % - Omni") ? event.data["Reg ST % - Omni"] = calculateRegStperOmni(event) : null;
    fxcalc("Total ST % - Online") ? event.data["Total ST % - Online"] = calculateTotalStPerOnline(event) : null;
    fxcalc("Total ST % - Retail") ? event.data["Total ST % - Retail"] = calculateTotalStPerRetail(event) : null;
    fxcalc("Total ST % - Omni") ? event.data["Total ST % - Omni"] = calculateTotalStPerOmni(event) : null;
    fxcalc("Total Reg Sales U/Week - Online") ? event.data["Total Reg Sales U/Week - Online"] = calculateTotalRegSalesUWeekOnline(event) : null;
    fxcalc("Total Reg Sales U/Week - Retail") ? event.data["Total Reg Sales U/Week - Retail"] = calculateTotalRegSalesUWeekRetail(event) : null;
    fxcalc("Total Reg Sales U/Week - Omni") ? event.data["Total Reg Sales U/Week - Omni"] = calculateTotalRegSalesUWeekOmni(event) : null;
    fxcalc("Total MD Sales Units - Omni") ? event.data["Total MD Sales Units - Omni"] = calculateTotalMdSalesOmni(event) : null;
    fxcalc("Total FP Sales Units - Omni") ? event.data["Total FP Sales Units - Omni"] = calculateTotalFpSalesOmni(event) : null;   
    fxcalc("Total Sales Units - Online") ? event.data["Total Sales Units - Online"] = calculateTotalSalesUnitsOnline(event) : null;
    fxcalc("Total Sales Units - Retail") ? event.data["Total Sales Units - Retail"] = calculateTotalSalesUnitRetail(event) : null;
    fxcalc("Total Sales Units - Omni") ? event.data["Total Sales Units - Omni"] = calculateTotalSalesUnitOmni(event) : null;   
    fxcalc("EOP Cost - Omni") ? event.data["EOP Cost - Omni"] = calculateEOPCostOmni(event) : null;
    fxcalc("EOP Cost - Retail") ? event.data["EOP Cost - Retail"] = calculateEOPCostRetail(event) : null;
    fxcalc("EOP Cost - Online") ? event.data["EOP Cost - Online"] = calculateEOPCostOnline(event) : null;
    fxcalc("EOP Units - Omni") ? event.data["EOP Units - Omni"] = calculateEOPUnitsOmni(event) : null;
    fxcalc("EOP Units - Retail") ? event.data["EOP Units - Retail"] = calculateEOPUnitsRetail(event) : null;
    fxcalc("EOP Units - Online") ? event.data["EOP Units - Online"] = calculateEOPUnitsOnline(event) : null;    
    fxcalc("Total MD Sales Cost - Online") ? event.data["Total MD Sales Cost - Online"] = calculateTotalMdCostOnline(event) : null;
    fxcalc("Total MD Sales Cost - Retail") ? event.data["Total MD Sales Cost - Retail"] = calculateTotalMdCostRetail(event) : null;
    fxcalc("Total MD Sales Cost - Omni") ? event.data["Total MD Sales Cost - Omni"] = calculateTotalMdCostOmni(event) : null;
    fxcalc("Total FP Sales Cost - Online") ? event.data["Total FP Sales Cost - Online"] = calculateTotalFpCostOnline(event) : null;
    fxcalc("Total FP Sales Cost - Retail") ? event.data["Total FP Sales Cost - Retail"] = calculateTotalFpCostRetail(event) : null;
    fxcalc("Total FP Sales Cost - Omni") ? event.data["Total FP Sales Cost - Omni"] = calculateTotalFpCostOmni(event) : null;
    fxcalc("Total Sales Cost - Online") ? event.data["Total Sales Cost - Online"] = calculateTotalSalesCostOnline(event) : null;
    fxcalc("Total Sales Cost - Retail") ? event.data["Total Sales Cost - Retail"] = calculateTotalSalesCostRetail(event) : null;
    fxcalc("Total Sales Cost - Omni") ? event.data["Total Sales Cost - Omni"] = calculateTotalSalesCostOmni(event) : null;
    fxcalc("Total Retail MD Sales $ - Omni") ? event.data["Total Retail MD Sales $ - Omni"] = calculateTotalRetailMDSalesOmni(event) : null;
    fxcalc("Total MD Margin Sales $ - Online") ? event.data["Total MD Margin Sales $ - Online"] = calculateTotalMDMarginSalesOnline(event) : null;
    fxcalc("Total MD Margin Sales $ - Retail") ? event.data["Total MD Margin Sales $ - Retail"] = calculateTotalMDMarginSalesRetail(event) : null;
    fxcalc("Total MD Margin Sales $ - Omni") ? event.data["Total MD Margin Sales $ - Omni"] = calculateTotalMDMarginSalesOmni(event) : null;
    fxcalc("Total Reg Margin Sales $ - Online") ? event.data["Total Reg Margin Sales $ - Online"] = calculateTotalRegMarginSalesOnline(event) : null;
    fxcalc("Total Reg Margin Sales $ - Retail") ? event.data["Total Reg Margin Sales $ - Retail"] = calculateTotalRegMarginSalesRetail(event) : null;
    fxcalc("Total Reg Margin Sales $ - Omni") ? event.data["Total Reg Margin Sales $ - Omni"] = calculateTotalRegMarginSalesOmni(event) : null;
    fxcalc("Total Margin Sales $ - Online") ? event.data["Total Margin Sales $ - Online"] = calculateTotalMarginSalesOnline(event) : null;
    fxcalc("Total Margin Sales $ - Retail") ? event.data["Total Margin Sales $ - Retail"] = calculateTotalMarginSalesRetail(event) : null;
    fxcalc("Total Margin Sales $ - Omni") ? event.data["Total Margin Sales $ - Omni"] = calculateTotalMarginSalesOmni(event) : null;
    fxcalc("Margin Metrics - Reg AUR") ? event.data["Margin Metrics - Reg AUR"] = calculateMarginMetricsRegAUR(event) : null;
    fxcalc("Margin Metrics - AUR") ? event.data["Margin Metrics - AUR"] = calculateMarginMetricsAUR(event) : null;
    fxcalc("Margin Metrics - IMU%") ? event.data["Margin Metrics - IMU%"] = calculateMarginMetricsIMUPer(event) : null;
    fxcalc("Margin Metrics - Reg GM%") ? event.data["Margin Metrics - Reg GM%"] = calculateMarginMetricsRegGMPer(event) : null;
    fxcalc("Margin Metrics - GM%") ? event.data["Margin Metrics - GM%"] = calculateMarginMetricsGMPer(event) : null;
    fxcalc("Margin Metrics - Reg Disc Rate") ? event.data["Margin Metrics - Reg Disc Rate"] = calculateMarginMetricsRegDiscRate(event) : null;
    fxcalc("Margin Metrics - Disc Rate") ? event.data["Margin Metrics - Disc Rate"] = calculateMarginMetricsDiscRate(event) : null; 
    fxcalc("Attribute - FP Life") ? event.data["Attribute - FP Life"] = calculateAttributeFPLife(event) : null;
  };  
  const calculateTotalRetailRegSales$Omni = (event) => {
    let TotalRetailRegSales$Online = parseFloat(event.data['Total Retail Reg Sales $ - Online'] || 0);
    let TotalRetailRegSales$Retail = parseFloat(event.data['Total Retail Reg Sales $ - Retail'] || 0);
    return TotalRetailRegSales$Online + TotalRetailRegSales$Retail;
  };
  const calculateTotalRetailSales$Online = (event) => {
    let TotalRetailRegSales$Online = parseFloat(event.data['Total Retail Reg Sales $ - Online'] || 0);
    let TotalRetailMDSales$Online = parseFloat(event.data['Total Retail MD Sales $ - Online'] || 0);
    return TotalRetailRegSales$Online + TotalRetailMDSales$Online;
  };
  const calculateTotalRetailSales$Retail = (event) => {
    let TotalRetailRegSales$Retail = parseFloat(event.data['Total Retail Reg Sales $ - Retail'] || 0);
    let TotalRetailMDSales$Retail = parseFloat(event.data['Total Retail MD Sales $ - Retail'] || 0);
    return TotalRetailRegSales$Retail + TotalRetailMDSales$Retail;
  };
  const calculateTotalRetailSales$Omni = (event) => {
    let TotalRetailRegSales$Omni = parseFloat(event.data['Total Retail Reg Sales $ - Omni'] || 0);
    let TotalRetailMDSales$Omni = parseFloat(event.data['Total Retail MD Sales $ - Omni'] || 0);
    return TotalRetailRegSales$Omni + TotalRetailMDSales$Omni;
  };
  const calculateRetailMDSalesAIROnline = (event) => {
    let TotalMDSalesUnitsOnline = parseFloat(event.data['Total MD Sales Units - Online'] || 0)
    let MarginMetricsAIR = parseFloat(event.data['Margin Metrics - AIR'] || 0)
    return TotalMDSalesUnitsOnline * MarginMetricsAIR;
  };
  const calculateRetailMDSalesAIRRetail = (event) => {
    let TotalMDSalesUnitsRetail = parseFloat(event.data['Total MD Sales Units - Retail'] || 0)
    let MarginMetricsAIR = parseFloat(event.data['Margin Metrics - AIR'] || 0)
    return TotalMDSalesUnitsRetail * MarginMetricsAIR;
  };
  const calculateRetailMDSalesAIROmni = (event) => {
    let RetailMDSalesAIRRetail = parseFloat(event.data['Retail MD Sales AIR - Retail'] || 0);
    let RetailMDSalesAIROnline = parseFloat(event.data['Retail MD Sales AIR - Online'] || 0);
    return RetailMDSalesAIRRetail + RetailMDSalesAIROnline;
  };
  const calculateRetailRegSalesAIROnline = (event) => {
    let TotalFPSalesUnitsOnline = parseFloat(event.data['Total FP Sales Units - Online'] || 0)
    let MarginMetricsAIR = parseFloat(event.data['Margin Metrics - AIR'] || 0)
    return TotalFPSalesUnitsOnline * MarginMetricsAIR;
  };
  const calculateRetailRegSalesAIRRetail = (event) => {
    let TotalFPSalesUnitsRetail = parseFloat(event.data['Total FP Sales Units - Retail'] || 0)
    let MarginMetricsAIR = parseFloat(event.data['Margin Metrics - AIR'] || 0)
    return TotalFPSalesUnitsRetail * MarginMetricsAIR;
  };
  const calculateRetailRegSalesAIROmni = (event) => {
    let RetailRegSalesAIRRetail = parseFloat(event.data['Retail Reg Sales AIR - Retail'] || 0)
    let RetailRegSalesAIROnline = parseFloat(event.data['Retail Reg Sales AIR - Online'] || 0)
    return RetailRegSalesAIRRetail + RetailRegSalesAIROnline;
  };
  const calculateRetailSalesAIROnline = (event) => {
    let RetailRegSalesAIROnline = parseFloat(event.data['Retail Reg Sales AIR - Online'] || 0);
    let RetailMDSalesAIROnline = parseFloat(event.data['Retail MD Sales AIR - Online'] || 0);
    return RetailRegSalesAIROnline + RetailMDSalesAIROnline;
  };
  const calculateRetailSalesAIRRetail = (event) => {
    let RetailRegSalesAIRRetail = parseFloat(event.data['Retail Reg Sales AIR - Retail'] || 0);
    let RetailMDSalesAIRRetail = parseFloat(event.data['Retail MD Sales AIR - Retail'] || 0);
    return RetailRegSalesAIRRetail + RetailMDSalesAIRRetail;
  };
  const calculateRetailSalesAIROmni = (event) => {
    let RetailRegSalesAIROmni = parseFloat(event.data['Retail Reg Sales AIR - Omni'] || 0);
    let RetailMDSalesAIROmni = parseFloat(event.data['Retail MD Sales AIR - Omni'] || 0);
    return RetailRegSalesAIROmni + RetailMDSalesAIROmni;
  };
  const calculateReceiptUnitsOmni = (event) => {
    let ReceiptUnitsRetail = parseFloat(event.data['Receipt Units - Retail'] || 0);
    let ReceiptUnitsOnline = parseFloat(event.data['Receipt Units - Online'] || 0);
    return ReceiptUnitsRetail + ReceiptUnitsOnline;
  };
  const calculateReceiptCostOnline = (event) => {
    let ReceiptUnitsOnline = parseFloat(event.data['Receipt Units - Online'] || 0);
    let MarginMetricsAUC = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    return ReceiptUnitsOnline * MarginMetricsAUC;
  };
  const calculateReceiptCostRetail = (event) => {
    let ReceiptUnitsRetail = parseFloat(event.data['Receipt Units - Retail'] || 0);
    let MarginMetricsAUC = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    return ReceiptUnitsRetail * MarginMetricsAUC;
  };
  const calculateReceiptCostOmni = (event) => {
    let ReceiptCostRetail = parseFloat(event.data['Receipt Cost - Retail'] || 0);
    let ReceiptCostOnline = parseFloat(event.data['Receipt Cost - Online'] || 0);
    return ReceiptCostRetail + ReceiptCostOnline;
  };
  const calculateReceiptRetailOnline = (event) => {
    let ReceiptUnitsOnline = parseFloat(event.data['Receipt Units - Online'] || 0);
    let MarginMetricsAIR = parseFloat(event.data['Margin Metrics - AIR'] || 0);
    return ReceiptUnitsOnline * MarginMetricsAIR;
  };
  const calculateReceiptRetailRetail = (event) => {
    let ReceiptUnitsRetail = parseFloat(event.data['Receipt Units - Retail'] || 0);
    let MarginMetricsAIR = parseFloat(event.data['Margin Metrics - AIR'] || 0);
    return ReceiptUnitsRetail * MarginMetricsAIR;
  };
  const calculateReceiptRetailOmni = (event) => {
    let ReceiptRetailRetail = parseFloat(event.data['Receipt Retail - Retail'] || 0);
    let ReceiptRetailOnline = parseFloat(event.data['Receipt Retail - Online'] || 0);
    return ReceiptRetailRetail + ReceiptRetailOnline;
  };
  const calculateEOPUnitsOnline = (event) => {
    let TotalAvailableUnitsOnline = parseFloat(event.data['Total Available Units - Online'] || 0);
    let TotalSalesUnitsOnline = parseFloat(event.data['Total Sales Units - Online'] || 0);
    return TotalAvailableUnitsOnline - TotalSalesUnitsOnline;
  };
  const calculateEOPUnitsRetail = (event) => {
    let TotalAvailableUnitsRetail = parseFloat(event.data['Total Available Units - Retail'] || 0);
    let TotalSalesUnitsRetail = parseFloat(event.data['Total Sales Units - Retail'] || 0);
    return TotalAvailableUnitsRetail - TotalSalesUnitsRetail;
  };
  const calculateEOPUnitsOmni = (event) => {
    let TotalAvailableUnitsOmni = parseFloat(event.data['Total Available Units - Omni'] || 0);
    let TotalSalesUnitsOmni = parseFloat(event.data['Total Sales Units - Omni'] || 0);
    return TotalAvailableUnitsOmni - TotalSalesUnitsOmni;
  };
  const calculateEOPCostOnline = (event) => {
    let TotalAvailableCostOnline = parseFloat(event.data['Total Available Cost - Online'] || 0);
    let TotalSalesCostOnline = parseFloat(event.data['Total Sales Cost - Online'] || 0);
    return TotalAvailableCostOnline - TotalSalesCostOnline;
  };
  const calculateEOPCostRetail = (event) => {
    let TotalAvailableCostRetail = parseFloat(event.data['Total Available Cost - Retail'] || 0);
    let TotalSalesCostRetail = parseFloat(event.data['Total Sales Cost - Retail'] || 0);
    return TotalAvailableCostRetail - TotalSalesCostRetail;
  };
  const calculateEOPCostOmni = (event) => {
    let TotalAvailableCostOmni = parseFloat(event.data['Total Available Cost - Omni'] || 0);
    let TotalSalesCostOmni = parseFloat(event.data['Total Sales Cost - Omni'] || 0);
    return TotalAvailableCostOmni - TotalSalesCostOmni;
  };
  const calculateBOPUnitsOnline = (event) => {
    let returnVal = 0;
    let BOPCostOnline = parseFloat(event.data['BOP Cost - Online'] || 0);
    let MarginMetricsAUC = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    if (MarginMetricsAUC !== 0)
      returnVal = BOPCostOnline / MarginMetricsAUC;
    return returnVal;
  };
  const calculateBOPUnitsRetail = (event) => {
    let returnVal = 0;
    let BOPCostRetail = parseFloat(event.data['BOP Cost - Retail'] || 0);
    let MarginMetricsAUC = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    if (MarginMetricsAUC !== 0)
      returnVal = BOPCostRetail / MarginMetricsAUC;
    return returnVal;
  };
  const calculateBOPUnitsOmni = (event) => {
    let returnVal = 0;
    let BOPCostOmni = parseFloat(event.data['BOP Cost - Omni'] || 0);
    let MarginMetricsAUC = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    if (MarginMetricsAUC !== 0)
      returnVal = BOPCostOmni / MarginMetricsAUC;
    return returnVal;
  };
  const calculateBOPCostOnline = (event) => {
    let TotalAvailableCostOnline = parseFloat(event.data['Total Available Cost - Online'] || 0);
    let ReceiptCostOnline = parseFloat(event.data['Receipt Cost - Online'] || 0);
    return TotalAvailableCostOnline - ReceiptCostOnline;
  };
  const calculateBOPCostRetail = (event) => {
    let TotalAvailableCostRetail = parseFloat(event.data['Total Available Cost - Retail'] || 0);
    let ReceiptCostRetail = parseFloat(event.data['Receipt Cost - Retail'] || 0);
    return TotalAvailableCostRetail - ReceiptCostRetail;
  };
  const calculateBOPCostOmni = (event) => {
    let BOPCostRetail = parseFloat(event.data['BOP Cost - Retail'] || 0);
    let BOPCostOnline = parseFloat(event.data['BOP Cost - Online'] || 0);
    return BOPCostRetail + BOPCostOnline;
  };
  const calculateTotalAvailableUnitsOmni = (event) => {
    let TotalAvailableUnitsRetail = parseFloat(event.data['Total Available Units - Retail'] || 0);
    let TotalAvailableUnitsOnline = parseFloat(event.data['Total Available Units - Online'] || 0);
    return TotalAvailableUnitsRetail + TotalAvailableUnitsOnline;
  };
  const calculateTotalAvailableCostOnline = (event) => {
    let TotalAvailableUnitsOnline = parseFloat(event.data['Total Available Units - Online'] || 0);
    let MarginMetricsAUC = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    return TotalAvailableUnitsOnline * MarginMetricsAUC;
  };
  const calculateTotalAvailableCostRetail = (event) => {
    let TotalAvailableUnitsRetail = parseFloat(event.data['Total Available Units - Retail'] || 0);
    let MarginMetricsAUC = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    return TotalAvailableUnitsRetail * MarginMetricsAUC;
  };
  const calculateTotalAvailableCostOmni = (event) => {
    let TotalAvailableCostRetail = parseFloat(event.data['Total Available Cost - Retail'] || 0);
    let TotalAvailableCostOnline = parseFloat(event.data['Total Available Cost - Online'] || 0);
    return TotalAvailableCostRetail + TotalAvailableCostOnline;
  };
  const calculateMarginMetricsDiscRate = (event) => {
    let resultVal = 0;
    let MarginMetricsAUR = parseFloat(event.data['Margin Metrics - AUR'] || 0);
    let MarginMetricsAIR = parseFloat(event.data['Margin Metrics - AIR'] || 0);
    if (MarginMetricsAIR !== 0)
      resultVal = (MarginMetricsAIR - MarginMetricsAUR) / MarginMetricsAIR;
    return resultVal;
  };
  const calculateMarginMetricsRegDiscRate = (event) => {
    let resultVal = 0;
    let MarginMetricsRegAUR = parseFloat(event.data['Margin Metrics - Reg AUR'] || 0);
    let MarginMetricsAIR = parseFloat(event.data['Margin Metrics - AIR'] || 0);
    if (MarginMetricsAIR !== 0)
      resultVal = (MarginMetricsAIR - MarginMetricsRegAUR) / MarginMetricsAIR;
    return resultVal;
  };
  const calculateMarginMetricsGMPer = (event) => {
    let resultVal = 0;
    let TotalMarginSalesOmni = parseFloat(event.data['Total Margin Sales $ - Omni'] || 0);
    let TotalRetailSalesOmni = parseFloat(event.data['Total Retail Sales $ - Omni'] || 0);
    if (TotalRetailSalesOmni !== 0)
      resultVal = TotalMarginSalesOmni / TotalRetailSalesOmni;
    return resultVal;
  };
  const calculateMarginMetricsRegGMPer = (event) => {
    let resultVal = 0;
    let TotalRegMarginSalesOmni = parseFloat(event.data['Total Reg Margin Sales $ - Omni'] || 0);
    let TotalRetailRegSalesOmni = parseFloat(event.data['Total Retail Reg Sales $ - Omni'] || 0);
    if (TotalRetailRegSalesOmni !== 0)
      resultVal = TotalRegMarginSalesOmni / TotalRetailRegSalesOmni;
    return resultVal;
  };
  const calculateMarginMetricsIMUPer = (event) => {
    let resultVal = 0;
    let MarginMetricsAUC = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    let MarginMetricsAIR = parseFloat(event.data['Margin Metrics - AIR'] || 0);
    if (MarginMetricsAIR !== 0)
      resultVal = (MarginMetricsAIR - MarginMetricsAUC) / MarginMetricsAIR;
    return resultVal;
  };
  const calculateMarginMetricsAUR = (event) => {
    let resultVal = 0;
    let TotalRetailSalesOmni = parseFloat(event.data['Total Retail Sales $ - Omni'] || 0);
    let TotalSalesUnitsOmni = parseFloat(event.data['Total Sales Units - Omni'] || 0);
    if (TotalSalesUnitsOmni !== 0)
      resultVal = TotalRetailSalesOmni / TotalSalesUnitsOmni;
    return resultVal;
  };
  const calculateMarginMetricsRegAUR = (event) => {
    let resultVal = 0;
    let TotalRetailRegSalesOmni = parseFloat(event.data['Total Retail Reg Sales $ - Omni'] || 0);
    let TotalFPSalesUnitsOmni = parseFloat(event.data['Total FP Sales Units - Omni'] || 0);
    if (TotalFPSalesUnitsOmni !== 0)
      resultVal = TotalRetailRegSalesOmni / TotalFPSalesUnitsOmni;
    return resultVal;
  };
  const calculateCCCountOnline = (event) => {
    let resultVal = 0;
    const StyleDescription = event.data["Style Description"] || 0;
    if (StyleDescription !== 0) resultVal = 1;
    return resultVal;
  };
  const calculateCCCountRetail = (event) => {
    let resultVal = 0;
    const StoreCount = parseFloat(event.data["Store Count"] || 0);
    if (StoreCount > 0) resultVal = 1;
    return resultVal;
  };
  const calculateTotalMarginSalesOnline = (event) => {
    let totalSalesCostOnline = parseFloat(event.data['Total Sales Cost - Online'] || 0);
    let totalRetailSalesOnline = parseFloat(event.data['Total Retail Sales $ - Online'] || 0);
    return totalRetailSalesOnline - totalSalesCostOnline
  };
  const calculateTotalMarginSalesRetail = (event) => {
    let totalSalesCostRetail = parseFloat(event.data['Total Sales Cost - Retail'] || 0);
    let totalRetailSalesRetail = parseFloat(event.data['Total Retail Sales $ - Retail'] || 0);
    return totalRetailSalesRetail - totalSalesCostRetail
  };
  const calculateTotalMarginSalesOmni = (event) => {
    let totalSalesCostOmni = parseFloat(event.data['Total Sales Cost - Omni'] || 0);
    let totalRetailSalesOmni = parseFloat(event.data['Total Retail Sales $ - Omni'] || 0);
    return totalRetailSalesOmni - totalSalesCostOmni
  };
  const calculateTotalRegMarginSalesOnline = (event) => {
    let totalFpSalesCostOnline = parseFloat(event.data['Total FP Sales Cost - Online'] || 0);
    let totalRetailRegSalesOnline = parseFloat(event.data['Total Retail Reg Sales $ - Online'] || 0);
    return totalRetailRegSalesOnline - totalFpSalesCostOnline
  };
  const calculateTotalRegMarginSalesRetail = (event) => {
    let totalFpSalesCostRetail = parseFloat(event.data['Total FP Sales Cost - Retail'] || 0);
    let totalRetailRegSalesRetail = parseFloat(event.data['Total Retail Reg Sales $ - Retail'] || 0);
    return totalRetailRegSalesRetail - totalFpSalesCostRetail
  };
  const calculateTotalRegMarginSalesOmni = (event) => {
    let totalFpSalesCostOmni = parseFloat(event.data['Total FP Sales Cost - Omni'] || 0);
    let totalRetailRegSalesOmni = parseFloat(event.data['Total Retail Reg Sales $ - Omni'] || 0);
    return totalRetailRegSalesOmni - totalFpSalesCostOmni
  };
  const calculateTotalRetailMDSalesOmni = (event) => {
    let totalRetailMDSalesRetail = parseFloat(event.data['Total Retail MD Sales $ - Retail'] || 0);
    let totalRetailMDSalesOnline = parseFloat(event.data['Total Retail MD Sales $ - Online'] || 0);
    return totalRetailMDSalesRetail + totalRetailMDSalesOnline
  };
  const calculateTotalMDMarginSalesOmni = (event) => {
    let totalRetailMDSalesOmni = parseFloat(event.data['Total Retail MD Sales $ - Omni'] || 0);
    let totalMDSalesCostOnline = parseFloat(event.data['Total MD Sales Cost - Omni'] || 0);
    return totalRetailMDSalesOmni - totalMDSalesCostOnline
  };
  const calculateTotalMDMarginSalesRetail = (event) => {
    let totalRetailMDSalesRetail = parseFloat(event.data['Total Retail MD Sales $ - Retail'] || 0);
    let totalMDSalesCostOnline = parseFloat(event.data['Total MD Sales Cost - Retail'] || 0);
    return totalRetailMDSalesRetail - totalMDSalesCostOnline
  };
  const calculateTotalMDMarginSalesOnline = (event) => {
    let totalRetailMDSalesOnline = parseFloat(event.data['Total Retail MD Sales $ - Online'] || 0);
    let totalMDSalesCostOnline = parseFloat(event.data['Total MD Sales Cost - Online'] || 0);
    return totalRetailMDSalesOnline - totalMDSalesCostOnline
  };
  const calculateTotalSalesCostOmni = (event) => {
    let totalSalesUnitOmni = parseFloat(event.data['Total Sales Units - Omni'] || 0);
    let marginMetricAuc = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    return totalSalesUnitOmni * marginMetricAuc
  };
  const calculateTotalSalesCostRetail = (event) => {
    let totalSalesUnitRetail = parseFloat(event.data['Total Sales Units - Retail'] || 0);
    let marginMetricAuc = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    return totalSalesUnitRetail * marginMetricAuc
  };
  const calculateTotalSalesCostOnline = (event) => {
    let totalSalesUnitOnline = parseFloat(event.data['Total Sales Units - Online'] || 0);
    let marginMetricAuc = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    return totalSalesUnitOnline * marginMetricAuc
  };
  const calculateTotalFpCostOmni = (event) => {
    let totalFPsalesUnitsOmni = parseFloat(event.data['Total FP Sales Units - Omni'] || 0);
    let marginMetricAuc = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    return totalFPsalesUnitsOmni * marginMetricAuc
  };
  const calculateTotalFpCostRetail = (event) => {
    let totalFPsalesUnitsRetail = parseFloat(event.data['Total FP Sales Units - Retail'] || 0);
    let marginMetricAuc = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    return totalFPsalesUnitsRetail * marginMetricAuc
  };
  const calculateTotalFpCostOnline = (event) => {
    let totalFPsalesUnitsOnline = parseFloat(event.data['Total FP Sales Units - Online'] || 0);
    let marginMetricAuc = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    return totalFPsalesUnitsOnline * marginMetricAuc
  };
  const calculateTotalMdCostOmni = (event) => {
    let totalMDsalesUnitsOmni = parseFloat(event.data['Total MD Sales Units - Omni'] || 0);
    let marginMetricAuc = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    return totalMDsalesUnitsOmni * marginMetricAuc
  };
  const calculateTotalMdCostRetail = (event) => {
    let totalMDsalesUnitsRetail = parseFloat(event.data['Total MD Sales Units - Retail'] || 0);
    let marginMetricAuc = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    return totalMDsalesUnitsRetail * marginMetricAuc
  };
  const calculateTotalMdCostOnline = (event) => {
    let totalMDsalesUnitsOnline = parseFloat(event.data['Total MD Sales Units - Online'] || 0);
    let marginMetricAuc = parseFloat(event.data['Margin Metrics - AUC'] || 0);
    return totalMDsalesUnitsOnline * marginMetricAuc
  };
  const calculateTotalSalesUnitOmni = (event) => {
    let totalMDsalesUnitsOmni = parseFloat(event.data['Total MD Sales Units - Omni'] || 0);
    let totalFPsalesUnitsOmni = parseFloat(event.data['Total FP Sales Units - Omni'] || 0);
    return totalMDsalesUnitsOmni + totalFPsalesUnitsOmni
  };
  const calculateTotalSalesUnitRetail = (event) => {
    let totalMDsalesUnitsRetail = parseFloat(event.data['Total MD Sales Units - Retail'] || 0);
    let totalFPsalesUnitsRetail = parseFloat(event.data['Total FP Sales Units - Retail'] || 0);
    return totalMDsalesUnitsRetail + totalFPsalesUnitsRetail
  };
  const calculateTotalSalesUnitsOnline = (event) => {
    let totalFPsalesUnitsOnline = parseFloat(event.data['Total FP Sales Units - Online'] || 0);
    let totalMDsalesUnitsOnline = parseFloat(event.data['Total MD Sales Units - Online'] || 0);
    return totalFPsalesUnitsOnline + totalMDsalesUnitsOnline
  };
  const calculateTotalFpSalesOmni = (event) => {
    let totalFPsalesUnitsOnline = parseFloat(event.data['Total FP Sales Units - Online'] || 0);
    let totalFPsalesUnitsRetail = parseFloat(event.data['Total FP Sales Units - Retail'] || 0);
    return totalFPsalesUnitsOnline + totalFPsalesUnitsRetail
  };
  const calculateTotalMdSalesOmni = (event) => {
    let totalMDsalesUnitsOnline = parseFloat(event.data['Total MD Sales Units - Online'] || 0);
    let totalMDsalesUnitsRetail = parseFloat(event.data['Total MD Sales Units - Retail'] || 0);
    return totalMDsalesUnitsOnline + totalMDsalesUnitsRetail
  };
  const calculateTotalRegSalesUWeekOmni = (event) => {
    let totalRegSalesUWeekOnline = parseFloat(event.data['Total Reg Sales U/Week - Online'] || 0);
    let totalRegSalesUWeekRetail = parseFloat(event.data['Total Reg Sales U/Week - Retail'] || 0);
    return totalRegSalesUWeekOnline + totalRegSalesUWeekRetail
  };
  const calculateTotalRegSalesUWeekOnline = (event) => {
    let returnVal = 0;
    let totalFpSalesUnitOnline = parseFloat(event.data['Total FP Sales Units - Online'] || 0);
    let fpWeeksOnline = parseFloat(event.data['FP Weeks - Online'] || 0);
    if (fpWeeksOnline !== 0) {
      returnVal = totalFpSalesUnitOnline / fpWeeksOnline;
    }
    return returnVal
  };
  const calculateTotalRegSalesUWeekRetail = (event) => {
    let returnVal = 0;
    let totalFpSalesUnitRetail = parseFloat(event.data['Total FP Sales Units - Retail'] || 0);
    let fpWeeksRetail = parseFloat(event.data['FP Weeks - Retail'] || 0);
    if (fpWeeksRetail !== 0) {
      returnVal = totalFpSalesUnitRetail / fpWeeksRetail;
    }
    return returnVal
  };
  const calculateTotalStPerOmni = (event) => {
    let returnVal = 0;
    let totalRegSalesUnitOmni = parseFloat(event.data['Total Sales Units - Omni'] || 0);
    let totalAvailableUnitsOmni = parseFloat(event.data['Total Available Units - Omni'] || 0);
    if (totalAvailableUnitsOmni !== 0) {
      returnVal = totalRegSalesUnitOmni / totalAvailableUnitsOmni;
    }
    return returnVal
  };
  const calculateTotalStPerRetail = (event) => {
    let returnVal = 0;
    let totalRegSalesUnitRetail = parseFloat(event.data['Total Sales Units - Retail'] || 0);
    let totalAvailableUnitsRetail = parseFloat(event.data['Total Available Units - Retail'] || 0);
    if (totalAvailableUnitsRetail !== 0) {
      returnVal = totalRegSalesUnitRetail / totalAvailableUnitsRetail;
    }
    return returnVal
  };
  const calculateTotalStPerOnline = (event) => {
    let returnVal = 0;
    let totalRegSalesUnitOnline = parseFloat(event.data['Total Sales Units - Online'] || 0);
    let totalAvailableUnitsOnline = parseFloat(event.data['Total Available Units - Online'] || 0);
    if (totalAvailableUnitsOnline !== 0) {
      returnVal = totalRegSalesUnitOnline / totalAvailableUnitsOnline;
    }
    return returnVal
  };
  const calculateAPSRetail = (event) => {
    let returnVal = 0;
    let totalRegSalesUWeekRetail = parseFloat(event.data['Total Reg Sales U/Week - Retail'] || 0);
    let storeCount = parseFloat(event.data['Store Count'] || 0);
    if (storeCount !== 0) {
      returnVal = totalRegSalesUWeekRetail / storeCount;
    }
    return returnVal
  };
  const calculateRegStperOnline = (event) => {
    let returnVal = 0;
    let totalFpSalesUnitOnline = parseFloat(event.data['Total FP Sales Units - Online'] || 0);
    let totalAvailableUnitsOnline = parseFloat(event.data['Total Available Units - Online'] || 0);
    if (totalAvailableUnitsOnline !== 0) {
      returnVal = totalFpSalesUnitOnline / totalAvailableUnitsOnline;
    }
    return returnVal
  };
  const calculateRegStperRetail = (event) => {
    let returnVal = 0;
    let totalFpSalesUnitRetail = parseFloat(event.data['Total FP Sales Units - Retail'] || 0);
    let totalAvailableUnitsRetail = parseFloat(event.data['Total Available Units - Retail'] || 0);
    if (totalAvailableUnitsRetail !== 0) {
      returnVal = totalFpSalesUnitRetail / totalAvailableUnitsRetail;
    }
    return returnVal
  };
  const calculateRegStperOmni = (event) => {
    let returnVal = 0;
    let totalFpSalesUnitOmni = parseFloat(event.data['Total FP Sales Units - Omni'] || 0);
    let totalAvailableUnitsOmni = parseFloat(event.data['Total Available Units - Omni'] || 0);
    if (totalAvailableUnitsOmni !== 0) {
      returnVal = totalFpSalesUnitOmni / totalAvailableUnitsOmni;
    }
    return returnVal
  };
  const calculateAttributeFPLife = (event) => {
    let returnVal = "Liable";
    let SeasonalLifeCycle = (event.data['Seasonal Life Cycle'] || "").toLowerCase();    
    if (["core", "sb1", "sb1 ext"].includes(SeasonalLifeCycle)) returnVal = "Non Liable";
    return returnVal;
  };
  const calcBottomRow = () => {
    // console.log("In calcBottomRow")
    const result = {};
    let filtredData = [];
    footerRowCalCols.forEach(c => { if (!result[c]) result[c] = { btmRow1: 0, btmRow2: 0 } });
    try { gridApi.current.forEachNodeAfterFilter(node => { filtredData.push(node.data) }); }
    catch { filtredData = TYReport; }
    filtredData = filtredData.filter((i) => i["Style Activity"] === "ACTIVE");
    const TYReportActive = TYReport.filter((i) => i["Style Activity"] === "ACTIVE");
    footerRowCalCols.forEach(column => {
      if (column === "Style Activity") {
        result["Style Activity"].btmRow1 = TYReport.length;
        result["Style Activity"].btmRow2 = filtredData.length;
      } else {
        // result[column].btmRow1 = TYReportActive.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue[column] ? parseFloat(currentValue[column]).toFixed(2) : 0), 0);
        // result[column].btmRow2 = filtredData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue[column] ? parseFloat(currentValue[column]).toFixed(2) : 0), 0);
        const total1 = TYReportActive.reduce((accumulator, currentValue) => {
          const value = parseFloat(currentValue[column]);
          return accumulator + (isNaN(value) ? 0 : value);
        }, 0);
        const count1 = TYReportActive.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue[column] ? 1 : 0);
        }, 0);
        const average1 = count1 > 0 ? (total1 / count1).toFixed(2) : 0;
        result[column].btmRow1 = average1;

        const total2 = filtredData.reduce((accumulator, currentValue) => {
          const value2 = parseFloat(currentValue[column]);
          return accumulator + (isNaN(value2) ? 0 : value2);
        }, 0);
        const count2 = filtredData.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue[column] ? 1 : 0);
        }, 0);
        const average2 = count2 > 0 ? (total2 / count2).toFixed(2) : 0;
        result[column].btmRow2 = average2;
      }
    });
    if (Object.keys(result).length === 0) setBottomRowData([]);
    else {
      let row1 = [];
      let row2 = [];
      footerRowCalCols.forEach(c => {
        row1[c] = `${result[c]["btmRow1"]}`
        row2[c] = `${result[c]["btmRow2"]}`
      });
      setBottomRowData([row1, row2]);
    }

  };
  return (
    <CustomAgGrid
      autoChangeState={autoChangeState}
      autoGetChangeResult={autoGetChangeResult}
      columnDefs={varColDefs}
      customColumnDef={TYReportColDef}
      filters={varFilters}
      isGrouppedHeader={true}
      calculateBottomRow={calcBottomRow}
      pinnedBottomRowData={bottomRowData}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      resteAutoChangeState={resteAutoChangeState}
      rowData={TYReport}
      setCellValueChanged={setCellValueChanged}
      calculateFormula={calculateReactFormula}
      setCurrentGridApi={onGridReady}
      SSNData={TYReportCache}
      tab_name="TY_Report"
      tbl_name={tbl_name}
      tblHighlightCells={varHighlightCell}
      columnGrpPref={columnGrpPref}
      columnPref={columnPref}
    />
  );
});

TYReportTab.propTypes = {
  setCurrentGridApi: PropTypes.func.isRequired,
};

export default TYReportTab;
