import React, { useState } from 'react';
import { Box, Typography, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const CollapsibleView = ({ title, children }) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Box >
            <Box sx={{
                width: "100%",
                borderBottom: '2px solid #3f51b5',

                marginBottom: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>

                <Typography variant="h6" component="div" sx={{
                    fontSize: '12px', // Reduced font size for title
                    textAlign: "center",
                    fontWeight: 400
                }}>
                    {title}
                </Typography>
                <IconButton onClick={toggleExpand} size="small">
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>

            <Collapse in={isExpanded}>
                {children}
            </Collapse>
        </Box>
    );
};

export default CollapsibleView;