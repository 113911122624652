// NotificationPanel.jsx
import React, { useEffect, useState } from 'react';
import { Badge, Box, IconButton, Popover, List, ListItem, ListItemText, Typography, Divider, ListSubheader, ListItemIcon, ListItemButton, } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MDButton from "components/MDButton";
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { toastMessage, getColumnDefByTblName, CH1, CH2, CH3, formatDateToFullString, doubleQuote, getHighlightByTblName } from "layouts/linelist/XL_Utils";
import AirtableIntegrationNotification from '../AirtableIntegrationNotification';
import AutoGetChangesNotification from '../AutoGetChangesNotification';
import { setCurrentTab } from "context";
import { useMaterialUIController } from "context";
import { checkYearSeasonDept } from 'layouts/linelist/getChangesComman';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const columnDefs = [
    { headerName: "Column Name", field: "Column Name" },
    { headerName: "New Value", field: "New Value" },
    { headerName: "Updated By", field: "Updated By" }
];

const NotificationPanel = ({ notificationData, airTableIntNfData, autoGetChangesData }) => {
    const [controller, dispatch] = useMaterialUIController();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [allNotifications, setAllNotifications] = useState({
        "Airtable Integration": [],
        "Linelist": [],
        "PD_Initial_Costing": [],
        "Recent Updates": [],
        "Action Items": []
    });

    const [actionMsg, setActionMsg] = useState([]);

    useEffect(() => {
        if (notificationData && Object.keys(notificationData).length > 0) {
            const preMsgs = [...actionMsg]
            if (Array.isArray(notificationData)) {
                preMsgs.unshift(...notificationData);
            } else {
                preMsgs.unshift(notificationData);
            }
            // console.log("preMsgs -> ", preMsgs);
            setActionMsg(preMsgs);
        }
    }, [notificationData]);

    useEffect(() => {
        // console.log("NotificationPanel -> autoGetChangesData -> ", autoGetChangesData);
        if (autoGetChangesData === null || autoGetChangesData === undefined) {
            return;
        }
        // console.log("autoGetChangesData.size -> ", autoGetChangesData.size);
        if (autoGetChangesData.size === 0) {
            return;
        }
        // const singleKey = [...autoGetChangesData.keys()][0];
        // console.log("singleKey -> ", singleKey);
        // const cd = getColumnDefByTblName(singleKey);
        const acgTabName = autoGetChangesData.get('tab_name');
        const acgTableName = autoGetChangesData.get('table_name');
        const acgDownloadType = autoGetChangesData.get('download_type');
        const cd = getColumnDefByTblName(acgTableName);
        // console.log("cd -> ", cd);
        let editableCols = new Array();
        if (cd) {
            cd.map((col) => {
                if (col.editable) {
                    // console.log("col -> ", col);
                    editableCols.push(col.field);
                }
            })
        }
        // console.log("editableCols -> ", editableCols);
        // const dt = 'insert00[]update33[["8269","Transit Days",""],["8269","modified_on","2024-10-25 10:17:03.176645+05:30"],["8269","user_modified","gd@admin.com"],["8390","CBM Per Carton","0"],["8390","Transit Days","0"],["8390","Vendor to Faherty Costing Comments","TEST"],["8390","modified_on","2024-10-25 10:17:03.176645+05:30"],["8390","user_modified","gd@admin.com"],["8370","CBM Per Carton","0"],["8370","Transit Days","0"],["8370","modified_on","2024-10-25 10:17:03.176645+05:30"],["8370","user_modified","gd@admin.com"]]delete00[]';
        // const changes = dt.split(CH3);
        const acgData = autoGetChangesData.get('data');
        const changes = acgData.split(CH3);
        // console.log("Configurator -> changes -> ", changes);
        if (!changes) {
            return;
        }

        changes.map((cng) => {
            // console.log("cng -> ", cng);
            const iudArr = cng.split(CH2);
            // console.log("iudArr -> ", iudArr);
            const insertedRowsArr = iudArr[0].split(CH1);
            const updatedCellsArr = iudArr[1].split(CH1);
            // const deletedRowsArr = iudArr[2].split(CH1);
            // console.log("insertedRowsArr -> ", insertedRowsArr);
            // console.log("updatedCellsArr -> ", updatedCellsArr);
            // console.log("deletedRowsArr -> ", deletedRowsArr);

            // INSERT
            const insertedRowsCnt1 = insertedRowsArr[1] ? parseInt(insertedRowsArr[1]) : 0;
            const insertedRowsCnt2 = insertedRowsArr[2] ? parseInt(insertedRowsArr[2]) : 0;
            // console.log("insertedRowsCnt1 -> ", insertedRowsCnt1);
            // console.log("insertedRowsCnt2 -> ", insertedRowsCnt2);
            let insertedRowsFinalList = new Array();
            let insertedRowsUser = '';
            if (insertedRowsCnt1 > 0 && insertedRowsCnt2 > 0) {
                const insertedRowsData = JSON.parse(insertedRowsArr[3]);
                if (insertedRowsData && insertedRowsData.length > 0) {
                    // console.log("insertedRowsData -> ", insertedRowsData);
                    insertedRowsData.map((r) => {
                        if (cd && r.length === cd.length && (checkYearSeasonDept(cd, r) || acgDownloadType.toLowerCase() === "default")) {
                            const element = {};
                            for (let i = 0; i < r.length; i++) {
                                const col = cd[i].field;
                                if (col === "user_inserted") {
                                    insertedRowsUser = r[i];
                                }
                                if (col === "inserted_on") {
                                    element[col] = formatDateToFullString(new Date(r[i]));
                                } else {
                                    element[col] = r[i];
                                }
                            }
                            insertedRowsFinalList.push(element);
                        }
                    });
                }
            }
            // console.log("insertedRowsFinalList -> ", insertedRowsFinalList);

            // UPDATE
            const updatedCellsCnt1 = updatedCellsArr[1] ? parseInt(updatedCellsArr[1]) : 0;
            const updatedCellsCnt2 = updatedCellsArr[2] ? parseInt(updatedCellsArr[2]) : 0;
            // console.log("updatedCellsCnt1 -> ", updatedCellsCnt1);
            // console.log("updatedCellsCnt2 -> ", updatedCellsCnt2);
            let updatedCellsFinalList = new Array();
            if (updatedCellsCnt1 > 0 && updatedCellsCnt2 > 0) {
                const updatedCellsData = JSON.parse(updatedCellsArr[3]);
                if (updatedCellsData && updatedCellsData.length > 0) {
                    // console.log("updatedCellsData -> ", updatedCellsData);
                    updatedCellsData.map((c) => {
                        if (editableCols.includes(c[1])) {
                            // console.log("c -> ", c);
                            // console.log("c[1] -> ", c[1]);
                            const oneRowJson = { "Column Name": c[1], "New Value": c[2], "Updated By": getUserModifiedValue(updatedCellsData, c[0]) };
                            updatedCellsFinalList.push(oneRowJson);
                        }
                    });
                }
            }
            // console.log("updatedCellsFinalList -> ", updatedCellsFinalList);
            if (updatedCellsFinalList && updatedCellsFinalList.length > 0) {
                const finalMp = { "id": Date.now(), type: "Recent Updates", "msg": (acgTabName + " : " + updatedCellsFinalList[0]["Updated By"] + ' made ' + updatedCellsFinalList.length + ' updates.'), "cell_changes": updatedCellsFinalList, "columnDefs": columnDefs };
                // console.log("updatedCellsFinalList -> finalMp -> ", finalMp);
                setAllNotifications(prevNotifications => ({
                    ...prevNotifications,
                    "Recent Updates": [
                        ...prevNotifications["Recent Updates"],
                        finalMp
                    ]
                }));
            }

            // DELETE
            /*
            const deletedRowsCnt1 = deletedRowsArr[1] ? parseInt(deletedRowsArr[1]) : 0;
            const deletedRowsCnt2 = deletedRowsArr[2] ? parseInt(deletedRowsArr[2]) : 0;
            console.log("deletedRowsCnt1 -> ", deletedRowsCnt1);
            console.log("deletedRowsCnt2 -> ", deletedRowsCnt2);
            let deletedRowsFinalList = new Array();
            let deletedRowsUser = '';
            if (deletedRowsCnt1 > 0 && deletedRowsCnt2 > 0) {
                const deletedRowsData = JSON.parse(deletedRowsArr[3]);
                if (deletedRowsData && deletedRowsData.length > 0) {
                    console.log("deletedRowsData -> ", deletedRowsData);
                    deletedRowsData.map((row_id) => {
                        deletedRowsFinalList.push(row_id);
                    });
                }
            }
            console.log("deletedRowsFinalList -> ", deletedRowsFinalList);

            if (deletedRowsFinalList && deletedRowsFinalList.length > 0) {
                const finalMp = { "id": Date.now(), type: "Recent Updates", "msg": (acgTabName + " : " + deletedRowsUser + ' has deleted ' + deletedRowsFinalList.length + ' rows.') };
                console.log("finalMp -> ", finalMp);
                setAllNotifications(prevNotifications => ({
                    ...prevNotifications,
                    "Recent Updates": [
                        ...prevNotifications["Recent Updates"],
                        finalMp
                    ]
                }));
            }
            */

            if (insertedRowsFinalList && insertedRowsFinalList.length > 0) {
                if (acgTableName === 'xl_action_item_details') {
                    const finalMp = { "id": Date.now(), type: "Action Items", "msg": (insertedRowsUser + ' has assigned you a task.'), "table_name": 'xl_action_item_details', "row_id": insertedRowsFinalList[0]['row_id'] };
                    // console.log("insertedRowsFinalList -> finalMp -> ", finalMp);
                    setAllNotifications(prevNotifications => ({
                        ...prevNotifications,
                        "Action Items": [
                            ...prevNotifications["Action Items"],
                            finalMp
                        ]
                    }));
                } else {
                    let insertedRowsColDef = [];
                    Object.keys(insertedRowsFinalList[0]).map(cn => {
                        const nm = cn.replace(doubleQuote, '')
                        if (["row_id", "user_inserted", "inserted_on", "user_modified", "modified_on"].includes(nm)) {
                            insertedRowsColDef.push({ field: nm, headerName: nm, hide: true });
                        } else {
                            insertedRowsColDef.push({ field: nm, headerName: nm });
                        }
                    });
                    // console.log("insertedRowsColDef -> ", insertedRowsColDef);

                    const finalMp = { "id": Date.now(), type: "Recent Updates", "msg": (acgTabName + " : " + insertedRowsUser + ' has added ' + insertedRowsFinalList.length + ' rows.'), "cell_changes": insertedRowsFinalList, "columnDefs": insertedRowsColDef };
                    // console.log("insertedRowsFinalList -> finalMp -> ", finalMp);
                    setAllNotifications(prevNotifications => ({
                        ...prevNotifications,
                        "Recent Updates": [
                            ...prevNotifications["Recent Updates"],
                            finalMp
                        ]
                    }));
                }
            }
        });
    }, [autoGetChangesData]);

    // Function to get the value of user_modified for the specific key
    const getUserModifiedValue = (data, key) => {
        const entry = data.find(item => item[0] === key && item[1] === "user_modified");
        return entry ? entry[2] : null; // Return the value or null if not found
    };

    useEffect(() => {
        // console.log("allNotifications -> ", allNotifications);
        if (!airTableIntNfData) {
            return;
        }
        if (airTableIntNfData && Object.keys(airTableIntNfData).length > 0) {
            // console.log("airTableIntNfData -> ", airTableIntNfData);
            // console.log('airTableIntNfData["Airtable Integration"] -> ', airTableIntNfData["Airtable Integration"]);
            setAllNotifications(prevNotifications => ({
                ...prevNotifications,
                "Airtable Integration": [
                    ...prevNotifications["Airtable Integration"],
                    ...airTableIntNfData["Airtable Integration"]
                ]
            }));
        }
    }, [airTableIntNfData]);

    // console.log("NotificationPanel -> allNotifications -> ", allNotifications);
    // const [allNotifications, setAllNotifications] = useState({
    //     "Airtable Integration": [],
    //     "Linelist": [
    //         { "id": 1, "value": "v1" },
    //         { "id": 2, "value": "v2" }
    //     ],
    //     "PD_Initial_Costing": [
    //         { "id": 1, "value": "v1" },
    //         { "id": 2, "value": "v2" }
    //     ],
    //     "Recent Updates": [
    //         { "id": 1, "value": "v1" },
    //         { "id": 2, "value": "v2" }
    //     ],
    //     "Action Items": [
    //         { "id": 1, "value": "v1" },
    //         { "id": 2, "value": "v2" }
    //     ]
    // });

    // Initialize expanded state for each category
    const [expandedSections, setExpandedSections] = useState(
        Object.keys(allNotifications).reduce((acc, key) => ({
            ...acc,
            [key]: true
        }), {})
    );

    const toggleSection = (section) => {
        // console.log("toggleSection -> section -> ", section);
        // console.log(expandedSections);
        // console.log(expandedSections[section]);
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const totalNotifications = Object.values(allNotifications)
        .reduce((total, items) => total + items.length, 0);

    const handleSubNotificationClick = (key, subItem) => {
        console.log("handleSubNotificationClick -> key -> ", key);
        console.log("handleSubNotificationClick -> subItem -> ", subItem);  
        setSelectedComponent(subItem);

        if (key === 'Action Items') {
            // console.log("Activating tab");
            setCurrentTab(dispatch, 'Action_Item');

            // console.log("gotoMilestone -> row_id -> ", row_id);
            let highlightCells = getHighlightByTblName(subItem.table_name) || [];
            highlightCells = highlightCells.filter(obj => {
                const value = Object.values(obj)[0]; // Get the value of the object
                return value !== 5; // Keep objects where value is not 5
            });
            // console.log("Action_Item -> highlightCells -> ", highlightCells);
            const ky = subItem.table_name + "|" + 'Task Title' + "|" + subItem.row_id;
            // console.log("Action_Item -> ky -> ", ky);
            highlightCells.push({ [ky]: 5 });
            setHighlightByTblName(subItem.table_name, highlightCells);
        } else {
            setDialogTitle(key);
            setOpenDialog(true);
        }

        // Create a new version of allNotifications
        const updatedNotifications = {
            ...allNotifications, // Spread the current state
            [key]: allNotifications[key].filter(am => am.id !== subItem.id) // Filter out the item with the matching id
        };

        // console.log("handleSubNotificationClick -> updatedNotifications -> ", updatedNotifications);

        // Update the state with the new object
        setAllNotifications(updatedNotifications);
    };

    const clearAllNotifications = () => {
        // Create a new object with empty arrays for each key dynamically
        const valMp = Object.keys(allNotifications).reduce((acc, key) => {
            acc[key] = []; // Set each key to an empty array
            return acc;
        }, {});
        // console.log("clearAllNotifications -> valMp -> ", valMp);
        setAllNotifications(valMp);
    };

    const renderComponent = () => {
        if (!dialogTitle || dialogTitle === '') {
            return;
        }
        // console.log("renderComponent -> dialogTitle -> ", dialogTitle);
        if (dialogTitle === 'Recent Updates') {
            return <AutoGetChangesNotification selectedComponent={selectedComponent} />;
        } else if (dialogTitle === 'Airtable Integration') {
            return <AirtableIntegrationNotification selectedComponent={selectedComponent} />;
        }
    };

    return (
        <>
            <Box sx={{ width: 325, maxHeight: 480, overflow: 'auto', mb: '5px' }}>
                {totalNotifications === 0 ? (
                    <>
                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 400, pl: 4 }}>
                            Your notification list is empty.
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 400, pl: 4 }}>
                            Stay tuned for updates!
                        </Typography>
                    </>
                ) : (
                    <>
                        <List sx={{ p: 0 }}>
                            {
                                Object.entries(allNotifications)
                                    .filter(([category, items]) => items.length > 0)
                                    .map(([category, items], index) => (
                                        <Box key={category}>
                                            {index > 0 && <Divider sx={{ backgroundColor: 'lightgray', marginTop: '3px', marginBottom: '3px' }} />}
                                            <ListSubheader
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    bgcolor: 'background.paper',
                                                    position: 'sticky',
                                                    top: 0,
                                                    zIndex: 1,
                                                    // color: "#344767",
                                                    color: "#1976d2",
                                                }}
                                            >
                                                <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 400 }}>
                                                    {category} ({items.length})
                                                </Typography>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => toggleSection(category)}
                                                    sx={{ ml: 1 }}
                                                >
                                                    {expandedSections[category] ? <RemoveIcon /> : <AddIcon />}
                                                </IconButton>
                                            </ListSubheader>
                                            <Box sx={{
                                                transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
                                                maxHeight: expandedSections[category] ? '1000px' : '0px',
                                                opacity: expandedSections[category] ? 1 : 0,
                                                overflow: 'hidden'
                                            }}>
                                                {items.map((notification) => (
                                                    <ListItem
                                                        key={`${category}-${notification.id}`}
                                                        disablePadding
                                                        sx={{ '&:hover': { bgcolor: '#e8f0fe', }, ml: 1, }}
                                                    >
                                                        <ListItemButton sx={{ p: 0, ml: 0.2 }}
                                                            onClick={() => handleSubNotificationClick(category, notification)}
                                                        >
                                                            <ListItemText
                                                                sx={{ pl: 2, mt: 0.2, mb: 0.2, cursor: 'pointer' }}
                                                                primary={<Typography variant="body2" sx={{ fontSize: '12px', fontWeight: 400 }}>{notification.msg}</Typography>}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </Box>
                                        </Box>
                                    ))}
                        </List>
                        <Box sx={{ textAlign: 'right' }}>
                            <MDButton onClick={() => clearAllNotifications()} sx={{ textTransform: "none", color: '#1976d2', fontWeight: 'normal' }} size="small">Clear All</MDButton>
                        </Box>
                    </>
                )}
            </Box>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth={"xxl"}
                // maxWidth={false}
                // fullWidth={!enableFullScreen}
                disableEscapeKeyDown
            // fullScreen={enableFullScreen}
            >
                <DialogTitle sx={{ pt: '4px', pb: '4px', fontSize: '16px', backgroundColor: '#fafafb' }}>
                    {dialogTitle}
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenDialog(false)}
                        style={{ position: "absolute", right: 8, top: -2 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider sx={{ margin: '0px', backgroundColor: 'gray' }} />
                <DialogContent sx={{ pt: '8px' }}>
                    {renderComponent()}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default NotificationPanel;