
import { executeDataVerification, toastMessage } from "layouts/linelist/XL_Utils";
import { GetAutoChanges, compareAndHighLightDataChanges } from "layouts/linelist/getChangesComman";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomAgGrid from "../CustomAgGrid";
import { setHighlightByTblName } from "layouts/linelist/getChangesComman";

const tbl_name = "xl_faherty_season";
const rng_name = "Seasons_Rng";


const SeasonTab = forwardRef(({ setCurrentGridApi, settimeLineDataFun, clearFilterBtnCall }, ref) => {
  const [isGridReady, setIsGridReady] = useState(false);
  const dispatch = useDispatch();
  const SeasonData = useSelector(s => s.SeasonData);
  const SSNData = useSelector((s) => s.SeasonDataSSN);

  const SeasonHighlistCell = useSelector(s => s.SeasonHighlistCell);
  const tmpColDef = useSelector(s => s.SeasonColDef ? s.SeasonColDef : []);
  const [SeasonColDef, setColDef] = useState([]);
  const SeasonSorting = useSelector(s => s.SeasonSorting ? s.SeasonSorting : []);
  const SeasonFilters = useSelector(s => s.SeasonFilters ? s.SeasonFilters : {});
  const columnPref = useSelector((s) => s.SeasonColPref);

  const highlightCells = useRef([]);
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const cellValueChanged = useRef([]);

  const [autoChangeState, setAutoChangeState] = useState(false)
  const [autoGetChangeResult, setAutoGetChangeResult] = useState({});
  const setResetFilter = (setReset) => {
    let s = setReset === "reset" ? null : SeasonSorting;
    let f = setReset === "reset" ? null : SeasonFilters;
    gridApi.current.applyColumnState({ state: s });
    gridApi.current.setFilterModel(f);
  }
  useImperativeHandle(ref, () => ({
    validateSendData: () => {
      if (!SeasonData) return false;
      const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);

      if (res.status === false) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        return (res)
      }
      cellValueChanged.current = [];
      removeHeihlight();
      return ({ status: true });
    },
    getChangesFunc: async (masterData, metaData) => {
      const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, SeasonColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
      if (res.status) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        ;
        dispatch({ type: "SET_SEASON_DATA", data: res.data });
        gridApi.current.refreshCells({ force: true });
      } else {
        toastMessage(res.msg, "warning");

      }
      return res;
    },
    getAutoChanges: (data, downloadType) => {
      setResetFilter("reset");
      const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType);
      setAutoChangeState(true);
      setAutoGetChangeResult(res);
    }
  }));
  const removeHeihlight = () => {
    highlightCells.current = [];
    if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true });
  };
  useEffect(() => {
    if (isGridReady) {
      if (SeasonSorting !== null && SeasonSorting !== undefined) {
        gridApi.current.applyColumnState({ state: SeasonSorting });
      }
      if (SeasonFilters !== null && SeasonFilters !== undefined) {
        gridApi.current.setFilterModel(SeasonFilters);
      }
    }
  }, [isGridReady]);

  // useEffect(() => {
  //   dispatch({ type: "SET_PLEASE_WAIT", data: true });
  //   if (tmpColDef && tmpColDef.length > 0) {
  //     let temp = tmpColDef;

  //     temp = temp.map((col) => {
  //       let mpCol = col;
  //       if (col.field === "Year"){
  //         mpCol["checkboxSelection"] = true;
  //       }
  //       return mpCol;
  //     })
  //     setColDef(temp);
  //     dispatch({ type: "SET_PLEASE_WAIT", data: false });
  //   }
  // }, [tmpColDef, SeasonData]);

  const onGridReady = (params) => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;
    setCurrentGridApi(params);
    setIsGridReady(true)
  };

  const onSortChanged = () => {
    const colState = gridApi.current.getColumnState();
    const savedSortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch({ type: "SET_SEASON_SORTING", data: savedSortState });
  };
  const onFilterChanged = () => {
    const savedFilterModel = gridApi.current.getFilterModel();
    dispatch({ type: "SET_SEASON_FILTERS", data: savedFilterModel });
  };

  const resteAutoChangeState = () => {
    setResetFilter("set");
    setAutoChangeState(false);
    setAutoGetChangeResult({});
  }
  const setCellValueChanged = (data) => {
    cellValueChanged.current = data;
  }
  return (
    <CustomAgGrid
      setCurrentGridApi={onGridReady}
      settimeLineDataFun={settimeLineDataFun}
      filters={SeasonFilters}
      tab_name="Seasons"
      tbl_name={tbl_name}
      columnDefs={tmpColDef}
      rowData={SeasonData}
      SSNData={SSNData}
      tblHighlightCells={SeasonHighlistCell}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      autoChangeState={autoChangeState}
      resteAutoChangeState={resteAutoChangeState}
      autoGetChangeResult={autoGetChangeResult}
      setCellValueChanged={setCellValueChanged}
      rowMultiSelectWithClick={true}
      suppressRowClickSelection={false}
      enableCheckBox={true}
      columnPref={columnPref}
    />
  );
});
SeasonTab.propTypes = {
  setCurrentGridApi: PropTypes.func.isRequired,
  settimeLineDataFun: PropTypes.func.isRequired,

};

export default SeasonTab;
