// const appUrl = "http://localhost:3000/";
// const restApiPath = "http://localhost:8080/faherty_linelist_restapi/";
// const webScoketUrl = "ws://localhost:8080/faherty_linelist_restapi/"

//***************Dev**********************
// const appUrl = "https://dev.linelist.faherty.sohum.tech/";
// const restApiPath = "https://dev.linelist.faherty.sohum.tech/faherty_linelist_restapi_dev/";
// const webScoketUrl = "wss://dev.linelist.faherty.sohum.tech/faherty_linelist_restapi_dev/";

//***************Test**********************
const appUrl = "https://test.linelist.faherty.sohum.tech/";
const restApiPath = "https://test.linelist.faherty.sohum.tech/faherty_linelist_restapi_test/";
const webScoketUrl = "wss://test.linelist.faherty.sohum.tech/faherty_linelist_restapi_test/";

//***************Production**********************
// const appUrl = "https://linelist.faherty.sohum.tech/";
// const restApiPath = "https://linelist.faherty.sohum.tech/faherty_linelist_restapi_prod/";
// const webScoketUrl = "wss://linelist.faherty.sohum.tech/faherty_linelist_restapi_prod/";

const applicationTitle = "Linelist";
const afterOtpVerifiedNevigateTo = "linelist";
const customerBrandName = 'Faherty Brand';
const customerBrandLogoPath = `${appUrl}Faherty-Brand.png`;
const groupHeaderHeight = 16;
const HeaderHeight = 50;
const rowHeight = 22;
const currentDate = new Date();
const utcDifference = ((-1) * (currentDate.getTimezoneOffset() / 60));
const isActionItemEnabled = true;
const isSideBarEnabled = true;

export {
  appUrl,
  restApiPath,
  applicationTitle,
  afterOtpVerifiedNevigateTo,
  customerBrandLogoPath,
  customerBrandName,
  webScoketUrl,
  groupHeaderHeight,
  HeaderHeight,
  rowHeight,
  utcDifference,
  isActionItemEnabled,
  isSideBarEnabled
};