import { Grid, List, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import  { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CustomTooltip = ({ items }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex < items.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    if (!items || items.length === 0) return null;

    const { season_code, user_email, updated_on } = items[currentIndex];

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <div style={{
                position: 'absolute',
                bottom: '100%',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: '#333',
                color: '#fff',
                padding: '4px 8px',
                borderRadius: '4px',
                whiteSpace: 'nowrap',
                zIndex: 1,
                fontSize: '12px',
            }}>
                <div>
                    Season Code: {season_code}, Email: {user_email}, Created On: {new Date(updated_on).toLocaleString()}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '4px' }}>
                    <button onClick={handlePrev} disabled={currentIndex === 0}>{"<"}</button>
                    <button onClick={handleNext} disabled={currentIndex === items.length - 1}>{">"}</button>
                </div>
            </div>
        </div>
    );
};

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

const ListHistoricData = ({ historicData, historicDataInfo, setVal }) => {

    return (
        <List sx={{ height: "200px", overflow: "hidden", overflowY: "auto" }}>
            <Grid container spacing={1}>
                {historicData.map((data) => (
                    // <CustomTooltip item xs={4} md={3}
                    // title={
                    //     <div>
                    //         <div>User: {data.user_email}</div>
                    //         <div>Updated On: {new Date(data.updated_on).toLocaleString()}</div>
                    //     </div>
                    // }
                    // >
                    <CustomWidthTooltip
                            title={
                                <div
                                style={{
                                    textAlign: 'left',
                                    fontFamily: '"Inter","Helvetica","Arial",sans-serif',
                                    fontSize: '12px',
                                    whiteSpace: 'nowrap', // Prevent text from wrapping
                                    // overflow: 'hidden', // Optional: Hide overflow if needed
                                    // textOverflow: 'ellipsis', // Optional: Add ellipsis for overflow
                                   
                                }}
                                >
                                <div>
                                    Season Code: {historicDataInfo[data] ? historicDataInfo[data][0]["season_code"] : null}
                                </div>
                                <div>
                                    User: {historicDataInfo[data] ? historicDataInfo[data][0]["user_email"] : null}
                                </div>
                                <div>
                                    Updated On: {historicDataInfo[data] ? historicDataInfo[data][0]["updated_on"] : null}
                                </div>
                                </div>
                            }
                            PopperProps={{
                                sx: {
                                  pointerEvents: 'none', // Prevent pointer events on the tooltip
                                  maxWidth: 500, // Set a fixed width for the tooltip
                                },
                              }}
                            >

                    <Grid item xs={4} md={3}>
                 
                        <ListItemButton
                            key={data}
                            onClick={() => setVal(data)}
                            sx={{
                                mb: 1,
                                padding: '4px 8px', // Adjusted padding for smaller button size
                                borderRadius: '2px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}
                        >
                            <ListItemText
                                primary={data}
                                primaryTypographyProps={{
                                    variant: 'body2', // Reduced font size for list items
                                    fontWeight: 'medium',
                                    color: '#333',
                                    fontSize: '10px',
                                    textAlign: "center"
                                }}
                            />
                        </ListItemButton>
                        
                    </Grid>
                    </CustomWidthTooltip>
                ))}
            </Grid>
            
        </List>
    )
}

export default ListHistoricData;