import { Avatar, Icon } from "@mui/material";
import MDAvatar from "components/MDAvatar";

const SmallAvatar = ({ children, size = 24, fontSize = 11, color = 'white' }) => {
    return (
        <Avatar
            sx={{
                width: size,
                height: size,
                fontSize: fontSize,
                fontWeight: "bold",
                borderRadius: '50%',
                color: color, // Change font color here
            }}
        >
            {children}
        </Avatar>
    );
};

// Array of predefined vibrant colors
const vibrantColors = [
    "#D2691E", // Dark Orange
    "#3F51B5", // Dark Blue
    "#4CAF50", // Dark Green
    "#F44336", // Dark Red
    "#9C27B0", // Dark Purple
    "#FF9800",  // Dark Amber
    "#CC0099",
    "#008B8B",
    "#663399",
    "#3399FF",
    "#C71585",
    "#DC143C"
];

const tabsName = ["Seasons", "Pd_Initial_Costing", "Merch_Assortment_Sandbox", "Linelist", "Chase", "DTC_Buy_Sheet", "Whsl_Buy_Sheet", "Whsl_Buy_Sheet_Nord_RP", "Sizing", "Summary_Report", "Buy_Sheet_Master_Sizing_%",
    "BuySht_Master_Data_Charts", "Comp_APS_Master_StoreEcom", "Freight_Master", "Freight_by_Category", "Delivery_Master", "FB_Fty_Database", "Size_Scales", "Shopify_Product_Type_Map",
    "Season_Inquiry", "Master_LOV", "Airtable_Mapping_Data", "Action_Item", "Pivot", "Manage_User", "Manage_Permission"
];

const routes = tabsName.map((name, index) => {
    let shortName = "";
    if (name.includes("_")) {
        const tmp = name.split("_");
        shortName = tmp[0].substring(0, 1) + tmp[1].substring(0, 1);
    } else {
        shortName = name.replace(/_/g, '').substring(0, 2); // Take first two letters, ignoring underscores
    }
    shortName = shortName.toUpperCase();

    const color = vibrantColors[index % vibrantColors.length];

    return {
        type: 'title',
        name: name,
        key: `title-${index}`, // Generating the key dynamically
        icon: <SmallAvatar color={color}>{shortName}</SmallAvatar> // Generating the icon with dynamic color and initials
    };
});
const userRoutes = [{
    type: "collapse",
    name: sessionStorage.getItem("email"),
    key: "brooklyn-alice",
    icon: <MDAvatar alt={sessionStorage.getItem("email") ? sessionStorage.getItem("email") : "Guest User"} size="sm" />,
    collapse: [
        {
            name: "Manage Users",
            key: "profile-settings",
            icon: <Icon fontSize="medium">manage_accounts</Icon>,
        },
        {
            name: "Assistant Bot",
            key: "aiAvatar",
            icon: (darkMode) => <img src={darkMode ? "./ai_white.png" : "./ai.ico"} width={39} height={39} style={{ marginLeft: "-5px" }} />,
        },
        {
            name: "Settings",
            key: "theme-settings",
            icon: <Icon fontSize="small">settings</Icon>,
        },
        {
            name: "Logout",
            key: "logout",
            icon: <Icon fontSize="medium">logout</Icon>,
        },
    ],
},

{
    type: 'title',
    name: "Run Airtable Integration",
    key: "air_table_integration",
    icon: <Icon fontSize="medium">cloud_sync</Icon>
},
]

export {
    routes,
    userRoutes
};
