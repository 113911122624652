import { colGrpMilestones } from "layouts/linelist/colGrpVariable";
import { compareAndHighLightDataChanges, GetAutoChanges, setHighlightByTblName } from "layouts/linelist/getChangesComman";
import {
    checkHeihlight,
    executeDataVerification,
    generateColumnGrouping,
    getColumnStyle,
    isEditable,
    JsDateToExcelDateNumber,
    toastMessage,
    CH1,
    CH2,
    CH3,
    CH4,
    CH5,
    CH6,
    CH7,
    formatDateToMmDdYyyyHhMmSsAmPm,
} from "layouts/linelist/XL_Utils";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomAgGrid from "../CustomAgGrid";
import moment from "moment";
import { setStateDataByTblName, setSessionDataByTblName } from "layouts/linelist/getChangesComman";
import { restApiPath, utcDifference, webScoketUrl } from "utils/app.props";

const groups = ['admin', 'read-only', 'vendor', 'merch', 'planning', 'wholesale', 'product development', 'accessories', 'global planning', 'merch reference']
const startCols = ['Mens Spring Start', 'Womens Spring Start', 'Mens Summer Start', 'Womens Summer Start', 'Mens Fall Start', 'Womens Fall Start', 'Mens Holiday Start', 'Womens Holiday (CNY) Start', 'Womens Holiday (Non-CNY) Start']

const tbl_name = "xl_faherty_milestones";
const rng_name = "Milestones_Rng";
const tab_name = "Milestones";

const Milestones = forwardRef(({ setCurrentGridApi }, ref) => {
    const dispatch = useDispatch();
    const gridRef = useRef();
    const gridApi = useRef({});
    const gridColumnApi = useRef({});
    const [isGridReady, setIsGridReady] = useState(false);
    const varSorting = useSelector((s) => s.MilestoneSorting || []);
    const varFilters = useSelector((s) => s.MilestonesFilters || {});
    const [MilestonesColDef, setMilestonesColDef] = useState([]);
    // const MilestonesColDef = useSelector((s) => s.MilestonesColDef || []);
    const tmpColDef = useSelector((s) => s.MilestonesColDef || []);

    const columnGrpPref = useSelector((s) => s.MilestonesGrpColPref);
    const columnPref = useSelector((s) => s.MilestonesColPref);

    const MilestonesData = useSelector((s) => s.MilestonesData);
    const [data, setData] = useState([]);
    const SSNData = useSelector((s) => s.MilestonesDataSSN);
    const MilestonesHighlistCell = useSelector((s) => s.MilestonesHighlistCell);
    const [autoChangeState, setAutoChangeState] = useState(false);
    const [autoGetChangeResult, setAutoGetChangeResult] = useState({});
    const cellValueChanged = useRef([]);
    const highlightCells = useRef([]);
    let unsentCount = useSelector((s) => s.MilestonesUnsentCount || 0);
    const changedData = useRef([]);
    const selectedChngData = useRef([]);
    // const FormulaCalcColLst = useState(["Mens Spring End", "Womens Spring End", "Mens Summer End", "Womens Summer End", "Mens Fall End", "Womens Fall End", "Mens Holiday End", "Womens Holiday (CNY) End", "Womens Holiday (Non-CNY) End"]);
    const FormulaCalcColLst = useSelector((s) => s.formulaCalcColLst[0][tbl_name]);
    const setResetFilter = (s) => {
        gridApi.current.applyColumnState({ state: s === "reset" ? null : varSorting });
        gridApi.current.setFilterModel(s === "reset" ? null : varFilters);
    };

    const valueFormatter = (params) => {
        const { value } = params;
        // console.log("valueFormatter -> params -> ", params);
        // console.log("valueFormatter -> value -> ", value);
        if (Array.isArray(value)) {
            return value
                .filter(item => item !== null & item !== undefined && item.trim() !== '') // Discard null, undefined, and blank values
                .join(", ");
        }
        return value;
    };

    const valueParser = (params) => {
        const { newValue } = params;
        // console.log("valueParser -> params -> ", params);
        // console.log("valueParser -> newValue -> ", newValue);
        if (newValue === undefined || newValue === null || newValue === "") {
            return null;
        }
        return params.newValue.split(",");
    };

    useEffect(() => {
        // console.log("FormulaCalcColLst -> ", FormulaCalcColLst);
        // console.log("tmpColDef -> ", tmpColDef);
        if (tmpColDef && tmpColDef.length > 0) {
            let temp = tmpColDef;
            temp = temp.map((col) => {
                let mpCol = col
                mpCol.suppressColumnsToolPanel = col.hide;
                mpCol.suppressFiltersToolPanel = col.hide;

                // console.log("mpCol -> ", mpCol);
                if (mpCol.field.includes('Spring Start')) {
                    mpCol.headerName = 'Spring'
                } else if (mpCol.field.includes('Spring End')) {
                    mpCol.headerName = 'Spring (Days Remaining)'
                } else if (mpCol.field.includes('Summer Start')) {
                    mpCol.headerName = 'Summer'
                } else if (mpCol.field.includes('Summer End')) {
                    mpCol.headerName = 'Summer (Days Remaining)'
                } else if (mpCol.field.includes('Fall Start')) {
                    mpCol.headerName = 'Fall'
                } else if (mpCol.field.includes('Fall End')) {
                    mpCol.headerName = 'Fall (Days Remaining)'
                } else if (mpCol.field.includes('Holiday Start')) {
                    mpCol.headerName = 'Holiday'
                } else if (mpCol.field.includes('Holiday End')) {
                    mpCol.headerName = 'Holiday (Days Remaining)'
                } else if (mpCol.field.includes('Holiday (CNY) Start')) {
                    mpCol.headerName = 'Holiday (CNY)'
                } else if (mpCol.field.includes('Holiday (CNY) End')) {
                    mpCol.headerName = 'Holiday (CNY) (Days Remaining)'
                } else if (mpCol.field.includes('Holiday (Non-CNY) Start')) {
                    mpCol.headerName = 'Holiday (Non-CNY)'
                } else if (mpCol.field.includes('Holiday (Non-CNY) End')) {
                    mpCol.headerName = 'Holiday (Non-CNY) (Days Remaining)'
                }

                if (mpCol.field.includes("Mens")) {
                    mpCol.headerClass = 'custom-header-ml-blue'
                } else if (mpCol.field.includes("Womens")) {
                    mpCol.headerClass = 'custom-header-ml-orange'
                }

                if (mpCol.field === 'Year') {
                    mpCol.rowDrag = true
                }

                if (mpCol.field === 'Ownership') {
                    mpCol.cellEditor = 'agRichSelectCellEditor'
                    mpCol.cellEditorPopup = true
                    mpCol.valueFormatter = valueFormatter
                    mpCol.valueParser = valueParser
                    mpCol.cellEditorParams = {
                        values: groups,
                        multiSelect: true
                    }
                }

                // if (mpCol.field === 'seq_no') {
                //     mpCol.hide = false
                // }

                mpCol.cellStyle = params => { return funCellStyle(params, col) }

                if ((mpCol.field.includes("Mens") || mpCol.field.includes("Womens")) && mpCol.field.includes("Start")) {
                    mpCol.cellEditorSelector = (params) => {
                        if (!params.data["Ownership"] || params.data["Ownership"].length === 0) {
                            return {
                                component: 'agTextCellEditor'
                            };
                        }
                        return {
                            component: 'Datepicker'
                        };
                    }
                }

                return mpCol;
            });
            // console.log("temp -> ", temp);
            setMilestonesColDef(generateColumnGrouping(colGrpMilestones, temp));
        }
        changedData.current = []
        selectedChngData.current = []
        cellValueChanged.current = []

        // console.log("MilestonesData -> ", MilestonesData);

        const parseOwnership = (data) => {
            return data.map(item => ({
                ...item,
                Ownership:
                    item.Ownership === null || item.Ownership === undefined || item.Ownership === ''
                        ? []
                        : typeof item.Ownership === 'string'
                            ? item.Ownership.split(',').map(owner => owner.trim())
                            : Array.isArray(item.Ownership)
                                ? item.Ownership
                                : []
            }));
        };

        const parsedMilestonesData = parseOwnership(MilestonesData);
        // console.log("parsedMilestonesData -> ", parsedMilestonesData);
        setData(parsedMilestonesData);
    }, [MilestonesData]);
    useEffect(() => {
        // console.log("useEffect -> MilestonesHighlistCell -> ", MilestonesHighlistCell);
        highlightCells.current = MilestonesHighlistCell;
        if (isGridReady) gridApi.current.refreshCells({ force: true })
    }, [MilestonesHighlistCell]);
    const funCellStyle = (params, col) => {
        if (isGridReady && gridColumnApi?.current?.isPivotMode()) return;
        if (!params?.node?.data && !params?.node?.data?.row_id) return;
        const value = params.value ? params.value : "";
        // console.log("funCellStyle -> value -> ", value);
        let bgColor = "";
        let bdrColor = "";
        let txtDeco = "";
        let fontColor = "#000000";
        let boldTxt = "normal";
        let borderBottom = "";
        let fontStyle = "normal";
        const customCode = getColumnStyle(tmpColDef, value, params, col);
        // console.log("funCellStyle -> col.field -> ", col.field);
        // console.log("funCellStyle -> customCode -> ", customCode);
        if (customCode.status) {
            bgColor = customCode.bgColor;
            bdrColor = customCode.bdrColor;
            txtDeco = customCode.txtDeco;
            fontColor = customCode.fontColor;
            boldTxt = customCode.boldTxt;
            borderBottom = customCode.borderBottom;
            fontStyle = customCode.fontStyle;
        } else {
            const editable = isEditable(params, col.field, col.editable, col.insert_editable);
            if (editable) bgColor = "#FFFFFF";
            else bgColor = "#F0F0F0";
            if (col.type === "dropdown") bgColor = "#FDFCEC";
        }
        // let key = tbl_name + "|" + col.headerName.trim() + "|" + params.node.data.row_id
        let key = tbl_name + "|" + col.field.trim() + "|" + params.node.data.row_id
        let obj = checkHeihlight(key, highlightCells.current)
        if (obj.status === true) {
            if (obj.colorCode === 1) bgColor = "cyan";
            else if (obj.colorCode === 2) bgColor = "#d5a7f2";
            else if (obj.colorCode === 3) bdrColor = "#2196f3";
            else if (obj.colorCode === 4) bdrColor = "#9B6155";
            else if (obj.colorCode === 5) bgColor = "#CAE2F9";
        }
        if (params.node.rowPinned === "bottom" && params.node.rowIndex === 0) { bgColor = "#C7F9CC"; boldTxt = "bold"; txtDeco = "" }
        if (params.node.rowPinned === "bottom" && params.node.rowIndex === 1) { bgColor = "#FBF8CC"; boldTxt = "bold"; txtDeco = "" }
        if (bdrColor !== "")
            return { backgroundColor: bgColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor, borderColor: bdrColor, fontStyle: fontStyle }
        else
            return { backgroundColor: bgColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor, borderBottomColor: borderBottom, fontStyle: fontStyle }
    };
    const onSortChanged = () => {
        const colState = gridApi.current.getColumnState();
        const savedSortState = colState
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            });
        dispatch({ type: "SET_MILESTONES_SORTING", data: savedSortState });
    };
    const onFilterChanged = () => {
        const savedFilterModel = gridApi.current.getFilterModel();
        dispatch({ type: "SET_MILESTONES_FILTERS", data: savedFilterModel });
    };
    const resteAutoChangeState = () => {
        setResetFilter("set");
        setAutoChangeState(false);
        setAutoGetChangeResult({});
    };
    const setCellValueChanged = (data) => {
        // console.log("setCellValueChanged -> data -> ", data);
        cellValueChanged.current = data;
    };
    const removeHeihlight = () => {
        highlightCells.current = [];
        if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true })
    };
    useImperativeHandle(ref, () => ({
        validateSendData: () => {
            if (!MilestonesData) return false
            const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current, gridColumnApi.current);
            // console.log("res -> ", res)
            if (res.status === false) {
                highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
                gridApi.current.refreshCells({ force: true })
                return (res)
            }
            removeHeihlight();
            cellValueChanged.current = [];
            return ({ status: true })
        },
        getChangesFunc: async (masterData, metaData) => {
            const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, tmpColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
            if (res.status) {
                highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
                dispatch({ type: "MILESTONES_DATA", data: res.data });
            } else {
                toastMessage(res.msg, "warning");
            }
            return res;
        },
        getAutoChanges: (data, downloadType) => {
            changedData.current = []
            setResetFilter("reset")
            const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
            setAutoChangeState(true);
            setAutoGetChangeResult(res);
            setResetFilter("set")
        }
    }));
    const fxcalc = (colnm) => {
        let retval = false;
        if (FormulaCalcColLst.includes(colnm)) retval = true;
        return retval;
    };
    const calculateWomensHolidayNonCnyEnd = (rowNode) => {
        if (!rowNode) return ""
        const node = rowNode.data;
        if (!node) return "";
        const valSplitDeadline = rowNode.data["Womens Holiday (Non-CNY) Start"] || "";
        const today = moment(new Date()).format("MM/DD/YYYY");
        const todayAsExcel = JsDateToExcelDateNumber(today);
        let res = valSplitDeadline === "" ? "" : JsDateToExcelDateNumber(valSplitDeadline) - todayAsExcel;
        return res;
    };
    const calculateMensHolidayEnd = (rowNode) => {
        if (!rowNode) return ""
        const node = rowNode.data;
        if (!node) return "";
        const valSplitDeadline = rowNode.data["Mens Holiday Start"] || "";
        const today = moment(new Date()).format("MM/DD/YYYY");
        const todayAsExcel = JsDateToExcelDateNumber(today);
        let res = valSplitDeadline === "" ? "" : JsDateToExcelDateNumber(valSplitDeadline) - todayAsExcel;
        return res;
    };
    const calculateWomensHolidayCnyEnd = (rowNode) => {
        if (!rowNode) return ""
        const node = rowNode.data;
        if (!node) return "";
        const valSplitDeadline = rowNode.data["Womens Holiday (CNY) Start"] || "";
        const today = moment(new Date()).format("MM/DD/YYYY");
        const todayAsExcel = JsDateToExcelDateNumber(today);
        let res = valSplitDeadline === "" ? "" : JsDateToExcelDateNumber(valSplitDeadline) - todayAsExcel;
        return res;
    };
    const calculateMensFallEnd = (rowNode) => {
        if (!rowNode) return ""
        const node = rowNode.data;
        if (!node) return "";
        const valSplitDeadline = rowNode.data["Mens Fall Start"] || "";
        const today = moment(new Date()).format("MM/DD/YYYY");
        const todayAsExcel = JsDateToExcelDateNumber(today);
        let res = valSplitDeadline === "" ? "" : JsDateToExcelDateNumber(valSplitDeadline) - todayAsExcel;
        return res;
    };
    const calculateWomensFallEnd = (rowNode) => {
        if (!rowNode) return ""
        const node = rowNode.data;
        if (!node) return "";
        const valSplitDeadline = rowNode.data["Womens Fall Start"] || "";
        const today = moment(new Date()).format("MM/DD/YYYY");
        const todayAsExcel = JsDateToExcelDateNumber(today);
        let res = valSplitDeadline === "" ? "" : JsDateToExcelDateNumber(valSplitDeadline) - todayAsExcel;
        return res;
    };
    const calculateMensSummerEnd = (rowNode) => {
        if (!rowNode) return ""
        const node = rowNode.data;
        if (!node) return "";
        const valSplitDeadline = rowNode.data["Mens Summer Start"] || "";
        const today = moment(new Date()).format("MM/DD/YYYY");
        const todayAsExcel = JsDateToExcelDateNumber(today);
        let res = valSplitDeadline === "" ? "" : JsDateToExcelDateNumber(valSplitDeadline) - todayAsExcel;
        return res;
    };
    const calculateWomensSummerEnd = (rowNode) => {
        if (!rowNode) return ""
        const node = rowNode.data;
        if (!node) return "";
        const valSplitDeadline = rowNode.data["Womens Summer Start"] || "";
        const today = moment(new Date()).format("MM/DD/YYYY");
        const todayAsExcel = JsDateToExcelDateNumber(today);
        let res = valSplitDeadline === "" ? "" : JsDateToExcelDateNumber(valSplitDeadline) - todayAsExcel;
        return res;
    };
    const calculateMensSpringEnd = (rowNode) => {
        if (!rowNode) return ""
        const node = rowNode.data;
        if (!node) return "";
        const valSplitDeadline = rowNode.data["Mens Spring Start"] || "";
        const today = moment(new Date()).format("MM/DD/YYYY");
        const todayAsExcel = JsDateToExcelDateNumber(today);
        let res = valSplitDeadline === "" ? "" : JsDateToExcelDateNumber(valSplitDeadline) - todayAsExcel;
        return res;
    };
    const calculateWomensSpringEnd = (rowNode) => {
        if (!rowNode) return ""
        const node = rowNode.data;
        if (!node) return "";
        const valSplitDeadline = rowNode.data["Womens Spring Start"] || "";
        const today = moment(new Date()).format("MM/DD/YYYY");
        const todayAsExcel = JsDateToExcelDateNumber(today);
        let res = valSplitDeadline === "" ? "" : JsDateToExcelDateNumber(valSplitDeadline) - todayAsExcel;
        return res;
    };
    const calculateEndDate = (rowNode, srcCol) => {
        // console.log("calculateEndDate -> srcCol -> ", srcCol);
        if (!rowNode) return "";
        const node = rowNode.data;
        if (!node) return "";
        const ownLst = node["Ownership"];
        if (ownLst === null || ownLst === undefined) {
            return "";
        } else {
            if (Array.isArray(ownLst)) {
                if (ownLst.length > 0) {
                    let srcVl = node[srcCol] || "";
                    if (srcVl === null || srcVl === undefined || srcVl === "" || srcVl === '-') {
                        return "";
                    } else {
                        // console.log("calculateEndDate -> srcVl -> ", srcVl);
                        srcVl = JsDateToExcelDateNumber(srcVl);
                        let isNumber = Number(srcVl) === Number(srcVl);
                        // console.log("calculateEndDate -> isNumber -> ", isNumber);
                        if (isNumber) {
                            // console.log("calculateEndDate -> IF");
                            const today = moment(new Date()).format("MM/DD/YYYY");
                            const todayAsExcel = JsDateToExcelDateNumber(today);
                            let res = (srcVl - todayAsExcel);
                            return res;
                        } else {
                            // console.log("calculateEndDate -> ELSE");
                            return "";
                        }
                    }
                } else {
                    return "";
                }
            } else {
                return "";
            }
        }
    };
    const calculateAllFormulas = () => {
        // console.log("In calculateAllFormulas");
        if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
            gridApi.current.forEachNode((event) => { calculateReactFormula(event, 'calculateAllFormulas') });
            gridApi.current.refreshCells({ force: true });
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
    };
    const calculateReactFormula = (event, from) => {
        // console.log("In calculateReactFormula -> event -> ", event);
        if (!event.data) return "";
        if (!event.data["Ownership"] || event.data["Ownership"] === "") return "";
        if (event.data["Milestone"] && event.data["Milestone"] === "Total Production Days") return "";
        // fxcalc("Mens Spring End") ? event.data["Mens Spring End"] = calculateMensSpringEnd(event) : null;
        // fxcalc("Womens Spring End") ? event.data["Womens Spring End"] = calculateWomensSpringEnd(event) : null;
        // fxcalc("Mens Summer End") ? event.data["Mens Summer End"] = calculateMensSummerEnd(event) : null;
        // fxcalc("Womens Summer End") ? event.data["Womens Summer End"] = calculateWomensSummerEnd(event) : null;
        // fxcalc("Mens Fall End") ? event.data["Mens Fall End"] = calculateMensFallEnd(event) : null;
        // fxcalc("Womens Fall End") ? event.data["Womens Fall End"] = calculateWomensFallEnd(event) : null;
        // fxcalc("Mens Holiday End") ? event.data["Mens Holiday End"] = calculateMensHolidayEnd(event) : null;
        // fxcalc("Womens Holiday (CNY) End") ? event.data["Womens Holiday (CNY) End"] = calculateWomensHolidayCnyEnd(event) : null;
        // fxcalc("Womens Holiday (Non-CNY) End") ? event.data["Womens Holiday (Non-CNY) End"] = calculateWomensHolidayNonCnyEnd(event) : null;

        fxcalc("Mens Spring End") ? event.data["Mens Spring End"] = calculateEndDate(event, "Mens Spring Start") : null;
        fxcalc("Womens Spring End") ? event.data["Womens Spring End"] = calculateEndDate(event, "Womens Spring Start") : null;
        fxcalc("Mens Summer End") ? event.data["Mens Summer End"] = calculateEndDate(event, "Mens Summer Start") : null;
        fxcalc("Womens Summer End") ? event.data["Womens Summer End"] = calculateEndDate(event, "Womens Summer Start") : null;
        fxcalc("Mens Fall End") ? event.data["Mens Fall End"] = calculateEndDate(event, "Mens Fall Start") : null;
        fxcalc("Womens Fall End") ? event.data["Womens Fall End"] = calculateEndDate(event, "Womens Fall Start") : null;
        fxcalc("Mens Holiday End") ? event.data["Mens Holiday End"] = calculateEndDate(event, "Mens Holiday Start") : null;
        fxcalc("Womens Holiday (CNY) End") ? event.data["Womens Holiday (CNY) End"] = calculateEndDate(event, "Womens Holiday (CNY) Start") : null;
        fxcalc("Womens Holiday (Non-CNY) End") ? event.data["Womens Holiday (Non-CNY) End"] = calculateEndDate(event, "Womens Holiday (Non-CNY) Start") : null;
    };
    const onRowDragEnd = useCallback((event) => {
        const updatedData = gridApi.current.getModel().rowsToDisplay.map((row, index) => ({
            ...row.data,
            seq_no: index + 1,
        }));

        let UpdateJsonData = '';
        let UpdateJson = '';
        let FinalStr = '';
        // console.log("onRowDragEnd -> updatedData -> ", updatedData);

        updatedData.map((data) => {
            UpdateJsonData = '';
            UpdateJsonData = UpdateJsonData + CH6 + "seq_no" + CH7 + CH1 + CH6 + data["seq_no"] + CH7 + CH4;
            if (UpdateJsonData.length > 0) {
                UpdateJsonData = UpdateJsonData.substring(0, UpdateJsonData.length - 1)
                UpdateJson = UpdateJson + CH2 + data.row_id + CH3 + UpdateJsonData
            }
        })
        if (UpdateJson.length > 0) {
            UpdateJson = UpdateJson.substring(1, UpdateJson.length);
            FinalStr = "t1" + CH1 + sessionStorage.getItem('email') + CH1 + tbl_name + CH5 + 'NoData' + CH5 + UpdateJson + CH5 + 'NoData' + CH5 + 'NoData' + CH5 + 'NoData' + CH5 + 'NoData'
        }
        // console.log("UpdateJson", UpdateJson);
        console.log("Milestones -> onRowDragEnd -> FinalStr", FinalStr);

        // Send only seq_no column value like "Highlight Merch" {no any msg}
        if (FinalStr === "NoAnyDataChangesFound") {
        } else {
            const apiNm = "SendUserDataOptimized"
            fetch(restApiPath + apiNm, {
                method: 'POST',
                headers: {
                    'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: (FinalStr)
            })
                .then(result => result.json())
                .then(resData => processingMilestonesSendData(resData))
        }
    }, []);
    const processingMilestonesSendData = (result) => {
        if (result.error === "invalid_token") {
            toastMessage("Session Expired", "warning");
            sessionStorage.clear();
            setTimeout(() => {
                nevigate("/login");
            }, 5000);
        } else {
            if (result.response === true) {
                let gridRowData = [];
                gridApi.current.forEachNode(node => gridRowData.push(node.data));
                setSessionDataByTblName(tbl_name, JSON.parse(JSON.stringify(gridRowData)));
                setStateDataByTblName(tbl_name, JSON.parse(JSON.stringify(gridRowData)));
                let sdCounts = JSON.parse(result.count);
                console.log("processingMilestonesSendData -> sdCounts -> ", sdCounts);
                sessionStorage.setItem(tbl_name + '_' + 'Latest_Tx_Id_On_Demand_Download', sessionStorage.getItem("Send_Data_Tx_Id_Rng"));
                sessionStorage.setItem(tbl_name + '_' + 'Latest_Tx_Id', sessionStorage.getItem("Send_Data_Tx_Id_Rng"));
                sessionStorage.setItem(tbl_name + '_' + 'On_Demand_Download_Time', new Date());
                sessionStorage.setItem(tbl_name + '_' + 'Cell_Updates_send', sdCounts[0].updated_cells_cnt);
                sessionStorage.setItem(tbl_name + '_' + 'New_Rows_send', sdCounts[0].new_rows_cnt);
                sessionStorage.setItem(tbl_name + '_' + 'Delete_Rows_send', sdCounts[0].deleted_rows_cnt);
                sessionStorage.setItem(tbl_name + "_Last_Send_Time", formatDateToMmDdYyyyHhMmSsAmPm(new Date()));
                gridApi.current.clearFocusedCell();
                toastMessage("Milestone resequenced successfully.", "success");
            }
        }
    };
    const onGridReady = (params) => {
        gridApi.current = params.api
        gridColumnApi.current = params.columnApi;
        setCurrentGridApi(params);
        calculateAllFormulas();
        setIsGridReady(true);
    };
    return (
        <>
            <CustomAgGrid
                ref={gridRef}
                setCurrentGridApi={onGridReady}
                filters={varFilters}
                tab_name={tab_name}
                tbl_name={tbl_name}
                columnDefs={tmpColDef}
                customColumnDef={MilestonesColDef}
                // rowData={MilestonesData}
                rowData={data}
                SSNData={SSNData}
                tblHighlightCells={MilestonesHighlistCell}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                autoChangeState={autoChangeState}
                resteAutoChangeState={resteAutoChangeState}
                autoGetChangeResult={autoGetChangeResult}
                setCellValueChanged={setCellValueChanged}
                isGrouppedHeader={true}
                calculateFormula={(event) => calculateReactFormula(event, 'ChangeEvent')}
                rowDragManaged={true}
                onRowDragEnd={onRowDragEnd}
                columnGrpPref={columnGrpPref}
                columnPref={columnPref}
            />
        </>
    )
});
Milestones.propTypes = {
    setCurrentGridApi: PropTypes.func.isRequired,
};
export default Milestones;