import React, { useState } from 'react';
import { Box, Typography, Collapse, IconButton, List, ListItem, ListItemText, Divider } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomToast = ({ title, details }) => {
  const [expanded, setExpanded] = useState(true);
  const showExpandButton = details.Items.length > 2;

  return (
    <Box sx={{ maxWidth: 250, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1, p: 1 }}>
      <Typography variant="subtitle2" fontWeight="bold" sx={{ fontSize: '0.9rem' }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ fontSize: '0.8rem', mt: 0.5 }}>
        {details.Summary}
      </Typography>
      <Divider sx={{paddingTop: "1px", paddingBottom: "1px"}} />
      {showExpandButton && (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
          <IconButton
            onClick={() => setExpanded(!expanded)}
            size="small"
            sx={{ p: 0, mr: 0.5 }}
          >
            {expanded ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
          </IconButton>
          <Typography variant="body2" sx={{ fontSize: '0.75rem', color: 'primary.main' }}>
            {expanded ? 'Show less' : 'Show more'}
          </Typography>
        </Box>
      )}
      <Collapse in={expanded || !showExpandButton}>
        <List dense disablePadding sx={{ mt: 0.5, maxHeight: "100px", overflow: "hidden", overflowY: "auto" }}>
          {details.Items.map((item, index) => (
            <ListItem key={index} disableGutters sx={{ py: 0.5 }}>
              <Box sx={{ width: 6, height: 6, backgroundColor: '#007FFF', borderRadius: '50%', mr: 1 }} />
              <ListItemText
                primary={item}
                primaryTypographyProps={{
                  variant: 'body2',
                  sx: { fontSize: '0.75rem' }
                }}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};

export default CustomToast;