


import { CH1, ExcelDateToJsDate, executeDataVerification, toastMessage } from 'layouts/linelist/XL_Utils';
import { GetAutoChanges, compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_faherty_buy_sheet_master_data_charts";
const rng_name = "BuySht_Master_Data_Charts_Rng";


const BuyShtMasterDataCharts = forwardRef(({
  setCurrentGridApi,
  settimeLineDataFun, setActionItemDataFun, clearFilterBtnCall }, ref) => {

  const dispatch = useDispatch(); //to update redux state variable
  // let allCol = useSelector(s => s.testColLst[0][tbl_name]);

  const BuyShtMasterDataChartsData = useSelector(state => state.BuyShtMasterDataChartsData);
  const SSNData = useSelector((s) => s.BuyShtMasterDataChartsDataSSN);
  let unsentCount = useSelector(s => s.BuyShtMasterDataChartsUnsentCount || 0);

  const BuyShtMasterDataChartsHighlightCell = useSelector(state => state.BuyShtMasterDataChartsHighlistCell);
  const tmpColDef = useSelector(state => state.BuyShtMasterDataChartsColDef ? state.BuyShtMasterDataChartsColDef : []);
  const columnGrpPref = useSelector((s) => s.BuyShtMasterDataChartsGrpColPref);
	const columnPref = useSelector((s) => s.BuyShtMasterDataChartsColPref);

  const BuyShtMasterDataChartsSorting = useSelector(state => state.BuyShtMasterDataChartsSorting ? state.BuyShtMasterDataChartsSorting : []);
  const BuyShtMasterDataChartsFilters = useSelector(state => state.BuyShtMasterDataChartsFilters ? state.BuyShtMasterDataChartsFilters : {});

  const highlightCells = useRef([]);
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const cellValueChanged = useRef([]);

  const msgArr = useRef([]);

  const [isGridReady, setIsGridReady] = useState(false);
  const [autoChangeState, setAutoChangeState] = useState(false)
  const [autoGetChangeResult, setAutoGetChangeResult] = useState({});

  const setResetFilter = (setReset) => {
    let sorting = BuyShtMasterDataChartsSorting
    let filtering = BuyShtMasterDataChartsFilters

    if (setReset === "reset") {
      sorting = null
      filtering = null
    }
    gridApi.current.applyColumnState({
      state: sorting
    })
    gridApi.current.setFilterModel(filtering);

  }
  //Required for all tabs (For before send data functionalities)
  useImperativeHandle(ref, () => ({
    validateSendData: () => {
      if (!BuyShtMasterDataChartsData) return false
      const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
      if (res.status === false) {
        highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
        gridApi.current.refreshCells({ force: true })
        return (res)
      } else {
        const check = validateAtsLevel();

        if (!check) return (res)
      }
      removeHeihlight();
      cellValueChanged.current = [];
      return ({ status: true})
    },
    getChangesFunc: async (masterData, metaData) => {
      const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, tmpColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
      if (res.status) {
        highlightCells.current = res.highlightCells

        dispatch({ type: "BUY_SHT_SIZE_CHART_DATA", data: res.data });
        gridApi.current.refreshCells({ force: true })
      } else {
        toastMessage(res.msg, "warning");

      }
      return res
    },
    getAutoChanges: (data, downloadType) => {
      setResetFilter("reset")
      const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
      setAutoChangeState(true);
      setAutoGetChangeResult(res);
    }
  }));
  const removeHeihlight = () => {
    highlightCells.current = []
    
    if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true })
  };

  useEffect(() => {
    if (isGridReady) {
      if (BuyShtMasterDataChartsSorting !== null && BuyShtMasterDataChartsSorting !== undefined) {
        gridApi.current.applyColumnState({
          state: BuyShtMasterDataChartsSorting
        })
      }
      if (BuyShtMasterDataChartsFilters !== null && BuyShtMasterDataChartsFilters !== undefined) {
        gridApi.current.setFilterModel(BuyShtMasterDataChartsFilters);
      }
    }
  }, [isGridReady])

  
  useEffect(() => {
    if (BuyShtMasterDataChartsHighlightCell) {
     highlightCells.current = BuyShtMasterDataChartsHighlightCell;
    }
  }, [BuyShtMasterDataChartsHighlightCell])

  const onGridReady = (params) => {
    gridApi.current = params.api
    gridColumnApi.current = params.columnApi;
    setCurrentGridApi(params);
    setIsGridReady(true)
  }

  const onSortChanged = (event) => {
    const colState = gridApi.current.getColumnState();
    const savedSortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch({ type: "SET_BUY_SHT_SIZE_CHART_SORTING", data: savedSortState });
  }
  const onFilterChanged = (event) => {
    const savedFilterModel = gridApi.current.getFilterModel();
    dispatch({ type: "SET_BUY_SHT_SIZE_CHART_FILTERS", data: savedFilterModel });
  }

  const findDuplicates = (arr, keys) => {
    const occurrences = arr.reduce((acc, item) => {
      const combinedKey = keys
        .map(key => item[key]?.toString().trim().toLowerCase())
        .join('|');

      if (combinedKey && combinedKey.split('|')[1] !== '') { // Ensure name is not blank
        acc[combinedKey] = (acc[combinedKey] || 0) + 1;
      }
      return acc;
    }, {});

    return arr.filter(item => {
      const combinedKey = keys
        .map(key => item[key]?.toString().trim().toLowerCase())
        .join('|');

      return combinedKey && occurrences[combinedKey] > 1;
    });
  };
  const validateAtsLevel = () => {
    const gridData = [];

    if (BuyShtMasterDataChartsFilters && BuyShtMasterDataChartsFilters.length > 0) {
      gridApi.current.forEachNodeAfterFilter((node) => {
        gridData.push(node.data)
      })
    } else {
      gridApi.current.forEachNode((node) => {
        gridData.push(node.data)
      })
    }

    const duplicates = findDuplicates(gridData, ['Season Code', 'ATS Level']);

    let status = true;
    const arr = highlightCells.current;

    if (duplicates && duplicates.length > 0) {
      status = false;
      duplicates.map((dup) => {
        const key = tbl_name + "|ATS Level|" + dup.row_id;
        arr.push({ [key]: 2 })
      })

      highlightCells.current = arr;
      setHighlightByTblName(tbl_name, highlightCells.current)
    }
    return { status: status, msg: "Duplicate value found. Please correct data at highlighted cells." }
  }
  const onCellValueCh = (event, from = "") => {
    let eventSource = event.source ? event.source : ""; // paste | edit | rangeService    
    if (eventSource !== "") {
      const col = event.colDef.headerName;
      let oldValue = event.oldValue || "";
      const column = tmpColDef.filter((cl) => cl.headerName === col);
      if (column && column.length > 0) {
        const type = column[0].type;
        if (type === "date" && !oldValue.includes('/')) oldValue = ExcelDateToJsDate(oldValue)
      }
      if (from === "keyDown" || event.newValue !== oldValue) {
        let res = executeDataVerification(event, tbl_name, highlightCells.current, gridApi.current);
        if (res && event.column.colId.includes("ATS Level")) {
          res = validateAtsLevel();
        }
        if (res.status === false) {
          event.data[col] = event.oldValue;
          if (res && res.msg && res.msg !== "") {
            // toastMessage(res.msg);
            // setSnackBarNotiColor("warning");
            // 
            if (eventSource === "edit") {
              toastMessage(res.msg, "warning");

            }
            else {
              let aa = res.msg;
              if (!msgArr.current.includes(aa)) msgArr.current.push(aa)
            }
          }
        }
        if (res.status === true)
          if (!cellValueChanged.current.includes(res.key)) cellValueChanged.current.push(res.key);
        optiFunction(event.colDef.field, event)

        gridApi.current.refreshCells({ force: true, rowNodes: [event.node] });
        dispatch({ type: "SET_BUYSHT_MASTER_CHART_UNSENT_COUNT", count: unsentCount });
      }
    }
  };
  const optiFunction = (colNm, rowNode) => {
    let arr = highlightCells.current;
    const filteredData = SSNData.filter((i) => i.row_id === rowNode.data.row_id);
    if (filteredData.length > 0) {
      let srcVal = rowNode.data[colNm] || "";
      let trgVal = filteredData[0][colNm] || "";
      let arrVal = { [`${tbl_name}|${colNm}|${rowNode.data.row_id}`]: 3 };
      let arrKey = `${tbl_name}|${colNm}|${rowNode.data.row_id}`;
      if (srcVal.toString() !== trgVal.toString()) {
        let foundObject = arr.find(obj => obj[arrKey] === 3);
        if (foundObject === undefined) {
          arr.push(arrVal);
          unsentCount++;
        }
      } else {
        arr = arr.filter((a) => {
          if (Object.values(a)[0] === 3 && Object.keys(a)[0] === arrKey) {
            unsentCount--;
            return false;
          }
          return true;
        });
      }
      highlightCells.current = arr;
      setHighlightByTblName(tbl_name, highlightCells.current)
    }
  };
  const onPasteEnd = () => {
    var msg = "";
    if (msgArr.current.length > 0) {
      msg = msgArr.current.join(CH1)
      toastMessage(msg, "warning");

    }
    msgArr.current = [];
  };
  const onCellKeyDown = (event) => {

    if (event.event.keyCode === 46) {
      const res = executeDataVerification(event, tbl_name, highlightCells.current, gridApi.current);
      if (res.status === false) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        if (res && res.msg && res.msg !== "") {
          toastMessage(res.msg, "warning");

        }
        gridApi.current.refreshCells({ force: true, rowNodes: [event.node] });
      }
    }
  }
  const setCellValueChanged = (data) => {
    cellValueChanged.current = data;
  }
  const resteAutoChangeState = () => {
    setResetFilter("set");
    setAutoChangeState(false);
    setAutoGetChangeResult({});
  }
  return (
    <CustomAgGrid
      setCurrentGridApi={onGridReady}
      filters={BuyShtMasterDataChartsFilters}
      setActionItemDataFun={setActionItemDataFun}
      tab_name="BuySht_Master_Data_Charts"
      tbl_name={tbl_name}
      columnDefs={tmpColDef}
      rowData={BuyShtMasterDataChartsData}
      SSNData={SSNData}
      tblHighlightCells={BuyShtMasterDataChartsHighlightCell}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      autoChangeState={autoChangeState}
      resteAutoChangeState={resteAutoChangeState}
      autoGetChangeResult={autoGetChangeResult}
      setCellValueChanged={setCellValueChanged}
      removeCellValueChange={true}
      onCellValueChanged={onCellValueCh}
      onPasteEnd={onPasteEnd}
      onCellKeyDown={onCellKeyDown}
      isGrouppedHeader = {true}
      columnGrpPref={columnGrpPref}
			columnPref={columnPref}
    />
  )
})

BuyShtMasterDataCharts.propTypes = {
  setCurrentGridApi: PropTypes.func.isRequired,
  settimeLineDataFun: PropTypes.func.isRequired,
};

export default BuyShtMasterDataCharts;