export const generateChartOptions = (data, chartType, series) => {
  const baseOptions = {
    data,
    // title: { text: title || 'Custom Chart' },
    // subtitle: { text: subtitle || 'Data Visualization' },
    footnote: {
      text: "Source: Linelist"
    },
    legend: {
      item: {
        label: {
          fontSize: 10
        }
      }
    }
  };

  switch (chartType) {
    case 'bar':
      return {
        ...baseOptions,
        series: series || [
          {
            type: 'bar',
            xKey: 'label',
            yKey: 'Total DTC SLS',
            yName: 'Total DTC SLS'
          },
          {
            type: 'bar',
            xKey: 'label',
            yKey: 'Total WHSL SLS',
            yName: 'Total WHSL SLS'
          }
        ],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            label: {
              fontSize: 10
            }
          },
          {
            type: 'number',
            position: 'left',
            label: {
              fontSize: 10
            }
          }
        ]
      };
    case 'pie':
    case 'donut':
      return {
        ...baseOptions,
        series: series || [
          {
            "type": chartType,
            "calloutLabelKey": "label",
            "angleKey": "value",
            "sectorLabelKey": "value",
            "calloutLabel": {
              "fontSize": 10
            },
            "sectorLabel": {
              "color": "white"
            }
          }
        ]
      };
    case 'line':
      return {
        ...baseOptions,
        series: Object.keys(data[0])
          .filter(key => key !== 'label' && typeof data[0][key] === 'number')
          .map(key => ({
            type: 'line',
            xKey: 'label',
            yKey: key,
            title: key
          }))
      };
    default:
      return baseOptions;
  }
};
