import { executeDataVerification, toastMessage } from "layouts/linelist/XL_Utils";
import { compareAndHighLightDataChanges, GetAutoChanges } from "layouts/linelist/getChangesComman";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import CustomAgGrid from "../CustomAgGrid";
import { setHighlightByTblName } from "layouts/linelist/getChangesComman";

const tbl_name = "xl_faherty_master_lov";
const rng_name = "Master_Lov_Rng";

const MasterLovTab = forwardRef(({ setCurrentGridApi }, ref) => {
  const dispatch = useDispatch();
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const MasterLov = useSelector((s) => s.MasterLovData);
  const MasterLovCache = useSelector((s) => s.MasterLovDataSSN);
  const varHighlightCell = useSelector((s) => s.MasterLovHighlistCell);
  const varColDefs = useSelector((s) => s.MasterLovColDef || []);

  const columnGrpPref = useSelector((s) => s.MasterLOVGrpColPref);
	const columnPref = useSelector((s) => s.MasterLOVColPref);

  const varSorting = useSelector((s) => s.MasterLovSorting || []);
  const varFilters = useSelector((s) => s.MasterLovFilters || {});
  const highlightCells = useRef([]);
  const cellValueChanged = useRef([]);
  const [isGridReady, setIsGridReady] = useState(false);
  const [autoChangeState, setAutoChangeState] = useState(false);
  const [autoGetChangeResult, setAutoGetChangeResult] = useState({});
  const setResetFilter = (s) => {
    gridApi.current.applyColumnState({ state: s === "reset" ? null : varSorting });
    gridApi.current.setFilterModel(s === "reset" ? null : varFilters);
  };
  useImperativeHandle(ref, () => ({
    validateSendData: () => {
      if (!MasterLov) return false;
      const res = executeDataVerification(
        null,
        tbl_name,
        highlightCells.current,
        gridApi.current,
        gridColumnApi.current
      );
      if (res.status === false) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        return res;
      }
      cellValueChanged.current = [];
      removeHeihlight();
      return { status: true, colDef: varColDefs };
    },
    getChangesFunc: async (masterData, metaData) => {
      const res = await compareAndHighLightDataChanges(
        masterData,
        rng_name,
        tbl_name,
        varColDefs,
        gridApi.current,
        cellValueChanged.current,
        highlightCells.current,
        metaData
      );
      if (res.status) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        dispatch({ type: "MASTER_LOV_DATA", data: res.data });
        gridApi.current.refreshCells({ force: true });
      } else {
        toastMessage(res.msg, "warning");
      }
      return res;
    },
    getAutoChanges: (data, downloadType) => {
      setResetFilter("reset");
      const res = GetAutoChanges(
        data,
        highlightCells.current,
        gridApi.current,
        cellValueChanged.current,
        tbl_name,
        downloadType
      );
      setAutoChangeState(true);
      setAutoGetChangeResult(res);
    },
  }));
  const removeHeihlight = () => {
    highlightCells.current = [];
    if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true });
  };
  const onGridReady = (params) => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;
    setCurrentGridApi(params);
    setIsGridReady(true);
  };
  const onSortChanged = (event) => {
    const colState = gridApi.current.getColumnState();
    const savedSortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch({ type: "SET_MASTER_LOV_SORTING", data: savedSortState });
  };
  const onFilterChanged = (event) => {
    const savedFilterModel = gridApi.current.getFilterModel();
    dispatch({ type: "SET_MASTER_LOV_FILTERS", data: savedFilterModel });
  };
  const resteAutoChangeState = () => {
    setResetFilter("set");
    setAutoChangeState(false);
    setAutoGetChangeResult({});
  };
  const setCellValueChanged = (data) => {
    cellValueChanged.current = data;
  };
  useEffect(() => {
    if (isGridReady) {
      if (varSorting !== null && varSorting !== undefined) {
        gridApi.current.applyColumnState({ state: varSorting });
      }
      if (varFilters !== null && varFilters !== undefined) {
        gridApi.current.setFilterModel(varFilters);
      }
    }
  }, [isGridReady]);
  return (
    <CustomAgGrid
      autoChangeState={autoChangeState}
      autoGetChangeResult={autoGetChangeResult}
      columnDefs={varColDefs}
      filters={varFilters}
      isGrouppedHeader={true}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      resteAutoChangeState={resteAutoChangeState}
      rowData={MasterLov}
      setCellValueChanged={setCellValueChanged}
      setCurrentGridApi={onGridReady}
      SSNData={MasterLovCache}
      tab_name="Master_LOV"
      tbl_name={tbl_name}
      tblHighlightCells={varHighlightCell}
      columnGrpPref={columnGrpPref}
			columnPref={columnPref}
    />
  );
});

MasterLovTab.propTypes = {
  setCurrentGridApi: PropTypes.func.isRequired,
};

export default MasterLovTab;
