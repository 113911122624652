import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
// import { applicationTitle } from 'utils/app.props';
// import data from "./SampleAsText.json"
// import data from "./SampleAsNumeric.json"
// import colDefs from "./xl_faherty_sample.json"

import { generateColumnGrouping } from 'layouts/linelist/XL_Utils';
import { colGrpSummaryReport } from "layouts/linelist/colGrpVariable";
// import { Scrollbars } from 'react-custom-scrollbars';
// import clearDataFilterPic from "assets/images/clear-filter.png";
import { Button, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import { getColumnIndex, uniqueValues } from "layouts/linelist/XL_Utils";
import { groupHeaderHeight } from 'utils/app.props';
import Ag_Charts from "../Ag_Charts";
import DashboardCard from "../DashboardCard";
import { SummaryReportColDef } from "./columnDefination";

import { Box, Paper } from '@mui/material';
import { ProperCase } from "layouts/linelist/XL_Utils";
import { getStateDataByTblName } from "layouts/linelist/getChangesComman";
import _ from "lodash";
import CheckBoxList from "../CheckBoxList";
import { generateChartOptions } from "./generateChartOptions";
import { convertToNumber, prepareSummaryReportData } from "./prepareSummaryReportData";


const agdivstyl2 = {
    width: "87%",
    fontFamily: "Inter",
    position: "relative",
    height: "300px",
};

const SummaryReportTab = () => {
    const [newColDef, setNewColDef] = useState([]);
    const gridApi = useRef({});
    const gridColumnApi = useRef({});
    const SsnData = useSelector(state => state.SummaryReportAggDataSSN);
    const LinelistData = useSelector(state => state.LinelistData);

    const [filteredData, setFilteredData] = useState([]);
    const [filteredSummaryData, setFilteredSummaryData] = useState([]);
    const [pinnedBottomRow, setPinnedBottomRow] = useState(null);

    const [dashboardData, setDashboardData] = useState({});
    const [showWorkingline, setShowWorkingLine] = useState(false);

    const [season, setSeason] = useState([]);
    const [department, setDepartment] = useState([]);
    const [category, setCategory] = useState([]);
    const [seasonOptions, setSeasonOption] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);

    const [chartOption, setChartOption] = useState([]);

    const generateColumnDefination = () => {
        let tmpColDef = SummaryReportColDef;
        if (tmpColDef && tmpColDef.length > 0) {
            let temp = tmpColDef;
            temp = temp.map((col) => {
                let mpCol = col
                mpCol = {
                    suppressSpanHeaderHeight: true,
                    headerName: col.headerName,
                    field: col.field,
                    filter: col.filter,

                    width: col.width,
                    autoHeaderHeight: true,
                    resizable: col.resizable,
                    valueFormatter: col.valueFormatter,
                    suppressKeyboardEvent: params => {
                        if (params.event.ctrlKey && params.event.key === "ArrowUp") {
                            // console.log(params)
                            gridApi.current.ensureIndexVisible(0, "top");
                            const element = document.querySelector("div[col-id='" + params.column.colId + "']");
                            if (element) {
                                element.setAttribute('tabindex', '1');
                                element.focus();
                                const colElement1 = element.querySelector(".ag-header-cell-comp-wrapper");
                                const colElement2 = colElement1.querySelector(".ag-cell-label-container");
                                if (colElement2) {
                                    colElement2.focus()
                                    return true;
                                }
                            }
                            return true
                        }
                        return false
                    }
                }
                return mpCol
            })
            setNewColDef(generateColumnGrouping(colGrpSummaryReport, temp))
        }
    }

    const setSlicerValues = () => {
        const ses = uniqueValues('Season Code', LinelistData);
        const dept = uniqueValues('Department', LinelistData.filter(row => (!season.length || season.includes(row["Season Code"]))));
        const cat = uniqueValues('Category', LinelistData.filter(row => (!season.length || season.includes(row["Season Code"])) && (!department.length || department.includes(row["Department"]))));

        setSeason(ses)
        setSeasonOption(ses)

        setDepartment(dept)
        setDepartmentOptions(dept)

        setCategory(cat)
        setCategoryOptions(cat)
    }

    const calculateDataPoints = (formattedData) => {

        //get production approval = 'pending'
        const productionApproval = formattedData.filter((dt) => dt["Change Type"] && dt["Change Type"] !== "" && dt["Production Lock"] && dt["Production Lock"].toLowerCase() === "locked" &&
            dt["DTC Confirmation"] && dt["DTC Confirmation"].toLowerCase() === "confirmed" &&
            dt["Wholesale Confirmation"] && dt["Wholesale Confirmation"].toLowerCase() === "confirmed");

        //get change memo notes <> ''
        const changeEmoNotes = formattedData.filter((dt) => dt["Change Type"] && dt["Change Type"] !== "" && dt["Change Memo - Notes"] && dt["Change Memo - Notes"] !== "");

        //get count of current fob status
        const fobStatusWorking = formattedData.filter((dt) => dt["Current FOB Status"] && dt["Current FOB Status"].toLowerCase() === "working");
        const fobStatusConfirmed = formattedData.filter((dt) => dt["Current FOB Status"] && dt["Current FOB Status"].toLowerCase() === "confirmed");

        // DTC buysheet and Whsl busheet data points
        const dtcData = getStateDataByTblName("xl_faherty_dtc_buy_sheet");
        const whslData = getStateDataByTblName("xl_faherty_whsl_buy_sheet");

        let workingLineDtc = 0;
        let workingLineWhsl = 0;

        if (dtcData.length > 0) {
            const filter = dtcData.filter((dt) => dt["Style Activity"] && dt["Style Activity"].toUpperCase() === "ACTIVE");
            workingLineDtc = filter.filter((dt) => dt["Working Line"] && dt["Working Line"].toLowerCase() === "working")
        }

        if (whslData.length > 0) {
            const filter = whslData.filter((dt) => dt["Style Activity"] && dt["Style Activity"].toUpperCase() === "ACTIVE");
            workingLineWhsl = filter.filter((dt) => dt["Working Line"] && dt["Working Line"].toLowerCase() === "working")
        }

        const tmp = {
            production_approval_pending: productionApproval.length,
            change_memo_total: changeEmoNotes.length,
            fob_status_working: fobStatusWorking.length,
            fob_status_confirmed: fobStatusConfirmed.length,
            style_activity_active: formattedData.length,
            style_activity_dropped: LinelistData.length - formattedData.length,
            dtc_working_count: workingLineDtc,
            whsl_working_count: workingLineWhsl
        };

        setDashboardData(tmp)
    }
    const generateLinelistTotalSalesChart = (formattedData) => {
        // Group by 'category'
        const groupedData = _.groupBy(formattedData, 'Category');

        // Group and sum for 'value1'
        const group1 = _.mapValues(groupedData, group => ({
            total_dtc: _.sumBy(group, "Total DTC SLS $")
        }));

        // Group and sum for 'value2'
        const group2 = _.mapValues(groupedData, group => ({
            total_whsl: _.sumBy(group, "Total WHSL SLS $")
        }));

        // Combine the two results
        const finalResult = _.map(Object.keys(group1), category => ({
            "Category": category,
            "Total DTC SLS": group1[category].total_dtc,
            "Total WHSL SLS": group2[category].total_whsl
        }));

        const customSeries = [
            { "type": "bar", "xKey": "Category", "yKey": "Total DTC SLS", "yName": "Total DTC SLS" },
            { "type": "bar", "xKey": "Category", "yKey": "Total WHSL SLS", "yName": "Total WHSL SLS" }
        ];

        const options = generateChartOptions(finalResult, "bar", customSeries);

        return { options: options, title: "Total Sales", formatTooltip: true, xs: 6 }
    }

    const generateLinelistTotalConfirmChart = (formattedData) => {
        const totalDtcConfirm = formattedData.filter((dt) => dt["DTC Confirmation"] && dt["DTC Confirmation"].toLowerCase() === "confirmed")
        const totalWhslConfirm = formattedData.filter((dt) => dt["Wholesale Confirmation"] && dt["Wholesale Confirmation"].toLowerCase() === "confirmed")
        const totalConfirm = formattedData.filter((dt) => dt["DTC Confirmation"] && dt["DTC Confirmation"].toLowerCase() !== "confirmed" &&
            dt["Wholesale Confirmation"] && dt["Wholesale Confirmation"].toLowerCase() !== "confirmed")

        const finalResult = [
            {
                "label": "DTC Confirmation",
                "value": totalDtcConfirm.length
            },
            {
                "label": "Wholesale Confirmation",
                "value": totalWhslConfirm.length
            },
            {
                "label": "Other",
                "value": totalConfirm.length
            }
        ]
        const options = generateChartOptions(finalResult, "pie");
        return { options: options, title: "Confirmation Variance", formatTooltip: false, xs: 6 }
    }

    const generateLinelistDaysLateChart = (formattedData) => {
        const late = formattedData.filter((dt) => dt["Days Late"] < 0)
        const early = formattedData.filter((dt) => dt["Days Late"] > 0)
        const ontime = formattedData.filter((dt) => dt["Days Late"] === 0)

        const finalResult = [
            {
                "label": "Late",
                "value": late.length
            },
            {
                "label": "Early",
                "value": early.length
            },
            {
                "label": "On Time",
                "value": ontime.length
            }
        ]
        const options = generateChartOptions(finalResult, "pie");
        return { options: options, title: "Days Status", formatTooltip: false, xs: 4 }
    }

    const generateLinelistItemUploadStatusLateChart = (formattedData) => {
        const planning = formattedData.filter((dt) => dt["Item Upload Status"] && dt["Item Upload Status"].toLowerCase() === "planning")
        const readyForUpload = formattedData.filter((dt) => dt["Item Upload Status"] && dt["Item Upload Status"].toLowerCase() === "ready for upload")
        const upload = formattedData.filter((dt) => dt["Item Upload Status"] && dt["Item Upload Status"].toLowerCase() === "uploaded")
        const total = planning.length + readyForUpload.length + upload.length;

        const finalResult = [
            {
                "label": "Planning",
                "value": planning.length
            },
            {
                "label": "Ready For Upload",
                "value": readyForUpload.length
            },
            {
                "label": "Upload",
                "value": upload.length
            }
        ]

        const customSeries = [
            {
                "type": "donut",
                "calloutLabelKey": "label",
                "angleKey": "value",
                "calloutLabel": {
                    "fontSize": 10
                },
                "innerRadiusRatio": 0.75,
                "innerLabels": [
                    {
                        "text": "Total",
                        "fontWeight": "bold",
                        "fontSize": 10
                    },
                    {
                        "text": `${total}`,
                        "fontSize": 10
                    }
                ]
            }
        ]
        const options = generateChartOptions(finalResult, "donut", customSeries);
        return { options: options, title: "Item Upload Status", formatTooltip: false, xs: 4 }
    }

    const generateLinelistDeliveryStatusChart = (formattedData) => {
        const planning = formattedData.filter((dt) => dt["Delivery Status"] && dt["Delivery Status"].toLowerCase() === "planning")
        const readyForUpload = formattedData.filter((dt) => dt["Delivery Status"] && dt["Delivery Status"].toLowerCase() === "requested")
        const upload = formattedData.filter((dt) => dt["Delivery Status"] && dt["Delivery Status"].toLowerCase() === "confirmed x-fty")
        const total = planning.length + readyForUpload.length + upload.length;

        const finalResult = [
            {
                "label": "Planning",
                "value": planning.length
            },
            {
                "label": "Requested",
                "value": readyForUpload.length
            },
            {
                "label": "Confirmed X-Fty",
                "value": upload.length
            }
        ]

        const customSeries = [
            {
                "type": "donut",
                "calloutLabelKey": "label",
                "angleKey": "value",
                "calloutLabel": {
                    "fontSize": 10
                },
                "innerRadiusRatio": 0.75,
                "innerLabels": [
                    {
                        "text": "Total",
                        "fontWeight": "bold",
                        "fontSize": 10
                    },
                    {
                        "text": `${total}`,
                        "fontSize": 10
                    }
                ]
            }
        ]
        const options = generateChartOptions(finalResult, "donut", customSeries);
        return { options: options, title: "Delivery Status", formatTooltip: false, xs: 4 }
    }

    const generateChasePOPlanningChart = () => {
        const chaseData = getStateDataByTblName("xl_faherty_chase");

        //apply filter based on slicer
        let data = JSON.parse(JSON.stringify(chaseData));
        const columnsToUpdate = ['Season Code', 'Department', 'Category'];
        data = _.map(data, (row) => {
            // Use _.mapValues to iterate over each row and modify the selected columns
            return _.mapValues(row, (value, key) => {
                if (columnsToUpdate.includes(key) && (value === null || value === undefined)) {
                    return ''; // Set null or undefined to blank
                }
                return value; // Keep other values as is
            });
        });
        if (season.length) {
            data = data.filter(row =>
                season.map(s => s.toLowerCase()).includes((row["Season Code"] || 'BLANK').toLowerCase())
            );
        }

        if (department.length) {
            data = data.filter(row =>
                department.map(dep => dep.toLowerCase()).includes((row["Department"] || 'BLANK').toLowerCase())
            );
        }

        if (category.length) {
            data = data.filter(row =>
                category.map(cat => cat.toLowerCase()).includes((row["Category"] || 'BLANK').toLowerCase())
            );
        }

        const filter = data.filter((dt) => dt["Style Activity"] && dt["Style Activity"].toUpperCase() === "ACTIVE");
        const poPlanning = filter.filter((dt) => dt["PO Status"] && dt["PO Status"].toLowerCase() === "planning")
        const poReady = filter.filter((dt) => dt["PO Status"] && dt["PO Status"].toLowerCase() === "ready")
        const poGenerated = filter.filter((dt) => dt["PO Status"] && dt["PO Status"].toLowerCase() === "generated")

        if (poPlanning.length === 0 && poReady.length === 0 && poGenerated.length === 0) return null;

        // Combine the two results
        const chartData = [
            { "label": "PO Generated", "value": poGenerated },
            { "label": "PO Planning", "value": poPlanning },
            { "label": "PO Ready", "value": poReady }]

        const customSeries = [
            { "type": "bar", "xKey": "label", "yKey": "value", "yName": "Total" },
        ];

        const options = generateChartOptions(chartData, "bar", customSeries);

        return { options: options, title: "Category Wise PO Status", formatTooltip: false, xs: 4 }
    }

    const generateSizingPOPlanningChart = () => {
        const sizigData = getStateDataByTblName("xl_faherty_sizing");

        //apply filter based on slicer
        let data = JSON.parse(JSON.stringify(sizigData));
        const columnsToUpdate = ['Season Code', 'Department', 'Category'];
        data = _.map(data, (row) => {
            // Use _.mapValues to iterate over each row and modify the selected columns
            return _.mapValues(row, (value, key) => {
                if (columnsToUpdate.includes(key) && (value === null || value === undefined)) {
                    return ''; // Set null or undefined to blank
                }
                return value; // Keep other values as is
            });
        });
        if (season.length) {
            data = data.filter(row =>
                season.map(s => s.toLowerCase()).includes((row["Season Code"] || 'BLANK').toLowerCase())
            );
        }

        if (department.length) {
            data = data.filter(row =>
                department.map(dep => dep.toLowerCase()).includes((row["Department"] || 'BLANK').toLowerCase())
            );
        }

        if (category.length) {
            data = data.filter(row =>
                category.map(cat => cat.toLowerCase()).includes((row["Category"] || 'BLANK').toLowerCase())
            );
        }

        const filter = data.filter((dt) => dt["Style Activity"] && dt["Style Activity"].toUpperCase() === "ACTIVE");
        const poPlanning = filter.filter((dt) => dt["PO Status"] && dt["PO Status"].toLowerCase() === "planning")
        const poReady = filter.filter((dt) => dt["PO Status"] && dt["PO Status"].toLowerCase() === "ready")
        const poGenerated = filter.filter((dt) => dt["PO Status"] && dt["PO Status"].toLowerCase() === "generated")

        if (poPlanning.length === 0 && poReady.length === 0 && poGenerated.length === 0) return null;

        // Combine the two results
        const chartData = [
            { "label": "PO Generated", "value": poGenerated },
            { "label": "PO Planning", "value": poPlanning },
            { "label": "PO Ready", "value": poReady }]

        const customSeries = [
            { "type": "bar", "xKey": "label", "yKey": "value", "yName": "Total" },
        ];

        const options = generateChartOptions(chartData, "bar", customSeries);

        return { options: options, title: "Category Wise PO Status", formatTooltip: false, xs: 4 }
    }

    useEffect(() => {
        // generate chart data
        const columnsToFetch = ["Total DTC SLS $", "Total WHSL SLS $", "DTC Confirmation", "Wholesale Confirmation", "Days Late", "Item Upload Status", "Delivery Status", "Category",
            "Production Lock", "Change Type", "Change Memo - Notes", "Current FOB Status"];
        const tmpData = [...filteredData];
        const filterData = tmpData.filter((dt) => dt["Style Activity"] && dt["Style Activity"].toUpperCase() === "ACTIVE");

        const result = _.map(filterData, (item) => _.pick(item, columnsToFetch));

        // Convert categories to camel case
        const formattedData = result.map(item => ({
            ...item,
            "Category": ProperCase(item["Category"]),
            "Total DTC SLS $": convertToNumber(item["Total DTC SLS $"]),
            "Total WHSL SLS $": convertToNumber(item["Total WHSL SLS $"]),
            "Days Late": convertToNumber(item["Days Late"])
        }));

        calculateDataPoints(formattedData);
        const chart1 = generateLinelistTotalSalesChart(formattedData);
        const chart2 = generateLinelistTotalConfirmChart(formattedData);
        const chart3 = generateLinelistDaysLateChart(formattedData);
        const chart4 = generateLinelistItemUploadStatusLateChart(formattedData);
        const chart5 = generateLinelistDeliveryStatusChart(formattedData);
        const chart6 = generateChasePOPlanningChart();
        const chart7 = generateSizingPOPlanningChart();

        const tmpCh = [chart1, chart2, chart3, chart4, chart5];

        if (chart6) tmpCh.push(chart6);
        if (chart7) tmpCh.push(chart7);

        setChartOption(tmpCh);
    }, [filteredData]);

    useEffect(() => {
        // generate summary column defination
        generateColumnDefination();
        
        //set slicer values
        setSlicerValues();

    }, []);
    const onGridReady = (params) => {
        gridApi.current = params.api
        gridColumnApi.current = params.columnApi;

        try {
            const cols = SummaryReportColDef.filter((col) => !col.hide);
            const firstColumnm = cols[0];
            gridApi.current.setFocusedCell(0, firstColumnm.colId);
            gridApi.current.ensureColumnVisible(firstColumnm.colId);
        } catch (e) { console.log(e) }

        // setCurrentGridApi(params)
    };
    const getRowStyle = (params) => {
        if (params.node.rowPinned === 'bottom') {
            return { "font-weight": "bold", "border-top": "1px solid #4472C4", "border-bottom": "2px solid #4472C4" }
        } else {
            return null
        }
    };

    const applyFilters = () => {
        //apply filter on Linelist data
        const tmp = LinelistData.filter((dt) => dt["Style Activity"] && dt["Style Activity"].toUpperCase() === "ACTIVE");
        const fd = JSON.parse(JSON.stringify(tmp));

        let data = fd;

        const columnsToUpdate = ['Season Code', 'Department', 'Category'];
        data = _.map(data, (row) => {
            // Use _.mapValues to iterate over each row and modify the selected columns
            return _.mapValues(row, (value, key) => {
                if (columnsToUpdate.includes(key) && (value === null || value === undefined)) {
                    return ''; // Set null or undefined to blank
                }
                return value; // Keep other values as is
            });
        });

        if (season.length) {
            data = data.filter(row =>
                season.map(s => s.toLowerCase()).includes((row["Season Code"] || 'BLANK').toLowerCase())
            );
        }

        if (department.length) {
            data = data.filter(row =>
                department.map(dep => dep.toLowerCase()).includes((row["Department"] || 'BLANK').toLowerCase())
            );
        }

        if (category.length) {
            data = data.filter(row =>
                category.map(cat => cat.toLowerCase()).includes((row["Category"] || 'BLANK').toLowerCase())
            );
        }

        setFilteredData(data);

        //apply filter on Summary_Tab data
        const newSummaryData = prepareSummaryReportData(data);
        
        setPinnedBottomRow(newSummaryData[newSummaryData.length - 1]);
        setFilteredSummaryData(newSummaryData.slice(0, newSummaryData.length - 1));
    };
    const onSesonChange = (value) => {
        const dept = uniqueValues('Department', filteredData.filter(row => (!value.length || value.includes(row["Season Code"]))))
        const cat = uniqueValues('Category', filteredData.filter(row => (!value.length || value.includes(row["Season Code"])) &&
            (!dept.length || dept.includes(row["Department"]))))

        setCategory(cat);
        setDepartment(dept);
        setSeason(value);
    }
    const onDepartmentChange = (value) => {
        const cat = uniqueValues('Category', filteredData.filter(row => (!season.length || season.includes(row["Season Code"])) &&
            (!value.length || value.includes(row["Department"]))))

        setCategory(cat);
        setDepartment(value);
    }
    const onCategoryChanged = (value) => {
        // const sty = uniqueValues('Style', filteredData.filter(row => (!season.length || season.includes(row["Season Code"])) &&
        //     (!department.length || department.includes(row["Department"])) && (!value.length || value.includes(row["Category"]))))

        // setStyle(sty);
        setCategory(value);
    }
    const resetSlicer = () => {
        setSeason(seasonOptions)
        setDepartment(departmentOptions)
        setCategory(categoryOptions)
    }

    useEffect(() => {
        applyFilters();
    }, [season, department, category]);
    const suppressHeaderKeyboardEvent = ({ event }) => {
        if (!event) return false;
        let tmpColDef = SummaryReportColDef
        if (event.altKey && event.key === "ArrowDown") {
            const column = tmpColDef.filter((col) => event.srcElement.innerText === col.headerName);
            const colIndex = getColumnIndex(column[0], gridApi) + 1;
            const colElement1 = document.querySelector("div[aria-colindex='" + (colIndex) + "']");

            const wrapper = colElement1.querySelector(".ag-header-cell-comp-wrapper");
            if (wrapper) {
                const container = wrapper.querySelector(".ag-cell-label-container");
                const colElement = container.querySelector('[data-ref="eFilterButton"]');
                colElement?.dispatchEvent(new Event("click"));
            } else {
                const colElement1 = document.querySelector(".ag-header-container");
                const colElement2 = colElement1.querySelector(".ag-header-row.ag-header-row-column");
                const colElement3 = colElement2.querySelector("div[aria-colindex='" + (colIndex) + "']");

                const wrapper = colElement3.querySelector(".ag-header-cell-comp-wrapper");
                const container = wrapper.querySelector(".ag-cell-label-container");
                const colElement = container.querySelector('[data-ref="eFilterButton"]');
                colElement?.dispatchEvent(new Event("click"));
            }
            return true;
        } else if (event.ctrlKey && event.key === "ArrowLeft") {
            const cols = tmpColDef.filter((col) => !col.hide);
            const firstColumnm = cols[0];
            gridApi.current.setFocusedCell(0, firstColumnm.colId);
            gridApi.current.ensureColumnVisible(firstColumnm.colId);
            const element = document.querySelector("div[col-id='" + firstColumnm.colId + "']");
            if (element) element.focus();
            return true;
        } else if (event.ctrlKey && event.key === "ArrowRight") {
            const cols = tmpColDef.filter((col) => !col.hide);
            const lastColumn = cols[cols.length - 1];
            gridApi.current.ensureColumnVisible(lastColumn.colId);
            const element = document.querySelector("div[col-id='" + lastColumn.colId + "']");
            if (element) {
                element.focus();
                return true;
            }
        } else if (event.ctrlKey && event.key === "ArrowDown") {
            const column = tmpColDef.filter((col) => col.headerName === event.srcElement.innerText);
            gridApi.current.ensureIndexVisible(SsnData.length - 1);
            gridApi.current.setFocusedCell(SsnData.length - 1, column[0].colId);
            return true;
        }
        return false;
    };

    return (
        <div style={{ padding: "5px" }}>
            <Paper
                elevation={3}
                sx={{
                    overflow: 'hidden',
                    borderRadius: 2,
                    position: 'relative',
                    transition: 'all 0.3s ease-in-out',
                    paddingBottom: 0.5,
                    bgcolor: 'background.paper',
                }}
            >
                <Box sx={{ p: 1, height: "110px" }}>
                    <Grid container direction={"row"} justifyContent="top" alignItems="top" spacing={0}>
                        <Grid item xs={11} md={11.65}>
                            <Grid container direction="row" justifyContent="top" alignItems="top" spacing={1.5}>
                                <Grid item  xs={2.7}>
                                    <CheckBoxList label="Season" items={seasonOptions} selectedValues={season} onSelectionChange={onSesonChange} />
                                </Grid>
                                <Grid item xs={2.7}>
                                    <CheckBoxList
                                        label="Department"
                                        items={departmentOptions}
                                        selectedValues={department}
                                        onSelectionChange={onDepartmentChange}
                                    />
                                </Grid>
                                <Grid item xs={6.6}>
                                    <CheckBoxList
                                        items={categoryOptions}
                                        label="Category"
                                        selectedValues={category}
                                        onSelectionChange={onCategoryChanged}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} md={0.35} justifyContent={"center"}>
                            <Button onClick={resetSlicer} size="small" sx={{justifyContent: "flex-start"}}>Reset</Button>
                        </Grid>
                    </Grid>

                </Box>
            </Paper>
            <div className="ag-theme-quartz" style={{ height: "300px", marginTop: "7px" }}>
                <AgGridReact
                    suppressFieldDotNotation
                    onGridReady={(event) => onGridReady(event)}
                    enableColResize={true}
                    columnDefs={newColDef}
                    rowData={filteredSummaryData}
                    suppressRowClickSelection={true}
                    suppressHorizontalScroll={false}
                    suppressMenuHide={true}
                    headerHeight={65}
                    groupHeaderHeight={groupHeaderHeight}
                    getRowStyle={getRowStyle}
                    defaultColDef={{
                        // sortable: true,
                        filter: true,
                        // unSortIcon: true,
                        menuTabs: ['filterMenuTab', 'generalMenuTab'],
                        wrapHeaderText: true,
                        suppressHeaderKeyboardEvent
                    }}
                    enableRangeSelection
                    allowContextMenuWithControlKey={true}
                    enterNavigatesVertically={true}
                    enableCharts={true}
                    pinnedBottomRowData={pinnedBottomRow ? [pinnedBottomRow] : null}
                />
            </div>
            <MDBox p={1.5} borderRadius="lg" shadow="lg" opacity={1} bgColor="white" mt={2} mb={1}>
                <Grid container spacing={1}>
                    <Grid item xs={9}>
                        <Typography sx={{ fontSize: "12px", color: "black", marginLeft: "4px", marginBottom: "2px" }}>Linelist</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={3} md={2}>
                                <DashboardCard count={dashboardData.production_approval_pending} title="Lock Controls" subTitle="Allow Changes Pending" bgColor={"#38B7B0"} />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <DashboardCard count={dashboardData.change_memo_total} title="Change Memo" subTitle="Change Memo Status Pending" bgColor={"#009CCC"} />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <DashboardCard count={dashboardData.fob_status_working} title="FOB Calculation" subTitle="Current FOB Status In Working" bgColor={"#F0BF00"} />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <DashboardCard count={dashboardData.fob_status_confirmed} title="FOB Calculation" subTitle="Current FOB Status In Confirmed" bgColor={"#FF7F00"} />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <DashboardCard count={dashboardData.style_activity_active} title="Style Status" subTitle="Style Activity Active" bgColor={"#F15D29"} />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <DashboardCard count={dashboardData.style_activity_dropped} title="Style Status" subTitle="Style Activity Dropped" bgColor={"#8255FF"} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography sx={{ fontSize: "12px", color: "black", marginLeft: "4px", marginBottom: "2px" }}>Working Line</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <DashboardCard count={dashboardData.dtc_working_count} title="DTC" subTitle="Working Line = Working" bgColor={"#38B7B0"} />
                            </Grid>
                            <Grid item xs={6}>
                                <DashboardCard count={dashboardData.whsl_working_count} title="Wholesale" subTitle="Working Line = Working" bgColor={"#009CCC"} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
            <Grid container spacing={1}>
                {chartOption.map((opt) => {
                    return (
                        <Grid item xs={opt.xs}>
                            <Ag_Charts chartOptions={opt.options} title={opt.title} formatTooltip={opt.formatTooltip} />
                        </Grid>)
                })}
            </Grid>
        </div>
    );
}

SummaryReportTab.propTypes = {
    setCurrentGridApi: PropTypes.func.isRequired
};

export default SummaryReportTab;