import _, { isNumber } from "lodash";

const pdMilestoneList = ['Initial Costing Review & Bucket Projection', 'Line Adoption Week - Start', 'Line Adoption Week - End'];
const merchMilestoneList = ['Core / Carry Over IR - Jan Capsule', 'LLT Fashion IR', 'Line Adoption Week - Start'];

const seasonMp = new Map([
    ['SP', 'Spring'],
    ['SU', 'Summer'],
    ['FA', 'Fall'],
    ['HO', 'Holiday']
]);

const colLst = ["Mens Spring End", "Womens Spring End", "Mens Summer End", "Womens Summer End", "Mens Fall End", "Womens Fall End", "Mens Holiday End", "Womens Holiday (CNY) End", "Womens Holiday (Non-CNY) End"];

const dataToPrepare = [
    {
        "id": 1,
        "user_group": "product development",
        "name": "Men's Fall",
        "Initial Costing Review & Bucket Projection": "0",
        "Line Adoption Week - Start": "24",
        "Line Adoption Week - End": "35"
    },
    {
        "id": 2,
        "name": "Women's Fall",
        "user_group": "merch",
        "Core / Carry Over IR - Jan Capsule": "14",
        "LLT Fashion IR": "14",
        "Line Adoption Week - Start": "24"
    }
];

export const prepareMilestonesData = (llData, MilestonesData) => {
    // console.log("IN -> prepareMilestonesData -> llData -> ", llData);
    // console.log("IN -> prepareMilestonesData -> MilestonesData -> ", MilestonesData);

    if (sessionStorage.getItem("user_type") === "vendor") {
        return [];
    }
    if (llData === null || llData === undefined || llData.length === 0) {
        return [];
    }
    if (MilestonesData === null || MilestonesData === undefined || MilestonesData.length === 0) {
        return [];
    }

    const parseOwnership = (data) => {
        return data.map(item => ({
            ...item,
            Ownership:
                item.Ownership === null || item.Ownership === undefined || item.Ownership === ''
                    ? []
                    : typeof item.Ownership === 'string'
                        ? item.Ownership.split(',').map(owner => owner.trim())
                        : Array.isArray(item.Ownership)
                            ? item.Ownership
                            : []
        }));
    };

    MilestonesData = parseOwnership(MilestonesData);

    // 1. Get unique seasons/years into map
    const uniqueYears = Array.from(new Set(llData.map(item => item["Year"])));
    // console.log("uniqueYears -> ", uniqueYears);

    const uniqueSeasons = Array.from(new Set(llData.map(item => item["Season Code"])));
    // console.log("uniqueSeasons -> ", uniqueSeasons);

    const uniqueDepartment = Array.from(new Set(llData.map(item => item["Department"])));
    // console.log("uniqueDepartment -> ", uniqueDepartment);

    const uniqueYearSeasons = _.chain(llData)
        .uniqBy(r => `${r["Year"]}-${r["Season Code"]}-${r["Department"]}`)
        .map(r => ({
            "Year": r["Year"],
            "Season Code": r["Season Code"],
            "Department": r["Department"]
        }))
        .value();
    // console.log("IN -> prepareMilestonesData -> uniqueYearSeasons -> ", uniqueYearSeasons);

    if (!uniqueYearSeasons || uniqueYearSeasons.length === 0) {
        return [];
    }

    const user_group = sessionStorage.getItem("user_type").toLowerCase();
    let ugList = new Array();
    if (user_group === 'product development') {
        ugList.push('pd');
        ugList.push(user_group);
    } else {
        ugList.push(user_group);
    }

    if (user_group === 'admin' || user_group === 'read-only') {
        ugList.length = 0; // Clear the array
    }
    // console.log("ugList -> ", ugList);

    // 2. Find year column value + season into respective seasons columns into Milestones tab
    // show next 3 milestones with days remining >=0 for applicable Ownership = user group
    // + keep milestones section expanded by default so it is visible when user click AI bot (without additional click)

    let mlFlDt1 = new Array();
    for (let j = 0; j < uniqueYears.length; j++) {
        for (let i = 0; i < MilestonesData.length; i++) {
            if (MilestonesData[i]["Year"] === uniqueYears[j] && MilestonesData[i]["Ownership"]) {
                // const ownershipList = MilestonesData[i]["Ownership"].toLowerCase().split(',').map(v => v.trim());
                const ownershipList = MilestonesData[i]["Ownership"].map(v => v.trim().toLowerCase());
                if (ownershipList && ownershipList.length > 0) {
                    if (user_group === 'admin' || user_group === 'read-only') {
                        mlFlDt1.push(MilestonesData[i]);
                    } else {
                        if (ugList.some(ug => ownershipList.includes(ug))) {
                            mlFlDt1.push(MilestonesData[i]);
                        }
                    }
                }
            }
        }
    }
    // console.log("mlFlDt1 -> ", mlFlDt1);

    let retVal = new Array();
    for (let j = 0; j < uniqueSeasons.length; j++) {
        // console.log('uniqueSeasons[j] -> ', uniqueSeasons[j]);
        for (const [code, season] of seasonMp) {
            // console.log('code -> ', code);
            if (uniqueSeasons[j].substring(0, 2).toLowerCase() === code.toLowerCase()) {
                // console.log('code -> ', code);
                for (let k = 0; k < uniqueDepartment.length; k++) {
                    // console.log("uniqueDepartment[k] -> ", uniqueDepartment[k]);
                    let lv = (uniqueDepartment[k] + ' ' + season + ' End');
                    // console.log("lv -> ", lv);
                    for (let i = 0; i < mlFlDt1.length; i++) {
                        // console.log("lv -> ", lv);
                        let daysRemaining = mlFlDt1[i][lv];
                        let isNumber = Number(daysRemaining) === Number(daysRemaining);
                        daysRemaining = ((daysRemaining && daysRemaining !== "" && isNumber) ? parseInt(daysRemaining) : null);
                        // console.log("daysRemaining -> ", daysRemaining);
                        // console.log(uniqueSeasons[j] + ' -> ' + uniqueDepartment[k] + ' -> ' + lv + ' -> ' + daysRemaining + ' -> ' + (daysRemaining >= 0));
                        if (daysRemaining !== null && daysRemaining !== undefined && daysRemaining >= 0) {
                            // console.log(uniqueSeasons[j] + ' -> ' + uniqueDepartment[k] + ' -> ' + lv + ' -> ' + daysRemaining + ' -> ' + (daysRemaining >= 0));
                            let oneMp = new Map();
                            // oneMp.set("user_group", sessionStorage.getItem("user_type"));
                            // oneMp.set("name", (uniqueDepartment[k] + ' ' + season));
                            // oneMp.set(mlFlDt1[i]["Milestone"], daysRemaining);
                            // console.log("oneMp -> ", oneMp);

                            oneMp.set("row_id", mlFlDt1[i]["row_id"]);
                            oneMp.set("name", (uniqueDepartment[k] + ' ' + season));
                            oneMp.set("milestone", mlFlDt1[i]["Milestone"]);
                            oneMp.set("daysRemaining", daysRemaining);

                            retVal.push(Object.fromEntries(oneMp));
                        }
                        // console.log("A -> daysRemaining -> ", daysRemaining);
                        // console.log("***");
                        isNumber = false
                        daysRemaining = null
                    }
                    lv = null;
                }
            }
        }
    }
    console.log('retVal -> ', retVal);

    if (retVal && retVal.length > 0) {
        // Group milestones by name
        const groupedMilestones = retVal.reduce((acc, milestone) => {
            if (!acc[milestone.name]) {
                acc[milestone.name] = [];
            }
            acc[milestone.name].push(milestone);
            return acc;
        }, {});

        // Sort and slice the top three milestones for each name
        const maxThreeMilestones = Object.keys(groupedMilestones).map(name => {
            const sortedMilestones = groupedMilestones[name].sort((a, b) => a.daysRemaining - b.daysRemaining);
            return {
                name, // Keep the name at this level
                milestones: sortedMilestones.slice(0, 3).map(({ name, ...milestone }) => milestone) // Get the first three entries + Exclude name from each milestone
            };
        });

        console.log("maxThreeMilestones -> ", maxThreeMilestones);
        return maxThreeMilestones;
    } else {
        return [];
    }
}