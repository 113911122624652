/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-github-btn

// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// @mui icons

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Material Dashboard 2 PRO React context
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import {
  setOpenConfigurator,
  useMaterialUIController
} from "context";
// Icons from MUI
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import ToDo from "layouts/linelist/components/ToDo";

import CloseIcon from "@mui/icons-material/Close";
import { Buffer } from 'buffer';
import { toastMessage, getColumnDefByTblName, CH1, CH2, CH3 } from "layouts/linelist/XL_Utils";
import DynamicQuery from "layouts/linelist/components/Analytics/DynamicQuery";
import Competitor_Analytics_Charts from "layouts/linelist/components/CompetitorAnalytics/Competitor_Analytics_Charts";
import MilestoneNotification from "layouts/linelist/components/Milestones/MilestoneNotification";
import FlagIcon from '@mui/icons-material/Flag';
import NotificationPanel from "layouts/linelist/components/UserNotification/NotificationPanel";

function Configurator({ changeTab, notificationData, airTableIntNfData, autoGetChangesData }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
    darkMode,
  } = controller;

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  const [openNotification, setOpenNotification] = useState(true);
  const [openTodo, setOpenTodo] = useState(true);
  const [openCharts, setOpenCharts] = useState(true);
  const [openDaysRemaining, setOpenDaysRemaining] = useState(true);

  const [selectedComponent, setSelectedComponent] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [enableFullScreen, setEnableFullScreen] = useState(false);

  const handleNotificationClick = () => {
    setOpenNotification(!openNotification);
  };

  const DynamicQueryClick = (subItem) => {
    if (subItem === "Summary") {
      if (sessionStorage.getItem("isDownloadLinelistPerformed") === false || sessionStorage.getItem("isDownloadLinelistPerformed") === 'false') {
        toastMessage("Please perform download data from Season tab.", "warning");
        return;
      }
      changeTab("Summary_Report");
      return;
    } else if (subItem === "Competitor Analytics") {
      setEnableFullScreen(true);
      setOpenDialog(true);
      setDialogTitle(subItem);
      return;
    }
    else {
      changeTab("Pivot");
      return;
      // setEnableFullScreen(true);
    }
    // setOpenDialog(true);
    // setDialogTitle(subItem);   
  }

  const handelSubTodoClick = (subItem) => {
    if (sessionStorage.getItem("isDownloadLinelistPerformed") === false || sessionStorage.getItem("isDownloadLinelistPerformed") === 'false') {
      toastMessage("Please perform download data from Season tab.", "warning");
      return;
    }
    setSelectedComponent(subItem);
    setDialogTitle("To Do: " + subItem);
    setOpenDialog(true);
  }
  const handleTodoClick = () => {
    setOpenTodo(!openTodo);
  };

  const handleAnalyticsClick = () => {
    setOpenCharts(!openCharts);
  };

  const handleDaysRemainingClick = () => {
    setOpenDaysRemaining(!openDaysRemaining);
  };

  const closeDialog = () => {
    // console.log("closeDialog -> selectedComponent -> " , selectedComponent);
    if (selectedComponent && selectedComponent.id) {
      setActionMsg(actionMsg.filter(am => am.id !== selectedComponent.id));
    }

    setEnableFullScreen(false);
    setOpenDialog(false);
  }

  // Custom styles
  const StyledListItem = styled(ListItem)(({ theme }) => ({
    '&:hover': {
      backgroundColor: '#f0f0f0', // Light hover background
    },
    '& .MuiListItemIcon-root': {
      color: '#1976d2', // Icon color
    },
  }));

  const SubItem = styled(ListItem)(({ theme }) => ({
    '&:hover': {
      backgroundColor: '#e8f0fe', // Light hover for sub-items
    },
  }));

  const subItemsTodo = ['Linelist', 'PD_Intial_Costing'];
  const subItemsAnalytics = ["vendor", "read-only", "merch reference", "planning", "global planning"].includes(sessionStorage.getItem("user_type")) ? ['Create Your Own Reports'] : ['Summary', 'Create Your Own Reports'];

  const renderComponent = () => {
    if (dialogTitle.includes('To Do')) return <ToDo selectedComponent={selectedComponent} closeDialog={closeDialog} />;
    else if (dialogTitle === 'chart') return "<ChartComponent />";
    else if (dialogTitle === 'Create Your Own Reports') return <DynamicQuery selectedComponent={selectedComponent} />;
    else if (dialogTitle === 'Competitor Analytics') return <Competitor_Analytics_Charts selectedComponent={selectedComponent} />;
    // else if(dialogTitle === 'Summary') return <Summary selectedComponent={selectedComponent} />;
    else return <div>Select an item to see the content</div>;
  };

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.4}
        px={3}
      >
        <MDBox
          display="flex"
          alignItems="center"
        >
          <ListAltIcon sx={{ marginRight: '10px' }} />
          <MDTypography variant="h5">Let's play!</MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            // transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>

      <Divider sx={{ backgroundColor: 'gray', marginTop: '6px', marginBottom: '6px' }} />
      <MDBox>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', boxShadow: 1, borderRadius: '8px', padding: '4px' }}>
          {/* Notification */}
          <StyledListItem button onClick={handleNotificationClick}>
            <ListItemIcon sx={{ minWidth: '10px', marginRight: '10px' }}>
              <NotificationsIcon sx={{ fontSize: '22px' }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2" sx={{ fontSize: '16px', fontWeight: 500 }}>
                  Notifications
                </Typography>
              }
            />
            {openNotification ? <ExpandLess sx={{ transition: 'all 0.3s ease' }} /> : <ExpandMore sx={{ transition: 'all 0.3s ease' }} />}
          </StyledListItem>
          <Collapse in={openNotification} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ minHeight: '50px', maxHeight: '500px', overflowY: 'auto' }}>
              <NotificationPanel
                notificationData={notificationData}
                airTableIntNfData={airTableIntNfData}
                autoGetChangesData={autoGetChangesData}
              />
            </List>
          </Collapse>

          <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

          {/* To Do */}
          <StyledListItem button onClick={handleTodoClick}>
            <ListItemIcon sx={{ minWidth: '10px', marginRight: '10px' }}>
              <CheckCircleIcon sx={{ fontSize: '22px' }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2" sx={{ fontSize: '16px', fontWeight: 500 }}>
                  To Do
                </Typography>
              }
            />
            {openTodo ? <ExpandLess sx={{ transition: 'all 0.3s ease' }} /> : <ExpandMore sx={{ transition: 'all 0.3s ease' }} />}
          </StyledListItem>
          <Collapse in={openTodo} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {subItemsTodo.map((subItem, index) => (
                <SubItem key={index} sx={{ pl: 4, mt: 0.5, mb: 0.5, cursor: 'pointer' }} onClick={() => handelSubTodoClick(subItem)}>
                  <ListItemText primary={<Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 400 }}>
                    {subItem}
                  </Typography>} />
                </SubItem>
              ))}
            </List>
          </Collapse>

          <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

          {/* Analytics */}
          <StyledListItem button onClick={handleAnalyticsClick}>
            <ListItemIcon sx={{ minWidth: '10px', marginRight: '10px' }}>
              <InsertChartIcon sx={{ fontSize: '22px' }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2" sx={{ fontSize: '16px', fontWeight: 500 }}>
                  Analytics
                </Typography>
              }
            />
            {openCharts ? <ExpandLess sx={{ transition: 'all 0.3s ease' }} /> : <ExpandMore sx={{ transition: 'all 0.3s ease' }} />}
          </StyledListItem>
          <Collapse in={openCharts} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {subItemsAnalytics.map((subItem, index) => (
                <SubItem key={index} sx={{ pl: 4, mt: 0.5, mb: 0.5, cursor: 'pointer' }} onClick={() => DynamicQueryClick(subItem)}>
                  <ListItemText primary={<Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 400 }}>
                    {subItem}
                  </Typography>} />
                </SubItem>
              ))}
            </List>
          </Collapse>

          {/* Milestones */}
          {
            ["vendor"].includes(sessionStorage.getItem("user_type"))
              ?
              null
              :
              <>
                <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
                <StyledListItem button onClick={handleDaysRemainingClick}>
                  <ListItemIcon sx={{ minWidth: '10px', marginRight: '10px' }}>
                    <FlagIcon sx={{ fontSize: '22px' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body2" sx={{ fontSize: '16px', fontWeight: 500 }}>
                        Milestones
                      </Typography>
                    }
                  />
                  {openDaysRemaining ? <ExpandLess sx={{ transition: 'all 0.3s ease' }} /> : <ExpandMore sx={{ transition: 'all 0.3s ease' }} />}
                </StyledListItem>
                <Collapse in={openDaysRemaining} timeout="auto" unmountOnExit>
                  <MilestoneNotification />
                </Collapse>
              </>
          }
        </List>
      </MDBox>

      <Dialog
        open={openDialog}
        onClose={closeDialog}
        // maxWidth={"xxl"}
        maxWidth={false}
        fullWidth={!enableFullScreen}
        disableEscapeKeyDown
        fullScreen={enableFullScreen}
      >
        <DialogTitle sx={{ pt: '4px', pb: '4px', fontSize: '16px', backgroundColor: '#fafafb' }}>
          {dialogTitle}
          <IconButton
            aria-label="close"
            onClick={closeDialog}
            style={{ position: "absolute", right: 8, top: -2 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ margin: '0px', backgroundColor: 'gray' }} />
        <DialogContent sx={{ pt: '8px' }}>
          {renderComponent()}
        </DialogContent>
      </Dialog>
    </ConfiguratorRoot>
  );
}

export default Configurator;
