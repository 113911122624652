import { DarkMode, LightMode, Opacity } from '@mui/icons-material';
import { Box, Chip, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { setTransparentSidenav, setWhiteSidenav, useMaterialUIController } from 'context';
import { useEffect, useState } from 'react';

const Settings = () => {
    const [controller, dispatch] = useMaterialUIController();
    const { transparentSidenav, whiteSidenav, darkMode } = controller;

    const [theme, setTheme] = useState('dark');

    useEffect(() => {
        if (transparentSidenav) setTheme('transparent');
        else if (whiteSidenav) setTheme('light');
        else setTheme('dark');
    }, []);

    const handleThemeChange = (event) => {
        const value = event.target.value
        setTheme(value);

        localStorage.setItem("sideNavBarTheme", value);

        switch (value) {
            case 'light':
                setWhiteSidenav(dispatch, true);
                setTransparentSidenav(dispatch, false);
                
                break;
            case 'transparent':
                setTransparentSidenav(dispatch, true);
                setWhiteSidenav(dispatch, false);
                
                break;
            case 'dark':
                setWhiteSidenav(dispatch, false);
                setTransparentSidenav(dispatch, false);
                
                break;
        }

        
    };

    return (<>
        <Box sx={{ mt: 1 }}>
            <Typography variant="h6" gutterBottom>
                Navigation Color
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <RadioGroup row value={theme} onChange={handleThemeChange}>
                    <FormControlLabel
                        value="light"
                        control={<Radio />}
                        label={
                            <Chip
                                icon={<LightMode />}
                                label="Light"
                                variant={whiteSidenav ? 'filled' : 'outlined'}
                                color="info"
                            />
                        }
                    />
                    <FormControlLabel
                        value="dark"
                        control={<Radio />}
                        label={
                            <Chip
                                icon={<DarkMode />}
                                label="Dark"
                                variant={darkMode ? 'filled' : 'outlined'}
                                color="info"
                            />
                        }
                    />
                    <FormControlLabel
                        value="transparent"
                        control={<Radio />}
                        label={
                            <Chip
                                icon={<Opacity />}
                                label="Transparent"
                                variant={transparentSidenav ? 'filled' : 'outlined'}
                                color="info"
                            />
                        }
                    />
                </RadioGroup>
            </Box>
        </Box>
    </>)
}

export default Settings;