import { xlRound, xlXlookup } from 'layouts/linelist/XL_Calculation';
import { JsDateToExcelDateNumber, executeDataVerification, toastMessage } from 'layouts/linelist/XL_Utils';
import { GetAutoChanges, compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_faherty_chase";
const rng_name = "Chase_Rng";

const ChaseTab = forwardRef(({ setCurrentGridApi, settimeLineDataFun, setActionItemDataFun, clearFilterBtnCall }, ref) => {
  const dispatch = useDispatch();
  // let allCol = useSelector(s => s.testColLst[0][tbl_name]);
  const FormulaCalcColLst = useSelector(s => s.formulaCalcColLst[0][tbl_name]);
  const ChaseData = useSelector(state => state.ChaseData);
  const SSNData = useSelector((s) => s.ChaseDataSSN);

  const ChaseHighlistCell = useSelector(state => state.ChaseHighlistCell);

  const TmpChaseColDef = useSelector(state => state.ChaseColDef ? state.ChaseColDef : []);
  const ChaseSorting = useSelector(state => state.ChaseSorting ? state.ChaseSorting : []);
  const ChaseFilters = useSelector(state => state.ChaseFilters ? state.ChaseFilters : {});

  const columnPref = useSelector((s) => s.ChaseColPref);

  const refFreightMaster = useSelector(state => state.FreightMasterData);
  const refFreightMasterChanges = useSelector(state => state.FreightMasterHighlistCell);
  const refFreightByCategory = useSelector(state => state.FreightByCategoryData);
  const refFreightByCategoryChanges = useSelector(state => state.FreightByCategoryHighlistCell);
  const refFBFtyDatabase = useSelector(state => state.FbFtyDatabaseData);
  const refFBFtyDatabaseChanges = useSelector(state => state.FbFtyDatabaseHighlistCell);

  const highlightCells = useRef([]);
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const cellValueChanged = useRef([]);

  const [autoChangeState, setAutoChangeState] = useState(false)
  const [autoGetChangeResult, setAutoGetChangeResult] = useState({});
  const [isGridReady, setIsGridReady] = useState(false);

  const setResetFilter = (setReset) => {
    let sorting = ChaseSorting
    let filtering = ChaseFilters
    if (setReset === "reset") {
      sorting = null
      filtering = null
    }
    gridApi.current.applyColumnState({ state: sorting })
    gridApi.current.setFilterModel(filtering);
  }
  //Required for all tabs (For before send data functionalities)
  useImperativeHandle(ref, () => ({
    validateSendData: () => {
      if (!ChaseData) return false
      const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
      if (res.status === false) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        return (res)
      }
      removeHeihlight();
      cellValueChanged.current = [];
      return ({ status: true, colDef: TmpChaseColDef })
    },
    getChangesFunc: async (masterData, metaData) => {
      const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, TmpChaseColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
      if (res.status) {
        highlightCells.current = res.highlightCells

        dispatch({ type: "SET_CHASE_DATA", data: res.data });
        gridApi.current.refreshCells({ force: true })
      } else {
        toastMessage(res.msg, "warning");

      }
      return res
    },
    getAutoChanges: (data, downloadType) => {
      setResetFilter("reset")
      const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
      setAutoChangeState(true);
      setAutoGetChangeResult(res);
    }
  }));
  const removeHeihlight = () => {
    highlightCells.current = []
    if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true })
  };

  useEffect(() => {
    if (isGridReady) {
      if (ChaseSorting !== null && ChaseSorting !== undefined) {
        gridApi.current.applyColumnState({
          state: ChaseSorting
        })
      }
      if (ChaseFilters !== null && ChaseFilters !== undefined) {
        gridApi.current.setFilterModel(ChaseFilters);
      }
    }
  }, [ChaseHighlistCell, isGridReady])
  useEffect(() => {
    if (ChaseData && ChaseData.length > 0) {

      if (gridApi.current.rowModel) {
        calculateAllFormulas()
      }
    }
  }, [ChaseData])
  useEffect(() => {
    if (!refFreightMaster || Object.keys(gridApi.current).length <= 0) return
    if (!refFreightMasterChanges || refFreightMasterChanges.length <= 0) return
    setResetFilter("reset")
    const arr = highlightCells.current
    const changes = []
    const columns = []
    // console.log("calculate chase for refFreightMasterChanges")

    refFreightMasterChanges.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refFreightMaster.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })

    gridApi.current.forEachNode((event) => {
      const row_id = event.data.row_id

      if (columns.includes("Ocean Transit Days")) {
        if (event.data["COO"] !== null && event.data["COO"] !== undefined) {
          const ch = changes.filter((element) => element["Season"].toLowerCase() === event.data["Season"].toLowerCase() &&
            element["COO"].toString().toLowerCase() === event.data["COO"].toString().toLowerCase())
          if (ch.length > 0) {
            if (fxcalc("Boat Timing")) {
              event.data["Boat Timing"] = calculateBoatTiming(event);
              arr.push({ [tbl_name + "|Boat Timing|" + row_id]: 1 });
            }
            if (fxcalc("Air Timing")) {
              event.data["Air Timing"] = calculateAirTiming(event);
              arr.push({ [tbl_name + "|Air Timing|" + row_id]: 1 });
            }
            if (fxcalc("Ex. Ocean Timing")) {
              event.data["Ex. Ocean Timing"] = calculateExpressOceanTiming(event);
              arr.push({ [tbl_name + "|Ex. Ocean Timing|" + row_id]: 1 });
            }
          }
        }
      }
    })
    setResetFilter("set")
    highlightCells.current = arr
    gridApi.current.refreshCells({ force: true });
  }, [refFreightMaster, refFreightMasterChanges])
  useEffect(() => {
    if (!refFreightMaster || !refFreightByCategory || Object.keys(gridApi.current).length <= 0) return
    if ((!refFreightMasterChanges || refFreightByCategoryChanges.length <= 0) && (!refFreightByCategoryChanges || refFreightByCategoryChanges.length <= 0)) return
    setResetFilter("reset")
    const arr = highlightCells.current
    const changes = []
    const columns = []
    // console.log("calculate chase for refFreightMasterChanges and refFreightByCategoryChanges")
    refFreightMasterChanges.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refFreightMaster.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })

    refFreightByCategoryChanges.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refFreightByCategory.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })

    gridApi.current.forEachNode((event) => {
      const row_id = event.data.row_id

      if (columns.includes("BOAT")) {
        if (event.data["COO"] !== null && event.data["COO"] !== undefined) {
          const ch = changes.filter((element) => element["COO"].toString().toLowerCase() === event.data["COO"].toString().toLowerCase() &&
            element["Season"].toLowerCase() === event.data["Season Code"].toLowerCase())
          if (ch.length > 0) {
            if (fxcalc("Boat Freight CPU")) {
              event.data["Boat Freight CPU"] = calculateBoatFreightCPU(event);
              arr.push({ [tbl_name + "|Boat Freight CPU|" + row_id]: 1 });
            }
            if (fxcalc("Air Frieght CPU")) {
              event.data["Air Frieght CPU"] = calculateAirFreightCPU(event)
              arr.push({ [tbl_name + "|Air Frieght CPU|" + row_id]: 1 });
            }
            if (fxcalc("Ex. Ocean Freight CPU")) {
              event.data["Ex. Ocean Freight CPU"] = calculateExpressOceanFreightCPU(event);
              arr.push({ [tbl_name + "|Ex. Ocean Freight CPU|" + row_id]: 1 });
            }
          }
        }
      }
      if (columns.includes("Final KG")) {
        if (event.data["Category"] !== null && event.data["Category"] !== undefined) {
          const ch = changes.filter((element) => element["Category"].toLowerCase() === event.data["Category"].toLowerCase() &&
            element["Season"].toLowerCase() === event.data["Season Code"].toLowerCase())
          if (ch.length > 0) {
            if (fxcalc("Boat Freight CPU")) {
              event.data["Boat Freight CPU"] = calculateBoatFreightCPU(event);
              arr.push({ [tbl_name + "|Boat Freight CPU|" + row_id]: 1 });
            }
            if (fxcalc("Air Frieght CPU")) {
              event.data["Air Frieght CPU"] = calculateAirFreightCPU(event)
              arr.push({ [tbl_name + "|Air Frieght CPU|" + row_id]: 1 });
            }
            if (fxcalc("Ex. Ocean Freight CPU")) {
              event.data["Ex. Ocean Freight CPU"] = calculateExpressOceanFreightCPU(event);
              arr.push({ [tbl_name + "|Ex. Ocean Freight CPU|" + row_id]: 1 });
            }
          }
        }
      }
    })
    setResetFilter("set")
    highlightCells.current = arr
    gridApi.current.refreshCells({ force: true });
  }, [refFreightMaster, refFreightByCategory, refFreightByCategoryChanges, refFreightMasterChanges])
  useEffect(() => {
    if (!refFBFtyDatabase || Object.keys(gridApi.current).length <= 0) return
    if (!refFBFtyDatabaseChanges || refFBFtyDatabaseChanges.length <= 0) return
    setResetFilter("reset")
    const arr = highlightCells.current
    const changes = []
    const columns = []
    // console.log("calculate chase for refFBFtyDatabaseChanges")

    refFBFtyDatabaseChanges.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refFBFtyDatabase.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })
    gridApi.current.forEachNode((event) => {
      const row_id = event.data.row_id

      if (columns.includes("Factory Country") && fxcalc("COO")) {
        if (event.data["Factory"] !== null && event.data["Factory"] !== undefined) {
          const ch = changes.filter((element) => element["Factory"].toLowerCase() === event.data["Factory"].toLowerCase())
          if (ch.length > 0) {
            event.data["COO"] = calculateCOO(event);
            arr.push({ [tbl_name + "|COO|" + row_id]: 1 })
          }
        }
      }
      if (columns.includes("Corporate/Agent") && fxcalc("Corporate/Agent")) {
        if (event.data["Factory"] !== null && event.data["Factory"] !== undefined) {
          const ch = changes.filter((element) => element["Factory"].toLowerCase() === event.data["Factory"].toLowerCase())
          if (ch.length > 0) {
            event.data["Corporate/Agent"] = calculateCorporateAgent(event);
            arr.push({ [tbl_name + "|Corporate/Agent|" + row_id]: 1 })
          }
        }
      }
      if (columns.includes("FOB/DDP/LDP") && fxcalc("Shipping Terms (FOB/DDP/LDP)")) {
        if (event.data["Factory"] !== null && event.data["Factory"] !== undefined) {
          const ch = changes.filter((element) => element["Factory"].toLowerCase() === event.data["Factory"].toLowerCase())
          if (ch.length > 0) {
            event.data["Shipping Terms (FOB/DDP/LDP)"] = calculateShippingTermsFOBDDPLDP(event);
            arr.push({ [tbl_name + "|Shipping Terms (FOB/DDP/LDP)|" + row_id]: 1 })
          }
        }
      }
    })
    setResetFilter("set")
    highlightCells.current = arr
    gridApi.current.refreshCells({ force: true });
  }, [refFBFtyDatabase, refFBFtyDatabaseChanges])
  const onGridReady = (params) => {
    gridApi.current = params.api
    gridColumnApi.current = params.columnApi;
    setCurrentGridApi(params)
    calculateAllFormulas();
    setIsGridReady(true)
  }

  //formula
  const calculateBoatTiming = (event) => {
    const node = event.data
    if (!node) return ""
    let boat_timing = ""
    let season_code = node["Season Code"]
    let coo = node["COO"]
    let x_fty_date = node["X-fty Date"]
    let boat_transit_days = ""
    if (season_code === null || season_code === undefined || season_code === "") { season_code = "" }
    if (coo === null || coo === undefined || coo === "") { coo = "" }
    if (x_fty_date === null || x_fty_date === undefined || x_fty_date === "") { x_fty_date = "" }
    if (season_code !== "" && coo !== "" && x_fty_date !== "") {
      boat_transit_days = xlXlookup(season_code + coo, refFreightMaster, 'Season|COO', 'Ocean Transit Days')
      if (boat_transit_days === null || boat_transit_days === "" || boat_transit_days === null || boat_transit_days === undefined || boat_transit_days === "NaN" || boat_transit_days === 0) { boat_timing = "" }
      else boat_timing = parseInt(JsDateToExcelDateNumber(x_fty_date)) + parseInt(boat_transit_days)
      if (boat_timing === null || boat_timing === undefined || boat_timing === "NaN") { boat_timing = "" }
    }
    else boat_timing = ""
    return boat_timing;
  };
  const calculateAirTiming = (event) => {
    const node = event.data
    if (!node) return ""
    let air_timing = ""
    let season_code = node["Season Code"]
    let coo = node["COO"]
    let x_fty_date = node["X-fty Date"]
    let air_transit_days = ""
    if (season_code === null || season_code === undefined || season_code === "") { season_code = "" }
    if (coo === null || coo === undefined || coo === "") { coo = "" }
    if (x_fty_date === null || x_fty_date === undefined || x_fty_date === "") { x_fty_date = "" }
    if (season_code !== "" && coo !== "" && x_fty_date !== "") {
      air_transit_days = xlXlookup(season_code + coo, refFreightMaster, 'Season|COO', 'Air Transit Days')
      if (air_transit_days === null || air_transit_days === "" || air_transit_days === undefined || air_transit_days === "NaN" || air_transit_days === 0) { air_timing = "" }
      else air_timing = parseInt(JsDateToExcelDateNumber(x_fty_date)) + parseInt(air_transit_days)
      if (air_timing === null || air_timing === undefined || air_timing === "NaN") { air_timing = "" }
    }
    else air_timing = ""
    return air_timing;
  };
  const calculateExpressOceanTiming = (event) => {
    const node = event.data
    if (!node) return ""
    let express_ocean_timing = ""
    let season_code = node["Season Code"]
    let coo = node["COO"]
    let x_fty_date = node["X-fty Date"]
    let express_ocean_transit_days = ""
    if (season_code === null || season_code === undefined || season_code === "") { season_code = "" }
    if (coo === null || coo === undefined || coo === "") { coo = "" }
    if (x_fty_date === null || x_fty_date === undefined || x_fty_date === "") { x_fty_date = "" }
    if (season_code !== "" && coo !== "" && x_fty_date !== "") {
      express_ocean_transit_days = xlXlookup(season_code + coo, refFreightMaster, 'Season|COO', 'Express Ocean Transit Days')
      // console.log(express_ocean_transit_days)
      if (express_ocean_transit_days === null || express_ocean_transit_days === "" || express_ocean_transit_days === undefined || express_ocean_transit_days === "NaN" || express_ocean_transit_days === 0) { express_ocean_timing = "" }
      else express_ocean_timing = parseInt(JsDateToExcelDateNumber(x_fty_date)) + parseInt(express_ocean_transit_days)
      if (express_ocean_timing === null || express_ocean_timing === undefined || express_ocean_timing === "NaN") { express_ocean_timing = "" }
    }
    else express_ocean_timing = ""
    return express_ocean_timing;
  };
  const calculateBoatFreightCPU = (event) => {
    const node = event.data
    if (!node) return ""
    let boat_freight_cpu = ""
    let season_code = node["Season Code"]
    let coo = node["COO"]
    let category = node["Category"]
    let boat = ""
    let final_kg = ""
    if (season_code === null || season_code === undefined || season_code === "") { season_code = "" }
    if (coo === null || coo === undefined || coo === "") { coo = "" }
    if (category === null || category === undefined || category === "") { category = "" }
    if (season_code !== "" && coo !== "" && category !== "") {
      boat = xlXlookup(season_code + coo, refFreightMaster, 'Season|COO', 'BOAT')
      final_kg = xlXlookup(season_code + category, refFreightByCategory, 'Season|Category', 'Final KG')
      if (boat === null || boat === "" || boat === undefined || boat === "NaN") { boat = 0 }
      if (final_kg === null || final_kg === "" || final_kg === undefined || final_kg === "NaN") { final_kg = 0 }
      boat_freight_cpu = (parseFloat(boat) * parseFloat(final_kg))
      if (boat_freight_cpu !== 0) { boat_freight_cpu = (parseFloat(boat) * parseFloat(final_kg)).toFixed(10) }

      boat_freight_cpu = xlRound(boat_freight_cpu, 6);
      if (boat_freight_cpu === null || boat_freight_cpu === undefined || boat_freight_cpu === "NaN" || boat_freight_cpu === 0) { boat_freight_cpu = "" }
    }
    else { boat_freight_cpu = "" }
    return boat_freight_cpu;
  };
  const calculateAirFreightCPU = (event) => {
    const node = event.data
    if (!node) return ""
    let air_freight_cpu = ""
    let season_code = node["Season Code"]
    let coo = node["COO"]
    let category = node["Category"]
    let air = ""
    let final_kg = ""
    if (season_code === null || season_code === undefined || season_code === "") { season_code = "" }
    if (coo === null || coo === undefined || coo === "") { coo = "" }
    if (category === null || category === undefined || category === "") { category = "" }
    if (season_code !== "" && coo !== "" && category !== "") {
      air = xlXlookup(season_code + coo, refFreightMaster, 'Season|COO', 'AIR')
      final_kg = xlXlookup(season_code + category, refFreightByCategory, 'Season|Category', 'Final KG')
      if (air === null || air === "" || air === undefined || air === "NaN") { air = 0 }
      if (final_kg === null || final_kg === "" || final_kg === undefined || final_kg === "NaN") { final_kg = 0 }
      air_freight_cpu = (parseFloat(air) * parseFloat(final_kg))
      if (air_freight_cpu !== 0) { air_freight_cpu = (parseFloat(air) * parseFloat(final_kg)).toFixed(10) }

      air_freight_cpu = xlRound(air_freight_cpu, 6);
      if (air_freight_cpu === null || air_freight_cpu === undefined || air_freight_cpu === "NaN" || air_freight_cpu === 0) { air_freight_cpu = "" }
    }
    else { air_freight_cpu = "" }
    return air_freight_cpu;
  };
  const calculateExpressOceanFreightCPU = (event) => {
    const node = event.data
    if (!node) return ""
    let express_ocean_freight_cpu = ""
    let season_code = node["Season Code"]
    let coo = node["COO"]
    let category = node["Category"]
    let express_ocean = ""
    let final_kg = ""
    if (season_code === null || season_code === undefined || season_code === "") { season_code = "" }
    if (coo === null || coo === undefined || coo === "") { coo = "" }
    if (category === null || category === undefined || category === "") { category = "" }
    if (season_code !== "" && coo !== "" && category !== "") {
      express_ocean = xlXlookup(season_code + coo, refFreightMaster, 'Season|COO', 'EXPRESS OCEAN')
      final_kg = xlXlookup(season_code + category, refFreightByCategory, 'Season|Category', 'Final KG')
      if (express_ocean === null || express_ocean === "" || express_ocean === undefined || express_ocean === "NaN") { express_ocean = 0 }
      if (final_kg === null || final_kg === "" || final_kg === undefined || final_kg === "NaN") { final_kg = 0 }
      express_ocean_freight_cpu = (parseFloat(express_ocean) * parseFloat(final_kg))
      if (express_ocean_freight_cpu !== 0) { express_ocean_freight_cpu = (parseFloat(express_ocean) * parseFloat(final_kg)).toFixed(10) }

      express_ocean_freight_cpu = xlRound(express_ocean_freight_cpu, 6);
      if (express_ocean_freight_cpu === null || express_ocean_freight_cpu === undefined || express_ocean_freight_cpu === "NaN" || express_ocean_freight_cpu === 0) { express_ocean_freight_cpu = "" }
    }
    else { express_ocean_freight_cpu = "" }
    return express_ocean_freight_cpu;
  };
  const calculateChaseUnitDtc = (event) => {
    const node = event.data
    if (!node) return ""
    let chase_unit_stores = node["Chase Unit Stores"]
    let chase_unit_ecomm = node["Chase Unit Ecomm"]
    let chase_unit_dtc = 0
    if (chase_unit_stores === null || chase_unit_stores === undefined || chase_unit_stores === "") { chase_unit_stores = 0 }
    if (chase_unit_ecomm === null || chase_unit_ecomm === undefined || chase_unit_ecomm === "") { chase_unit_ecomm = 0 }
    // chase_unit_dtc = parseInt(chase_unit_stores) + parseInt(chase_unit_ecomm)
    chase_unit_dtc = parseFloat(chase_unit_stores) + parseFloat(chase_unit_ecomm)

    chase_unit_dtc = xlRound(chase_unit_dtc, 0);
    if (chase_unit_dtc === null || chase_unit_dtc === undefined || chase_unit_dtc.toString() === "NaN" || chase_unit_dtc === "") { chase_unit_dtc = 0 }
    return chase_unit_dtc;
  };
  const calculateTotalChaseUnits = (event) => {
    const node = event.data
    if (!node) return ""
    let chase_unit_dtc = node["Chase Unit DTC"]
    let chase_unit_wholesale = node["Chase Unit Wholesale"]
    let total_chase_units = 0
    if (chase_unit_dtc === null || chase_unit_dtc === undefined || chase_unit_dtc === "") { chase_unit_dtc = 0 }
    if (chase_unit_wholesale === null || chase_unit_wholesale === undefined || chase_unit_wholesale === "") { chase_unit_wholesale = 0 }
    // total_chase_units = parseInt(chase_unit_dtc) + parseInt(chase_unit_wholesale)
    total_chase_units = parseFloat(chase_unit_dtc) + parseFloat(chase_unit_wholesale)

    total_chase_units = xlRound(total_chase_units, 0);
    if (total_chase_units === null || total_chase_units === undefined || total_chase_units.toString() === "NaN" || total_chase_units === "") { total_chase_units = 0 }
    return total_chase_units;
  };
  const calculateLineTotalUnits = (event) => {
    const node = event.data
    if (!node) return ""
    // let size_arr = ["2T", "3T", "4T", "5", "6", "8", "10", "OS", "XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL", "XS/S", "S/M", "M/L", "L/XL", "MT", "LT", "XLT", "XXLT", "00_", "0", "2", "4", "6_", "8_", "10_", "12", "14", "16", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "38", "40", "42", "30T", "32T", "34T", "36T", "38T", "40T"]
    let size_arr = ["2T", "3T", "4T", "5", "6", "8", "10", "OS", "XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL", "XS/S", "S/M", "M/L", "L/XL", "MT", "LT", "XLT", "XXLT", "00_", "0", "2", "4", "6_", "8_", "10_", "12", "14", "16", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "38", "40", "42", "30T", "32T", "34T", "36T", "38T", "40T", "28WX30L", "29WX30L", "30WX30L", "31WX30L", "32WX30L", "33WX30L", "34WX30L", "35WX30L", "36WX30L", "38WX30L", "40WX30L", "42WX30L", "28WX32L", "29WX32L", "30WX32L", "31WX32L", "32WX32L", "33WX32L", "34WX32L", "35WX32L", "36WX32L", "38WX32L", "40WX32L", "42WX32L", "28WX34L", "29WX34L", "30WX34L", "31WX34L", "32WX34L", "33WX34L", "34WX34L", "35WX34L", "36WX34L", "38WX34L", "40WX34L", "42WX34L", "1XL", "2XL", "3XL", "4XL", "5XL", "1XLT", "2XLT", "3XLT", "4XLT", "40W36L", "42W32L", "42W34L", "44W30L", "44W32L", "44W34L", "46W30L", "46W32L", "48W30L", "48W32L", "50W30L", "52W30L", "36_", "38_", "40_", "42_", "44_", "46_", "48_", "50_"]
    let line_total_units = 0
    let temp_size_unit = 0
    for (let i = 0; i < size_arr.length; i++) {
      if ((node[size_arr[i]]) === null || (node[size_arr[i]]) === undefined || (node[size_arr[i]]) === "") { temp_size_unit = 0 }
      // else { temp_size_unit = parseInt(node[size_arr[i]]) }
      else { temp_size_unit = parseFloat(node[size_arr[i]]) }
      line_total_units = line_total_units + temp_size_unit
    }
    line_total_units = xlRound(line_total_units, 0);
    if (line_total_units === null || line_total_units === undefined || line_total_units.toString() === "NaN" || line_total_units === "") { line_total_units = 0 }
    return line_total_units;
  };
  const calculateLineVariance = (event) => {
    const node = event.data
    if (!node) return ""
    let total_chase_units = node["Total Chase Units"]
    let line_total_units = node["Line Total Units"]
    let line_variance = 0
    if (total_chase_units === null || total_chase_units === undefined || total_chase_units === "") { total_chase_units = 0 }
    if (line_total_units === null || line_total_units === undefined || line_total_units === "") { line_total_units = 0 }
    // line_variance = parseInt(line_total_units) - parseInt(total_chase_units)
    line_variance = parseFloat(line_total_units) - parseFloat(total_chase_units)

    line_variance = xlRound(line_variance, 0);
    if (line_variance === null || line_variance === undefined || line_variance.toString() === "NaN" || line_variance === "") { line_variance = 0 }
    return line_variance;
  };
  const calculateCOO = (event) => {
    const node = event.data
    if (!node) return ""
    let coo = ""
    let factory = node["Factory"]
    if (factory === null || factory === undefined || factory === "") { factory = "" }
    if (factory !== "") {
      coo = xlXlookup(factory, refFBFtyDatabase, 'Factory', 'Factory Country')
      if (coo === null || coo === undefined || coo === "" || coo === "NaN") { coo = "" }
    }
    return coo;
  };
  const calculateCorporateAgent = (event) => {
    const node = event.data
    if (!node) return ""
    let corporate_agent = ""
    let factory = node["Factory"]
    if (factory === null || factory === undefined || factory === "") { factory = "" }
    if (factory !== "") {
      corporate_agent = xlXlookup(factory, refFBFtyDatabase, 'Factory', 'Corporate/Agent')
      if (corporate_agent === null || corporate_agent === undefined || corporate_agent === "" || corporate_agent === "NaN") { corporate_agent = "" }
    }
    return corporate_agent;
  };
  const calculateShippingTermsFOBDDPLDP = (event) => {
    const node = event.data
    if (!node) return ""
    let shipping_terms_fob_ddp_ldp = ""
    let factory = node["Factory"]
    if (factory === null || factory === undefined || factory === "") { factory = "" }
    if (factory !== "") {
      // shipping_terms_fob_ddp_ldp = xlXlookup(factory, refFBFtyDatabase, 'Factory', 'Shipping Terms (FOB/DDP/LDP)')
      shipping_terms_fob_ddp_ldp = xlXlookup(factory, refFBFtyDatabase, 'Factory', 'FOB/DDP/LDP')
      if (shipping_terms_fob_ddp_ldp === null || shipping_terms_fob_ddp_ldp === undefined || shipping_terms_fob_ddp_ldp === "" || shipping_terms_fob_ddp_ldp === "NaN") { shipping_terms_fob_ddp_ldp = "" }
    }
    return shipping_terms_fob_ddp_ldp
  };
  const calculateBrand = (event) => {
    const node = event.data
    if (!node) return ""
    let rslt = ""
    let dprt = node["Department"]
    if (dprt === null || dprt === undefined || dprt === "") { dprt = "" }
    if (dprt === "Mens" || dprt === "Womens" || dprt === "Kids") { rslt = "Faherty Brand" }
    return rslt;
  };
  const fxcalc = (colnm) => {
    let retval = false;
    if (FormulaCalcColLst.includes(colnm)) retval = true;
    return retval;
  };
  const calculateAllFormulas = () => {
    if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
      gridApi.current.forEachNode((event) => { calculateReactFormula(event) });
      gridApi.current.refreshCells({ force: true });
    }
    dispatch({ type: "SET_PLEASE_WAIT", data: false });
  };
  const calculateReactFormula = (event) => {
    fxcalc("Brand") ? event.data["Brand"] = calculateBrand(event) : null;
    fxcalc("COO") ? event.data["COO"] = calculateCOO(event) : null;
    fxcalc("Corporate/Agent") ? event.data["Corporate/Agent"] = calculateCorporateAgent(event) : null;
    fxcalc("Shipping Terms(FOB / DDP / LDP)") ? event.data["Shipping Terms(FOB / DDP / LDP)"] = calculateShippingTermsFOBDDPLDP(event) : null;
    fxcalc("Boat Timing") ? event.data["Boat Timing"] = calculateBoatTiming(event) : null;
    fxcalc("Air Timing") ? event.data["Air Timing"] = calculateAirTiming(event) : null;
    fxcalc("Ex. Ocean Timing") ? event.data["Ex. Ocean Timing"] = calculateExpressOceanTiming(event) : null;
    fxcalc("Boat Freight CPU") ? event.data["Boat Freight CPU"] = calculateBoatFreightCPU(event) : null;
    fxcalc("Air Frieght CPU") ? event.data["Air Frieght CPU"] = calculateAirFreightCPU(event) : null;
    fxcalc("Ex. Ocean Freight CPU") ? event.data["Ex. Ocean Freight CPU"] = calculateExpressOceanFreightCPU(event) : null;
    fxcalc("Chase Unit DTC") ? event.data["Chase Unit DTC"] = calculateChaseUnitDtc(event) : null;
    fxcalc("Total Chase Units") ? event.data["Total Chase Units"] = calculateTotalChaseUnits(event) : null;
    fxcalc("Line Total Units") ? event.data["Line Total Units"] = calculateLineTotalUnits(event) : null;
    fxcalc("Line Variance") ? event.data["Line Variance"] = calculateLineVariance(event) : null;
  };

  const onSortChanged = (event) => {
    const colState = gridApi.current.getColumnState();
    const savedSortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch({ type: "SET_CHASE_SORTING", data: savedSortState });
  }
  const onFilterChanged = (event) => {
    const savedFilterModel = gridApi.current.getFilterModel();
    dispatch({ type: "SET_CHASE_FILTERS", data: savedFilterModel });
  }

  const resteAutoChangeState = () => {
    setResetFilter("set");
    setAutoChangeState(false);
    setAutoGetChangeResult({});
  }
  const setCellValueChanged = (data) => {
    cellValueChanged.current = data;
  }
  return (
    <CustomAgGrid
      setCurrentGridApi={onGridReady}
      settimeLineDataFun={settimeLineDataFun}
      clearFilterBtnCall={clearFilterBtnCall}
      setActionItemDataFun={setActionItemDataFun}
      tab_name="Chase"
      tbl_name={tbl_name}
      columnDefs={TmpChaseColDef}
      rowData={ChaseData}
      SSNData={SSNData}
      tblHighlightCells={ChaseHighlistCell}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      autoChangeState={autoChangeState}
      resteAutoChangeState={resteAutoChangeState}
      autoGetChangeResult={autoGetChangeResult}
      setCellValueChanged={setCellValueChanged}
      calculateFormula={calculateReactFormula}
      isGrouppedHeader={true}
      columnPref={columnPref}
    />
  )
})

ChaseTab.propTypes = {
  setCurrentGridApi: PropTypes.func.isRequired,
  settimeLineDataFun: PropTypes.func.isRequired,

};

export default ChaseTab;