import { xlRound } from 'layouts/linelist/XL_Calculation';
import { executeDataVerification, toastMessage } from 'layouts/linelist/XL_Utils';
import { GetAutoChanges, compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_faherty_freight_kg_rates";
const rng_name = "Freight_Master_Rng";


const FreightMasterTab = forwardRef(({
    setCurrentGridApi,
    settimeLineDataFun, setActionItemDataFun, clearFilterBtnCall }, ref) => {
    const dispatch = useDispatch(); //to update redux state variable
    // let allCol = useSelector(s => s.testColLst[0][tbl_name]);

    const FormulaCalcColLst = useSelector(s => s.formulaCalcColLst[0][tbl_name]);
    const FreightMasterData = useSelector(state => state.FreightMasterData);
    const SSNData = useSelector((s) => s.FreightMasterDataSSN);

    const FreightMasterHighlistCell = useSelector(state => state.FreightMasterHighlistCell);
    const tmpColDef = useSelector(state => state.FreightMasterColDef ? state.FreightMasterColDef : []);
    
	const columnPref = useSelector((s) => s.FreightMasterColPref);
    const columnGrpPref = useSelector((s) => s.FreightMasterGrpColPref);

    const FreightMasterSorting = useSelector(state => state.FreightMasterSorting ? state.FreightMasterSorting : []);
    const FreightMasterFilters = useSelector(state => state.FreightMasterFilters ? state.FreightMasterFilters : {});

    const highlightCells = useRef([]);
    const gridApi = useRef({});
    const gridColumnApi = useRef({});
    const cellValueChanged = useRef([]);

    const [isGridReady, setIsGridReady] = useState(false);
    const [autoChangeState, setAutoChangeState] = useState(false)
    const [autoGetChangeResult, setAutoGetChangeResult] = useState({});

    const setResetFilter = (setReset) => {
        let sorting = FreightMasterSorting
        let filtering = FreightMasterFilters

        if (setReset === "reset") {
            sorting = null
            filtering = null
        }
        gridApi.current.applyColumnState({
            state: sorting
        })
        gridApi.current.setFilterModel(filtering);

    }
    //Required for all tabs (For before send data functionalities)
    useImperativeHandle(ref, () => ({
        validateSendData: () => {
            if (!FreightMasterData) return false
            const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
            if (res.status === false) {
                highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
                return (res)
            }
            cellValueChanged.current = [];
            removeHeihlight();
            return ({ status: true})
        },
        getChangesFunc: async (masterData, metaData) => {
            const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, tmpColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
            if (res.status) {
                highlightCells.current = res.highlightCells

                dispatch({ type: "FRIGHT_MASTER_DATA", data: res.data });
                gridApi.current.refreshCells({ force: true })
            } else {
                toastMessage(res.msg, "warning");

            }
            return res
        },
        getAutoChanges: (data, downloadType) => {
            setResetFilter("reset")
            const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
            setAutoChangeState(true);
            setAutoGetChangeResult(res);
        }
    }));
    const removeHeihlight = () => {
        highlightCells.current = []
        if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true })
    };

    useEffect(() => {
        if (isGridReady) {
            if (FreightMasterSorting !== null && FreightMasterSorting !== undefined) {
                gridApi.current.applyColumnState({
                    state: FreightMasterSorting
                })
            }
            if (FreightMasterFilters !== null && FreightMasterFilters !== undefined) {
                gridApi.current.setFilterModel(FreightMasterFilters);
            }
        }
    }, [isGridReady])

    const onGridReady = (params) => {
        gridApi.current = params.api
        gridColumnApi.current = params.columnApi;
        setCurrentGridApi(params)
        calculateAllFormulas();

        setIsGridReady(true)
    }

    const calculateBlended = (rowNode) => {
        let res = "";
        let valboat = rowNode.data["BOAT"] ? rowNode.data["BOAT"] : "";
        let valair = rowNode.data["AIR"] ? rowNode.data["AIR"] : "";
        if (valboat !== "" && valair !== "")
            res = ((parseFloat(valair) + parseFloat(valboat)) / 2);
        else if (valboat === "" && valair !== "")
            res = parseFloat(valair);
        else if ((valboat !== "" && valair === ""))
            res = parseFloat(valboat);

        if (res !== "") {
            res = xlRound(res, 6);
            if (res === null || res === undefined || res === "" || res.toString() === "NaN") { res = 0 }
        }

        return res;
    };

    const fxcalc = (colnm) => {
        let retval = false;
        if (FormulaCalcColLst.includes(colnm)) retval = true;
        return retval;
    };
    const calculateAllFormulas = () => {
        if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
            gridApi.current.forEachNode((event) => { calculateReactFormula(event) });
            gridApi.current.refreshCells({ force: true });
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
    };
    const calculateReactFormula = (event) => {
        fxcalc("BLENDED") ? event.data["BLENDED"] = calculateBlended(event) : null;
    };

    const onSortChanged = (event) => {
        const colState = gridApi.current.getColumnState();
        const savedSortState = colState
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            });
        dispatch({ type: "SET_FRIGHT_MASTER_SORTING", data: savedSortState });
    }
    const onFilterChanged = (event) => {
        const savedFilterModel = gridApi.current.getFilterModel();
        dispatch({ type: "SET_FRIGHT_MASTER_FILTERS", data: savedFilterModel });
    }

    const resteAutoChangeState = () => {
        setResetFilter("set");
        setAutoChangeState(false);
        setAutoGetChangeResult({});
    }
    const setCellValueChanged = (data) => {
        cellValueChanged.current = data;
    }
    return (
        <CustomAgGrid
            setCurrentGridApi={onGridReady}
            filters={FreightMasterFilters}
            setActionItemDataFun={setActionItemDataFun}
            tab_name="Freight_Master"
            tbl_name={tbl_name}
            columnDefs={tmpColDef}
            rowData={FreightMasterData}
            SSNData={SSNData}
            tblHighlightCells={FreightMasterHighlistCell}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            autoChangeState={autoChangeState}
            resteAutoChangeState={resteAutoChangeState}
            autoGetChangeResult={autoGetChangeResult}
            setCellValueChanged={setCellValueChanged}
            calculateFormula={calculateReactFormula}
            isGrouppedHeader={true}
            columnPref={columnPref}
            columnGrpPref={columnGrpPref}
        />
    )
})

FreightMasterTab.propTypes = {
    setCurrentGridApi: PropTypes.func.isRequired,
    settimeLineDataFun: PropTypes.func.isRequired,


};

export default FreightMasterTab;