import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from 'components/MDButton';

const ConfirmationDialog = ({
    open,
    onClose,
    onConfirm,
    title = 'Are you sure?',
    description = 'This action cannot be undone.',
    cancelText = 'Cancel',
    confirmText = 'Delete',
    severity = 'info', // 'error' | 'warning' | 'info' | 'success'
  }) => {
    const getButtonColor = () => {
      switch (severity) {
        case 'error':
          return 'error';
        case 'warning':
          return 'warning';
        case 'info':
          return 'info';
        case 'success':
          return 'success';
        default:
          return 'info';
      }
    };
  
    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={onClose} color={getButtonColor()} variant="outlined">
            {cancelText}
          </MDButton>
          <MDButton 
            onClick={() => {
              onConfirm();
              onClose();
            }} 
            color={getButtonColor()}
            // variant="contained"
            // autoFocus
          >
            {confirmText}
          </MDButton>
        </DialogActions>
      </Dialog>
    );
  };

  export default ConfirmationDialog;