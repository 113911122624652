import PropTypes from "prop-types";
import CustomAgGrid from "../CustomAgGrid";
import { useDispatch, useSelector } from "react-redux";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { checkHeihlight, executeDataVerification, generateColumnGrouping, getColumnStyle, isEditable, JsDateToExcelDateNumber, toastMessage, CH1, CH2, CH3, CH4, CH5, CH6, CH7, formatDateToMmDdYyyyHhMmSsAmPm } from "layouts/linelist/XL_Utils";
import { colGrpMatrix } from "layouts/linelist/colGrpVariable";
import { setHighlightByTblName, compareAndHighLightDataChanges, GetAutoChanges } from "layouts/linelist/getChangesComman";

const tbl_name = "xl_faherty_matrix";
const rng_name = "Matrix_Rng";

const MatrixTab = forwardRef(({ setCurrentGridApi }, ref) => {
    const dispatch = useDispatch();
    const gridApi = useRef({});
    const gridColumnApi = useRef({});
    const MatrixData = useSelector((s) => s.MatrixData);
    const MatrixSsnData = useSelector((s) => s.MatrixSsnData);
    const varSorting = useSelector((s) => s.MatrixSorting || []);
    const varFilters = useSelector((s) => s.MatrixFilters || {});
    const [MatrixColDef, setMatrixColDef] = useState([]);
    const tmpColDef = useSelector((s) => s.MatrixColDef || []);
    const [isGridReady, setIsGridReady] = useState(false);
    const [autoChangeState, setAutoChangeState] = useState(false);
    const [autoGetChangeResult, setAutoGetChangeResult] = useState({});
    const cellValueChanged = useRef([]);
    const highlightCells = useRef([]);
    const MatrixHighlightCell = useSelector((s) => s.MatrixHighlightCell);
    const columnPref = useSelector((s) => s.MatrixColPref);
    const columnGrpPref = useSelector((s) => s.MatrixGrpColPref);
    const FormulaCalcColLst = useSelector((s) => s.formulaCalcColLst[0][tbl_name]);
    useEffect(() => {
        // console.log("MatrixData -> ", MatrixData);
        // console.log("tmpColDef -> ", tmpColDef);
        if (tmpColDef && tmpColDef.length > 0) {
            let temp = tmpColDef;
            temp = temp.map((col) => {
                let mpCol = col
                mpCol.suppressColumnsToolPanel = col.hide;
                mpCol.suppressFiltersToolPanel = col.hide;

                // console.log("mpCol -> ", mpCol);
                if (mpCol.field.includes('FP WEEKS MATRIX - ')) {
                    mpCol.headerName = mpCol.headerName.replace('FP WEEKS MATRIX - ', '');
                } else if (mpCol.field.includes('Size Count and Mix - ')) {
                    mpCol.headerName = mpCol.headerName.replace('Size Count and Mix - ', '');
                } else if (mpCol.field.includes('SSN Code Coverage Matrix - ')) {
                    mpCol.headerName = mpCol.headerName.replace('SSN Code Coverage Matrix - ', '');
                } else if (mpCol.field.includes('Return Rate/Coverage Matrix - ')) {
                    mpCol.headerName = mpCol.headerName.replace('Return Rate/Coverage Matrix - ', '');
                } else if (mpCol.field.includes('MD ST Override - ')) {
                    mpCol.headerName = mpCol.headerName.replace('MD ST Override - ', '');
                } else if (mpCol.field.includes('Disc Rate Coverage - Full Price ')) {
                    mpCol.headerName = mpCol.headerName.replace('Disc Rate Coverage - Full Price ', '');
                } else if (mpCol.field.includes('Store Counts Matrix - ')) {
                    mpCol.headerName = mpCol.headerName.replace('Store Counts Matrix - ', '');
                } else if (mpCol.field.includes('Disc Rate Coverage - Final Full Price ')) {
                    mpCol.headerName = mpCol.headerName.replace('Disc Rate Coverage - Final Full Price ', 'Final ');
                } else if (mpCol.field.includes('Disc Rate Coverage - Markdown Price ')) {
                    mpCol.headerName = mpCol.headerName.replace('Disc Rate Coverage - Markdown Price ', '');
                } else if (mpCol.field.includes('Disc Rate Coverage - Final Markdown Price ')) {
                    mpCol.headerName = mpCol.headerName.replace('Disc Rate Coverage - Final Markdown Price ', 'Final ');
                } else if (mpCol.field.includes('Disc Rate Coverage - ')) {
                    mpCol.headerName = mpCol.headerName.replace('Disc Rate Coverage - ', '');
                }

                mpCol.cellStyle = params => { return funCellStyle(params, col) }
                return mpCol;
            });
            // console.log("temp -> ", temp);
            setMatrixColDef(generateColumnGrouping(colGrpMatrix, temp));
        }
    }, [MatrixData]);
    useEffect(() => {
        highlightCells.current = MatrixHighlightCell;
        if (isGridReady) gridApi.current.refreshCells({ force: true })
    }, [MatrixHighlightCell])
    const funCellStyle = (params, col) => {
        if (isGridReady && gridColumnApi?.current?.isPivotMode()) return;
        if (!params?.node?.data && !params?.node?.data?.row_id) return;
        const value = params.value ? params.value : "";
        let bgColor = "";
        let bdrColor = "";
        let txtDeco = "";
        let fontColor = "#000000";
        let boldTxt = "normal";
        let borderBottom = "";
        let fontStyle = "normal";
        const customCode = getColumnStyle(tmpColDef, value, params, col);
        if (customCode.status) {
            bgColor = customCode.bgColor;
            bdrColor = customCode.bdrColor;
            txtDeco = customCode.txtDeco;
            fontColor = customCode.fontColor;
            boldTxt = customCode.boldTxt;
            borderBottom = customCode.borderBottom;
            fontStyle = customCode.fontStyle;
        } else {
            const editable = isEditable(params, col.field, col.editable, col.insert_editable);
            if (editable) bgColor = "#FFFFFF";
            else bgColor = "#F0F0F0";
            if (col.type === "dropdown") bgColor = "#FDFCEC";
        }
        // let key = tbl_name + "|" + col.headerName.trim() + "|" + params.node.data.row_id
        let key = tbl_name + "|" + col.field.trim() + "|" + params.node.data.row_id
        let obj = checkHeihlight(key, highlightCells.current)
        if (obj.status === true) {
            if (obj.colorCode === 1) bgColor = "cyan";
            else if (obj.colorCode === 2) bgColor = "#d5a7f2";
            else if (obj.colorCode === 3) bdrColor = "#2196f3";
            else if (obj.colorCode === 4) bdrColor = "#9B6155";
            else if (obj.colorCode === 5) bgColor = "#CAE2F9";
        }
        if (params.node.rowPinned === "bottom" && params.node.rowIndex === 0) { bgColor = "#C7F9CC"; boldTxt = "bold"; txtDeco = "" }
        if (params.node.rowPinned === "bottom" && params.node.rowIndex === 1) { bgColor = "#FBF8CC"; boldTxt = "bold"; txtDeco = "" }
        if (bdrColor !== "")
            return { backgroundColor: bgColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor, borderColor: bdrColor, fontStyle: fontStyle }
        else
            return { backgroundColor: bgColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor, borderBottomColor: borderBottom, fontStyle: fontStyle }
    };
    const onFilterChanged = () => {
        const savedFilterModel = gridApi.current.getFilterModel();
        dispatch({ type: "MatrixFilters", data: savedFilterModel });
    };
    const onSortChanged = () => {
        const colState = gridApi.current.getColumnState();
        const savedSortState = colState
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            });
        dispatch({ type: "MatrixSorting", data: savedSortState });
    };
    const setResetFilter = (s) => {
        gridApi.current.applyColumnState({ state: s === "reset" ? null : varSorting });
        gridApi.current.setFilterModel(s === "reset" ? null : varFilters);
    };
    useImperativeHandle(ref, () => ({
        validateSendData: () => {
            if (!MatrixData) return false;
            const res = executeDataVerification(
                null,
                tbl_name,
                highlightCells.current,
                gridApi.current,
                gridColumnApi.current
            );
            if (res.status === false) {
                highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
                gridApi.current.refreshCells({ force: true })
                return res;
            }
            removeHeihlight();
            cellValueChanged.current = [];
            return ({ status: true });
        },
        getChangesFunc: async (masterData, metaData) => {
            const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, tmpColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData);
            if (res.status) {
                highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
                dispatch({ type: "MatrixData", data: res.data });
                gridApi.current.refreshCells({ force: true });
            } else {
                toastMessage(res.msg, "warning");
            }
            return res;
        },
        getAutoChanges: (data, downloadType) => {
            setResetFilter("reset");
            const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType);
            setAutoChangeState(true);
            setAutoGetChangeResult(res);
            setResetFilter("set")
        },
    }));
    const removeHeihlight = () => {
        highlightCells.current = [];
        if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true });
    };
    const resteAutoChangeState = () => {
        setResetFilter("set");
        setAutoChangeState(false);
        setAutoGetChangeResult({});
    };
    const setCellValueChanged = (data) => {
        cellValueChanged.current = data;
    };
    const calculate_FP_WEEKS_MATRIX_Concat = (event) => {
        const cn1 = 'FP WEEKS MATRIX - Season Lifecycle Code';
        const cn2 = 'FP WEEKS MATRIX - RO CODE';
        const cn3 = 'FP WEEKS MATRIX - Delivery Month';
        const cv1 = (event.data[cn1] ? event.data[cn1] : '');
        const cv2 = (event.data[cn2] ? event.data[cn2] : '');
        const cv3 = (event.data[cn3] ? event.data[cn3] : '');
        const resultVal = (cv1 + cv2 + cv3);
        return resultVal;
    }
    const fxcalc = (colnm) => {
        let retval = false;
        if (FormulaCalcColLst.includes(colnm)) retval = true;
        return retval;
    };
    const calculateAllFormulas = () => {
        // console.log("In calculateAllFormulas -> FormulaCalcColLst -> ", FormulaCalcColLst);
        if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
            gridApi.current.forEachNode((event) => { calculateReactFormula(event) });
            gridApi.current.refreshCells({ force: true });
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
    };
    const calculateReactFormula = (event) => {
        const cn1 = 'FP WEEKS MATRIX - Concat'
        fxcalc(cn1) ? event.data[cn1] = calculate_FP_WEEKS_MATRIX_Concat(event) : null;

        // Disc Rate Coverage - Properties
    };
    const onGridReady = (params) => {
        gridApi.current = params.api;
        gridColumnApi.current = params.columnApi;
        setCurrentGridApi(params);
        calculateAllFormulas();
        setIsGridReady(true);
    };

    return (
        <CustomAgGrid
            autoChangeState={autoChangeState}
            autoGetChangeResult={autoGetChangeResult}
            columnDefs={tmpColDef}
            customColumnDef={MatrixColDef}
            rowData={MatrixData}
            filters={varFilters}
            isGrouppedHeader={true}
            SSNData={MatrixSsnData}
            // calculateBottomRow={calcBottomRow}
            // pinnedBottomRowData={bottomRowData}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            resteAutoChangeState={resteAutoChangeState}
            setCellValueChanged={setCellValueChanged}
            calculateFormula={calculateReactFormula}
            setCurrentGridApi={onGridReady}
            tab_name="MATRIX"
            tbl_name={tbl_name}
            tblHighlightCells={MatrixHighlightCell}
            columnGrpPref={columnGrpPref}
            columnPref={columnPref}
        />
    );
});

MatrixTab.propTypes = {
    setCurrentGridApi: PropTypes.func.isRequired,
};

export default MatrixTab;