import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid, Paper, Divider } from '@mui/material';
import { CheckCircle, Schedule, TableChart, TimerOutlined, PostAdd, Description, Person } from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';

const InfoItem = ({ icon, label, value }) => (
    <Box display="flex" alignItems="center" mb={2}>
        <Box mr={2} color="primary.main">
            {icon}
        </Box>
        <Box>
            <Typography variant="body2" color="textSecondary" fontSize='15px' fontWeight='normal'>
                {label}
            </Typography>
            <Typography variant="body1" fontSize='15px' fontWeight='normal'>
                {value}
            </Typography>
        </Box>
    </Box>
);

const AirtableIntegrationNotification = ({ selectedComponent }) => {
    // console.log("AirtableIntegrationNotification -> selectedComponent -> ", selectedComponent);

    return (
        <div>
            <Paper elevation={0} sx={{ bgcolor: 'background.default', p: 1, mb: 1, mt: 1 }}>
                <Grid container spacing={0.5}>
                    <Grid item xs={12} sm={6}>
                        <InfoItem
                            icon={<Person sx={{ color: '#1A73E8' }} />}
                            label="Triggered by"
                            value={selectedComponent?.triggered_by}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InfoItem
                            icon={<Schedule sx={{ color: '#1A73E8' }} />}
                            label="Integration completed at"
                            value={selectedComponent?.integration_completed_at}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InfoItem
                            icon={<TimerOutlined sx={{ color: '#1A73E8' }} />}
                            label="Process duration"
                            value={selectedComponent?.process_duration}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InfoItem
                            icon={<Description sx={{ color: '#1A73E8' }} />}
                            label="Tabs updated"
                            value={selectedComponent?.tabs_updated}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InfoItem
                            icon={<PostAdd sx={{ color: '#1A73E8' }} />}
                            label="Total records impacted"
                            value={selectedComponent?.total_records_impacted}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Box display="flex" alignItems="center">
                <CheckCircle color="success" sx={{ mr: 1 }} />
                <Typography variant="h6">{selectedComponent?.msg}</Typography>
            </Box>
        </div>
    );
};

export default AirtableIntegrationNotification;