import { xlRound } from 'layouts/linelist/XL_Calculation';
import { checkActionItemAssigned, checkHeihlight, executeDataVerification, getColumnStyle, toastMessage } from 'layouts/linelist/XL_Utils';
import { GetAutoChanges, compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_faherty_whsl_buy_sheet";
const rng_name = "Whsl_Buy_Sheet_Rng";

const WhslBuySheetTab = forwardRef(({ setCurrentGridApi, settimeLineDataFun, setActionItemDataFun, clearFilterBtnCall }, ref) => {
  const dispatch = useDispatch();
  const ActionItemData = useSelector(state => state.ActionItemData);
  const FormulaCalcColLst = useSelector(s => s.formulaCalcColLst[0][tbl_name]);
  const [isGridReady, setIsGridReady] = useState(false);
  const WhslBuySheetData = useSelector(state => state.WhslBuySheetData);
  const SSNData = useSelector((s) => s.WhslBuySheetDataSSN);
  const WhslBuySheetHighlistCell = useSelector(state => state.WhslBuySheetHighlistCell);
  const [WhslBuySheetColDef, setColumnDef] = useState([]);
  const ColumnDef = useSelector(state => state.WhslBuySheetColDef ? state.WhslBuySheetColDef : []);
  const columnGrpPref = useSelector((s) => s.WhslBuySheetGrpColPref);
	const columnPref = useSelector((s) => s.WhslBuySheetColPref);

  const WhslBuySheetSorting = useSelector(state => state.WhslBuySheetSorting ? state.WhslBuySheetSorting : []);
  const WhslBuySheetFilters = useSelector(state => state.WhslBuySheetFilters ? state.WhslBuySheetFilters : {});

  const refBuyShtMasterDataCharts = useSelector(state => state.BuyShtMasterDataChartsData);
  const refBuyShtMasterDataChartsChange = useSelector(state => state.BuyShtMasterDataChartsHighlistCell);
  const refWhslBuySheetNordRp = useSelector(state => state.WhslBuySheetNordRpData);
  const refWhslBuySheetNordRpChange = useSelector(state => state.WhslBuySheetNordHighlistCell);
  const refSizing = useSelector(state => state.SizingData);
  const refSizingChange = useSelector(state => state.SizingHighlistCell);
  const highlightCells = useRef([]);
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const cellValueChanged = useRef([]);
  const footerRowCalCols = ["Total Units for INTERNATIONAL", "Total Wholesale Units", "Wholesale Chase Units", "Wholesale Chase Units + Total Wholesale Units"]
  const [bottomRowData, setBottomRowData] = useState([]);
  const [autoChangeState, setAutoChangeState] = useState(false)
  const [autoGetChangeResult, setAutoGetChangeResult] = useState({});
  const setResetFilter = (setReset) => {
    let sorting = WhslBuySheetSorting
    let filtering = WhslBuySheetFilters
    if (setReset === "reset") filtering = sorting = null
    gridApi.current.applyColumnState({ state: sorting })
    gridApi.current.setFilterModel(filtering);
  }
  useImperativeHandle(ref, () => ({
    validateSendData: () => {
      if (!WhslBuySheetData) return false
      const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
      if (res.status === false) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        return (res)
      }
      cellValueChanged.current = [];
      removeHeihlight();
      return ({ status: true })
    },
    getChangesFunc: async (masterData, metaData) => {
      const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, tmpColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
      if (res.status) {
        highlightCells.current = res.highlightCells
        dispatch({ type: "SET_WHSL_BY_SHT_DATA", data: res.data });
        gridApi.current.refreshCells({ force: true })
      } else {
        toastMessage(res.msg, "warning");
      }
      return res
    },
    getAutoChanges: (data, downloadType) => {
      setResetFilter("reset")
      const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
      setAutoChangeState(true);
      setAutoGetChangeResult(res);
    }
  }));
  const removeHeihlight = () => {
    highlightCells.current = []
    if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true })
  };
  const calculateTtlActiveAccSpecialty = (event) => {
    const Ranking = (event.data["Ranking"] || "").toLowerCase();
    const ActiveAccTotalSpecialty = parseFloat(event.data["Active Acc / Total Specialty"] || 0);
    const SpecialtyAvg = parseFloat(event.data["Specialty Avg"] || 0);
    const result = Ranking === "pass" ? 0 : xlRound(ActiveAccTotalSpecialty * SpecialtyAvg, 0);
    return result;
  };
  const calculateTtlNord = (event) => {
    const Ranking = (event.data["Ranking"] || "").toLowerCase();
    const NordstromDoors = parseFloat(event.data["Nordstrom Doors"] || 0);
    const NordstromAvg = parseFloat(event.data["Nordstrom Avg"] || 0);
    return Ranking === "pass" ? 0 : xlRound(NordstromDoors * NordstromAvg, 0);
  };
  const calculateTtlKeyAcct = (event) => {
    const Ranking = (event.data["Ranking"] || "").toLowerCase();
    const KeyAcct = parseFloat(event.data["Key Acct"] || 0);
    const KeyAcctAvg = parseFloat(event.data["Key Acct Avg"] || 0);
    return Ranking === "pass" ? 0 : xlRound(KeyAcct * KeyAcctAvg, 0);
  };
  const calculateTtlCorp = (event) => {
    const Ranking = (event.data["Ranking"] || "").toLowerCase();
    const CorpAcct = parseFloat(event.data["Corp Acct"] || 0);
    const CorpAcctAvg = parseFloat(event.data["Corp Acct Avg"] || 0);
    return Ranking === "pass" ? 0 : xlRound(CorpAcct * CorpAcctAvg, 0);
  };
  const calculateTtlIntr = (event) => {
    const Ranking = (event.data["Ranking"] || "").toLowerCase();
    const IntrAcc = parseFloat(event.data["Intr Acc"] || 0);
    const IntrAccAvg = parseFloat(event.data["Intr Acc Avg"] || 0);
    return Ranking === "pass" ? 0 : xlRound(IntrAcc * IntrAccAvg, 0);
  };
  const calculateTtlNordRP = (event) => {
    let SumOfTotalNordsReplenUnits = 0;
    const Ranking = (event.data["Ranking"] || "").toLowerCase();
    const Year = event.data["Year"] || "";
    const SeasonCode = event.data["Season Code"] || "";
    const Department = event.data["Department"] || "";
    const Category = event.data["Category"] || "";
    const Subcategory = event.data["Subcategory"] || "";
    const SKU = event.data["SKU"] || "";
    if (Ranking === "pass") SumOfTotalNordsReplenUnits = 0;
    else {
      if (Year !== "" && SeasonCode !== "" && Department !== "" && Category !== "" && Subcategory !== "" && SKU !== "") {
        for (let i = 0; i < refWhslBuySheetNordRp.length; i++) {
          if (refWhslBuySheetNordRp[i]["Year"] === Year &&
            refWhslBuySheetNordRp[i]["Season Code"] === SeasonCode &&
            refWhslBuySheetNordRp[i]["Department"] === Department &&
            refWhslBuySheetNordRp[i]["Category"] === Category &&
            refWhslBuySheetNordRp[i]["Subcategory"] === Subcategory &&
            refWhslBuySheetNordRp[i]["SKU"] === SKU)
            SumOfTotalNordsReplenUnits += parseInt(refWhslBuySheetNordRp[i]["Total Nords Replen Units"] || 0);
        }
      }
    }
    return SumOfTotalNordsReplenUnits;
  };
  const calculateATS = (event) => {
    let resultVal = 0;
    const Ranking = (event.data["Ranking"] || "").toLowerCase();
    const ActiveAccTotalSpecialty = parseFloat(event.data["Active Acc / Total Specialty"] || 0);
    const SpecialtyAvg = parseFloat(event.data["Specialty Avg"] || 0);
    const ATSLevel = event.data["ATS Level"] || "";
    const SeasonCode = event.data["Season Code"] || "";
    if (Ranking === "pass") resultVal = 0;
    else {
      let ATS_Per = 0;
      if (ATSLevel !== "" && SeasonCode !== "") {
        let bsmdc = refBuyShtMasterDataCharts;
        bsmdc = bsmdc.filter((i) => `${i["ATS Level"]}${i["Season Code"]}` === ATSLevel + SeasonCode);
        if (bsmdc.length > 0) ATS_Per = parseFloat(bsmdc[0]["ATS %"] || 0);
        resultVal = xlRound(ActiveAccTotalSpecialty * SpecialtyAvg * ATS_Per, 0);
      }
    }
    return resultVal;
  };
  const calculateTotalUnitsforUSONLY = (event) => {
    const TtlActiveAccSpecialty = parseInt(event.data["Ttl Active Acc / Specialty"] || 0);
    const TtlNord = parseInt(event.data["Ttl Nord"] || 0);
    const TtlNordRP = parseInt(event.data["Ttl Nord RP"] || 0);
    const TtlKeyAcct = parseInt(event.data["Ttl Key Acct"] || 0);
    const TtlCorp = parseInt(event.data["Ttl Corp"] || 0);
    const ATS = parseInt(event.data["ATS"] || 0);
    const Evereve = parseInt(event.data["Evereve"] || 0);
    return TtlActiveAccSpecialty + TtlNord + TtlNordRP + TtlKeyAcct + TtlCorp + ATS + Evereve;
  };
  const calculateTotalWholesaleUnits = (event) => {
    let WholesaleTotalUnitOverride = event.data["Wholesale Total Unit Override"] || "";
    let TotalUnitsforUSONLY = parseInt(event.data["Total Units for US ONLY"] || 0);
    let TotalUnitsforINTERNATIONAL = parseInt(event.data["Total Units for INTERNATIONAL"] || 0);
    return WholesaleTotalUnitOverride === "" ? TotalUnitsforUSONLY + TotalUnitsforINTERNATIONAL : parseInt(WholesaleTotalUnitOverride);
  };
  const calculateWhslChaseUnitsTotalWhslUnits = (event) => {
    return parseInt(event.data["Total Wholesale Units"] || 0) + parseInt(event.data["Wholesale Chase Units"] || 0);
  };
  const calculateTotalforCAD = (event) => {
    return xlRound((parseInt(event.data["Total Units for INTERNATIONAL"] || 0) * parseFloat(event.data["WHLS"] || 0)).toFixed(10), 6);
  };
  const calculateTotalforUSONLY = (event) => {
    const TotalWholesaleUnits = parseFloat(event.data["Total Wholesale Units"] || 0);
    const WHLS = parseFloat(event.data["WHLS"] || 0);
    const Total$forCAD = parseFloat(event.data["Total $ for CAD"] || 0);
    return xlRound(((TotalWholesaleUnits * WHLS) - Total$forCAD).toFixed(10), 6);
  };
  const calculateTotalWholesale = (event) => {
    return xlRound((parseFloat(event.data["Total $ for US ONLY"] || 0) + parseFloat(event.data["Total $ for CAD"] || 0)).toFixed(10), 6);
  };
  const calculateMonth = (event, paramMonth) => {
    let resultVal = 0;
    let Year = event.data["Year"] || "";
    let SeasonCode = event.data["Season Code"] || "";
    let Department = event.data["Department"] || "";
    let SKU = event.data["SKU"] || "";
    if (Year !== "" && SeasonCode !== "" && Department !== "" && SKU !== "") {
      let sz = refSizing.filter((i) => (`${i["Year"]}${i["Season Code"]}${i["Department"]}${i["SKU"]}${i["Delivery"]}${i["Location"]}`).trim().toLowerCase() === (Year + SeasonCode + Department + SKU + paramMonth + "Wholesale").trim().toLowerCase());
      sz.map(item => resultVal += parseInt(item["Line Total Units"] || 0));
    }
    return resultVal;
  };
  const calculateTotal = (event) => {
    const JAN = parseInt(event.data["JAN"] || 0);
    const FEB = parseInt(event.data["FEB"] || 0);
    const MAR = parseInt(event.data["MAR"] || 0);
    const APR = parseInt(event.data["APR"] || 0);
    const MAY = parseInt(event.data["MAY"] || 0);
    const JUN = parseInt(event.data["JUN"] || 0);
    const JUL = parseInt(event.data["JUL"] || 0);
    const AUG = parseInt(event.data["AUG"] || 0);
    const SEP = parseInt(event.data["SEP"] || 0);
    const OCT = parseInt(event.data["OCT"] || 0);
    const NOV = parseInt(event.data["NOV"] || 0);
    const DEC = parseInt(event.data["DEC"] || 0);
    return JAN + FEB + MAR + APR + MAY + JUN + JUL + AUG + SEP + OCT + NOV + DEC;
  };
  const calculateDoorsBasedonStoreTier = (event) => {
    let resultVal = 0;
    const StoreTier = event.data["Store Tier"] || "";
    const SeasonCode = event.data["Season Code"] || "";
    const Department = event.data["Department"] || "";
    if (StoreTier !== "" && SeasonCode !== "" && Department !== "") {
      let f = refBuyShtMasterDataCharts.filter((i) => `${i["STORE TIER"]}${i["Season Code"]}${i["Department"]}` === StoreTier + SeasonCode + Department);
      if (f.length > 0) resultVal = parseInt(f[0]["Doors"] || 0);
    }
    return resultVal;
  };
  const calculateMonthDLR = (event, month) => {
    return xlRound((parseFloat(event.data[month] || 0) * parseFloat(event.data["WHLS"] || 0)).toFixed(10), 6);
  };
  const isEditableWHSLBuySheet = function (params, field, editable, insertEditable) {
    if (params.node.data["Planning Lock"] === "Master Lock - Buy" && whenplaninglocksetlock.includes(field)) return false;
    else return editable;
  };
  const funCellStyle = (params, col) => {
    const value = params.value ? params.value : "";
    let bgColor = "";
    let bdrColor = "";
    let txtDeco = "";
    let fontColor = "#000000";
    let boldTxt = "normal";
    let borderBottom = "";
    const editable = isEditableWHSLBuySheet(params, col.field, col.editable, col.insert_editable);
    if (editable) bgColor = "#FFFFFF";
    else bgColor = "#F0F0F0";
    if (col.type === "dropdown" && editable) bgColor = "#FDFCEC";

    // custom code
    const customCode = getColumnStyle(ColumnDef, value, params, col);
    if (customCode.status) {
      if (customCode.bgColor && customCode.bgColor !== "") bgColor = customCode.bgColor;
      if (customCode.bdrColor && customCode.bdrColor !== "") bdrColor = customCode.bdrColor;
      if (customCode.txtDeco && customCode.txtDeco !== "") txtDeco = customCode.txtDeco;
      if (customCode.fontColor && customCode.fontColor !== "") fontColor = customCode.fontColor;
      if (customCode.boldTxt && customCode.boldTxt !== "") boldTxt = customCode.boldTxt;
      if (customCode.borderBottom && customCode.borderBottom !== "") borderBottom = customCode.borderBottom;
      if (customCode.fontStyle && customCode.fontStyle !== "") fontStyle = customCode.fontStyle;
    }

    if (params.node.rowPinned === "bottom" && params.node.rowIndex === 0) { bgColor = "#C7F9CC"; boldTxt = "bold" }
    if (params.node.rowPinned === "bottom" && params.node.rowIndex === 1) { bgColor = "#FBF8CC"; boldTxt = "bold" }

    let key = tbl_name + "|" + col.headerName.trim() + "|" + params.node.data.row_id
    let obj = checkHeihlight(key, highlightCells.current)
    if (obj.status === true) {
      if (obj.colorCode == 1) bgColor = "cyan";
      else if (obj.colorCode == 2) bgColor = "#d5a7f2";
      else if (obj.colorCode == 3) bdrColor = "#2196f3";
      else if (obj.colorCode === 4) bdrColor = "#9B6155";
    }
    if (params.node.data["Working Line"] === "Working") bgColor = "#FFE699";
    if (checkActionItemAssigned(ActionItemData, params, tbl_name)) {
      borderBottom = "purple"; boldTxt = "bold";
    }
    if (bdrColor !== "")
      return { backgroundColor: bgColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor, borderColor: bdrColor }
    else
      return { backgroundColor: bgColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor, borderBottomColor: borderBottom }
  };
  useEffect(() => {
    if (ColumnDef && ColumnDef.length > 0 && WhslBuySheetColDef.length === 0) {
      let temp = ColumnDef;
      temp = temp.map((col) => {
        let mpCol = col
        mpCol = {
          headerName: col.headerName,
          field: col.field,
          colId: col.colId,
          width: col.wdth,
          editable: params => isEditableWHSLBuySheet(params, col.field, col.editable, col.insert_editable),
          hide: col.hide,
          myType: col.type,
          autoHeaderHeight: true,
          cellEditor: col.type === "dropdown" || col.type === "date" ? col.cellEditor : "",
          cellEditorParams: col.type === "dropdown" || col.type === "date" ? col.cellEditorParams : "",
          suppressKeyboardEvent: col.suppressKeyboardEvent,
          filter: col.filter,
          filterParams: col.filterParams,
          width: col.width,
          resizable: col.resizable,
          wrapText: col.wrapText,
          cellStyle: params => { return funCellStyle(params, col) },
          valueFormatter: col.valueFormatter,
          suppressColumnsToolPanel: col.hide,
          valueParser: col.valueParser,
          cellClass: col.type === "dropdown" ? "" : col.type
        }
        return mpCol;
      })
      setColumnDef(temp)
    }
    if (isGridReady) {
      if (WhslBuySheetSorting !== null && WhslBuySheetSorting !== undefined) {
        gridApi.current.applyColumnState({
          state: WhslBuySheetSorting
        })
      }
      if (WhslBuySheetFilters !== null && WhslBuySheetFilters !== undefined) {
        gridApi.current.setFilterModel(WhslBuySheetFilters);
      }
      calcBottomRow();
    }
  }, [isGridReady])
  useEffect(() => {
    highlightCells.current = WhslBuySheetHighlistCell;
    if (isGridReady) gridApi.current.refreshCells({ force: true })
  }, [WhslBuySheetHighlistCell])
  useEffect(() => {
    if (!refBuyShtMasterDataCharts || Object.keys(gridApi.current).length <= 0) return
    if (!refBuyShtMasterDataChartsChange || refBuyShtMasterDataChartsChange.length <= 0) return
    setResetFilter("reset")
    const arr = highlightCells.current
    const changes = []
    const columns = []
    refBuyShtMasterDataChartsChange.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refBuyShtMasterDataCharts.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })
    gridApi.current.forEachNode((event) => {
      const row_id = event.data.row_id
      if (columns.includes("ATS %") && fxcalc("ATS")) {
        if (event.data["ATS Level"] && event.data["Season Code"]) {
          const ch = changes.filter((element) => element["Season Code"].toLowerCase() === event.data["Season Code"].toLowerCase() &&
            element["ATS Level"].toString().toLowerCase() === event.data["ATS Level"].toString().toLowerCase())
          if (ch.length > 0) {
            event.data["ATS"] = calculateATS(event);
            arr.push({ [tbl_name + "|ATS|" + row_id]: 1 })
          }
        }
      }
      if (columns.includes("Doors") && fxcalc("Doors Based on Store Tier")) {
        if (event.data["Store Tier"] && event.data["Season Code"] && event.data["Department"]) {
          const ch = changes.filter((element) => element["Season Code"].toLowerCase() === event.data["Season Code"].toLowerCase() &&
            element["Department"].toLowerCase() === event.data["Department"].toLowerCase() &&
            element["STORE TIER"].toString().toLowerCase() === event.data["Store Tier"].toString().toLowerCase())
          if (ch.length > 0) {
            event.data["Doors Based on Store Tier"] = calculateDoorsBasedonStoreTier(event);
            arr.push({ [tbl_name + "|Doors Based on Store Tier|" + row_id]: 1 })
          }
        }
      }
    })
    setResetFilter("set")
    highlightCells.current = arr
    gridApi.current.refreshCells({ force: true });
  }, [refBuyShtMasterDataCharts, refBuyShtMasterDataChartsChange])
  useEffect(() => {
    if (!refWhslBuySheetNordRp || Object.keys(gridApi.current).length <= 0) return
    if (!refWhslBuySheetNordRpChange || refWhslBuySheetNordRpChange.length <= 0) return
    setResetFilter("reset")
    const arr = highlightCells.current
    const changes = []
    const columns = []
    refWhslBuySheetNordRpChange.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refWhslBuySheetNordRp.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })
    gridApi.current.forEachNode((event) => {
      const row_id = event.data.row_id
      if (columns.includes("Total Nords Replen Units") && fxcalc("Ttl Nord RP")) {
        if (event.data["Year"] && event.data["Season Code"] && event.data["Department"] && event.data["SKU"] && event.data["Category"] && event.data["Subcategory"]) {
          const ch = changes.filter((element) => element["Season Code"].toLowerCase() === event.data["Season Code"].toLowerCase() &&
            element["Year"].toLowerCase() === event.data["Year"].toLowerCase() &&
            element["SKU"].toLowerCase() === event.data["SKU"].toLowerCase() &&
            element["Department"].toLowerCase() === event.data["Department"].toLowerCase() &&
            element["Category"].toLowerCase() === event.data["Category"].toLowerCase() &&
            element["Subcategory"].toLowerCase() === event.data["Subcategory"].toLowerCase())
          if (ch.length > 0) {
            event.data["Ttl Nord RP"] = calculateTtlNordRP(event);
            arr.push({ [tbl_name + "|Ttl Nord RP|" + row_id]: 1 })
          }
        }
      }
    })
    setResetFilter("set")
    highlightCells.current = arr
    gridApi.current.refreshCells({ force: true });
  }, [refWhslBuySheetNordRp, refWhslBuySheetNordRpChange])
  useEffect(() => {
    if (!refSizing || Object.keys(gridApi.current).length <= 0) return
    if (!refSizingChange || refSizingChange.length <= 0) return
    const arr = highlightCells.current
    const changes = []
    const columns = []
    refSizingChange.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refSizing.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })
    gridApi.current.forEachNode((event) => {
      const row_id = event.data.row_id
      if (columns.includes("Line Total Units") || columns.includes("Location")) {
        if (event.data["Year"] && event.data["Season Code"] && event.data["Department"]) {
          const ch = changes.filter((element) => element["Season Code"].toLowerCase() === event.data["Season Code"].toLowerCase() &&
            element["Year"].toLowerCase() === event.data["Year"].toLowerCase() &&
            element["Department"].toLowerCase() === event.data["Department"].toLowerCase())
          if (ch.length > 0) {
            if (fxcalc("JAN")) {
              event.data["JAN"] = calculateMonth(event, "January");
              arr.push({ [tbl_name + "|JAN|" + row_id]: 1 });
            }
            if (fxcalc("FEB")) {
              event.data["FEB"] = calculateMonth(event, "February");
              arr.push({ [tbl_name + "|FEB|" + row_id]: 1 });
            }
            if (fxcalc("MAR")) {
              event.data["MAR"] = calculateMonth(event, "March");
              arr.push({ [tbl_name + "|MAR|" + row_id]: 1 });
            }
            if (fxcalc("APR")) {
              event.data["APR"] = calculateMonth(event, "April");
              arr.push({ [tbl_name + "|APR|" + row_id]: 1 });
            }
            if (fxcalc("MAY")) {
              event.data["MAY"] = calculateMonth(event, "May");
              arr.push({ [tbl_name + "|MAY|" + row_id]: 1 });
            }
            if (fxcalc("JUN")) {
              event.data["JUN"] = calculateMonth(event, "June");
              arr.push({ [tbl_name + "|JUN|" + row_id]: 1 });
            }
            if (fxcalc("JUL")) {
              event.data["JUL"] = calculateMonth(event, "July");
              arr.push({ [tbl_name + "|JUL|" + row_id]: 1 });
            }
            if (fxcalc("AUG")) {
              event.data["AUG"] = calculateMonth(event, "August");
              arr.push({ [tbl_name + "|AUG|" + row_id]: 1 });
            }
            if (fxcalc("SEP")) {
              event.data["SEP"] = calculateMonth(event, "September");
              arr.push({ [tbl_name + "|SEP|" + row_id]: 1 });
            }
            if (fxcalc("OCT")) {
              event.data["OCT"] = calculateMonth(event, "October");
              arr.push({ [tbl_name + "|OCT|" + row_id]: 1 });
            }
            if (fxcalc("NOV")) {
              event.data["NOV"] = calculateMonth(event, "November");
              arr.push({ [tbl_name + "|NOV|" + row_id]: 1 });
            }
            if (fxcalc("DEC")) {
              event.data["DEC"] = calculateMonth(event, "December");
              arr.push({ [tbl_name + "|DEC|" + row_id]: 1 });
            }
          }
        }
      }
    })
    highlightCells.current = arr
    gridApi.current.refreshCells({ force: true });
  }, [refSizing, refSizingChange])
  const onGridReady = (params) => {
    gridApi.current = params.api
    gridColumnApi.current = params.columnApi;
    setCurrentGridApi(params);
    calculateAllFormulas();
    setIsGridReady(true)
  };
  const fxcalc = (colnm) => {
    let retval = false;
    if (FormulaCalcColLst.includes(colnm)) retval = true;
    return retval;
  };
  const calculateAllFormulas = () => {
    if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
      gridApi.current.forEachNode((event) => { calculateReactFormula(event) });
      gridApi.current.refreshCells({ force: true });
    }
    dispatch({ type: "SET_PLEASE_WAIT", data: false });
  };
  const calculateReactFormula = (event) => {
    if (!event.data) return "";
    fxcalc("Ttl Active Acc / Specialty") ? event.data["Ttl Active Acc / Specialty"] = calculateTtlActiveAccSpecialty(event) : null;
    fxcalc("Ttl Nord") ? event.data["Ttl Nord"] = calculateTtlNord(event) : null;
    fxcalc("Ttl Key Acct") ? event.data["Ttl Key Acct"] = calculateTtlKeyAcct(event) : null;
    fxcalc("Ttl Corp") ? event.data["Ttl Corp"] = calculateTtlCorp(event) : null;
    fxcalc("Ttl Intr") ? event.data["Ttl Intr"] = calculateTtlIntr(event) : null;
    fxcalc("Ttl Nord RP") ? event.data["Ttl Nord RP"] = calculateTtlNordRP(event) : null;
    fxcalc("ATS") ? event.data["ATS"] = calculateATS(event) : null;
    fxcalc("Total Units for US ONLY") ? event.data["Total Units for US ONLY"] = calculateTotalUnitsforUSONLY(event) : null;
    fxcalc("Total Units for INTERNATIONAL") ? event.data["Total Units for INTERNATIONAL"] = event.data["Ttl Intr"] : null;
    fxcalc("Total Wholesale Units") ? event.data["Total Wholesale Units"] = calculateTotalWholesaleUnits(event) : null;
    fxcalc("Wholesale Chase Units + Total Wholesale Units") ? event.data["Wholesale Chase Units + Total Wholesale Units"] = calculateWhslChaseUnitsTotalWhslUnits(event) : null;
    fxcalc("Total $ for CAD") ? event.data["Total $ for CAD"] = calculateTotalforCAD(event) : null;
    fxcalc("Total $ for US ONLY") ? event.data["Total $ for US ONLY"] = calculateTotalforUSONLY(event) : null;
    fxcalc("Total Wholesale $") ? event.data["Total Wholesale $"] = calculateTotalWholesale(event) : null;
    fxcalc("JAN") ? event.data["JAN"] = calculateMonth(event, "January") : null;
    fxcalc("FEB") ? event.data["FEB"] = calculateMonth(event, "February") : null;
    fxcalc("MAR") ? event.data["MAR"] = calculateMonth(event, "March") : null;
    fxcalc("APR") ? event.data["APR"] = calculateMonth(event, "April") : null;
    fxcalc("MAY") ? event.data["MAY"] = calculateMonth(event, "May") : null;
    fxcalc("JUN") ? event.data["JUN"] = calculateMonth(event, "June") : null;
    fxcalc("JUL") ? event.data["JUL"] = calculateMonth(event, "July") : null;
    fxcalc("AUG") ? event.data["AUG"] = calculateMonth(event, "August") : null;
    fxcalc("SEP") ? event.data["SEP"] = calculateMonth(event, "September") : null;
    fxcalc("OCT") ? event.data["OCT"] = calculateMonth(event, "October") : null;
    fxcalc("NOV") ? event.data["NOV"] = calculateMonth(event, "November") : null;
    fxcalc("DEC") ? event.data["DEC"] = calculateMonth(event, "December") : null;
    fxcalc("Total") ? event.data["Total"] = calculateTotal(event) : null;
    fxcalc("Doors Based on Store Tier") ? event.data["Doors Based on Store Tier"] = calculateDoorsBasedonStoreTier(event) : null;
    fxcalc("$ JAN") ? event.data["$ JAN"] = calculateMonthDLR(event, "JAN") : null;
    fxcalc("$ FEB") ? event.data["$ FEB"] = calculateMonthDLR(event, "FEB") : null;
    fxcalc("$ MAR") ? event.data["$ MAR"] = calculateMonthDLR(event, "MAR") : null;
    fxcalc("$ APR") ? event.data["$ APR"] = calculateMonthDLR(event, "APR") : null;
    fxcalc("$ MAY") ? event.data["$ MAY"] = calculateMonthDLR(event, "MAY") : null;
    fxcalc("$ JUN") ? event.data["$ JUN"] = calculateMonthDLR(event, "JUN") : null;
    fxcalc("$ JUL") ? event.data["$ JUL"] = calculateMonthDLR(event, "JUL") : null;
    fxcalc("$ AUG") ? event.data["$ AUG"] = calculateMonthDLR(event, "AUG") : null;
    fxcalc("$ SEP") ? event.data["$ SEP"] = calculateMonthDLR(event, "SEP") : null;
    fxcalc("$ OCT") ? event.data["$ OCT"] = calculateMonthDLR(event, "OCT") : null;
    fxcalc("$ NOV") ? event.data["$ NOV"] = calculateMonthDLR(event, "NOV") : null;
    fxcalc("$ DEC") ? event.data["$ DEC"] = calculateMonthDLR(event, "DEC") : null;
  };
  const onSortChanged = (event) => {
    const colState = gridApi.current.getColumnState();
    const savedSortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch({ type: "SET_WHSL_BY_SHT_SORTING", data: savedSortState });
  }
  const onFilterChanged = (event) => {
    const savedFilterModel = gridApi.current.getFilterModel();
    dispatch({ type: "SET_WHSL_BY_SHT_FILTERS", data: savedFilterModel });
    calcBottomRow();
  }
  const calcBottomRow = () => {
    const result = {};
    footerRowCalCols.forEach(column => {
      if (!result[column]) result[column] = { btmRow1: 0, btmRow2: 0 };
    });
    let filtredData = [];
    try {
      gridApi.current.forEachNodeAfterFilter(node => {
        filtredData.push(node.data);
      });
    } catch { filtredData = WhslBuySheetData; }
    filtredData = filtredData.filter((i) => i["Style Activity"] === "ACTIVE");
    const activeRows = WhslBuySheetData.filter((i) => i["Style Activity"] === "ACTIVE");
    footerRowCalCols.forEach(column => {
      // result[column].btmRow1 = activeRows.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue[column] ? currentValue[column] : 0), 0)
      // result[column].btmRow2 = filtredData.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue[column] ? currentValue[column] : 0), 0)

      const total1 = activeRows.reduce((accumulator, currentValue) => {
        const value = parseInt(currentValue[column]);
        return accumulator + (isNaN(value) ? 0 : value);
      }, 0);
      const count1 = activeRows.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue[column] ? 1 : 0);
      }, 0);
      const average1 = count1 > 0 ? (total1 / count1).toFixed(0) : 0;
      result[column].btmRow1 = average1;

      const total2 = filtredData.reduce((accumulator, currentValue) => {
        const value2 = parseInt(currentValue[column]);
        return accumulator + (isNaN(value2) ? 0 : value2);
      }, 0);
      const count2 = filtredData.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue[column] ? 1 : 0);
      }, 0);
      const average2 = count2 > 0 ? (total2 / count2).toFixed(0) : 0;
      result[column].btmRow2 = average2;

    });
    if (Object.keys(result).length === 0) setBottomRowData([]);
    else {
      let row1 = []
      let row2 = []
      footerRowCalCols.forEach(column => {
        row1[column] = `${result[column]["btmRow1"]}`
        row2[column] = `${result[column]["btmRow2"]}`
      });
      setBottomRowData([row1, row2]);
    }
  };
  const resteAutoChangeState = () => {
    setResetFilter("set");
    setAutoChangeState(false);
    setAutoGetChangeResult({});
  }
  const setCellValueChanged = (data) => {
    cellValueChanged.current = data;
  }
  return (
    <CustomAgGrid
      setCurrentGridApi={onGridReady}
      filters={WhslBuySheetFilters}
      setActionItemDataFun={setActionItemDataFun}
      tab_name="Whsl_Buy_Sheet"
      tbl_name={tbl_name}
      columnDefs={ColumnDef}
      rowData={WhslBuySheetData}
      SSNData={SSNData}
      tblHighlightCells={WhslBuySheetHighlistCell}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      autoChangeState={autoChangeState}
      resteAutoChangeState={resteAutoChangeState}
      autoGetChangeResult={autoGetChangeResult}
      setCellValueChanged={setCellValueChanged}
      calculateFormula={calculateReactFormula}
      isGrouppedHeader={true}
      calculateBottomRow={calcBottomRow}
      pinnedBottomRowData={bottomRowData}
      customColumnDef={WhslBuySheetColDef}
      columnGrpPref={columnGrpPref}
			columnPref={columnPref}
    />
  )
})

WhslBuySheetTab.propTypes = {
  setCurrentGridApi: PropTypes.func.isRequired,
  settimeLineDataFun: PropTypes.func.isRequired,
};

export default WhslBuySheetTab;