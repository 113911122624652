import { xlRound, xlXlookup } from 'layouts/linelist/XL_Calculation';
import { CH1, executeDataVerification, toastMessage } from 'layouts/linelist/XL_Utils';
import { GetAutoChanges, compareAndHighLightDataChanges, setHighlightByTblName } from 'layouts/linelist/getChangesComman';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';

const tbl_name = "xl_pd_initial_costing";
const rng_name = "PD_Initial_Costing_Rng";

const PDInitialCostingTab = forwardRef(({ setCurrentGridApi, setActionItemDataFun }, ref) => {
    const dispatch = useDispatch(); //to update redux state variable
    const FormulaCalcColLst = useSelector(s => s.formulaCalcColLst[0][tbl_name]);
    const PDInitialCostingData = useSelector(state => state.PDInitialCostingData);
    const SSNData = useSelector((s) => s.PDInitialCostingDataSSN);

    const PDInitialCostingHighlistCell = useSelector(state => state.PDInitialCostingHighlistCell);
    const tmpColDef = useSelector(state => state.PDInitialCostingColDef ? state.PDInitialCostingColDef : []);
    const columnPref = useSelector((s) => s.PDInitialCostingColPref);

    const PDInitialCostingSorting = useSelector(state => state.PDInitialCostingSorting ? state.PDInitialCostingSorting : []);
    const PDInitialCostingFilters = useSelector(state => state.PDInitialCostingFilters ? state.PDInitialCostingFilters : {});
    const refFBFtyDatabase = useSelector(state => state.FbFtyDatabaseData);
    const refFreightMaster = useSelector(state => state.FreightMasterData);
    const refFreightByCategory = useSelector(state => state.FreightByCategoryData);

    const highlightCells = useRef([]);
    const gridApi = useRef({});
    const gridColumnApi = useRef({});
    const cellValueChanged = useRef([]);
    const [autoChangeState, setAutoChangeState] = useState(false)
    const [autoGetChangeResult, setAutoGetChangeResult] = useState({});
    const [isGridReady, setIsGridReady] = useState(false);

    const [gridView, setGridView] = useState(true);
    const [catalogView, setCatalogView] = useState(false);

    const setResetFilter = (setReset) => {
        let sorting = PDInitialCostingSorting
        let filtering = PDInitialCostingFilters
        if (setReset === "reset") {
            sorting = null
            filtering = null
        }
        gridApi.current.applyColumnState({
            state: sorting
        })
        gridApi.current.setFilterModel(filtering);
    }
    //Required for all tabs (For before send data functionalities)
    useImperativeHandle(ref, () => ({
        validateSendData: () => {
            if (!PDInitialCostingData) return false
            const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
            if (res.status === false) {
                highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
                gridApi.current.refreshCells({ force: true })
                return (res)
            }
            cellValueChanged.current = [];
            removeHeihlight();
            return ({ status: true })
        },
        getChangesFunc: async (masterData, metaData) => {
            const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, PDInitialCostingColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
            if (res.status) {
                highlightCells.current = res.highlightCells
                dispatch({ type: "SEASON_INQUIRY_DATA", data: res.data });
                gridApi.current.refreshCells({ force: true })
            } else {
                toastMessage(res.msg, "warning");
            }
            return res
        },
        getAutoChanges: (data, downloadType) => {
            setResetFilter("reset")
            const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
            setAutoChangeState(true);
            setAutoGetChangeResult(res);
        }
    }));
    const removeHeihlight = () => {
        highlightCells.current = []
        if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true });
    };
    useEffect(() => {
        if (isGridReady) {
            if (PDInitialCostingSorting !== null && PDInitialCostingSorting !== undefined) {
                gridApi.current.applyColumnState({
                    state: PDInitialCostingSorting
                })
            }
            if (PDInitialCostingFilters !== null && PDInitialCostingFilters !== undefined && Object.keys(PDInitialCostingFilters).length > 0) {
                gridApi.current.setFilterModel(PDInitialCostingFilters);
            } 
        }
    }, [isGridReady])

    const calculateInitialCost = (event) => {
        const BucketProjectionCost = event.data["Bucket Projection Cost"] || "";
        const SMScostperMOQ = event.data["SMS cost per MOQ"] || "";
        const ProtocostperMOQ = event.data["Proto cost per MOQ"] || "";
        const PlaceholderFlashFOB = event.data["Placeholder/Flash FOB"] || "";
        const PreviousFOB = event.data["Previous FOB"] || "";
        let resultVal = 0;
        if (BucketProjectionCost === "") {
            if (SMScostperMOQ === "") {
                if (ProtocostperMOQ === "") {
                    if (PlaceholderFlashFOB === "") resultVal = PreviousFOB;
                    else resultVal = PlaceholderFlashFOB;
                }
                else resultVal = ProtocostperMOQ;
            }
            else resultVal = SMScostperMOQ;
        }
        else resultVal = BucketProjectionCost;
        return resultVal === "" ? 0 : resultVal;
    }
    const calculateMerchFee = (event) => {
        let resultVal = 0.003464;
        let DutyRate = parseFloat(event.data["Duty Rate"] || 0);
        if (DutyRate === 0) resultVal = 0;
        return resultVal;
    };
    const calculateSection301 = (event) => {
        let resultVal = 0;
        let ProductionCOO = (event.data["Production COO"] || "").trim().toUpperCase();
        let COO = (event.data["COO"] || "").trim().toUpperCase();
        if (ProductionCOO === "") {
            if (['CHINA', 'HONG KONG'].includes(COO)) resultVal = 0.075;
        } else {
            if (['CHINA', 'HONG KONG'].includes(ProductionCOO)) resultVal = 0.075;
        }
        return resultVal;
    };
    const calculateDutyTotal = (event) => {
        return xlRound(parseFloat(event.data["Duty Rate"] || 0) + parseFloat(event.data["Merch Fee"] || 0) + parseFloat(event.data["Harbor Maintenance Fee"] || 0) + parseFloat(event.data["Section 301"] || 0), 6);
    };
    const calculateAgentCommissionRate = (event) => {
        let Factory = event.data["Factory"] || "";
        let resultVal = 0;
        if (Factory !== "")
            resultVal = xlXlookup(Factory, refFBFtyDatabase, 'Factory', 'Commission Percent');
        return resultVal === "" ? 0 : resultVal;
    };
    const calculateAirFreight = (event) => {
        let SeasonCode = (event.data["Season Code"] || "").toLowerCase();
        let COO = (event.data["COO"] || "").toLowerCase();
        let Category = (event.data["Category"] || "").toLowerCase();
        let AIR = 0;
        let FinalKG = 0
        refFreightMaster.forEach((element) => {
            if (element["Season"].toLowerCase() === SeasonCode && (element["COO"] || "").toLowerCase() === COO)
                AIR += parseFloat(element["AIR"] || 0);
        });
        refFreightByCategory.forEach((element) => {
            if (element["Season"].toLowerCase() === SeasonCode && (element["Category"] || "").toLowerCase() === Category)
                FinalKG += parseFloat(element["Final KG"] || 0);
        });
        return xlRound(AIR * FinalKG, 6);
    };
    const calculateSeaFreight = (event) => {
        let SeasonCode = (event.data["Season Code"] || "").toLowerCase();
        let COO = (event.data["COO"] || "").toLowerCase();
        let Category = (event.data["Category"] || "").toLowerCase();
        let BOAT = 0;
        let FinalKG = 0
        refFreightMaster.forEach((element) => {
            if (element["Season"].toLowerCase() === SeasonCode && (element["COO"] || "").toLowerCase() === COO)
                BOAT += parseFloat(element["BOAT"] || 0);
        });
        refFreightByCategory.forEach((element) => {
            if (element["Season"].toLowerCase() === SeasonCode && (element["Category"] || "").toLowerCase() === Category)
                FinalKG += parseFloat(element["Final KG"] || 0);
        });
        return xlRound(BOAT * FinalKG, 6);
    };
    const calculateBlendedFreight = (event) => {
        let AirFreight = parseFloat(event.data["Air Freight"] || 0);
        let SeaFreight = parseFloat(event.data["Sea Freight"] || 0);
        let AirSea = ((AirFreight * 0.35) + (SeaFreight * 0.65)).toFixed(10);
        if (parseFloat(AirSea) === 0) AirSea = 2;
        return xlRound(AirSea, 6);
    };
    const calculateLDP = (event) => {
        let InitialCost = parseFloat(event.data["Initial Cost"] || 0);
        let BlendedFreight = parseFloat(event.data["Blended Freight"] || 0);
        let DutyTotal = parseFloat(event.data["Duty Total"] || 0);
        let AgentCommissionRate = parseFloat(event.data["Agent Commission Rate"] || 0);
        let resultVal = (InitialCost + BlendedFreight + (InitialCost * DutyTotal) + (InitialCost * AgentCommissionRate)).toFixed(10);
        return xlRound(resultVal, 6);
    };
    const calculateRTLIMUPer = (event) => { // 
        let MSRPPerAirTable = parseFloat(event.data["MSRP (Per Air Table)"] || 0);
        let LDP = parseFloat(event.data["LDP"] || 0);
        let resultVal = "";
        if (MSRPPerAirTable !== 0) resultVal = xlRound(((MSRPPerAirTable - LDP) / MSRPPerAirTable).toFixed(10), 6);
        return resultVal;
    };
    const calculateScenarioRTLIMUPer = (event) => { // 
        let MSRP = parseFloat(event.data["Scenario MSRP"] || 0);
        let LDP = parseFloat(event.data["LDP"] || 0);
        let resultVal = "";
        if (MSRP !== 0) resultVal = xlRound(((MSRP - LDP) / MSRP).toFixed(10), 6);
        return resultVal;
    };
    const calculateScenarioWHLSIMUPer = (event) => {
        let WHLS = parseFloat(event.data["Scenario WHLS"] || 0);
        let LDP = parseFloat(event.data["LDP"] || 0);
        let resultVal = "";
        if (WHLS !== 0) resultVal = xlRound(((WHLS - LDP) / WHLS).toFixed(10), 6);
        return resultVal;
    };
    const calculateUnitProjection = (event) => {
        const Projection = (event.data["Bucket Projection (For Costing)"] || "").toLowerCase();
        switch (Projection) {
            case "high": return "2000+";
            case "medium": return "1000-1999";
            case "low": return "501-999";
            default: return "";
        }
    }
    const calculateCOO = (event) => {
        let coo = ""
        let factory = event.data["Factory"]
        if (factory === null || factory === undefined || factory === "") { factory = "" }
        if (factory !== "") {
            coo = xlXlookup(factory, refFBFtyDatabase, 'Factory', 'Factory Country')
            if (coo === null || coo === undefined || coo === "" || coo === "NaN") { coo = "" }
        }
        return coo;
    };
    const calculateCorporateAgent = (event) => {
        let corporate_agent = ""
        let factory = event.data["Factory"]
        if (factory === null || factory === undefined || factory === "") { factory = "" }
        if (factory !== "") {
            corporate_agent = xlXlookup(factory, refFBFtyDatabase, 'Factory', 'Corporate/Agent')
            if (corporate_agent === null || corporate_agent === undefined || corporate_agent === "" || corporate_agent === "NaN") { corporate_agent = "" }
        }
        return corporate_agent;
    };
    const fxcalc = (colnm) => {
        let retval = false;
        if (FormulaCalcColLst.includes(colnm)) retval = true;
        return retval;
    };
    const calculateAllFormulas = () => {
        if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
            gridApi.current.forEachNode((event) => { calculateReactFormula(event) });
            gridApi.current.refreshCells({ force: true });
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
    };
    const calculateReactFormula = (event) => {
        if (!event.data) return "";
        fxcalc("Harbor Maintenance Fee") ? event.data["Harbor Maintenance Fee"] = "0.0006" : null;
        fxcalc("Initial Cost") ? event.data["Initial Cost"] = calculateInitialCost(event) : null;
        fxcalc("Merch Fee") ? event.data["Merch Fee"] = calculateMerchFee(event) : null;
        fxcalc("Section 301") ? event.data["Section 301"] = calculateSection301(event) : null;
        fxcalc("Duty Total") ? event.data["Duty Total"] = calculateDutyTotal(event) : null;
        fxcalc("Agent Commission Rate") ? event.data["Agent Commission Rate"] = calculateAgentCommissionRate(event) : null;
        fxcalc("Air Freight") ? event.data["Air Freight"] = calculateAirFreight(event) : null;
        fxcalc("Sea Freight") ? event.data["Sea Freight"] = calculateSeaFreight(event) : null;
        fxcalc("Blended Freight") ? event.data["Blended Freight"] = calculateBlendedFreight(event) : null;
        fxcalc("LDP") ? event.data["LDP"] = calculateLDP(event) : null;
        fxcalc("RTL IMU%") ? event.data["RTL IMU%"] = calculateRTLIMUPer(event) : null;
        fxcalc("Scenario RTL IMU%") ? event.data["Scenario RTL IMU%"] = calculateScenarioRTLIMUPer(event) : null;
        fxcalc("Scenario WHLS IMU%") ? event.data["Scenario WHLS IMU%"] = calculateScenarioWHLSIMUPer(event) : null;
        fxcalc("Unit Projection") ? event.data["Unit Projection"] = calculateUnitProjection(event) : null;
        fxcalc("Corporate/Agent") ? event.data["Corporate/Agent"] = calculateCorporateAgent(event) : null;
        fxcalc("COO") ? event.data["COO"] = calculateCOO(event) : null;
    };
    const onGridReady = (params) => {
        gridApi.current = params.api
        gridColumnApi.current = params.columnApi;
        setCurrentGridApi(params)
        calculateAllFormulas();
        setIsGridReady(true)
    }
    const onSortChanged = (event) => {
        const colState = gridApi.current.getColumnState();
        const savedSortState = colState
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            });
        dispatch({ type: "SET_PDInitialCosting_SORTING", data: savedSortState });
    }
    const onFilterChanged = (event) => {
        const savedFilterModel = gridApi.current.getFilterModel();
        
        dispatch({ type: "SET_PDInitialCosting_FILTERS", data: savedFilterModel });
    }
    const resteAutoChangeState = () => {
        setResetFilter("set");
        setAutoChangeState(false);
        setAutoGetChangeResult({});
    }
    const setCellValueChanged = (data) => {
        cellValueChanged.current = data;
    }
    const setView = (view) => {
        if (view === "grid") {
            setGridView(true);
            setCatalogView(false);
        } else {
            setGridView(false);
            setCatalogView(true);
        }
    }
    return (
        <>
            {/* <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'right',paddingRight:"10px" }}>
                <IconButton size='small' onClick={() => setView("catalog")}><Reorder /></IconButton>
                <IconButton size='small' onClick={() => setView("grid")}><GridOn sx={{maxWidth: "16px", maxHeight: "16px"}} /></IconButton>
            </div> */}
            {
                gridView ? (
                    <CustomAgGrid
                        setCurrentGridApi={onGridReady}
                        filters={PDInitialCostingFilters}
                        setActionItemDataFun={setActionItemDataFun}
                        tab_name="PD_Initial_Costing"
                        tbl_name={tbl_name}
                        columnDefs={tmpColDef}
                        rowData={PDInitialCostingData}
                        SSNData={SSNData}
                        tblHighlightCells={PDInitialCostingHighlistCell}
                        onFilterChanged={onFilterChanged}
                        onSortChanged={onSortChanged}
                        autoChangeState={autoChangeState}
                        resteAutoChangeState={resteAutoChangeState}
                        autoGetChangeResult={autoGetChangeResult}
                        setCellValueChanged={setCellValueChanged}
                        calculateFormula={calculateReactFormula}
                        columnPref={columnPref}
                    />
                ) : null
            }
            {
                catalogView ? (<>Catalog View</>) : null
            }
        </>
    )
})
PDInitialCostingTab.propTypes = {
    setCurrentGridApi: PropTypes.func.isRequired,
    settimeLineDataFun: PropTypes.func.isRequired,
};

export default PDInitialCostingTab;