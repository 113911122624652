import { Grid, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { toastMessage } from "../../../XL_Utils";
import { restApiPath } from "utils/app.props";

const Avatar = () => {
    const dispatch = useDispatch();
    const btnChangeAvatar = async (av) => {
        const postBody = {
            "param_function": "sys_set_avatar",
            "param_body": {
                "param_user_avatar": av,
                "param_user_email": sessionStorage.getItem('email')
            }
        };
        const url = `${restApiPath}callPGFunction`;
        const requestbody = {
            method: "POST",
            headers: {
                Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postBody),
        };
        try {
            const response = await fetch(url, requestbody);
            const json = await response.json();
            if (json.error === "invalid_token") {
                toastMessage("Session Expired", "error");
                sessionStorage.clear();
                setTimeout(() => { nevigate("/login"); }, 5000);
            }
            if (json.response) {
                // const resJsonData = JSON.parse(json.data);
                // metaData.current = resJsonData;
                // setMetaDataDownloaded(true)                
                dispatch({ type: "setMyAvatar", data: av });
                toastMessage("Avatar Saved Successfully", "info");
            }
        } catch (exception) { }
    }

    return (<>
        <Grid container spacing={1} padding={0.4} style={{ textAlign: "Center" }}>
            <Tooltip title="Eve" placement="top">
                <Grid item sm={2}>
                    <img className="myimg" src="Final/Eve.png" height="85px" style={{ cursor: 'pointer' }} onClick={() => { btnChangeAvatar("Eve.png") }} />
                </Grid>
            </Tooltip>
            <Tooltip title="Genie" placement="top">
                <Grid item sm={2}>
                    <img className="myimg" src="Final/Genie.png" height="85px" style={{ cursor: 'pointer' }} onClick={() => { btnChangeAvatar("Genie.png") }} />
                </Grid>
            </Tooltip>
            <Tooltip title="Skipper" placement="top">
                <Grid item sm={2}>
                    <img className="myimg" src="Final/Penguin.png" height="85px" style={{ cursor: 'pointer' }} onClick={() => { btnChangeAvatar("Penguin.png") }} />
                </Grid>
            </Tooltip>
            <Tooltip title="Niko" placement="top">
                <Grid item sm={2}>
                    <img className="myimg" src="Final/Reindeer.png" height="85px" style={{ cursor: 'pointer' }} onClick={() => { btnChangeAvatar("Reindeer.png") }} />
                </Grid>
            </Tooltip>
            <Tooltip title="Bruno" placement="top">
                <Grid item sm={2}>
                    <img className="myimg" src="Final/Bruno.png" height="85px" style={{ cursor: 'pointer' }} onClick={() => { btnChangeAvatar("Bruno.png") }} />
                </Grid>
            </Tooltip>
            <Tooltip title="Sassy" placement="top">
                <Grid item sm={2}>
                    <img className="myimg" src="Final/Sassy.png" height="85px" style={{ cursor: 'pointer' }} onClick={() => { btnChangeAvatar("Sassy.png") }} />
                </Grid>
            </Tooltip>
            <Tooltip title="Jack Sparrow" placement="bottom">
                <Grid item sm={2}>
                    <img className="myimg" src="Final/JackSparrow.png" height="85px" style={{ cursor: 'pointer' }} onClick={() => { btnChangeAvatar("JackSparrow.png") }} />
                </Grid>
            </Tooltip>
            <Tooltip title="Captain America" placement="bottom">
                <Grid item sm={2}>
                    <img className="myimg" src="Final/CaptainAmerica.png" height="85px" style={{ cursor: 'pointer' }} onClick={() => { btnChangeAvatar("CaptainAmerica.png") }} />
                </Grid>
            </Tooltip>
            <Tooltip title="Hulk" placement="bottom">
                <Grid item sm={2}>
                    <img className="myimg" src="Final/Hulk.png" height="85px" style={{ cursor: 'pointer' }} onClick={() => { btnChangeAvatar("Hulk.png") }} />
                </Grid>
            </Tooltip>
            <Tooltip title="Minion" placement="bottom">
                <Grid item sm={2}>
                    <img className="myimg" src="Final/Minions.png" height="85px" style={{ cursor: 'pointer' }} onClick={() => { btnChangeAvatar("Minions.png") }} />
                </Grid>
            </Tooltip>
            <Tooltip title="Simba" placement="bottom">
                <Grid item sm={2}>
                    <img className="myimg" src="Final/Simba.png" height="85px" style={{ cursor: 'pointer' }} onClick={() => { btnChangeAvatar("Simba.png") }} />
                </Grid>
            </Tooltip>
            <Tooltip title="Rocket Raccoon" placement="bottom">
                <Grid item sm={2}>
                    <img className="myimg" src="Final/RocketRaccoon.png" height="85px" style={{ cursor: 'pointer' }} onClick={() => { btnChangeAvatar("RocketRaccoon.png") }} />
                </Grid>
            </Tooltip>
        </Grid>
    </>)
}

export default Avatar;