import { executeDataVerification, toastMessage, generateColumnGrouping, checkHeihlight, getColumnStyle, isEditable } from "layouts/linelist/XL_Utils";
import { colGrpPst } from "layouts/linelist/colGrpVariable";
import { compareAndHighLightDataChanges, GetAutoChanges } from "layouts/linelist/getChangesComman";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import CustomAgGrid from "../CustomAgGrid";
import { setHighlightByTblName } from "layouts/linelist/getChangesComman";
import { xlRound, xlXlookup } from "layouts/linelist/XL_Calculation";

const tbl_name = "xl_faherty_pst";
const rng_name = "Pst_Rng";

const PstTab = forwardRef(({ setCurrentGridApi }, ref) => {
  const dispatch = useDispatch();
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const FormulaCalcColLst = useSelector((s) => s.formulaCalcColLst[0][tbl_name]);
  const Pst = useSelector((s) => s.PstData);
  const PstCache = useSelector((s) => s.PstDataSSN);
  const varHighlightCell = useSelector((s) => s.PstHighlistCell);
  const varColDefs = useSelector((s) => s.PstColDef || []);
  const [PstColDef, setPstColDef] = useState([]);
  const columnGrpPref = useSelector((s) => s.PstGrpColPref);
	const columnPref = useSelector((s) => s.PstColPref);

  const varSorting = useSelector((s) => s.PstSorting || []);
  const varFilters = useSelector((s) => s.PstFilters || {});
  const highlightCells = useRef([]);
  const cellValueChanged = useRef([]);
  const [isGridReady, setIsGridReady] = useState(false);
  const [autoChangeState, setAutoChangeState] = useState(false);
  const [autoGetChangeResult, setAutoGetChangeResult] = useState({});

  const MatrixData = useSelector((s) => s.MatrixData);

  const [bottomRowData, setBottomRowData] = useState([]);
  const footerRowCalCols = ["Buy Status", "Style Activity", "AIR", "AUC", "Buy Units - Retail Final Buy", "Buy Units - Online Final Buy", "Total DTC Buy", "Total Wholesale Buy", "Total Brand Buy", "Omni Total Sales $"]

  const setResetFilter = (s) => {
    gridApi.current.applyColumnState({ state: s === "reset" ? null : varSorting });
    gridApi.current.setFilterModel(s === "reset" ? null : varFilters);
  };
  useImperativeHandle(ref, () => ({
    validateSendData: () => {
      if (!Pst) return false;
      const res = executeDataVerification(
        null,
        tbl_name,
        highlightCells.current,
        gridApi.current,
        gridColumnApi.current
      );
      if (res.status === false) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        return res;
      }
      cellValueChanged.current = [];
      removeHeihlight();
      return { status: true, colDef: varColDefs };
    },
    getChangesFunc: async (masterData, metaData) => {
      const res = await compareAndHighLightDataChanges(
        masterData,
        rng_name,
        tbl_name,
        varColDefs,
        gridApi.current,
        cellValueChanged.current,
        highlightCells.current,
        metaData
      );
      if (res.status) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        dispatch({ type: "PST_DATA", data: res.data });
        gridApi.current.refreshCells({ force: true });
      } else {
        toastMessage(res.msg, "warning");
      }
      return res;
    },
    getAutoChanges: (data, downloadType) => {
      setResetFilter("reset");
      const res = GetAutoChanges(
        data,
        highlightCells.current,
        gridApi.current,
        cellValueChanged.current,
        tbl_name,
        downloadType
      );
      setAutoChangeState(true);
      setAutoGetChangeResult(res);
    },
  }));
  const removeHeihlight = () => {
    highlightCells.current = [];
    if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true });
  };
  const onGridReady = (params) => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;
    setCurrentGridApi(params);
    calculateAllFormulas();

    setIsGridReady(true);
  };
  const onSortChanged = (event) => {
    const colState = gridApi.current.getColumnState();
    const savedSortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch({ type: "SET_PST_SORTING", data: savedSortState });
  };
  const onFilterChanged = (event) => {
    const savedFilterModel = gridApi.current.getFilterModel();
    dispatch({ type: "SET_PST_FILTERS", data: savedFilterModel });
    calcBottomRow();
  };
  const resteAutoChangeState = () => {
    setResetFilter("set");
    setAutoChangeState(false);
    setAutoGetChangeResult({});
  };
  const setCellValueChanged = (data) => {
    cellValueChanged.current = data;
  };
  useEffect(() => {
    // console.log("PstData -> ", PstData);
    // console.log("varColDefs -> ", varColDefs);
    if (varColDefs && varColDefs.length > 0) {
        let temp = varColDefs;
        temp = temp.map((col) => {
            let mpCol = col
            mpCol.suppressColumnsToolPanel = col.hide;
            mpCol.suppressFiltersToolPanel = col.hide;

            // console.log("mpCol -> ", mpCol);
            if (mpCol.field.includes('FP DISC RATE - ')) {
                mpCol.headerName = mpCol.headerName.replace('FP DISC RATE - ', '');
            } else if (mpCol.field.includes('FP OVERRIDE DISC RATE - ')) {
                mpCol.headerName = mpCol.headerName.replace('FP OVERRIDE DISC RATE - ', '');
            } else if (mpCol.field.includes('FP FINAL DISC RATE - ')) {
                mpCol.headerName = mpCol.headerName.replace('FP FINAL DISC RATE - ', '');
            } else if (mpCol.field.includes('MD DISC RATE - ')) {
                mpCol.headerName = mpCol.headerName.replace('MD DISC RATE - ', '');
            } else if (mpCol.field.includes('MD OVERRIDE DISC RATE - ')) {
                mpCol.headerName = mpCol.headerName.replace('MD OVERRIDE DISC RATE - ', '');
            } else if (mpCol.field.includes('MD FINAL DISC RATE - ')) {
                mpCol.headerName = mpCol.headerName.replace('MD FINAL DISC RATE - ', '');
            } else if (mpCol.field.includes('FP AUR - ')) {
                mpCol.headerName = mpCol.headerName.replace('FP AUR - ', '');
            } else if (mpCol.field.includes('MD AUR - ')) {
                mpCol.headerName = mpCol.headerName.replace('MD AUR - ', '');
            } else if (mpCol.field.includes('Size Count - ')) {
              mpCol.headerName = mpCol.headerName.replace('Size Count - ', '');
            } else if (mpCol.field.includes('Buy Min - ')) {
              mpCol.headerName = mpCol.headerName.replace('Buy Min - ', '');
            } else if (mpCol.field.includes('EOP Min - ')) {
              mpCol.headerName = mpCol.headerName.replace('EOP Min - ', '');
            } else if (mpCol.field.includes('Online - ')) {
              mpCol.headerName = mpCol.headerName.replace('Online - ', '');
            } else if (mpCol.field.includes('APS Plan - ')) {
              mpCol.headerName = mpCol.headerName.replace('APS Plan - ', '');
            } else if (mpCol.field.includes('APS Override - ')) {
              mpCol.headerName = mpCol.headerName.replace('APS Override - ', '');
            } else if (mpCol.field.includes('APS Final - ')) {
              mpCol.headerName = mpCol.headerName.replace('APS Final - ', '');
            } else if (mpCol.field.includes('MD ST Plan - ')) {
              mpCol.headerName = mpCol.headerName.replace('MD ST Plan - ', '');
            } else if (mpCol.field.includes('MD ST Override - ')) {
              mpCol.headerName = mpCol.headerName.replace('MD ST Override - ', '');
            } else if (mpCol.field.includes('MD ST Final - ')) {
              mpCol.headerName = mpCol.headerName.replace('MD ST Final - ', '');
            } else if (mpCol.field.includes('Buy Units - ')) {
              mpCol.headerName = mpCol.headerName.replace('Buy Units - ', '');
            } else if (mpCol.field.includes('BOP Plan - ')) {
              mpCol.headerName = mpCol.headerName.replace('BOP Plan - ', '');
            } else if (mpCol.field.includes('BOP Override - ')) {
              mpCol.headerName = mpCol.headerName.replace('BOP Override - ', '');
            } else if (mpCol.field.includes('Total BOP Units - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total BOP Units - ', '');
            } else if (mpCol.field.includes('Total Receipt Units - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total Receipt Units - ', '');
            } else if (mpCol.field.includes('Total Available Units - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total Available Units - ', '');
            } else if (mpCol.field.includes('Total Sales Units - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total Sales Units - ', '');
            } else if (mpCol.field.includes('Total FP Sales Units - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total FP Sales Units - ', '');
            } else if (mpCol.field.includes('Total MD Sales Units - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total MD Sales Units - ', '');
            } else if (mpCol.field.includes('Total ST% - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total ST% - ', '');
            } else if (mpCol.field.includes('FP ST% - ')) {
              mpCol.headerName = mpCol.headerName.replace('FP ST% - ', '');
            } else if (mpCol.field.includes('Total EOP Units - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total EOP Units - ', '');
            } else if (mpCol.field.includes('Total FP EOP Units - ')) {
              mpCol.headerName = mpCol.headerName.replace('Total FP EOP Units - ', '');
            } else if (mpCol.field.includes('MIN FLAG - ')) {
              mpCol.headerName = mpCol.headerName.replace('MIN FLAG - ', '');
            } else if (mpCol.field.includes('MIN FLAG - ')) {
              mpCol.headerName = mpCol.headerName.replace('MIN FLAG - ', '');
            } else if (mpCol.field.includes('LY - ')) {
              mpCol.headerName = mpCol.headerName.replace('LY - ', '');
            }

            mpCol.cellStyle = params => { return funCellStyle(params, col) }
            return mpCol;
        });
        // console.log("temp -> ", temp);
        setPstColDef(generateColumnGrouping(colGrpPst, temp));
    }
  }, [Pst]);
  useEffect(() => {
      highlightCells.current = varHighlightCell;
      if (isGridReady) gridApi.current.refreshCells({ force: true })
  }, [varHighlightCell])
  const funCellStyle = (params, col) => {
    if (isGridReady && gridColumnApi?.current?.isPivotMode()) return;
    if (!params?.node?.data && !params?.node?.data?.row_id) return;
    const value = params.value ? params.value : "";
    let bgColor = "";
    let bdrColor = "";
    let txtDeco = "";
    let fontColor = "#000000";
    let boldTxt = "normal";
    let borderBottom = "";
    let fontStyle = "normal";
    const customCode = getColumnStyle(varColDefs, value, params, col);
    if (customCode.status) {
        bgColor = customCode.bgColor;
        bdrColor = customCode.bdrColor;
        txtDeco = customCode.txtDeco;
        fontColor = customCode.fontColor;
        boldTxt = customCode.boldTxt;
        borderBottom = customCode.borderBottom;
        fontStyle = customCode.fontStyle;
    } else {
        const editable = isEditable(params, col.field, col.editable, col.insert_editable);
        if (editable) bgColor = "#FFFFFF";
        else bgColor = "#F0F0F0";
        if (col.type === "dropdown") bgColor = "#FDFCEC";
    }
    // let key = tbl_name + "|" + col.headerName.trim() + "|" + params.node.data.row_id
    let key = tbl_name + "|" + col.field.trim() + "|" + params.node.data.row_id
    let obj = checkHeihlight(key, highlightCells.current)
    if (obj.status === true) {
        if (obj.colorCode === 1) bgColor = "cyan";
        else if (obj.colorCode === 2) bgColor = "#d5a7f2";
        else if (obj.colorCode === 3) bdrColor = "#2196f3";
        else if (obj.colorCode === 4) bdrColor = "#9B6155";
        else if (obj.colorCode === 5) bgColor = "#CAE2F9";
    }
    if (params.node.rowPinned === "bottom" && params.node.rowIndex === 0) { bgColor = "#C7F9CC"; boldTxt = "bold"; txtDeco = "" }
    if (params.node.rowPinned === "bottom" && params.node.rowIndex === 1) { bgColor = "#FBF8CC"; boldTxt = "bold"; txtDeco = "" }
    if (bdrColor !== "")
        return { backgroundColor: bgColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor, borderColor: bdrColor, fontStyle: fontStyle }
    else
        return { backgroundColor: bgColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor, borderBottomColor: borderBottom, fontStyle: fontStyle }
  };
  useEffect(() => {
    if (isGridReady) {
      if (varSorting !== null && varSorting !== undefined) {
        gridApi.current.applyColumnState({ state: varSorting });
      }
      if (varFilters !== null && varFilters !== undefined) {
        gridApi.current.setFilterModel(varFilters);
      }
    }
  }, [isGridReady]);
  useEffect(() => {
    if (Pst && Pst.length > 0 && isGridReady) {
      if (isGridReady) {
        calculateAllFormulas();
        calcBottomRow();
      }
    }
  }, [Pst,isGridReady]);

  const calculateFinalMDExitWeek = (event) => {
    const MDExitWeekOverride = event.data["MD Exit Week Override"] || "";
    const MDExitWeek = event.data["MD Exit Week"] || "";
    if (MDExitWeekOverride === null || MDExitWeekOverride === undefined || MDExitWeekOverride === "") {
        return MDExitWeek;
    }
    else {return MDExitWeekOverride;}
  };
  const calculateStoreCount = (event) => {
    const StoreTier = event.data["Store Tier"] || "";
    let resultVal = "";
    if (StoreTier !== "" && StoreTier !== null && StoreTier !== undefined) {
      resultVal = xlXlookup(StoreTier, MatrixData, 'Store Counts Matrix - Store Tier', 'Store Counts Matrix - Store Count');
    }
    // console.log("calculateStoreCount -> resultVal -> ", resultVal);
    return resultVal;
  };
  const calculateFPWeeks_Retail = (event) => {
    const cv1 = event.data["Seasonal Life Cycle"] || "";
    const cv2 = event.data["Roll Out Code - Retail"] || "";
    const cv3 = event.data["Delivery Month"] || "";
    const cv4 = event.data["MD Exit Week"] || "";
    const fv = (cv1 + cv2 + cv3 + cv4)
    let resultVal = "";
    if (fv !== "" && fv !== null && fv !== undefined) {
      resultVal = xlXlookup(fv, MatrixData, 'FP WEEKS MATRIX - Season Lifecycle Code|FP WEEKS MATRIX - RO CODE|FP WEEKS MATRIX - Delivery Month|FP WEEKS MATRIX - MD Exit Week', 'FP WEEKS MATRIX - FP Weeks');
    }
    // console.log("calculateFPWeeks_Retail -> resultVal -> ", resultVal);
    return resultVal;
  };
  const calculateFPWeeks_Online = (event) => {
    const cv1 = event.data["Seasonal Life Cycle"] || "";
    const cv2 = event.data["Roll Out Code - Online"] || "";
    const cv3 = event.data["Delivery Month"] || "";
    const cv4 = event.data["MD Exit Week"] || "";
    const fv = (cv1 + cv2 + cv3 + cv4)
    let resultVal = "";
    if (fv !== "" && fv !== null && fv !== undefined) {
      resultVal = xlXlookup(fv, MatrixData, 'FP WEEKS MATRIX - Season Lifecycle Code|FP WEEKS MATRIX - RO CODE|FP WEEKS MATRIX - Delivery Month|FP WEEKS MATRIX - MD Exit Week', 'FP WEEKS MATRIX - FP Weeks');
    }
    // console.log("calculateFPWeeks_Online -> resultVal -> ", resultVal);
    return resultVal;
  };
  const calculateAUCFinal = (event) => {
      const AUCOVERRIDE = event.data["AUC OVERRIDE"];
      const AUC = event.data["AUC"];
      if (AUCOVERRIDE !== null && AUCOVERRIDE !== undefined && AUCOVERRIDE !== "") {
          return AUCOVERRIDE;
      }
      else {return AUC;}
  };
  const calculateIMU = (event) => {
      const AIR = parseFloat(event.data["AIR"] || 0);
      const AUCFINAL = parseFloat(event.data["AUC FINAL"] || 0);
      return AIR !== 0 ? xlRound(((AIR - AUCFINAL) / AIR).toFixed(10), 6) : 0;
  };
  const calculateFPFINALDISCRATE_Retail = (event) => {
      const FPOVERRIDEDISCRATE_Retail = event.data["FP OVERRIDE DISC RATE - Retail"];
      const FPDISCRATE_Retail = event.data["FP DISC RATE - Retail"];
      if (FPOVERRIDEDISCRATE_Retail !== null && FPOVERRIDEDISCRATE_Retail !== undefined && FPOVERRIDEDISCRATE_Retail !== "") {
          return FPOVERRIDEDISCRATE_Retail;
      }
      else {return FPDISCRATE_Retail;}
  };
  const calculateFPFINALDISCRATE_Online = (event) => {
      const FPOVERRIDEDISCRATE_Online = event.data["FP OVERRIDE DISC RATE - Online"];
      const FPDISCRATE_Online = event.data["FP DISC RATE - Online"];
      if (FPOVERRIDEDISCRATE_Online !== null && FPOVERRIDEDISCRATE_Online !== undefined && FPOVERRIDEDISCRATE_Online !== "") {
          return FPOVERRIDEDISCRATE_Online;
      }
      else {return FPDISCRATE_Online;}
  };
  const calculateMDDISCRATE_Retail = (event) => {
    let searchVal = (event.data["Category"] || "");
    let resultVal = xlXlookup(searchVal, MatrixData, 'Disc Rate Coverage - Properties', 'Disc Rate Coverage - Markdown Price Retail (TY Discount %)');
    // console.log("calculateMDDISCRATE_Retail -> resultVal -> ", resultVal);
    return resultVal;
  };
  const calculateMDDISCRATE_Online = (event) => {
    let searchVal = (event.data["Category"] || "");
    let resultVal = xlXlookup(searchVal, MatrixData, 'Disc Rate Coverage - Properties', 'Disc Rate Coverage - Markdown Price Online (TY Discount %)');
    // console.log("calculateMDDISCRATE_Online -> resultVal -> ", resultVal);
    return resultVal;
  };
  const calculateMDFINALDISCRATE_Retail = (event) => {
      const MDOVERRIDEDISCRATE_Retail = event.data["MD OVERRIDE DISC RATE - Retail"];
      const MDDISCRATE_Retail = event.data["MD DISC RATE - Retail"];
      if (MDOVERRIDEDISCRATE_Retail !== null && MDOVERRIDEDISCRATE_Retail !== undefined && MDOVERRIDEDISCRATE_Retail !== "") {
          return MDOVERRIDEDISCRATE_Retail;
      }
      else {return MDDISCRATE_Retail;}
  };
  const calculateMDFINALDISCRATE_Online = (event) => {
      const MDOVERRIDEDISCRATE_Online =event.data["MD OVERRIDE DISC RATE - Online"];
      const MDDISCRATE_Online = event.data["MD DISC RATE - Online"];
      if (MDOVERRIDEDISCRATE_Online !== null && MDOVERRIDEDISCRATE_Online !== undefined && MDOVERRIDEDISCRATE_Online !== "") {
          return MDOVERRIDEDISCRATE_Online;
      }
      else {return MDDISCRATE_Online;}
  };
  const calculateFPAUR_Retail = (event) => {
      const AIR = parseFloat(event.data["AIR"] || 0);
      const FPFINALDISCRATE_Retail = parseFloat(event.data["FP FINAL DISC RATE - Retail"] || 0);
      return AIR !== 0 ? xlRound((AIR * (1 - FPFINALDISCRATE_Retail)).toFixed(10), 6) : 0;
  };
  const calculateFPAUR_Online = (event) => {
      const AIR = parseFloat(event.data["AIR"] || 0);
      const FPFINALDISCRATE_Online = parseFloat(event.data["FP FINAL DISC RATE - Online"] || 0);
      return AIR !== 0 ? xlRound((AIR * (1 - FPFINALDISCRATE_Online)).toFixed(10), 6) : 0;
  };
  const calculateMDAUR_Retail = (event) => {
      const AIR = parseFloat(event.data["AIR"] || 0);
      const MDFINALDISCRATE_Retail = parseFloat(event.data["MD FINAL DISC RATE - Retail"] || 0);
      return AIR !== 0 ? xlRound((AIR * (1 - MDFINALDISCRATE_Retail)).toFixed(10), 6) : 0;
  };
  const calculateMDAUR_Online = (event) => {
      const AIR = parseFloat(event.data["AIR"] || 0);
      const MDFINALDISCRATE_Online = parseFloat(event.data["MD FINAL DISC RATE - Online"] || 0);
      return AIR !== 0 ? xlRound((AIR * (1 - MDFINALDISCRATE_Online)).toFixed(10), 6) : 0;
  };
  const calculateAPSFinal_Retail = (event) => {
      const APSOverride_Retail = event.data["APS Override - Retail"];
      const APSPlan_Retail = event.data["APS Plan - Retail"];
      if (APSOverride_Retail === null || APSOverride_Retail === undefined || APSOverride_Retail === "" || isNaN(APSOverride_Retail)) {
          return APSPlan_Retail;
      }
      else {return APSOverride_Retail;}
  };
  const calculateAPSFinal_Online = (event) => {
      const APSOverride_Online = event.data["APS Override - Online"];
      const APSPlan_Online = event.data["APS Plan - Online"];
      if (APSOverride_Online === null || APSOverride_Online === undefined || APSOverride_Online === "" || isNaN(APSOverride_Online)) {
          return APSPlan_Online;
      }
      else {return APSOverride_Online;}
  };
  const calculateMDSTFinal_Retail = (event) => {
      const MDSTOverride_Retail = event.data["MD ST Override - Retail"];
      const MDSTPlan_Retail = event.data["MD ST Plan - Retail"];
      if (MDSTOverride_Retail !== null && MDSTOverride_Retail !== undefined && MDSTOverride_Retail !== "") {
          return MDSTOverride_Retail;
      }
      else {return MDSTPlan_Retail;}
  };
  const calculateMDSTFinal_Online = (event) => {
      const MDSTOverride_Online = event.data["MD ST Override - Online"];
      const MDSTPlan_Online = event.data["MD ST Plan - Online"];
      if (MDSTOverride_Online !== null && MDSTOverride_Online !== undefined && MDSTOverride_Online !== "") {
          return MDSTOverride_Online;
      }
      else {return MDSTPlan_Online;}
  };
  const calculateBuyUnits_RetailNeed = (event) => {
    let resultVal = 0;
    let StoreCount = parseFloat(event.data["Store Count"] || 0);
    let WeeksBoughtRetail = parseFloat(event.data["Weeks Bought - Retail"] || 0);
    let EOPMinRetail = parseFloat(event.data["EOP Min - Retail"] || 0);
    let APSPlanRetail = parseFloat(event.data["APS Plan - Retail"] || 0);
    let BOPOverrideRetail = parseFloat(event.data["BOP Override - Retail"] || 0);
    if ((((StoreCount * WeeksBoughtRetail * APSPlanRetail) + (StoreCount * EOPMinRetail)) - BOPOverrideRetail) < 0)
      return resultVal;
    else
      resultVal = (((StoreCount * WeeksBoughtRetail * APSPlanRetail) + (StoreCount * EOPMinRetail)) - BOPOverrideRetail);

    resultVal = xlRound(resultVal, 6);
    return resultVal;
  };
  const calculateBuyUnits_RetailBuyMin = (event) => {
    const BuyMin_Retail = parseFloat(event.data["Buy Min - Retail"] || 0);
    const Store_Count = parseFloat(event.data["Store Count"] || 0);
    return xlRound((BuyMin_Retail * Store_Count).toFixed(10), 6);
  };
  const calculateBuyUnits_RetailNeedwithMin = (event) => {
    let resultVal = 0;
    let BOPOverrideRetail = parseFloat(event.data["BOP Override - Retail"] || 0);
    let BuyUnitsRetailNeed = parseFloat(event.data["Buy Units - Retail Need"] || 0);
    let BuyUnitsRetailBuyMin = parseFloat(event.data["Buy Units - Retail Buy Min"] || 0);
    if ((BOPOverrideRetail !== 0 ))
      resultVal =  BuyUnitsRetailNeed;
    else
      if ((BuyUnitsRetailNeed > BuyUnitsRetailBuyMin ))
        resultVal =  BuyUnitsRetailNeed;
      else
        resultVal =  BuyUnitsRetailBuyMin;

    resultVal = xlRound(resultVal, 6);
    return resultVal;
  };    
  const calculateBuyUnits_RetailFinalBuy = (event) => {
      const BuyUnits_RetailOverride = event.data["Buy Units - Retail Override"];
      const BuyUnits_RetailNeedwithMin = event.data["Buy Units - Retail Need with Min"];
      if (BuyUnits_RetailOverride !== null && BuyUnits_RetailOverride !== undefined && BuyUnits_RetailOverride !== "") {
          return BuyUnits_RetailOverride;
      }
      else {return BuyUnits_RetailNeedwithMin;}
  };
  const calculateBuyUnits_Online = (event) => {
      let resultVal = 0;
      let APSPlanOnline = parseFloat(event.data["APS Plan - Online"] || 0);
      let WeeksBoughtOnline = parseFloat(event.data["Weeks Bought - Online"] || 0);
      let OnlineCoverage = parseFloat(event.data["Online - Coverage"] || 0);
      let BOPOverrideOnline = parseFloat(event.data["BOP Override - Online"] || 0);
      if (((WeeksBoughtOnline * OnlineCoverage * APSPlanOnline) - BOPOverrideOnline) < 0)
        return resultVal;
      else
        resultVal = ((WeeksBoughtOnline * OnlineCoverage * APSPlanOnline) - BOPOverrideOnline);
  
      resultVal = xlRound(resultVal, 6);
      return resultVal;
  };
  const calculateBuyUnits_OnlineFinalBuy = (event) => {
      const BuyUnits_OnlineOverride = event.data["Buy Units - Online Override"];
      const BuyUnits_Online = event.data["Buy Units - Online"];
      if (BuyUnits_OnlineOverride !== null && BuyUnits_OnlineOverride !== undefined && BuyUnits_OnlineOverride !== "") {
          return BuyUnits_OnlineOverride;
      }
      else {return BuyUnits_Online;}
  };
  const calculateBOPPlan_Omni = (event) => {
      return xlRound(parseFloat(event.data["BOP Plan - Retail"] || 0) + parseFloat(event.data["BOP Plan - Online"] || 0), 6);
  };
  const calculateBOPOverride_Omni = (event) => {
      return xlRound(parseFloat(event.data["BOP Override - Retail"] || 0) + parseFloat(event.data["BOP Override - Online"] || 0), 6);
  };
  const calculateTotalBOPUnits_Omni = (event) => {
      return xlRound(parseFloat(event.data["Total BOP Units - Retail"] || 0) + parseFloat(event.data["Total BOP Units - Online"] || 0), 6);
  };
  const calculateTotalBOPUnits_Retail = (event) => {
      const BOPOverride_Retail = event.data["BOP Override - Retail"];
      const BOPPlan_Retail = event.data["BOP Plan - Retail"];
      if (BOPOverride_Retail !== null && BOPOverride_Retail !== undefined && BOPOverride_Retail !== "") {
          return BOPOverride_Retail;
      }
      else {return BOPPlan_Retail;}
  };
  const calculateTotalBOPUnits_Online = (event) => {
      const BOPOverride_Online = event.data["BOP Override - Online"];
      const BOPPlan_Online = event.data["BOP Plan - Online"];
      if (BOPOverride_Online !== null && BOPOverride_Online !== undefined && BOPOverride_Online !== "") {
          return BOPOverride_Online;
      }
      else {return BOPPlan_Online;}
  };
  const calculateTotalReceiptUnits_Omni = (event) => {
      return xlRound(parseFloat(event.data["Total Receipt Units - Retail"] || 0) + parseFloat(event.data["Total Receipt Units - Online"] || 0), 6);
  };
  const calculateTotalReceiptUnits_Retail = (event) => {
      return xlRound(parseFloat(event.data["Buy Units - Retail Final Buy"] || 0), 6);
  };
  const calculateTotalReceiptUnits_Online = (event) => {
      return xlRound(parseFloat(event.data["Buy Units - Online Final Buy"] || 0), 6);
  };
  const calculateTotalAvailableUnits_Omni = (event) => {
      return xlRound(parseFloat(event.data["Total Available Units - Retail"] || 0) + parseFloat(event.data["Total Available Units - Online"] || 0), 6);
  };
  const calculateTotalAvailableUnits_Retail = (event) => {
      return xlRound(parseFloat(event.data["Total BOP Units - Retail"] || 0) + parseFloat(event.data["Buy Units - Retail Final Buy"] || 0), 6);
  };
  const calculateTotalAvailableUnits_Online = (event) => {
      return xlRound(parseFloat(event.data["Total BOP Units - Online"] || 0) + parseFloat(event.data["Buy Units - Online Final Buy"] || 0), 6);
  };
  const calculateTotalSalesUnits_Omni = (event) => {
      return xlRound(parseFloat(event.data["Total Sales Units - Retail"] || 0) + parseFloat(event.data["Total Sales Units - Online"] || 0), 6);
  };
  const calculateTotalSalesUnits_Retail = (event) => {
      const TotalAvailableUnits_Retail = event.data["Total Available Units - Retail"];
      const MDSTFinal_Retail = event.data["MD ST Final - Retail"];
      const TotalFPSalesUnits_Retail = event.data["Total FP Sales Units - Retail"];
      if (MDSTFinal_Retail !== null && MDSTFinal_Retail !== undefined && MDSTFinal_Retail !== "") {
        return xlRound((TotalAvailableUnits_Retail * MDSTFinal_Retail).toFixed(10), 6);
      }
      else {return TotalFPSalesUnits_Retail;}
  };
  const calculateTotalSalesUnits_Online = (event) => {
      const TotalAvailableUnits_Online = event.data["Total Available Units - Online"];
      const MDSTFinal_Online = event.data["MD ST Final - Online"];
      const TotalFPSalesUnits_Online = event.data["Total FP Sales Units - Online"];
      if (MDSTFinal_Online !== null && MDSTFinal_Online !== undefined && MDSTFinal_Online !== "") {
        return xlRound((TotalAvailableUnits_Online * MDSTFinal_Online).toFixed(10), 6);
      }
      else {return TotalFPSalesUnits_Online;}
  };
  const calculateTotalFPSalesUnits_Omni = (event) => {
      return xlRound(parseFloat(event.data["Total FP Sales Units - Retail"] || 0) + parseFloat(event.data["Total FP Sales Units - Online"] || 0), 6);
  };
  const calculateTotalFPSalesUnits_Retail = (event) => {
      const APSPlan_Retail = parseFloat(event.data["APS Plan - Retail"] || 0);
      const FPWeeks_Retail = parseFloat(event.data["FP Weeks - Retail"] || 0);
      const StoreCount = parseFloat(event.data["Store Count"] || 0);
      return xlRound((APSPlan_Retail * FPWeeks_Retail * StoreCount).toFixed(10), 6);
  };
  const calculateTotalFPSalesUnits_Online = (event) => {
      const APSPlan_Online = parseFloat(event.data["APS Plan - Online"] || 0);
      const FPWeeks_Online = parseFloat(event.data["FP Weeks - Online"] || 0);
      return xlRound((APSPlan_Online * FPWeeks_Online).toFixed(10), 6);
  }; 
  const calculateTotalMDSalesUnits_Omni = (event) => {
      return xlRound(parseFloat(event.data["Total MD Sales Units - Retail"] || 0) + parseFloat(event.data["Total MD Sales Units - Online"] || 0), 6);
  };
  const calculateTotalMDSalesUnits_Retail = (event) => {
      return xlRound(parseFloat(event.data["Total Sales Units - Retail"] || 0) - parseFloat(event.data["Total FP Sales Units - Retail"] || 0), 6);
  };
  const calculateTotalMDSalesUnits_Online = (event) => {
      return xlRound(parseFloat(event.data["Total Sales Units - Online"] || 0) - parseFloat(event.data["Total FP Sales Units - Online"] || 0), 6);
  };
  const calculateTotalST_Omni = (event) => {
      let resultVal = 0;
      let TotalSalesUnitsRetail = parseFloat(event.data["Total Sales Units - Retail"] || 0);
      let TotalSalesUnitsOnline = parseFloat(event.data["Total Sales Units - Online"] || 0);
      let TotalAvailableUnitsRetail = parseFloat(event.data["Total Available Units - Retail"] || 0);
      let TotalAvailableUnitsOnline = parseFloat(event.data["Total Available Units - Online"] || 0);
      let pram1 = (TotalSalesUnitsRetail + TotalSalesUnitsOnline);
      let pram2 = (TotalAvailableUnitsRetail + TotalAvailableUnitsOnline);
      if (pram2 !== 0)
        return xlRound(pram1/pram2, 6);
      else
        return resultVal;
  };
  const calculateTotalST_Retail = (event) => {
      const TotalSalesUnits_Retail = parseFloat(event.data["Total Sales Units - Retail"] || 0);
      const TotalAvailableUnits_Retail = parseFloat(event.data["Total Available Units - Retail"] || 0);
      return TotalAvailableUnits_Retail !== 0 ? xlRound(TotalSalesUnits_Retail / TotalAvailableUnits_Retail, 6) : 0;
  };
  const calculateTotalST_Online = (event) => {
      const TotalSalesUnits_Online = parseFloat(event.data["Total Sales Units - Online"] || 0);
      const TotalAvailableUnits_Online = parseFloat(event.data["Total Available Units - Online"] || 0);
      return TotalAvailableUnits_Online !== 0 ? xlRound(TotalSalesUnits_Online / TotalAvailableUnits_Online, 6) : 0;
  };
  const calculateFPST_Omni = (event) => {
      let resultVal = 0;
      let TotalFPSalesUnitsRetail = parseFloat(event.data["Total FP Sales Units - Retail"] || 0);
      let TotalFPSalesUnitsOnline = parseFloat(event.data["Total FP Sales Units - Online"] || 0);
      let TotalAvailableUnitsRetail = parseFloat(event.data["Total Available Units - Retail"] || 0);
      let TotalAvailableUnitsOnline = parseFloat(event.data["Total Available Units - Online"] || 0);
      let pram1 = (TotalFPSalesUnitsRetail + TotalFPSalesUnitsOnline);
      let pram2 = (TotalAvailableUnitsRetail + TotalAvailableUnitsOnline);
      if (pram2 !== 0)
        return xlRound(pram1/pram2, 6);
      else
        return resultVal;
  };
  const calculateFPST_Retail = (event) => {
      const TotalFPSalesUnits_Retail = parseFloat(event.data["Total FP Sales Units - Retail"] || 0);
      const TotalAvailableUnits_Retail = parseFloat(event.data["Total Available Units - Retail"] || 0);
      return TotalAvailableUnits_Retail !== 0 ? xlRound(TotalFPSalesUnits_Retail / TotalAvailableUnits_Retail, 6) : 0;
  };
  const calculateFPST_Online = (event) => {
      const TotalFPSalesUnits_Online = parseFloat(event.data["Total FP Sales Units - Online"] || 0);
      const TotalAvailableUnits_Online = parseFloat(event.data["Total Available Units - Online"] || 0);
      return TotalAvailableUnits_Online !== 0 ? xlRound(TotalFPSalesUnits_Online / TotalAvailableUnits_Online, 6) : 0;
  };
  const calculateTotalEOPUnits_Omni = (event) => {
      return xlRound(parseFloat(event.data["Total EOP Units - Retail"] || 0) + parseFloat(event.data["Total EOP Units - Online"] || 0), 6);
  };
  const calculateTotalEOPUnits_Retail = (event) => {
      return xlRound(parseFloat(event.data["Total Available Units - Retail"] || 0) - parseFloat(event.data["Total Sales Units - Retail"] || 0), 6);
  };
  const calculateTotalEOPUnits_Online = (event) => {
      return xlRound(parseFloat(event.data["Total Available Units - Online"] || 0) - parseFloat(event.data["Total Sales Units - Online"] || 0), 6);
  };
  const calculateTotalFPEOPUnits_Omni = (event) => {
    return xlRound(parseFloat(event.data["Total FP EOP Units - Retail"] || 0) + parseFloat(event.data["Total FP EOP Units - Online"] || 0), 6);
  };
  const calculateTotalFPEOPUnits_Retail = (event) => {
    const MDSTFinal_Retail = event.data["MD ST Final - Retail"];
    if (MDSTFinal_Retail !== null && MDSTFinal_Retail !== undefined && MDSTFinal_Retail !== "") {
      return 0;
    }
    else {return xlRound(parseInt(event.data["Total Available Units - Retail"] || 0) - parseInt(event.data["Total FP Sales Units - Retail"] || 0), 6);}
  };
  const calculateTotalFPEOPUnits_Online = (event) => {
    const MDSTFinal_Online = event.data["MD ST Final - Online"];
    if (MDSTFinal_Online !== null && MDSTFinal_Online !== undefined && MDSTFinal_Online !== "") {
      return 0;
    }
    else {return xlRound(parseInt(event.data["Total Available Units - Online"] || 0) - parseInt(event.data["Total FP Sales Units - Online"] || 0), 6);}
  };  
  const calculateMINFLAG_STORES = (event) => {
      let resultVal = "";
      let BuyUnitsRetailFinalBuy = parseFloat(event.data["Buy Units - Retail Final Buy"] || 0);
      let BuyUnitsRetailBuyMin = parseFloat(event.data["Buy Units - Retail Buy Min"] || 0);
      if ((BuyUnitsRetailFinalBuy > 0 ) && (BuyUnitsRetailFinalBuy === BuyUnitsRetailBuyMin))
        return "RETAIL MIN";
      else
        return resultVal;
  };
  const calculateMINFLAG_PROD = (event) => {
      let resultVal = "";
      let BuyUnits_RetailFinalBuy = parseFloat(event.data["Buy Units - Retail Final Buy"] || 0);
      let BuyUnits_OnlineFinalBuy = parseFloat(event.data["Buy Units - Online Final Buy"] || 0);
      if ((BuyUnits_RetailFinalBuy + BuyUnits_OnlineFinalBuy) === 300 )
        return "PROD MIN";
      else
        return resultVal;
  };
  const calculateMINFLAG_COSTMIN = (event) => {
      let resultVal = "";
      let TotalBrandBuy = parseFloat(event.data["Total Brand Buy"] || 0);
      let MINFLAG_COSTMinUnits = parseFloat(event.data["MIN FLAG - COST Min Units"] || 0);
      if (TotalBrandBuy < MINFLAG_COSTMinUnits)
        return "UNDER MIN";
      else
        return resultVal;
  };
  const calculateTotalDTCBuy = (event) => {
      return xlRound(parseFloat(event.data["Total Receipt Units - Omni"] || 0), 6);
  };
  const calculateTotalBrandBuy = (event) => {
      return xlRound(parseFloat(event.data["Total DTC Buy"] || 0) + parseFloat(event.data["Total Wholesale Buy"] || 0), 6);
  };
  const calculateOmniTotalSales = (event) => {
      let resultVal = 0;
      let TotalFPSalesUnits_Retail = parseFloat(event.data["Total FP Sales Units - Retail"] || 0);
      let FPAUR_Retail = parseFloat(event.data["FP AUR - Retail"] || 0);
      let TotalFPSalesUnits_Online = parseFloat(event.data["Total FP Sales Units - Online"] || 0);
      let MDAUR_Retail = parseFloat(event.data["MD AUR - Retail"] || 0);
      let TotalMDSalesUnits_Retail = parseFloat(event.data["Total MD Sales Units - Retail"] || 0);
      let FPAUR_Online = parseFloat(event.data["FP AUR - Online"] || 0);
      let TotalMDSalesUnits_Online = parseFloat(event.data["Total MD Sales Units - Online"] || 0);
      let MDAUR_Online = parseFloat(event.data["MD AUR - Online"] || 0);
        // resultVal = ((TotalFPSalesUnits_Retail * FPAUR_Retail) + (TotalFPSalesUnits_Online * MDAUR_Retail) + (TotalMDSalesUnits_Retail * FPAUR_Online) + (TotalMDSalesUnits_Online * MDAUR_Online));
        resultVal = ((TotalFPSalesUnits_Retail * FPAUR_Retail) + (TotalFPSalesUnits_Online * FPAUR_Online) + (TotalMDSalesUnits_Retail * MDAUR_Retail) + (TotalMDSalesUnits_Online * MDAUR_Online));
  
      resultVal = xlRound(resultVal, 6);
      return resultVal;
  };

  const fxcalc = (colnm) => {
    let retval = false;
    if (FormulaCalcColLst.includes(colnm)) retval = true;
    return retval;
  };
  const calculateAllFormulas = () => {
    // console.log("In calculateAllFormulas->",FormulaCalcColLst.length)
    if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
      gridApi.current.forEachNode((event) => { calculateReactFormula(event) });
      gridApi.current.refreshCells({ force: true });
    }
    dispatch({ type: "SET_PLEASE_WAIT", data: false });
  };
  const calculateReactFormula = (event) => {
    // console.log("In calculateReactFormula")
    // console.log("event.data -->",event.data)
    if (!event.data) return "";
    fxcalc("Final MD Exit Week") ? event.data["Final MD Exit Week"] = calculateFinalMDExitWeek(event) : null;
    // fxcalc("Store Count") ? event.data["Store Count"] = calculateStoreCount(event) : null; due to sync from matrix tab - removed formula
    // fxcalc("FP Weeks - Retail") ? event.data["FP Weeks - Retail"] = calculateFPWeeks_Retail(event) : null; due to sync from matrix tab - removed formula
    // fxcalc("FP Weeks - Online") ? event.data["FP Weeks - Online"] = calculateFPWeeks_Online(event) : null; due to sync from matrix tab - removed formula
    fxcalc("AUC FINAL") ? event.data["AUC FINAL"] = calculateAUCFinal(event) : null;
    fxcalc("IMU") ? event.data["IMU"] = calculateIMU(event) : null;
    fxcalc("FP FINAL DISC RATE - Retail") ? event.data["FP FINAL DISC RATE - Retail"] = calculateFPFINALDISCRATE_Retail(event) : null;
    fxcalc("FP FINAL DISC RATE - Online") ? event.data["FP FINAL DISC RATE - Online"] = calculateFPFINALDISCRATE_Online(event) : null;
    // fxcalc("MD DISC RATE - Retail") ? event.data["MD DISC RATE - Retail"] = calculateMDDISCRATE_Retail(event) : null; due to sync from matrix tab - removed formula
    // fxcalc("MD DISC RATE - Online") ? event.data["MD DISC RATE - Online"] = calculateMDDISCRATE_Online(event) : null; due to sync from matrix tab - removed formula
    fxcalc("MD FINAL DISC RATE - Retail") ? event.data["MD FINAL DISC RATE - Retail"] = calculateMDFINALDISCRATE_Retail(event) : null;
    fxcalc("MD FINAL DISC RATE - Online") ? event.data["MD FINAL DISC RATE - Online"] = calculateMDFINALDISCRATE_Online(event) : null;
    fxcalc("FP AUR - Retail") ? event.data["FP AUR - Retail"] = calculateFPAUR_Retail(event) : null;
    fxcalc("FP AUR - Online") ? event.data["FP AUR - Online"] = calculateFPAUR_Online(event) : null;
    fxcalc("MD AUR - Retail") ? event.data["MD AUR - Retail"] = calculateMDAUR_Retail(event) : null;
    fxcalc("MD AUR - Online") ? event.data["MD AUR - Online"] = calculateMDAUR_Online(event) : null;
    fxcalc("APS Final - Retail") ? event.data["APS Final - Retail"] = calculateAPSFinal_Retail(event) : null;
    fxcalc("APS Final - Online") ? event.data["APS Final - Online"] = calculateAPSFinal_Online(event) : null;
    fxcalc("MD ST Final - Retail") ? event.data["MD ST Final - Retail"] = calculateMDSTFinal_Retail(event) : null;
    fxcalc("MD ST Final - Online") ? event.data["MD ST Final - Online"] = calculateMDSTFinal_Online(event) : null;
    fxcalc("Buy Units - Retail Need") ? event.data["Buy Units - Retail Need"] = calculateBuyUnits_RetailNeed(event) : null;
    fxcalc("Buy Units - Retail Buy Min") ? event.data["Buy Units - Retail Buy Min"] = calculateBuyUnits_RetailBuyMin(event) : null;
    fxcalc("Buy Units - Retail Need with Min") ? event.data["Buy Units - Retail Need with Min"] = calculateBuyUnits_RetailNeedwithMin(event) : null;
    fxcalc("Buy Units - Retail Final Buy") ? event.data["Buy Units - Retail Final Buy"] = calculateBuyUnits_RetailFinalBuy(event) : null;
    fxcalc("Buy Units - Online") ? event.data["Buy Units - Online"] = calculateBuyUnits_Online(event) : null;
    fxcalc("Buy Units - Online Final Buy") ? event.data["Buy Units - Online Final Buy"] = calculateBuyUnits_OnlineFinalBuy(event) : null;
    fxcalc("BOP Plan - Omni") ? event.data["BOP Plan - Omni"] = calculateBOPPlan_Omni(event) : null;
    fxcalc("BOP Override - Omni") ? event.data["BOP Override - Omni"] = calculateBOPOverride_Omni(event) : null;
    fxcalc("Total BOP Units - Retail") ? event.data["Total BOP Units - Retail"] = calculateTotalBOPUnits_Retail(event) : null;
    fxcalc("Total BOP Units - Online") ? event.data["Total BOP Units - Online"] = calculateTotalBOPUnits_Online(event) : null;
    fxcalc("Total BOP Units - Omni") ? event.data["Total BOP Units - Omni"] = calculateTotalBOPUnits_Omni(event) : null;
    fxcalc("Total Receipt Units - Retail") ? event.data["Total Receipt Units - Retail"] = calculateTotalReceiptUnits_Retail(event) : null;
    fxcalc("Total Receipt Units - Online") ? event.data["Total Receipt Units - Online"] = calculateTotalReceiptUnits_Online(event) : null;
    fxcalc("Total Receipt Units - Omni") ? event.data["Total Receipt Units - Omni"] = calculateTotalReceiptUnits_Omni(event) : null;
    fxcalc("Total Available Units - Retail") ? event.data["Total Available Units - Retail"] = calculateTotalAvailableUnits_Retail(event) : null;
    fxcalc("Total Available Units - Online") ? event.data["Total Available Units - Online"] = calculateTotalAvailableUnits_Online(event) : null;
    fxcalc("Total Available Units - Omni") ? event.data["Total Available Units - Omni"] = calculateTotalAvailableUnits_Omni(event) : null;
    fxcalc("Total FP Sales Units - Retail") ? event.data["Total FP Sales Units - Retail"] = calculateTotalFPSalesUnits_Retail(event) : null;
    fxcalc("Total FP Sales Units - Online") ? event.data["Total FP Sales Units - Online"] = calculateTotalFPSalesUnits_Online(event) : null;
    fxcalc("Total FP Sales Units - Omni") ? event.data["Total FP Sales Units - Omni"] = calculateTotalFPSalesUnits_Omni(event) : null;
    fxcalc("Total Sales Units - Retail") ? event.data["Total Sales Units - Retail"] = calculateTotalSalesUnits_Retail(event) : null;
    fxcalc("Total Sales Units - Online") ? event.data["Total Sales Units - Online"] = calculateTotalSalesUnits_Online(event) : null;
    fxcalc("Total Sales Units - Omni") ? event.data["Total Sales Units - Omni"] = calculateTotalSalesUnits_Omni(event) : null;
    fxcalc("Total MD Sales Units - Retail") ? event.data["Total MD Sales Units - Retail"] = calculateTotalMDSalesUnits_Retail(event) : null;
    fxcalc("Total MD Sales Units - Online") ? event.data["Total MD Sales Units - Online"] = calculateTotalMDSalesUnits_Online(event) : null;
    fxcalc("Total MD Sales Units - Omni") ? event.data["Total MD Sales Units - Omni"] = calculateTotalMDSalesUnits_Omni(event) : null;
    fxcalc("Total ST% - Retail") ? event.data["Total ST% - Retail"] = calculateTotalST_Retail(event) : null;
    fxcalc("Total ST% - Online") ? event.data["Total ST% - Online"] = calculateTotalST_Online(event) : null;
    fxcalc("Total ST% - Omni") ? event.data["Total ST% - Omni"] = calculateTotalST_Omni(event) : null;
    fxcalc("FP ST% - Retail") ? event.data["FP ST% - Retail"] = calculateFPST_Retail(event) : null;
    fxcalc("FP ST% - Online") ? event.data["FP ST% - Online"] = calculateFPST_Online(event) : null;
    fxcalc("FP ST% - Omni") ? event.data["FP ST% - Omni"] = calculateFPST_Omni(event) : null;
    fxcalc("Total EOP Units - Retail") ? event.data["Total EOP Units - Retail"] = calculateTotalEOPUnits_Retail(event) : null;
    fxcalc("Total EOP Units - Online") ? event.data["Total EOP Units - Online"] = calculateTotalEOPUnits_Online(event) : null;
    fxcalc("Total EOP Units - Omni") ? event.data["Total EOP Units - Omni"] = calculateTotalEOPUnits_Omni(event) : null;
    fxcalc("Total FP EOP Units - Retail") ? event.data["Total FP EOP Units - Retail"] = calculateTotalFPEOPUnits_Retail(event) : null;
    fxcalc("Total FP EOP Units - Online") ? event.data["Total FP EOP Units - Online"] = calculateTotalFPEOPUnits_Online(event) : null;
    fxcalc("Total FP EOP Units - Omni") ? event.data["Total FP EOP Units - Omni"] = calculateTotalFPEOPUnits_Omni(event) : null;
    fxcalc("MIN FLAG - STORES") ? event.data["MIN FLAG - STORES"] = calculateMINFLAG_STORES(event) : null;
    fxcalc("MIN FLAG - PROD") ? event.data["MIN FLAG - PROD"] = calculateMINFLAG_PROD(event) : null;
    fxcalc("Total DTC Buy") ? event.data["Total DTC Buy"] = calculateTotalDTCBuy(event) : null;
    fxcalc("Total Brand Buy") ? event.data["Total Brand Buy"] = calculateTotalBrandBuy(event) : null;
    fxcalc("MIN FLAG - COST MIN") ? event.data["MIN FLAG - COST MIN"] = calculateMINFLAG_COSTMIN(event) : null;
    fxcalc("Omni Total Sales $") ? event.data["Omni Total Sales $"] = calculateOmniTotalSales(event) : null;

  };
  const calcBottomRow = () => {
    // console.log("In calcBottomRow")
    const result = {};
    let filtredData = [];
    footerRowCalCols.forEach(c => { if (!result[c]) result[c] = { btmRow1: 0, btmRow2: 0 } });
    try { gridApi.current.forEachNodeAfterFilter(node => { filtredData.push(node.data) }); }
    catch { filtredData = Pst; }
    filtredData = filtredData.filter((i) => i["Style Activity"] === "ACTIVE");
    const pstActive = Pst.filter((i) => i["Style Activity"] === "ACTIVE");
    const BuyStatus = Pst.filter((i) => i["Buy Status"] === "Confirmed");
    footerRowCalCols.forEach(column => {
      if (column === "Style Activity") {
        result["Style Activity"].btmRow1 = Pst.length;
        result["Style Activity"].btmRow2 = filtredData.length;
      } else if (column === "Buy Status") {
        result["Buy Status"].btmRow1 = "Buy Status";
        result["Buy Status"].btmRow2 = ((BuyStatus.length / Pst.length) * 100).toFixed(2) + "%";
      } else {
        // result[column].btmRow1 = pstActive.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue[column] ? parseFloat(currentValue[column]).toFixed(2) : 0), 0);
        // result[column].btmRow2 = filtredData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue[column] ? parseFloat(currentValue[column]).toFixed(2) : 0), 0);
        const total1 = pstActive.reduce((accumulator, currentValue) => {
          const value = parseFloat(currentValue[column]);
          return accumulator + (isNaN(value) ? 0 : value);
        }, 0);
        const count1 = pstActive.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue[column] ? 1 : 0);
        }, 0);
        const average1 = count1 > 0 ? (total1 / count1).toFixed(2) : 0;
        result[column].btmRow1 = average1;

        const total2 = filtredData.reduce((accumulator, currentValue) => {
          const value2 = parseFloat(currentValue[column]);
          return accumulator + (isNaN(value2) ? 0 : value2);
        }, 0);
        const count2 = filtredData.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue[column] ? 1 : 0);
        }, 0);
        const average2 = count2 > 0 ? (total2 / count2).toFixed(2) : 0;
        result[column].btmRow2 = average2;
      }
    });
    if (Object.keys(result).length === 0) setBottomRowData([]);
    else {
      let row1 = [];
      let row2 = [];
      footerRowCalCols.forEach(c => {
        row1[c] = `${result[c]["btmRow1"]}`
        row2[c] = `${result[c]["btmRow2"]}`
      });
      setBottomRowData([row1, row2]);
    }

  };

  return (
    <CustomAgGrid
      autoChangeState={autoChangeState}
      autoGetChangeResult={autoGetChangeResult}
      columnDefs={varColDefs}
      customColumnDef={PstColDef}
      filters={varFilters}
      isGrouppedHeader={true}
      calculateBottomRow={calcBottomRow}
      pinnedBottomRowData={bottomRowData}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      resteAutoChangeState={resteAutoChangeState}
      rowData={Pst}
      setCellValueChanged={setCellValueChanged}
      calculateFormula={calculateReactFormula}
      setCurrentGridApi={onGridReady}
      SSNData={PstCache}
      tab_name="PST"
      tbl_name={tbl_name}
      tblHighlightCells={varHighlightCell}
      columnGrpPref={columnGrpPref}
			columnPref={columnPref}
    />
  );
});

PstTab.propTypes = {
  setCurrentGridApi: PropTypes.func.isRequired,
};

export default PstTab;
