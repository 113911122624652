/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// react-router-dom components

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components

// Material Dashboard 2 PRO React components

// Material Dashboard 2 PRO React examples

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import CloseIcon from "@mui/icons-material/Close";

// Material Dashboard 2 PRO React context
import { Search } from "@mui/icons-material";
import { IconButton, createTheme, Divider, InputAdornment, List, TextField, ThemeProvider } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  setCurrentTab,
  setDefault,
  setMiniSidenav,
  setSideNavBtnClick,
  useMaterialUIController
} from "context";
import { useCallback, useMemo, useState } from "react";
import { applicationTitle, restApiPath, utcDifference } from "utils/app.props";
import { userRoutes } from "./navigationList";
import SidenavCollapse from "./SidenavCollapse";
import SidenavList from "./SidenavList";
import sidenavLogoLabel from "./styles/sidenav";
import Avatar from "layouts/linelist/components/Analytics/Avatar";
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "layouts/linelist/XL_Utils";
import Settings from "layouts/linelist/components/Settings";
import CustomThemeCreator from "./CustomThemeCreator";

function Sidenav({ color, onQuickFilterChange, downloadBtn, ...rest }) {
  const dispatch = useDispatch(); //to update redux state variable
  const nevigate = useNavigate();

  const [controller, dispatchMui] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const theme = CustomThemeCreator({ darkMode, transparentSidenav, whiteSidenav });

  let textColor = "white";

  const [quickFilterText, setQuickFilterText] = useState("");
  const [openCollapse, setOpenCollapse] = useState(true);

  const [aiAvatarDialog, setAiAvatarDialog] = useState(false);
  const [openSettingDialog, setOpenSettingDialog] = useState(false);

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const btnAIAvatarClick = () => {
    setAiAvatarDialog(true);
  };
  const handleLogout = () => {
    dispatch({ type: "SET_PLEASE_WAIT", data: true });
    fetch(restApiPath + "userLogout", {
      method: 'POST',
      headers: {
        'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: sessionStorage.getItem('access_token')
    })
      .then(result => result.json())
      .then(result => {
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
        if (result.error === "invalid_token") {
          sessionStorage.clear()
          setDefault(dispatchMui);
          nevigate("/login");
          return;
        }
        if (result && result.response) {
          sessionStorage.clear()
          setCurrentTab(dispatchMui, "");
          setSideNavBtnClick(dispatchMui, false);
          setMiniSidenav(dispatchMui, true);
          setDefault(dispatchMui);
          nevigate("/login");
        } else {
          toastMessage("Opps. Something went wrong. ", "error");
        }
      })
  };
  const onChange = (event) => {
    setQuickFilterText(event.target.value)
    onQuickFilterChange(event);
  }

  const showSetting = () => {
    setOpenSettingDialog(true);
  }
  // JsonServiceCall("runFahertyWIPDataLoadProcess", get_app_prop("User_Name") & "|WIP_NetSuite_Data_Load|" & get_app_prop("time_diff_hours"), True)

  const runAirTableIntegration = () => {
    dispatch({ type: "SET_PLEASE_WAIT", data: true });
    fetch(restApiPath + "runFahertyLinelistDataLoadProcess", {
      method: 'POST',
      headers: {
        'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      // body: sessionStorage.getItem('access_token')
      body: (sessionStorage.getItem("email") + "|air_table_data_load|" + utcDifference)
    })
      .then(result => result.json())
      .then(result => {
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
        if (result.error === "invalid_token") {
          sessionStorage.clear()
          setDefault(dispatchMui);
          nevigate("/login");
          return;
        }
        if (result && result.response) {
          const { message } = result;
          if (message === "SUCCESS")
            toastMessage("You will be notified via email once your process completed.", "success");
          else if (message === "RUNNING")
            toastMessage("Another process is already running!! Please try after some time.", "info");
        } else {
          toastMessage("Due to some technical reasons,there was failure in Run Integration Process.Please contact your system administrator.", "error");
        }
      })
  }
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map((route, index) => {
      const { name, collapse, icon, href, key } = route;
      let returnValue;
      if (key === 'logout')
        returnValue = <SidenavCollapse key={key} name={name} icon={icon} noCollapse onClick={handleLogout} />;
      else if (key === 'aiAvatar')
        returnValue = <SidenavCollapse key={key} name={name} icon={route.icon(!transparentSidenav && !whiteSidenav)} noCollapse onClick={btnAIAvatarClick} />;
      else if (key === 'profile-settings' && sessionStorage.getItem("user_type") === "admin")
        returnValue = <SidenavCollapse key={key} name={name} icon={icon} noCollapse onClick={() => downloadBtn("Manage User")} />;
      else if (key === 'theme-settings')
        returnValue = <SidenavCollapse key={key} name={name} icon={icon} noCollapse onClick={showSetting} />;

      return <SidenavList key={key}>{returnValue}</SidenavList>;
    })

  const renderUserProfile = userRoutes.map(({ type, name, icon, title, collapse, noCollapse, key, href, route }, index) => {
    let returnValue;

    if (type === "title" && sessionStorage.getItem("user_type") === "admin") {

      returnValue = (
        <SidenavCollapse
          key={key}
          name={name}
          icon={icon}
          noCollapse
          onClick={runAirTableIntegration}
        />
      );
    } else if (type === "collapse") {
      returnValue = (
        <SidenavCollapse
          key={key}
          name={sessionStorage.getItem("email")}
          icon={icon}
          // active={index === tabValue}
          open={openCollapse !== key}
          onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
        >
          {collapse ? renderCollapse(collapse) : null}
        </SidenavCollapse>
      );
    }
    return returnValue;
  }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={1} textAlign="center">
        <MDBox display="flex" alignItems="center" px={1}>
          <MDBox
            component="img"
            src="/Faherty-Brand.png"
            alt="name"
            width="80px"
            maxWidth="150px"
            maxHeight="150px"
            position="relative"
            borderRadius="5px"
            zIndex={1}
          />
          {/* {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />} */}
          <MDBox
            width={!applicationTitle && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <div style={{width: "100%", textAlign: "center"}}>
              <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor} ml={1}>
                {applicationTitle}
              </MDTypography>
            </div>
          </MDBox>
        </MDBox>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        <ThemeProvider theme={theme}>
          <TextField
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: !transparentSidenav && !whiteSidenav ? "white" : "black" }} />
                </InputAdornment>
              ),
            }}
            placeholder="Quick search in active tab...."
            value={quickFilterText}
            onChange={onChange}
            ssx={{
              '& .MuiInputBase-input::placeholder': {
                color: darkMode ? 'white' : 'black',
                opacity: 0.7,
                fontSize: "12px",
              },
              '& .MuiOutlinedInput-root': {
                height: '40px', // Set a fixed height for the input
              },
            }}
          />
        </ThemeProvider>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        <List>{renderUserProfile}</List>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />

      </MDBox>
      <Dialog
        open={aiAvatarDialog}
        onClose={() => setAiAvatarDialog(false)}
        maxWidth={"xxl"}
        disableEscapeKeyDown
      // fullS
      >
        <DialogTitle sx={{ pt: '4px', pb: '4px', fontSize: '16px', backgroundColor: '#fafafb' }}>
          Select Your Assistant
          <IconButton
            aria-label="close"
            onClick={() => setAiAvatarDialog(false)}
            style={{ position: "absolute", right: 8, top: -2 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ margin: '0px', backgroundColor: 'gray' }} />
        <DialogContent sx={{ pt: '8px' }}>
          <Avatar />
        </DialogContent>
      </Dialog>
      <Dialog open={openSettingDialog}
        onClose={() => setOpenSettingDialog(false)}
        maxWidth={"xxl"}
        disableEscapeKeyDown>
        <DialogTitle sx={{ pt: '4px', pb: '4px', fontSize: '16px', backgroundColor: '#fafafb' }}>
          Settings
          <IconButton
            aria-label="close"
            onClick={() => setOpenSettingDialog(false)}
            style={{ position: "absolute", right: 8, top: -2 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ margin: '0px', backgroundColor: 'gray' }} />
        <DialogContent sx={{ pt: '8px' }}>
          <Settings />
        </DialogContent>
      </Dialog>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
};

export default Sidenav;
