import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Autocomplete, Box, Collapse, Divider, Grid, List, ListItem, ListItemButton, ListItemText, TextField, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { setCurrentTab } from "context";
import { useMaterialUIController } from "context";
import { setUnsentCount } from "layouts/linelist/getChangesComman";
import { setHighlightByTblName } from "layouts/linelist/getChangesComman";
import { setStateDataByTblName } from "layouts/linelist/getChangesComman";
import { getUnsentCount } from "layouts/linelist/getChangesComman";
import { getStateDataByTblName } from "layouts/linelist/getChangesComman";
import { getHighlightByTblName } from "layouts/linelist/XL_Utils";
import { getColumnDropDownValue } from "layouts/linelist/XL_Utils";
import { toastMessage } from "layouts/linelist/XL_Utils";
import { getColumnDefByTblName } from "layouts/linelist/XL_Utils";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import HistoricDataPanel from "../HistoricDataPanel";

const LineListToDo = ({ closeDialog }) => {
    const [controller, dispatch] = useMaterialUIController();
    const {
        asRequiredDownloadQuery,
    } = controller;

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    }
    useEffect(() => {
        function handleResize() { setWindowDimensions(getWindowDimensions()); }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const gridApi = useRef();
    const [bulkEditValues, setBulkEditValues] = useState({});
    const [selectedColumn, setSelectedColumn] = useState("");
    const [dropDownOptions, setDropDownOptions] = useState([]);

    const [pendingActionList, setPendingActionList] = useState([]);
    const [missingValueList, setMissingValueList] = useState([]);

    const [openPendingActions, setOpenPendingActions] = useState(true);
    const [openMissingData, setOpenMissingData] = useState(true);

    const [focusedCell, setFocusedCell] = useState();
    const [focusedCellEvent, setFocusedCellEvent] = useState();
    const [showHistoricDataPnael, setShowHistoricDataPanel] = useState(false);
    const focusedRowRef = useRef(null);

    const tbl_name = "xl_faherty_linelist";

    const missingDataCol = useMemo(() => [
        "MSRP", "WHLS", "Previous FOB", "Placeholder/Flash FOB", "Initial Cost",
        "300 - FOB", "500 - FOB", "1000 - FOB", "Bulk Cost", "Minimum Buy",
        "Upcharge for under Minimum", "Faherty Target FOB", "Factory Counter",
        "Final FOB", "Scenario Cost", "PO Override", "Duty Rate"
    ], []);
    const pendingActionDataCol = useMemo(() => [
        "PD Owner", "Factory"
    ], []);

    const colDef = useMemo(() => getColumnDefByTblName(tbl_name), []);

    const setDropDown = (column) => {
        const tmp = column.dropDownValues ? column.dropDownValues : [];
        setDropDownOptions(tmp);
    }

    useEffect(() => {
        if (asRequiredDownloadQuery && asRequiredDownloadQuery !== "") {
            const stateData = getStateDataByTblName(tbl_name);

            const filtredData = stateData.filter((dt) => dt['Style Activity'] === 'ACTIVE');
            const commanColDef = [
                { field: "Season Code", headerComponent: null, cellStyle: { "backgroundColor": "#F0F0F0" }, editable: false },
                { field: "Department", headerComponent: null, cellStyle: { "backgroundColor": "#F0F0F0" }, editable: false },
                { field: "SKU", headerComponent: null, cellStyle: { "backgroundColor": "#F0F0F0" }, editable: false },
                { field: "row_id", hide: true },
                { field: "__isDataChanged ", hide: true }];

            // add action needned column list
            const tmpPendingActions = [];

            const colDefProductionLock = columnDefs.find((cl) => cl.headerName === "Production Lock" && cl.editable);
            if (colDefProductionLock) {
                colDefProductionLock["cellStyle"] = { "backgroundColor": "#FDFCEC" };
                colDefProductionLock["autoHeaderHeight"] = true;
                colDefProductionLock["minWidth"] = 180;
                const columnDefination = [...commanColDef];
                columnDefination.push(colDefProductionLock);

                const pendingProductionLockFilter = filtredData.filter(r => (r['DTC Confirmation'] === 'Confirmed' && r['Wholesale Confirmation'] === 'Confirmed' &&
                    (r['Production Lock'] === undefined || r['Production Lock'] === null || r['Production Lock'] === '')));

                if (pendingProductionLockFilter && pendingProductionLockFilter.length > 0) {
                    const specificColumns = _.map(pendingProductionLockFilter, (row) => ({
                        row_id: row.row_id,
                        ["Season Code"]: row["Season Code"],
                        ["Department"]: row["Department"],
                        SKU: row["SKU"],
                        ["Production Lock"]: row["Production Lock"],
                        __isDataChanged: false
                    }));
                    tmpPendingActions.push({ column: "Production Lock", colDef: columnDefination, rowData: specificColumns, dropDownValues: getColumnDropDownValue(colDefProductionLock.column_data_source) });
                }
            }

            pendingActionDataCol.map((col) => {
                const tmpColDef = colDef.find((cl) => cl.headerName === col && cl.editable);
                const columnDefination = [...commanColDef];

                let extraCol = "";
                if (col === "PD Owner") {
                    columnDefination.push({ field: "Factory", width: "100", headerComponent: null, cellStyle: { "backgroundColor": "#F0F0F0" }, editable: false })
                    extraCol = "Factory";
                } else if (col === "Factory") {
                    columnDefination.push({ field: "PD Owner", width: "100", headerComponent: null, cellStyle: { "backgroundColor": "#F0F0F0" }, editable: false })
                    extraCol = "PD Owner";
                }

                if (tmpColDef) {
                    tmpColDef["autoHeaderHeight"] = true;
                    tmpColDef["cellStyle"] = { "backgroundColor": "#FDFCEC" };
                    tmpColDef["minWidth"] = 180;
                    columnDefination.push(tmpColDef);
                    const rows = filtredData.filter((dt) => dt[col] === "" || dt[col] === null || dt[col] === undefined);
                    if (rows && rows.length > 0) {
                        const specificColumns = _.map(rows, (row) => ({
                            row_id: row.row_id,
                            ["Season Code"]: row["Season Code"],
                            ["Department"]: row["Department"],
                            SKU: row["SKU"],
                            [extraCol]: row[extraCol],
                            [col]: row[col],
                            __isDataChanged: false
                        }));
                        tmpPendingActions.push({ column: col, colDef: columnDefination, rowData: specificColumns, dropDownValues: getColumnDropDownValue(tmpColDef.column_data_source) });
                    }
                }
            })
            if (tmpPendingActions.length > 0) {
                const first = tmpPendingActions[0];

                setDropDown(first);
                setColumnDefs(first.colDef);
                setRowData(first.rowData);
                setSelectedColumn(first.column);
                setPendingActionList(tmpPendingActions);
            }
            // add missing column list
            const tmpMissingColList = [];
            const tmpComman = [
                { field: "Category", headerComponent: null, cellStyle: { "backgroundColor": "#F0F0F0" }, editable: false },
                { field: "Subcategory", headerComponent: null, cellStyle: { "backgroundColor": "#F0F0F0" }, editable: false },
                { field: "Style Description", headerComponent: null, cellStyle: { "backgroundColor": "#F0F0F0" }, editable: false },
                { field: "Color Description", headerComponent: null, cellStyle: { "backgroundColor": "#F0F0F0" }, editable: false },
            ]

            missingDataCol.map((col) => {
                const tmpColDef = colDef.find((cl) => cl.headerName === col && cl.editable);
                const columnDefination = [...commanColDef, ...tmpComman];
                if (tmpColDef) {
                    tmpColDef["autoHeaderHeight"] = true;
                    tmpColDef["minWidth"] = 180;
                    columnDefination.push(tmpColDef);
                    const rows = filtredData.filter((dt) => dt[col] === "" || dt[col] === null || dt[col] === undefined);
                    if (rows && rows.length > 0) {
                        const specificColumns = _.map(rows, (row) => ({
                            row_id: row.row_id,
                            ["Season Code"]: row["Season Code"],
                            ["Department"]: row["Department"],
                            ["Category"]: row["Category"],
                            ["Subcategory"]: row["Subcategory"],
                            SKU: row["SKU"],
                            ["Style Description"]: row["Style Description"],
                            ["Color Description"]: row["Color Description"],
                            [col]: row[col],
                            __isDataChanged: false
                        }));
                        tmpMissingColList.push({ column: col, colDef: columnDefination, rowData: specificColumns });
                    }
                }
            })
            if (tmpMissingColList.length > 0) {
                setMissingValueList(tmpMissingColList);
                if (tmpPendingActions.length === 0) {
                    const first = tmpMissingColList[0];

                    setShowHistoricDataPanel(true);
                    setDropDown(first);
                    setColumnDefs(first.colDef);
                    setRowData(first.rowData);
                    setSelectedColumn(first.column);
                }
            }
        }
    }, [asRequiredDownloadQuery])

    const handleBulkEdit = useCallback((field, value) => {
        gridApi.current.forEachNode((node) => {
            if (node.data[field] !== value) {
                node.setDataValue(field, value);
                node.data.__isDataChanged = true;
            }
        });
        gridApi.current.refreshCells({ force: true });
    }, []);

    const BulkEditHeader = (props) => {
        const { displayName, column } = props;
        const field = column.getColId();
        const [inputValue, setInputValue] = useState('');

        const { onBulkEdit } = useMemo(() => ({
            ...column.getColDef().headerComponentParams,
            ...props,
        }), [column, props]);

        const handleInputChange = (event, newValue) => {
            const { colDef } = column;
            let value = newValue;

            if (colDef.type === "percentage" && !value.includes(".")) {
                value = parseInt(value) / 100;
            }
            setInputValue(value);
            onBulkEdit(field, value);
            setBulkEditValues(prev => ({ ...prev, [field]: value }));
        };
        return (
            <div style={{ height: '60px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                <div>{displayName}</div>
                <Autocomplete
                    freeSolo
                    options={dropDownOptions}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            placeholder="Bulk edit (enter to apply)"
                        />
                    )}
                    value={inputValue}
                    onChange={handleInputChange}
                    style={{ width: '95%', backgroundColor: "#FFFFFF" }}
                />
            </div>
        );
    };

    const defaultColDef = useMemo(() => ({
        editable: true,
        sortable: true,
        filter: true,
        flex: 1,
        headerComponent: BulkEditHeader,
        headerComponentParams: {
            bulkEditValues,
            onBulkEdit: handleBulkEdit,
        },
    }), [bulkEditValues, dropDownOptions]);

    const onGridReady = (params) => {
        gridApi.current = params.api;
    }

    const onCellEditingStopped = useCallback((event) => {
        const { data } = event;
        data.__isDataChanged = true;
    }, []);

    const onCellFocused = useCallback((event) => {
        const focusedRowId = event.rowIndex;

        if (focusedRowId !== focusedRowRef.current) {
            focusedRowRef.current = focusedRowId;
            setFocusedCell(event.api.getFocusedCell());
            setFocusedCellEvent(event);
        }
    }, []);

    const handleMissingValueClick = () => setOpenMissingData(!openMissingData);
    const showMissingCol = (subItem, from) => {
        setFocusedCell(null);
        setDropDown(subItem);
        setColumnDefs(subItem.colDef);
        setRowData(subItem.rowData);
        setSelectedColumn(subItem.column);

        if (from === "missingValue") setShowHistoricDataPanel(true);
        else setShowHistoricDataPanel(false);
    }

    const updateArray = (arr1, arr2) => {
        _.forEach(arr1, (item1) => {
            const match = _.find(arr2, { row_id: item1.row_id });
            if (match) {
                _.assign(item1, match);
            }
        });
    };

    const updateAction = () => {
        const stateData = getStateDataByTblName(tbl_name);
        let unsentCount = getUnsentCount(tbl_name);
        const highlightCells = getHighlightByTblName(tbl_name) || [];

        const updatedRows = [];
        gridApi.current.forEachNodeAfterFilterAndSort((node) => {
            if (node.data.__isDataChanged) {
                updatedRows.push(node.data);

                const key = tbl_name + "|" + selectedColumn + "|" + node.data.row_id;
                highlightCells.push({ [key]: 1 });
            }
        });

        updateArray(stateData, updatedRows);

        unsentCount += updatedRows.length;

        setUnsentCount(tbl_name, unsentCount);
        setStateDataByTblName(tbl_name, stateData);
        setHighlightByTblName(tbl_name, highlightCells);

        setCurrentTab(dispatch, "Linelist");

        toastMessage("Please perform send data from Linelist tab.", "success");
        closeDialog();
    }
    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: "agTotalAndFilteredRowCountComponent" },
                // { statusPanel: "agTotalRowCountComponent", align: "center" },
                // { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" },
            ],
        };
    }, []);

    const setSelectedHistoricValue = (value) => {
        if (focusedCell) {
            const { rowIndex } = focusedCell;
            // Stop editing if a cell is being edited
            gridApi.current.stopEditing();

            // Use applyTransaction to update the specific row
            const updatedRow = {
                ...rowData[rowIndex],
                [selectedColumn]: value,
                __isDataChanged: true
            };

            // Apply the transaction to update the grid
            gridApi.current.applyTransaction({
                update: [updatedRow],
            });
        }
    }

    const getRowId = params => params.data.row_id;
    // sx={{ height: windowDimensions.height - 150, width: windowDimensions.width - 545 }}
    return (
        <MDBox>
            <Grid container spacing={1.5} >
                <Grid item xs={1.5}>
                    <MDBox variant='contained' borderRadius="lg"
                        shadow="lg"
                        opacity={1} p={1} sx={{ height: windowDimensions.height - 210, overflow: "hidden", overflowY: "scroll" }}>
                        <List>
                            <ListItemButton sx={{ padding: "2px" }} onClick={() => setOpenPendingActions(!openPendingActions)}>
                                <ListItemText>
                                    <Typography variant="body2" sx={{ fontSize: '0.8rem', fontFamily: "Inter", fontWeight: "bold" }} color="dark">
                                        Pending Actions
                                    </Typography>
                                </ListItemText>
                                {openPendingActions ? <ExpandLess sx={{ transition: 'all 0.3s ease' }} /> : <ExpandMore sx={{ transition: 'all 0.3s ease' }} />}
                            </ListItemButton>
                            <Collapse in={openPendingActions} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {pendingActionList.map((subItem, index) => (
                                        <ListItem key={index}
                                            sx={{
                                                pl: "4px", mt: 0.5, mb: 0.5, cursor: 'pointer', backgroundColor: selectedColumn === subItem.column ? '#007BFF' : 'white',
                                                color: selectedColumn === subItem.column ? '#FFFFFF' : 'black',
                                                '&:hover': {
                                                    backgroundColor: '#CCCCFF',
                                                },
                                            }}
                                            onClick={() => showMissingCol(subItem, "pendingActions")}
                                        >
                                            <ListItemText primary={<Typography variant="body2" sx={{ fontSize: '0.7rem', fontFamily: "Inter", color: "black" }}>
                                                {subItem.column}
                                            </Typography>} />
                                            <Divider />
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                            <Divider />
                            <ListItemButton sx={{ padding: "2px" }} onClick={handleMissingValueClick}>
                                <ListItemText>
                                    <Typography variant="body2" sx={{ fontSize: '0.8rem', fontFamily: "Inter", fontWeight: "bold" }} color="dark">
                                        Missing Values
                                    </Typography>
                                </ListItemText>
                                {openMissingData ? <ExpandLess sx={{ transition: 'all 0.3s ease' }} /> : <ExpandMore sx={{ transition: 'all 0.3s ease' }} />}
                            </ListItemButton>
                            <Collapse in={openMissingData} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {missingValueList.map((subItem, index) => (
                                        <ListItem key={index}
                                            sx={{
                                                pl: "4px", mt: 0.5, mb: 0.5, cursor: 'pointer', backgroundColor: selectedColumn === subItem.column ? '#007BFF' : 'white',
                                                color: selectedColumn === subItem.column ? '#FFFFFF' : 'black',
                                                '&:hover': {
                                                    backgroundColor: '#CCCCFF',
                                                },
                                            }}
                                            onClick={() => showMissingCol(subItem, "missingValue")}
                                        >
                                            <ListItemText primary={<Typography variant="body2" sx={{ fontSize: '0.7rem', fontFamily: "Inter", color: "black" }}>
                                                {subItem.column}
                                            </Typography>} />
                                            <Divider />
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </List>
                    </MDBox>
                </Grid>

                <Grid item xs={showHistoricDataPnael ? 8.5 : 10.5}>
                    <Box sx={{ height: windowDimensions.height - 210, }} className="ag-theme-quartz">
                        <AgGridReact
                            columnDefs={columnDefs}
                            rowData={rowData}
                            onGridReady={onGridReady}
                            onCellFocused={onCellFocused}
                            onCellEditingStopped={onCellEditingStopped}
                            enableFillHandle={true}
                            enableRangeSelection={true}
                            enterNavigatesVertically={true}
                            enterNavigatesVerticallyAfterEdit={true}
                            defaultColDef={defaultColDef}
                            statusBar={statusBar}
                            getRowId={getRowId}
                        />
                    </Box>
                </Grid>

                {
                    showHistoricDataPnael ? (
                        <Grid item xs={showHistoricDataPnael ? 2 : 0} >
                            <HistoricDataPanel
                                focusedCell={focusedCell}
                                focusedCellEvent={focusedCellEvent}
                                columnName={selectedColumn}
                                tbl_name={tbl_name}
                                onSelectValue={setSelectedHistoricValue} />
                        </Grid>
                    ) : null

                }
            </Grid>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", marginBottom: "5px", flex: 1, }}>
                <MDButton onClick={updateAction} sx={{ textTransform: "none" }} size="small" variant="gradient" color="info">Update</MDButton>
            </div>
        </MDBox>)
}

export default LineListToDo;