import { executeDataVerification, toastMessage } from 'layouts/linelist/XL_Utils';
import { GetAutoChanges, compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';


const tbl_name = "xl_faherty_comp_aps_master_stores_ecomm";
const rng_name = "Comp_APS_Master_StoreEcom_Rng";


const CompApsMasterStoreEcomTab = forwardRef(({
  setCurrentGridApi,
  setActionItemDataFun}, ref) => {

  const dispatch = useDispatch(); //to update redux state variable
  const CompApsMasterStoreEcomData = useSelector(state => state.CompApsMasterStoreEcomData);
  const SSNData = useSelector((s) => s.CompApsMasterStoreEcomDataSSN);

  const columnPref = useSelector((s) => s.CompAPSMasterStoreEcomColPref);

  const CompApsMasterStoreEcomHighlistCell = useSelector(state => state.CompApsMasterStoreEcomHighlistCell);
  const tmpColDef = useSelector(state => state.CompApsMasterStoreEcomColDef ? state.CompApsMasterStoreEcomColDef : []);
  const CompApsMasterStoreEcomSorting = useSelector(state => state.CompApsMasterStoreEcomSorting ? state.CompApsMasterStoreEcomSorting : []);
  const CompApsMasterStoreEcomFilters = useSelector(state => state.CompApsMasterStoreEcomFilters ? state.CompApsMasterStoreEcomFilters : {});

  const highlightCells = useRef([]);
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const cellValueChanged = useRef([]);

  const [isGridReady, setIsGridReady] = useState(false);
  const [autoChangeState, setAutoChangeState] = useState(false)
  const [autoGetChangeResult, setAutoGetChangeResult] = useState({});

  const setResetFilter = (setReset) => {
    let sorting = CompApsMasterStoreEcomSorting
    let filtering = CompApsMasterStoreEcomFilters

    if (setReset === "reset") {
      sorting = null
      filtering = null
    }
    gridApi.current.applyColumnState({
      state: sorting
    })
    gridApi.current.setFilterModel(filtering);

  }
  //Required for all tabs (For before send data functionalities)
  useImperativeHandle(ref, () => ({
    validateSendData: () => {
      if (!CompApsMasterStoreEcomData) return false
      const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
      if (res.status === false) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        return (res)
      }
      removeHeihlight();
      cellValueChanged.current = [];
      return ({ status: true })
    },
    getChangesFunc: async (masterData, metaData) => {
      const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, CompApsMasterStoreEcomColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
      if (res.status) {
        highlightCells.current = res.highlightCells

        dispatch({ type: "COMP_APS_MASTER_DATA", data: res.data });
        gridApi.current.refreshCells({ force: true })
      } else {
        toastMessage(res.msg, "warning");

      }
      return res
    },
    getAutoChanges: (data, downloadType) => {
      setResetFilter("reset")
      const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
      setAutoChangeState(true);
      setAutoGetChangeResult(res);
    }
  }));
  const removeHeihlight = () => {
    highlightCells.current = []
    if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true })
  };

  useEffect(() => {
    if (isGridReady) {
      if (CompApsMasterStoreEcomSorting !== null && CompApsMasterStoreEcomSorting !== undefined) {
        gridApi.current.applyColumnState({
          state: CompApsMasterStoreEcomSorting
        })
      }
      if (CompApsMasterStoreEcomFilters !== null && CompApsMasterStoreEcomFilters !== undefined) {
        gridApi.current.setFilterModel(CompApsMasterStoreEcomFilters);
      }
    }
  }, [isGridReady])
  const onGridReady = (params) => {
    gridApi.current = params.api
    gridColumnApi.current = params.columnApi;
    setCurrentGridApi(params);
    setIsGridReady(true)
  }
  const onSortChanged = (event) => {
    const colState = gridApi.current.getColumnState();
    const savedSortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch({ type: "SET_COMP_APS_MASTER_SORTING", data: savedSortState });
  }
  const onFilterChanged = (event) => {
    const savedFilterModel = gridApi.current.getFilterModel();
    dispatch({ type: "SET_COMP_APS_MASTER_FILTERS", data: savedFilterModel });
  }
  const resteAutoChangeState = () => {
    setResetFilter("set");
    setAutoChangeState(false);
    setAutoGetChangeResult({});
  }
  const setCellValueChanged = (data) => {
    cellValueChanged.current = data;
  }
  return (
    <CustomAgGrid
      setCurrentGridApi={onGridReady}
      filters={CompApsMasterStoreEcomFilters}
      setActionItemDataFun={setActionItemDataFun}
      tab_name="Comp_APS_Master_StoreEcom"
      tbl_name={tbl_name}
      columnDefs={tmpColDef}
      rowData={CompApsMasterStoreEcomData}
      SSNData={SSNData}
      tblHighlightCells={CompApsMasterStoreEcomHighlistCell}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      autoChangeState={autoChangeState}
      resteAutoChangeState={resteAutoChangeState}
      autoGetChangeResult={autoGetChangeResult}
      setCellValueChanged={setCellValueChanged}
      columnPref={columnPref}
    />
  )
})

CompApsMasterStoreEcomTab.propTypes = {
  setCurrentGridApi: PropTypes.func.isRequired,
  settimeLineDataFun: PropTypes.func.isRequired,

};

export default CompApsMasterStoreEcomTab;
