import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { getGroupListByTableName } from "layouts/linelist/colGrpVariable";
import {
  functionalityAccess,
  getDataFromMetaData,
} from "layouts/linelist/customFunctions";
import {
  generateSummary,
  getUnsentCount,
  setHighlightByTblName,
  setSessionDataByTblName,
  setStateDataByTblName,
  setUnsentCount,
} from "layouts/linelist/getChangesComman";
import {
  CH1,
  checkActionItemAssigned,
  checkHeihlight,
  ExcelDateToJsDate,
  executeDataVerification,
  freezePane,
  generateColumnGrouping,
  getCellTimeline,
  getColumnIndex,
  getColumnStyle,
  getDatePicker,
  getExcelStyle,
  getRowNodeFromId,
  isEditable,
  showCustomToastMessage,
  toastMessage,
  unFreezePane
} from "layouts/linelist/XL_Utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // isActionItemEnabled,
  applicationTitle,
  groupHeaderHeight,
  HeaderHeight,
} from "utils/app.props";
import CellTimeline from "../CellTimeLine";
import ShowChanges from "../ShowChanges";
import RowInputDialog from "../RowInputDialog";
import ConfirmationDialog from "../ConfirmationDialog";


const CustomAgGrid = ({
  autoChangeState,
  autoGetChangeResult,
  calculateBottomRow,
  calculateFormula,
  columnDefs,
  customCellStyle,
  customColumnDef,
  enableCheckBox,
  filters,
  isGrouppedHeader,
  removeCellValueChange,
  resteAutoChangeState,
  rowData,
  rowDraggable,
  setActionItemDataFun,
  setCellValueChanged,
  setCurrentGridApi,
  SSNData,
  tab_name,
  tbl_name,
  tblHighlightCells,
  columnGrpPref,
  columnPref,
  ...props
}) => {
  const [blCellTimeline, setBlCellTimeline] = useState(false);
  const [PopupImage, setPopupImage] = useState(false);
  const [PopupImageData, setPopupImageData] = useState([]);
  const [openAddRowDlg, setOpenAddRowDlg] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState(0);

  const [CellTimelineData, setCellTimelineData] = useState([]);
  const [colDef, setColDef] = useState([]);
  const [isGridReady, setIsGridReady] = useState(false);
  const [openChangesDlg, setOpenChangesDlg] = useState(false);
  const ActionItemData = useSelector((s) => s.ActionItemData);
  const CellTimelineColNm = useRef("");
  const CellTimelineRowId = useRef("");
  const cellValueChanged = useRef([]);
  const changedData = useRef([]);
  const dispatch = useDispatch();
  const gridApi = useRef(null);
  const highlightCells = useRef(tblHighlightCells);
  // const metaData = useSelector((s) => s.appMetaData);
  const msgArr = useRef([]);
  const selectedChngData = useRef([]);
  let unsentCount = getUnsentCount(tbl_name);

  const funCellStyle = (params, col) => {
    const value = params.value ? params.value : "";
    let bgColor = "";
    let bdrColor = "";
    let txtDeco = "";
    let fontColor = "#000000";
    let boldTxt = "normal";
    let borderBottom = "";
    // custom code
    const customCode = getColumnStyle(columnDefs, value, params, col);
    if (customCode.status) {
      bgColor = customCode.bgColor;
      bdrColor = customCode.bdrColor;
      txtDeco = customCode.txtDeco;
      fontColor = customCode.fontColor;
      boldTxt = customCode.boldTxt;
      borderBottom = customCode.borderBottom;
      if (customCode.fontStyle) fontStyle = customCode.fontStyle;
    } else {
      const editable = isEditable(params, col.field, col.editable, col.insert_editable);
      if (editable) bgColor = "#FFFFFF";
      else bgColor = "#F0F0F0";
      if (col.type === "dropdown" && editable) bgColor = "#FDFCEC";
    }
    if (params.node.rowPinned === "bottom" && params.node.rowIndex === 0) {
      bgColor = "#C7F9CC";
      boldTxt = "bold";
    }
    if (params.node.rowPinned === "bottom" && params.node.rowIndex === 1) {
      bgColor = "#FBF8CC";
      boldTxt = "bold";
    }
    let key = tbl_name + "|" + col.headerName.trim() + "|" + params.node.data.row_id;
    let obj = checkHeihlight(key, highlightCells.current);
    if (obj.status === true) {
      if (obj.colorCode == 1) bgColor = "cyan";
      else if (obj.colorCode == 2) bgColor = "#d5a7f2";
      else if (obj.colorCode == 3) bdrColor = "#2196f3";
      else if (obj.colorCode === 4) bdrColor = "#9B6155";
      else if (obj.colorCode === 101) bgColor = "#83E28E";
      else if (obj.colorCode === 102) bgColor = "#FF7474";
      else if (obj.colorCode === 103) bgColor = "#A6C9EC";       
    }
    // ActionItem Footer Highlight
    if (checkActionItemAssigned(ActionItemData, params, tbl_name)) {
      borderBottom = "purple";
      boldTxt = "bold";
    }
    if (bdrColor !== "")
      return {
        backgroundColor: bgColor,
        fontWeight: boldTxt,
        textDecoration: txtDeco,
        color: fontColor,
        borderColor: bdrColor,
      };
    else
      return {
        backgroundColor: bgColor,
        fontWeight: boldTxt,
        textDecoration: txtDeco,
        color: fontColor,
        borderBottomColor: borderBottom,
      };
  };
  const imageCellRander = (params) => {
    let src = "https://mediasvr.sohum.tech/ciimages/cismallimages/" + params.data.image
    return <img
      onClick={() => {
        setPopupImageData(params.data);
        setPopupImage(true);
      }}
      style={{ height: "30px" }}
      src={src}
    />;
  }
  useEffect(() => {
    dispatch({ type: "SET_PLEASE_WAIT", data: true });
    if (!customColumnDef && colDef.length === 0 && columnDefs && columnDefs.length > 0) {
      let temp = columnDefs;
      temp = temp.map((col) => {
        let mpCol = col;
        if (col.field === "image") {
          mpCol = {
            cellRenderer: imageCellRander,
            headerName: "",
            field: col.field,
            colId: col.colId,
            width: 30,
            editable: (params) => isEditable(params, col.field, col.editable, col.insert_editable),
            suppressColumnsToolPanel: col.hide,
            suppressFiltersToolPanel: col.hide,
            myType: col.type,
            suppressKeyboardEvent: col.suppressKeyboardEvent,
            // filter: col.filter,
            // filterParams: col.filterParams,            
            autoHeaderHeight: true,
            resizable: col.resizable,
            wrapText: col.wrapText,
            valueFormatter: col.valueFormatter,
            valueParser: col.valueParser,
            suppressMovable: true,
          };
        }
        else {
          mpCol = {
            headerName: col.headerName,
            field: col.field,
            colId: col.colId,
            editable: (params) => isEditable(params, col.field, col.editable, col.insert_editable),
            hide: col.hide,
            suppressColumnsToolPanel: col.hide,
            suppressFiltersToolPanel: col.hide,
            cellEditor: ["dropdown", "date"].includes(col.type) ? col.cellEditor : "",
            cellEditorParams: ["dropdown", "date"].includes(col.type) ? col.cellEditorParams : "",
            myType: col.type,
            cellClass: col.type === "dropdown" ? "" : col.type,
            suppressKeyboardEvent: col.suppressKeyboardEvent,
            filter: col.filter,
            filterParams: col.filterParams,
            width: col.width,
            autoHeaderHeight: true,
            resizable: col.resizable,
            wrapText: col.wrapText,
            cellStyle: (params) => {
              return customCellStyle ? customCellStyle(params, col) : funCellStyle(params, col);
            },
            valueFormatter: col.valueFormatter,
            valueParser: col.valueParser,

          };
        }
        if (enableCheckBox && col.headerName === "Year") {
          mpCol["checkboxSelection"] = true;
        }
        if (rowDraggable && col.headerName === rowDraggable) {
          mpCol["rowDrag"] = true;
        }

        // if (col.type && col.type === "date") mpCol.cellDataType = 'date';
        return mpCol;
      });
      if (isGrouppedHeader) {
        const groups = getGroupListByTableName(tbl_name);
        setColDef(generateColumnGrouping(groups, temp));
      } else setColDef(temp);
    }
    dispatch({ type: "SET_PLEASE_WAIT", data: false });
  }, [rowData]);

  useEffect(() => {
    if (ActionItemData && ActionItemData.length > 0 && isGridReady && setActionItemDataFun) {
      toastMessage("New task assigned.");
      gridApi.current.refreshCells({ force: true });
    }
  }, [ActionItemData]);
  useEffect(() => {
    if (tblHighlightCells) {
      highlightCells.current = tblHighlightCells;
      if (isGridReady) {
        tblHighlightCells.map((item) => {
          let tmpRowId = "";

          Object.entries(item).some(([key, value]) => {
            if (value === 4 || value === '4') {
              const keys = key.split("|");
              const row_id = keys[2];
              if (tmpRowId !== row_id) {
                tmpRowId = row_id;
                const node = getRowNodeFromId(row_id, gridApi);
                if (node) {
                  const colId = keys[0] + CH1 + keys[1];
                  gridApi.current.setFocusedCell(node.rowIndex, colId);
                  gridApi.current.ensureColumnVisible(colId);
                } else {
                  toastMessage("Opps. No match found. Please download data from Seasons");
                }
              }
              return true;
            } else {
              const keys = key.split("|");
              const row_id = keys[2];
              if (tmpRowId !== row_id) {
                tmpRowId = row_id;
                const node = getRowNodeFromId(row_id, gridApi);
                if (node) {
                  const colId = keys[0] + CH1 + keys[1];

                  gridApi.current.ensureIndexVisible(node.rowIndex, 'middle');
                  gridApi.current.ensureColumnVisible(colId);
                  gridApi.current.setFocusedCell(node.rowIndex, colId);
                }
                return true;
              }
            }
            return false;
          });
        });
        gridApi.current.refreshCells({ force: true });
      }
    }
  }, [tblHighlightCells, isGridReady]);
  useEffect(() => {
    if (autoChangeState) autoGetChangesDetected();
    else {
      changedData.current = [];
      selectedChngData.current = [];
      cellValueChanged.current = [];
    }
  }, [autoChangeState]);
  useEffect(() => {
    // console.log("columnPref", columnPref);
    // console.log("columnGrpPref", columnGrpPref);
    try {
      if (isGridReady && columnPref) {
        setTimeout(() => {
          gridApi.current.applyColumnState({ state: columnPref, applyOrder: true });
        }, 1000);
      }
      if (isGridReady && columnGrpPref && columnGrpPref.length > 0) {
        setTimeout(() => {
          gridApi.current.setColumnGroupState(columnGrpPref);
        }, 1000);
      }

    } catch (e) {
      console.log("error", e);
    }
  }, [columnPref, columnGrpPref, isGridReady]);

  useEffect(() => {

    if (isGridReady) {
      //move focus to first cell
      try {
        const cols = columnDefs.filter((col) => !col.hide);
        const firstColumnm = cols[0];

        const focusedCell = gridApi.current.getFocusedCell();

        if (!focusedCell) {
          gridApi.current.setFocusedCell(0, firstColumnm.colId);
          gridApi.current.ensureColumnVisible(firstColumnm.colId);
        }
      } catch (e) { console.log(e) }
    }
  }, [isGridReady, rowData, columnDefs])

  const onGridReady = (params) => {
    gridApi.current = params.api;
    setCurrentGridApi(params);
    setIsGridReady(true);
  };
  const suppressHeaderKeyboardEvent = ({ event }) => {
    if (!event) return false;
    if (event.altKey && event.key === "ArrowDown") {
      const column = columnDefs.filter((col) => event.srcElement.innerText === col.headerName);
      const colIndex = getColumnIndex(column[0], gridApi) + 1;
      const colElement1 = document.querySelector("div[aria-colindex='" + colIndex + "']");
      const wrapper = colElement1.querySelector(".ag-header-cell-comp-wrapper");

      let errorOccured = false;

      if (wrapper) {
        try {
          const container = wrapper.querySelector(".ag-cell-label-container");
          const colElement = container.querySelector('[data-ref="eFilterButton"]');
          colElement.dispatchEvent(new Event("click"));
        } catch (e) {
          errorOccured = true;
        }
      }
      if (errorOccured) {
        const colElement1 = document.querySelector(".ag-header-container");
        const colElement2 = colElement1.querySelector(".ag-header-row.ag-header-row-column");
        const colElement3 = colElement2.querySelector("div[aria-colindex='" + colIndex + "']");
        const wrapper = colElement3.querySelector(".ag-header-cell-comp-wrapper");
        const container = wrapper.querySelector(".ag-cell-label-container");
        const colElement = container.querySelector('[data-ref="eFilterButton"]');
        colElement?.dispatchEvent(new Event("click"));
      }
      return true;
    } else if (event.ctrlKey && event.key === "ArrowLeft") {
      const cols = columnDefs.filter((col) => !col.hide);
      const firstColumnm = cols[0];
      gridApi.current.setFocusedCell(0, firstColumnm.colId);
      gridApi.current.ensureColumnVisible(firstColumnm.colId);
      const element = document.querySelector("div[col-id='" + firstColumnm.colId + "']");
      if (element) element.focus();
      return true;
    } else if (event.ctrlKey && event.key === "ArrowRight") {
      const cols = columnDefs.filter((col) => !col.hide);
      const lastColumn = cols[cols.length - 1];
      gridApi.current.ensureColumnVisible(lastColumn.colId);
      const element = document.querySelector("div[col-id='" + lastColumn.colId + "']");
      if (element) {
        element.focus();
        return true;
      }
    } else if (event.ctrlKey && event.key === "ArrowDown") {
      const column = columnDefs.filter((col) => col.headerName === event.srcElement.innerText);
      gridApi.current.ensureIndexVisible(rowData.length - 1);
      gridApi.current.setFocusedCell(rowData.length - 1, column[0].colId);
      return true;
    }
    return false;
  };
  const viewAssorted = () => {
    let column = tbl_name + CH1 + "Assort";
    let newFilterItem = {
      filterType: "multi",
      filterModels: [
        {
          filterType: "text",
          type: "contains",
          filter: "assorted",
        },
        null,
      ],
    };
    filters[column] = newFilterItem;
    gridApi.current.setFilterModel(filters);
    gridApi.current.onFilterChanged();
  };
  const viewNonAssorted = () => {
    const column = tbl_name + CH1 + "Assort";
    const newFilterItem = {
      filterType: "multi",
      filterModels: [
        null,
        {
          values: [null],
          filterType: "set",
        },
      ],
    };
    filters[column] = newFilterItem;
    gridApi.current.setFilterModel(filters);
    gridApi.current.onFilterChanged();
  };
  const viewPdAll = () => {
    const column = tbl_name + CH1 + "Assort";
    delete filters[column];
    gridApi.current.setFilterModel(filters);
    gridApi.current.onFilterChanged();
  };
  const viewUploadedToLl = () => {
    let column = tbl_name + CH1 + "Pushed To Linelist";
    let newFilterItem = {
      filterType: "multi",
      filterModels: [
        null,
        {
          values: ["Yes"],
          filterType: "set",
        },
      ],
    };
    filters[column] = newFilterItem;
    gridApi.current.setFilterModel(filters);
    gridApi.current.onFilterChanged();
  };
  const viewNonUploadedToLl = () => {
    const column = tbl_name + CH1 + "Pushed To Linelist";
    const newFilterItem = {
      filterType: "multi",
      filterModels: [
        null,
        {
          values: [null],
          filterType: "set",
        },
      ],
    };
    filters[column] = newFilterItem;
    gridApi.current.setFilterModel(filters);
    gridApi.current.onFilterChanged();
  };
  const viewAllMerchAssort = () => {
    const column = tbl_name + CH1 + "Pushed To Linelist";
    delete filters[column];
    gridApi.current.setFilterModel(filters);
    gridApi.current.onFilterChanged();
  };
  const actionItemClick = (params) => {
    setActionItemDataFun(params.node.data, gridApi.current, tbl_name);
    gridApi.current.refreshCells({ force: true });
  };
  const filterByCellValue = (p) => {
    let column = tbl_name + CH1 + p.column.colDef.field;
    let value = `${p.value}`;
    let aabbcc = [
      null,
      {
        values: [value],
        filterType: "set",
      },
    ]
    if (p.column.colDef.myType === "date") {
      aabbcc = [
        {
          values: [value],
          filterType: "set",
        },
      ]
    }
    let newFilterItem = {
      filterType: "multi",
      filterModels: aabbcc,
    };
    filters[column] = newFilterItem;
    gridApi.current.setFilterModel(filters);
    gridApi.current.onFilterChanged();
  };
  const clearFilterByCellValue = (p) => {
    let column = tbl_name + CH1 + p.column.colDef.field;
    delete filters[column];
    gridApi.current.setFilterModel(filters);
    gridApi.current.onFilterChanged();
  };
  const clearFilterBtnCall = () => {
    gridApi.current.setFilterModel(null);
  };
  const callAddRow = () => {
    let is_allow = getDataFromMetaData(tbl_name, "insert_row");
    if (
      is_allow === null ||
      is_allow === undefined ||
      is_allow === "" ||
      is_allow === "No" ||
      is_allow.length === 0
    ) {
      toastMessage("Insert row functionality is not available for this tab.");
      setOpenAddRowDlg(false);
      return;
    }
    setOpenAddRowDlg(true);
  }
  const btnAddRow = (numRows, position) => {
    let is_allow = getDataFromMetaData(tbl_name, "insert_row");
    if (
      is_allow === null ||
      is_allow === undefined ||
      is_allow === "" ||
      is_allow === "No" ||
      is_allow.length === 0
    ) {
      toastMessage("Insert row functionality is not available for this tab.");
      setOpenAddRowDlg(false);
      return;
    } else {
      const addRows = [];
      for (let i = 0; i < numRows; i++) {
        let oneClVl = new Object();
        columnDefs.map((od) => {
          // oneClVl["" + od.field + ""] = "";
          if ((tbl_name === "xl_faherty_linelist" && od.field === "Style Activity") || tbl_name === "xl_faherty_merch_assortment_sandbox" && od.field === "Style Activity") {
            oneClVl["" + od.field + ""] = "ACTIVE";
          } else {
            oneClVl["" + od.field + ""] = "";
          }
        });
        addRows.push(oneClVl);
      }
      const stateData = rowData;
      const focusedCell = gridApi.current.getFocusedCell();
      const rowIndex = focusedCell ? focusedCell.rowIndex : 0;

      let myData = [];
      let rowPosition = 0;
      switch (position) {
        case 'top':
          rowPosition = 0;
          break;

        case 'bottom':
          rowPosition = stateData.length;
          break;

        case 'belowRowSelected':
          rowPosition = rowIndex + 1;
          break;

        case 'aboveRowSelected':
          rowPosition = rowIndex;
          break;
      }
      if (stateData.length > 0) {
        myData = [
          ...stateData.slice(0, rowPosition),  // Data before insertion point
          ...addRows,                                 // New rows
          ...stateData.slice(rowPosition)      // Data after insertion point
        ];
      } else myData = addRows;

      setStateDataByTblName(tbl_name, JSON.parse(JSON.stringify(myData)));
      setOpenAddRowDlg(false);
      return "";
    }
  };
  const callDeleteRow = () => {
    let is_allow = getDataFromMetaData(tbl_name, "delete_row");
    if (
      is_allow === null ||
      is_allow === undefined ||
      is_allow === "" ||
      is_allow === "No" ||
      is_allow.length === 0
    ) {
      toastMessage("Delete Row functionality is not available for this tab.");
      setOpenDeleteConfirmation(false);
      return;
    }

    let tmpSelectedNodes = gridApi.current.getSelectedNodes().length;
    if (tmpSelectedNodes === 0) {
      const focusedCell = gridApi.current.getFocusedCell();
      tmpSelectedNodes = focusedCell ? 1 : 0;
    }

    setSelectedNodes(tmpSelectedNodes)
    setOpenDeleteConfirmation(true);
  }
  const btnDeleteRow = () => {
    let is_allow = getDataFromMetaData(tbl_name, "delete_row");
    if (
      is_allow === null ||
      is_allow === undefined ||
      is_allow === "" ||
      is_allow === "No" ||
      is_allow.length === 0
    ) {
      toastMessage("Delete Row functionality is not available for this tab.");
      setOpenDeleteConfirmation(false);
      return;
    } else {
      // Store current filter model
      const filterModel = gridApi.current.getFilterModel();

      // Clear all filters
      gridApi.current.setFilterModel(null);

      const tmpSelectedNodes = gridApi.current.getSelectedNodes();
      let selectedIndexes;

      if (tmpSelectedNodes.length === 0) {
        const focusedCell = gridApi.current.getFocusedCell();
        const rowIndex = focusedCell ? focusedCell.rowIndex : -1;
        if (rowIndex === -1) {
          toastMessage("Please select at least one row to delete.", "warning");
          return;
        }
        selectedIndexes = [rowIndex];
      } else selectedIndexes = tmpSelectedNodes.map(node => node.rowIndex); // Get the indexes of the selected rows

      const myData = rowData.filter((_, index) => !selectedIndexes.includes(index));

      toastMessage("Please perform send data to commit changes.");
      setStateDataByTblName(tbl_name, JSON.parse(JSON.stringify(myData)));

      // Reapply the filters
      if(filterModel) gridApi.current.setFilterModel(filterModel);

      setOpenDeleteConfirmation(false);
      return "";
    }
  };

  const btnCopyRow = () => {

    let gridRowData = new Array();
    gridApi.current.forEachNode((nd) => gridRowData.push(nd.data));

    const focusedCell = gridApi.current.getFocusedCell();
    const row = gridApi.current.getDisplayedRowAtIndex(focusedCell.rowIndex);
    const OneRowData = row.data;
    if (OneRowData === null || OneRowData === undefined) return;

    let blankColList = ["row_id", "inserted_on", "modified_on", "user_inserted", "user_modified"];
    if (tbl_name === "xl_faherty_linelist") {
      let llblankCol = [
        "Change Memo - Status",
        "Change Memo - Notes",
        "Change Memo - Date",
        "Carry Over",
        "Suite Line ID"
      ];
      blankColList = [...blankColList, ...llblankCol];
    } else if (tbl_name === "xl_pd_initial_costing") {
      blankColList.push("Suite Line ID");
      blankColList.push("Assort");
    } else if (tbl_name === "xl_faherty_merch_assortment_sandbox") {
      blankColList.push("Suite Line ID")
      blankColList.push("Pushed To Linelist");
      blankColList.push("Upload To Linelist Check");
    }

    let oneClVl = new Object();
    Object.entries(OneRowData).forEach((en) => {
      const [k, v] = en;

      if (blankColList.includes(k)) oneClVl["" + k + ""] = "";

      else oneClVl["" + k + ""] = v;

    });

    let newData = new Array();
    newData = [oneClVl, ...gridRowData];
    setStateDataByTblName(tbl_name, JSON.parse(JSON.stringify(newData)));
    toastMessage("Copy row successfully !", "success");
  };
  const btnCopyAndDropRow = () => {




    let isAllow = getDataFromMetaData(tbl_name, "copy_row");
    if (
      isAllow === null ||
      isAllow === undefined ||
      isAllow.trim() === "" ||
      isAllow.toLowerCase() !== "Yes".toLowerCase()
    ) {
      toastMessage("This tab has no copy and drop row functionality.");
      return;
    }
    let gridRowData = new Array();
    gridApi.current.forEachNode((nd) => gridRowData.push(nd.data));




    const focusedCell = gridApi.current.getFocusedCell();




    const row = gridApi.current.getDisplayedRowAtIndex(focusedCell.rowIndex);
    const OneRowData = row.data;
    if (OneRowData === null || OneRowData === undefined) {
      return;
    }

    let blankColList = ["row_id", "inserted_on", "modified_on", "user_inserted", "user_modified"];
    if (tbl_name === "xl_faherty_linelist") {
      let llblankCol = [
        "Change Memo - Status",
        "Change Memo - Notes",
        "Change Memo - Date",
        "Item Upload Status",
        "Delivery Status",
        "DTC Confirmation",
        "Wholesale Confirmation",
        "Suite Line ID",
        "Carry Over"
      ];
      blankColList = [...blankColList, ...llblankCol];
    }

    let oneClVl = new Object();
    Object.entries(OneRowData).forEach((en) => {
      const [k, v] = en;







      if (blankColList.includes(k)) oneClVl["" + k + ""] = "";
      else if (k === "Style Activity") oneClVl["" + k + ""] = "DROPPED";




      else oneClVl["" + k + ""] = v;

    });

    let newData = [];
    newData = [oneClVl, ...gridRowData];
    setStateDataByTblName(tbl_name, JSON.parse(JSON.stringify(newData)), false);
    toastMessage("Copy and drop row successfully !", "success");
  };
  const btnAddWorkingLine = () => {
    if (["xl_faherty_dtc_buy_sheet", "xl_faherty_whsl_buy_sheet"].includes(tbl_name)) {
      let gridRowData = new Array();
      gridApi.current.forEachNode((nd) => gridRowData.push(nd.data));
      const focusedCell = gridApi.current.getFocusedCell();
      const row = gridApi.current.getDisplayedRowAtIndex(focusedCell.rowIndex);
      const OneRowData = row.data;
      if (OneRowData === null || OneRowData === undefined) return;
      let oneClVl = new Object();
      Object.entries(OneRowData).forEach((en) => {
        const [k, v] = en;
        if (
          k === "row_id" ||
          k === "inserted_on" ||
          k === "modified_on" ||
          k === "user_inserted" ||
          k === "user_modified"
        ) {
          oneClVl["" + k + ""] = "";
        } else if (k === "Working Line") {
          oneClVl["" + k + ""] = "Working";
        } else if (k === "Working Line Status") {
          oneClVl["" + k + ""] = "Working";
        } else {
          oneClVl["" + k + ""] = v;
        }
      });
      let newData = [];
      newData = [oneClVl, ...gridRowData];
      setStateDataByTblName(tbl_name, JSON.parse(JSON.stringify(newData)), false);
      toastMessage("Add working line successfully !", "success");
    } else toastMessage("This tab has no working line functionality !");
  };
  const getCellTimelineFunc = () => {
    let colName = CellTimelineColNm.current;
    let rowId = CellTimelineRowId.current;
    if (rowId === null || rowId === undefined || rowId === "") {
      toastMessage("Cell Timeline is not available for unsent new row.");
      return;
    }
    let is_allow = getDataFromMetaData(tbl_name, "cell_history");
    if (
      is_allow === null ||
      is_allow === undefined ||
      is_allow === "" ||
      is_allow === "No" ||
      is_allow.length === 0
    ) {
      toastMessage("Cell Timeline functionality is not available for this tab.");
      return;
    }
    if (
      tbl_name !== null &&
      tbl_name !== undefined &&
      tbl_name !== "" &&
      colName !== null &&
      colName !== undefined &&
      colName !== "" &&
      rowId !== null &&
      rowId !== undefined &&
      rowId !== ""
    ) {
      if (is_allow === "Yes") {
        getCellTimeline(
          sessionStorage.getItem("Template_Type"),
          colName,
          rowId,
          tbl_name,
          sessionStorage.getItem("email")
        ).then((resData) => {
          let CellTimelineData = [];
          let tempVal = resData;
          if (tempVal !== null && tempVal !== undefined && tempVal !== "")
            CellTimelineData = JSON.parse(tempVal);
          if (
            CellTimelineData === null ||
            CellTimelineData === undefined ||
            CellTimelineData === "" ||
            CellTimelineData.length === 0
          ) {
            toastMessage("Cell Timeline not found for selected cell.", "success");
          } else {
            setCellTimelineData(CellTimelineData);
            setBlCellTimeline(true);
          }
        });
      } else {
        toastMessage("Cell Timeline functionality is not available for this tab.");
      }
    } else {
      setCellTimelineData([]);
      toastMessage("Please first click on cell in table.");
    }
    // CellTimelineRowId.current = "";
    // CellTimelineColNm.current = "";
    setCellTimelineData([]);
    gridApi.current.clearFocusedCell();
  };
  const getContextMenuItems = (params) => {
    let result = [];
    if (params.node !== null && params.node !== undefined) {
      result.push({
        name: "Filter By Value",
        action: () => filterByCellValue(params),
        icon: '<i class="fa fa-filter" aria-hidden="true"></i>',
      });
      result.push({
        name: `Clear Filter "${params.column.colDef.field}"`,
        action: () => clearFilterByCellValue(params),
        icon: '<i class="fa fa-times" aria-hidden="true"></i>',
      });
      result.push({
        name: "Clear All Filter",
        action: () => clearFilterBtnCall(),
        icon: '<i class="fa fa-times" aria-hidden="true"></i>',
      });
      result.push({
        name: "Cell Timeline",
        action: () => getCellTimelineFunc(),
        icon: '<i class="fas fa-border-all"></i>',
      });
      result.push("separator");
      if (functionalityAccess("btnAddWokringLine", tab_name))
        result.push({
          name: "Add Working Line",
          action: () => btnAddWorkingLine(),
          icon: '<i class="fas fa-plus"></i>',
        });
      if (functionalityAccess("btnAddRow", tab_name))
        result.push({
          name: "Add Row",
          action: () => callAddRow(),
          icon: '<i class="fas fa-plus"></i>',
        });
      if (functionalityAccess("btnDeleteRow", tab_name))
        result.push({
          name: "Delete Row",
          action: () => callDeleteRow(),
          icon: '<i class="fas fa-minus"></i>',
        });
      if (functionalityAccess("btnCopyRow", tab_name))
        result.push({
          name: "Copy Row",
          action: () => btnCopyRow(params),
          icon: '<i class="far fa-copy"></i>',
        });
      if (functionalityAccess("btnCopyDropRow", tab_name))
        result.push({
          name: "Copy And Drop Row",
          action: () => btnCopyAndDropRow(params),
          icon: '<i class="far fa-copy"></i>',
        });
      result.push("separator");
      if (functionalityAccess("btnPDInitialCostingView", tab_name))
        result.push({
          name: "View",
          icon: '<i class="fas fa-receipt"></i>',
          subMenu: [
            {
              name: "Assorted",
              action: viewAssorted,
            },
            {
              name: "Non Assorted",
              action: viewNonAssorted,
            },
            {
              name: "All",
              action: viewPdAll,
            },
          ],
        });
      if (functionalityAccess("btnMerchAssortmentView", tab_name))
        result.push({
          name: "View",
          icon: '<i class="fas fa-receipt"></i>',
          subMenu: [
            {
              name: "Uploaded To Linelist",
              action: viewUploadedToLl,
            },
            {
              name: "Non Uploaded To Linelist",
              action: viewNonUploadedToLl,
            },
            {
              name: "All",
              action: viewAllMerchAssort,
            },
          ],
        });
      result.push({
        name: "Freeze Pane",
        icon: '<i class="fas fa-columns"></i>',
        subMenu: [
          {
            name: "Freeze Left Panes",
            action: () => freezePane(params, columnDefs, gridApi, "left"),
            icon: '<i class="fas fa-lock"></i>',
          },
          {
            name: "Freeze Right Panes",
            action: () => freezePane(params, columnDefs, gridApi, "right"),
            icon: '<i class="fas fa-lock"></i>',
          },
          {
            name: "UnFreeze Panes",
            action: () => unFreezePane(gridApi),
            icon: '<i class="fas fa-unlock"></i>',
          },
        ],
      });
      if (tab_name === 'Linelist') {
        result.push({
          name: "Impact View Columns",
          icon: '<i class="fas fa-eye"></i>',
          subMenu: [
            {
              name: "MSRP",
              action: () => btnMSRPView(),
              icon: '<i class="fas fa-columns"></i>',
            },
            {
              name: "WHSL",
              action: () => btnWHLSView(),
              icon: '<i class="fas fa-columns"></i>',
            },
            {
              name: "Final FOB",
              action: () => btnFinalFob(),
              icon: '<i class="fas fa-columns"></i>',
            },
          ],
        });
      }
      result.push("separator");
      result.push("cut");
      result.push("copy");
      result.push("copyWithHeaders");
      result.push("copyWithGroupHeaders");
      result.push("paste");
      result.push("separator");
      result.push({
        name: "Excel Export",
        action: function () {
          params.api.exportDataAsExcel({
            fileName:
              applicationTitle +
              "_" +
              new Date().toLocaleString().replace(" ", "_").replace(",", "_"),
            sheetName: tab_name,
          });
        },
        icon: '<i class="far fa-file-excel"></i>',
      });
      // isActionItemEnabled && setActionItemDataFun
      if (setActionItemDataFun) {
        result.push({
          name: "Action Item",
          action: () => actionItemClick(params),
          icon: '<i class="fa fa-tasks" aria-hidden="true"></i>',
        });
      }
    } else { // if there were no rows
      result.push({
        name: "Clear All Filter",
        action: () => clearFilterBtnCall(),
        icon: '<i class="fa fa-times" aria-hidden="true"></i>',
      });
      if (functionalityAccess("btnAddRow", tab_name))
        result.push({
          name: "Add Row",
          action: () => callAddRow(),
          icon: '<i class="fas fa-plus"></i>',
        });
    }
    return result;
  };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      menuTabs: ["filterMenuTab", "generalMenuTab"],
      wrapHeaderText: true,
      suppressHeaderKeyboardEvent,
      // tooltipComponent: CustomTooltip,

    };
  }, []);

  const getSideBarPanels = () => {
    return {
      toolPanels: [
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
          },
        },
      ],
      defaultToolPanel: null,
    };
  };
  const gridOptions = useMemo(() => {
    return {
      processCellForClipboard: (params) => {
        let ct = params.column.colDef.myType || "";
        if (ct === "" || !params.value) return params.value;
        else if (ct === "date" && params.value && !params?.value?.toString().includes("/"))
          return ExcelDateToJsDate(params.value);
        else return params.value ? params?.value?.toString().replace(/[%$]/g, "") : params.value;
      },
      getRowHeight: (params) => {
        if (params.node.rowPinned) return 13;
      },
      groupHeaderHeight,
      sideBar: getSideBarPanels(),
      icons: {
        stats: '<i class="fa fa-bars" aria-hidden="true"></i>' // Example using FontAwesome icon
      },
    };
  }, []);
  const excelStyles = useMemo(() => {
    return getExcelStyle();
  }, []);
  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent" },
        // { statusPanel: "agTotalRowCountComponent", align: "center" },
        // { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);
  const onCellFocused = (event) => {
    if (event === null || event === undefined) return;
    if (gridApi.current === null || gridApi.current === undefined) return;
    const focusedCell = event.api.getFocusedCell();
    if (focusedCell === null || focusedCell === undefined) return;
    const rIdx = focusedCell.rowIndex;
    if (rIdx !== null && rIdx !== undefined) {
      let colId = event.column.colId;
      var row = gridApi.current.getDisplayedRowAtIndex(rIdx);
      if (row !== null && row !== undefined && row !== "") {
        let tmpArr = new Array();
        if (colId !== null && colId !== undefined && colId !== "") {
          tmpArr = colId.split(CH1);
        }
        if (tmpArr.length > 0) {
          CellTimelineRowId.current = row.data.row_id;
          CellTimelineColNm.current = event.column.colDef.field;
        }
      }
    }
  };
  const onCellKeyDown = (event) => {
    if (event.event.keyCode === 46) {
      const res = executeDataVerification(event, tbl_name, highlightCells.current, gridApi.current);
      if (res.status === false) {
        highlightCells.current = highlightCells.current;
        if (tab_name === "Linelist" || tab_name === "BuySht_Master_Data_Charts") {
          setHighlightByTblName(tbl_name, highlightCells.current);
        }
        if (res && res.msg && res.msg !== "") {
          toastMessage(res.msg, "warning");
        }
        gridApi.current.refreshCells({ force: true, rowNodes: [event.node] });
      } else {
        if (calculateFormula) calculateFormula(event);
        if (calculateBottomRow) calculateBottomRow();
      }
    }
  };
  const onCellValueCh = (event, from = "") => {
    const eventSource = event.source ? event.source : "";
    if (eventSource !== "") {
      // const col = event.colDef.headerName;
      const col = event.colDef.field;
      let oldValue = event.oldValue || "";
      // const column = columnDefs.filter((cl) => cl.headerName === col);
      const column = columnDefs.filter((cl) => cl.field === col);
      if (column && column.length > 0) {
        const type = column[0].type;
        if (type === "date" && !oldValue.includes("/")) oldValue = ExcelDateToJsDate(oldValue);
      }
      if (from === "keyDown" || event.newValue !== oldValue) {
        const res = executeDataVerification(
          event,
          tbl_name,
          highlightCells.current,
          gridApi.current
        );
        // const col = event.colDef.headerName;
        const col = event.colDef.field;
        if (res.status === false) {
          console.log("res", res);
          event.data[col] = event.oldValue;
          if (res && res.msg && res.msg !== "") {
            if (eventSource === "edit") {
              toastMessage(res.msg, "warning");
            } else {
              let aa = res.msg;
              if (!msgArr.current.includes(aa)) msgArr.current.push(aa);
            }
          }
        }
        if (res.status === true)
          if (!cellValueChanged.current.includes(res.key)) {
            const key = column[0].field + "|" + event.data["row_id"];
            cellValueChanged.current.push(key);
            setCellValueChanged(cellValueChanged.current);
          }
        optiFunction(event.colDef.field, event);
        if (calculateFormula) calculateFormula(event);
        if (eventSource === "edit" && calculateBottomRow) calculateBottomRow();
        gridApi.current.refreshCells({ force: true, rowNodes: [event.node] });
        setUnsentCount(tbl_name, unsentCount);
      }
    }
  };
  const optiFunction = (colNm, rowNode) => {
    let arr = highlightCells.current;
    // const eventSource = rowNode.source ? rowNode.source : "";
    const filteredData = SSNData.filter((i) => i.row_id === rowNode.data.row_id);
    if (filteredData.length > 0) {
      let srcVal = rowNode.data[colNm] || "";
      let trgVal = filteredData[0][colNm] || "";
      let arrVal = { [`${tbl_name}|${colNm}|${rowNode.data.row_id}`]: 3 };
      let arrKey = `${tbl_name}|${colNm}|${rowNode.data.row_id}`;
      if (srcVal.toString() !== trgVal.toString()) {
        const foundObject = arr.find((obj) => obj[arrKey] === 3);
        if (!foundObject) {
          arr.push(arrVal);
          ++unsentCount;
        }
      } else {
        arr = arr.filter((a) => {
          if (Object.values(a)[0] === 3 && Object.keys(a)[0] === arrKey) {
            --unsentCount;
            return false;
          }
          return true;
        });
      }
      highlightCells.current = arr;
      setHighlightByTblName(tbl_name, highlightCells.current);
      if (unsentCount < 0) unsentCount = 0;
      // setUnsentCountState(uc);
    }
  };
  const onPasteEnd = () => {
    var msg = "";
    if (msgArr.current.length > 0) {
      msg = msgArr.current.join("\n");
      toastMessage(msg, "warning");
    }
    msgArr.current = [];
    if (calculateBottomRow) calculateBottomRow();
  };

  const autoGetChangesDetected = () => {
    // console.log("autoGetChangeResult", autoGetChangeResult);

    const arr = autoGetChangeResult.highlightCells;
    const delCount = autoGetChangeResult.delCount;
    const insertCount = autoGetChangeResult.insertCount;
    const updateCount = autoGetChangeResult.updateCount;

    changedData.current = autoGetChangeResult.jsonData;
    const userName = autoGetChangeResult.userName ? autoGetChangeResult.userName.split("@")[0] : "Guest";

    const summary = generateSummary(insertCount, updateCount, delCount, userName);

    if (changedData.current && changedData.current.length > 0) setOpenChangesDlg(true);
    else resteAutoChangeState();
    let delMsg = "";
    if (arr.length > 0 || delCount > 0) {
      highlightCells.current = arr;
      if (tab_name === "Linelist" || tab_name === "BuySht_Master_Data_Charts") {
        setHighlightByTblName(tbl_name, highlightCells.current);
      }
      gridApi.current.refreshCells({ force: true });
      // if (delCount > 0) delMsg = delCount + " row(s) deleted.";

      //show custom toast message
      showCustomToastMessage(
        `Changes Detected`,
        {
          Summary: summary,
          Items: autoGetChangeResult.updates
        })

      // if (delCount > 0) {
      //   delMsg = delCount + " row(s) deleted."
      //   toastMessage("Auto Changes detected. " + delMsg);
      // } else {
      //   toastMessage("Auto Changes detected." + delMsg + " Please see highlighted cells.");
      // }
    }
  };
  const getSelectedChanges = (selectedRows) => {
    selectedChngData.current = selectedRows;
  };
  const acceptNewChanges = () => {
    let uc = unsentCount;
    const arr = highlightCells.current;
    let changeAccepted = false;
    selectedChngData.current.map((node) => {
      const data = node.data;
      gridApi.current.forEachNode((rowNode) => {
        const col = data["Column Name"];
        if (data.row_id === rowNode.data.row_id) {
          rowNode.setDataValue(tbl_name + CH1 + col, data["New Changes"]);
          const key = tbl_name + "|" + col + "|" + rowNode.data.row_id;
          arr.push({ [key]: 1 });
          uc--;

          //update session state also
          changeAccepted = true;
          const find = SSNData.find((dt) => dt.row_id === data.row_id);
          if (find) find[col] = data["New Changes"];
        }
      });

      // Take session data and also update it accordingly
      if (changeAccepted) setSessionDataByTblName(tbl_name, SSNData);
    });
    if (arr.length > 0) {
      highlightCells.current = arr;
      if (tab_name === "Linelist" || tab_name === "BuySht_Master_Data_Charts") {
        setHighlightByTblName(tbl_name, highlightCells.current);
      }
      gridApi.current.refreshCells({ force: true });
    }
    handleRejected();
    resteAutoChangeState();
  };
  const handleRejected = () => {
    const tempDatat = changedData.current;
    const tempChData = [];
    if (changedData.current.length !== selectedChngData.current.length) {
      selectedChngData.current.map((node) => {
        const data = node.data;
        tempChData.push(data);
      });
      const rejected = tempDatat.filter(
        (item1) =>
          !tempChData.some(
            (item2) =>
              item1.row_id === item2.row_id && item1["Column Name"] === item2["Column Name"]
          )
      );
      //remove rejected rows from highlight cell
      const newHighLigh = [];
      highlightCells.current.map((item1) => {
        rejected.map((item2) => {
          Object.entries(item1).forEach(([key, value]) => {
            if (!key.includes(item2.row_id)) newHighLigh.push(item1);
          });
        });
      });
      highlightCells.current = [];
      highlightCells.current = newHighLigh;
      if (tab_name === "Linelist" || tab_name === "BuySht_Master_Data_Charts") {
        setHighlightByTblName(tbl_name, highlightCells.current);
      }
    }
    changedData.current = [];
    selectedChngData.current = [];
    cellValueChanged.current = [];
    setOpenChangesDlg(false);
  };
  const closeShowChanges = () => {
    handleRejected();
  };
  const btnMSRPView = () => {
    const colToShow = ["Year", "Season Code", "Department", "SKU",
      "MSRP", "1000 - IMU%", "300 - IMU%", "500 - IMU%", "RTL IMU%", "Scenario RTL IMU%",
      "Total DTC SLS $", "Working Vs Scenario RTL IMU%", "Working Vs Scenario RTL IMU%", "Total Brand SLS $"
    ];
    const columnIds = columnDefs.map(c => c.colId);
    const viewColIds = columnDefs.filter((c) => colToShow.includes(c.field));
    const listofview = viewColIds.map(c => c.colId);
    gridApi.current.setColumnsVisible(columnIds, false);
    gridApi.current.setColumnsVisible(listofview, true);
  }
  const btnWHLSView = () => {
    const colToShow = ["Year", "Season Code", "Department", "SKU",
      "WHLS", "Scenario WHLS IMU%", "Total WHSL SLS $", "WHLS IMU%",
      "Working Vs Scenario WHSL IMU%", "Working Vs Scenario WHSL IMU%",
      "Total Brand SLS $", "Working Vs Scenario WHSL IMU%",
    ];
    const columnIds = columnDefs.map(c => c.colId);
    const viewColIds = columnDefs.filter((c) => colToShow.includes(c.field));
    const listofview = viewColIds.map(c => c.colId);
    gridApi.current.setColumnsVisible(columnIds, false);
    gridApi.current.setColumnsVisible(listofview, true);
  }
  const btnFinalFob = () => {
    const colToShow = ["Year", "Season Code", "Department", "SKU",
      "Final FOB", "Current FOB", "Cost Under Min - Estimated",
      "Current LDP - Status", "LDP", "RTL IMU%", "WHLS IMU%",
      "Working Vs Scenario RTL IMU%", "Working Vs Scenario WHSL IMU%",
    ];
    const columnIds = columnDefs.map(c => c.colId);
    const viewColIds = columnDefs.filter((c) => colToShow.includes(c.field));
    const listofview = viewColIds.map(c => c.colId);
    gridApi.current.setColumnsVisible(columnIds, false);
    gridApi.current.setColumnsVisible(listofview, true);
  }
  const byDefaultActiveRow = () => {
    if (tbl_name !== "xl_faherty_master_lov") {
      const column = tbl_name + CH1 + "Style Activity";
      const newFilterItem = {
        filterType: "multi",
        filterModels: [
          null,
          {
            values: ['ACTIVE'],
            filterType: "set",
          },
        ],
      };
      filters[column] = newFilterItem;
      gridApi.current.setFilterModel(filters);
      gridApi.current.onFilterChanged();
    }
  };
  // Check box Keep seleted
  const onFirstDataRendered = (event) => {
    byDefaultActiveRow()
    if (tbl_name === "xl_faherty_season") {
      try {
        let paramwhere = sessionStorage.getItem("asRequiredDownloadQuery") === null ? "" : sessionStorage.getItem("asRequiredDownloadQuery");
        const CH1 = String.fromCharCode(1);
        const CH2 = String.fromCharCode(2);
        const CH3 = String.fromCharCode(3);
        let a = paramwhere.split(CH3)
        a.forEach((ai) => {
          let aa = ai.split(CH2)
          let nodesToSelect = [];
          gridApi.current.forEachNode((node) => { nodesToSelect.push(node) });
          aa.forEach((i) => {
            let a = i.split(CH1)
            nodesToSelect = nodesToSelect.filter(item => item.data[a[0]].includes(a[1]));
          })
          gridApi.current.setNodesSelected({ nodes: nodesToSelect, newValue: true });
        })
      }
      catch { }
    }
  };

  return (
    <>
      <div className="ag-theme-quartz" id="agGridStl">
        {removeCellValueChange ? (
          <AgGridReact
            onFirstDataRendered={(event) => onFirstDataRendered(event)}
            allowContextMenuWithControlKey={true}
            columnDefs={colDef}
            components={{ Datepicker: getDatePicker() }}
            defaultColDef={defaultColDef}
            enableColResize={true}
            enableFillHandle={true}
            enableRangeSelection={true}
            enterNavigatesVertically={true}
            enterNavigatesVerticallyAfterEdit={true}
            excelStyles={excelStyles}
            getContextMenuItems={getContextMenuItems}
            gridOptions={gridOptions}
            headerHeight={HeaderHeight}
            onCellFocused={(event) => onCellFocused(event)}
            onGridReady={(event) => onGridReady(event)}
            rowData={rowData}
            rowSelection="multiple"
            statusBar={statusBar}
            suppressFieldDotNotation
            suppressHorizontalScroll={false}
            suppressMenuHide={true}
            // suppressRowClickSelection={true}

            {...props}
          />
        ) : (
          <AgGridReact
            onFirstDataRendered={(event) => onFirstDataRendered(event)}
            allowContextMenuWithControlKey={true}
            columnDefs={customColumnDef ? customColumnDef : colDef}
            components={{ Datepicker: getDatePicker() }}
            defaultColDef={defaultColDef}
            enableColResize={true}
            enableFillHandle={true}
            enableRangeSelection={true}
            enterNavigatesVertically={true}
            enterNavigatesVerticallyAfterEdit={true}
            excelStyles={excelStyles}
            getContextMenuItems={getContextMenuItems}
            gridOptions={gridOptions}
            headerHeight={HeaderHeight}
            onCellFocused={(event) => onCellFocused(event)}
            onCellKeyDown={onCellKeyDown}
            onCellValueChanged={onCellValueCh}
            onGridReady={(event) => onGridReady(event)}
            onPasteEnd={onPasteEnd}
            rowData={rowData}
            rowSelection="multiple"
            statusBar={statusBar}
            suppressFieldDotNotation
            suppressHorizontalScroll={false}
            suppressMenuHide={true}
            // suppressRowClickSelection={true}
            {...props}
          />
        )}
      </div>
      <RowInputDialog 
        open={openAddRowDlg}
        onClose={() => setOpenAddRowDlg(false)}
        onAddRows={btnAddRow}
        table_name={tbl_name}
      />
      <ConfirmationDialog
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        onConfirm={btnDeleteRow}
        title={` Are you sure you want to delete ${selectedNodes} ${selectedNodes === 1 ? 'row' : 'rows'}?`}
      />
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={openChangesDlg}
        onClose={closeShowChanges}
        aria-labelledby="classic-modal-slide-title-cell-history"
        aria-describedby="classic-modal-slide-description-cell-history"
      >
        <DialogTitle
          id="classic-modal-slide-title-cell-history"
          disableTypography
          style={{ fontWeight: "600", fontSize: "15px" }}
        >
          Auto Changes Detected
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description-sync-data">
          <ShowChanges data={changedData.current} getSelectedChanges={getSelectedChanges} />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={acceptNewChanges} sx={{ textTransform: "none" }} size="small" variant="gradient" color="info">Accept New Changes</MDButton>
          <MDButton onClick={closeShowChanges} sx={{ textTransform: "none" }} size="small" variant="gradient" color="info">Close</MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="xxl"
        open={blCellTimeline}
        onClose={() => setBlCellTimeline(false)}
        aria-labelledby="classic-modal-slide-title-cell-history"
        aria-describedby="classic-modal-slide-description-cell-history"
      >
        <DialogTitle
          id="classic-modal-slide-title-cell-history"
          disableTypography
          style={{ fontWeight: "600", fontSize: "15px" }}
        >
          {"Cell Timeline : " + tab_name}
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description-sync-data">
          <CellTimeline data={CellTimelineData} />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={PopupImage}
        onClose={() => setPopupImage(false)}
      >
        <DialogTitle
          disableTypography
          style={{ fontWeight: "600", fontSize: "15px" }}
        >
          Product Summary
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} container justifyContent="center">
              <MDBox
                component="img"
                src={`https://mediasvr.sohum.tech/ciimages/ciregularimages/${PopupImageData.image}`}
                style={{
                  maxHeight: "300px",
                  borderRadius: "5%",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <MDBox mb={1}>{PopupImageData.brnd}</MDBox> */}
              {/* <MDBox mb={1}>
                <h5>{PopupImageData.stsku}</h5>
              </MDBox>
              <MDBox mb={1}>
                <h2>{PopupImageData.nm}</h2>
              </MDBox> */}
              <MDBox mb={1}>
                <b>Style : </b>
                {PopupImageData["Style"]}
              </MDBox>
              <MDBox mb={1}>
                <b>Style Description : </b>
                {PopupImageData["Style Description"]}
              </MDBox>
              <MDBox mb={1}>
                <b>Department : </b>
                {PopupImageData["Department"]}
              </MDBox>
              <MDBox mb={1}>
                <b>Color : </b>
                {PopupImageData["Color Description"]}
              </MDBox>
              <MDBox mb={1}>
                <b>Category : </b>
                {PopupImageData["Category"]}
              </MDBox>
              <MDBox mb={1}>
                <b>Fabric : </b>
                {PopupImageData["Fabric Content"]}
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <MDBox>
                <b>Description : </b>
                {PopupImageData.description}
              </MDBox>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPopupImage(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomAgGrid;
