const myValueFormatter = (params, ct) => {
    if(!params.value || params.value === 0) return "0.00%"
    return params.value + "%";
}
export const SummaryReportColDef = [
    {
        "headerName": "Season Code",
        "field": "Season Code",
        "colId": "Season Code",
        "width": 150,
        "resizable": true,
    },
    {
        "headerName": "Department",
        "field": "Department",
        "colId": "Department",
        "width": 150,
        "resizable": true,
    },
    {
        "headerName": "Category",
        "field": "Category",
        "colId": "Category",
        "width": 150,
        "resizable": true,
    },
    {
        "headerName": "Count of Style",
        "field": "Count of Style",
        "colId": "Count of Style",
        "width": 90,
        "resizable": true,
        valueFormatter: (params) => params.value && params.value.toLocaleString('en-US')
    },
    {
        "headerName": "Retail Margin (Actual)",
        "field": "Retail Margin (Actual)",
        "colId": "Retail Margin (Actual)",
        "width": 100,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        cellDataType: 'number',
        "resizable": true
    },
    {
        "headerName": "Wholesale Margin (Actual)",
        "field": "Wholesale Margin (Actual)",
        "colId": "Wholesale Margin (Actual)",
        "width": 110,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Blended Brand Margin (Actual)",
        "field": "Blended Brand Margin (Actual)",
        "colId": "Blended Brand Margin (Actual)",
        "width": 100,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Retail Gross Margin (Actual)",
        "field": "Retail Gross Margin (Actual)",
        "colId": "Retail Gross Margin (Actual)",
        "width": 100,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Wholesale Gross Margin (Actual)",
        "field": "Wholesale Gross Margin (Actual)",
        "colId": "Wholesale Gross Margin (Actual)",
        "width": 110,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true,
    },
    {
        "headerName": "Blended Brand Gross Margin (Actual)",
        "field": "Blended Brand Gross Margin (Actual)",
        "colId": "Blended Brand Gross Margin (Actual)",
        "width": 100,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Retail Margin (Scenario)",
        "field": "Retail Margin (Scenario)",
        "colId": "Retail Margin (Scenario)",
        "width": 110,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Wholesale Margin (Scenario)",
        "field": "Wholesale Margin (Scenario)",
        "colId": "Wholesale Margin (Scenario)",
        "width": 110,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Blended Brand Margin (Scenario)",
        "field": "Blended Brand Margin (Scenario)",
        "colId": "Blended Brand Margin (Scenario)",
        "width": 110,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Retail Gross Margin (Scenario)",
        "field": "Retail Gross Margin (Scenario)",
        "colId": "Retail Gross Margin (Scenario)",
        "width": 110,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Wholesale Gross Margin (Scenario)",
        "field": "Wholesale Gross Margin (Scenario)",
        "colId": "Wholesale Gross Margin (Scenario)",
        "width": 110,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Blended Brand Gross Margin (Scenario)",
        "field": "Blended Brand Gross Margin (Scenario)",
        "colId": "Blended Brand Gross Margin (Scenario)",
        "width": 110,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Retail Margin Variance",
        "field": "Retail Margin Variance",
        "colId": "Retail Margin Variance",
        "width": 100,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Wholesale Margin Variance",
        "field": "Wholesale Margin Variance",
        "colId": "Wholesale Margin Variance",
        "width": 110,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Blended Brand Margin Variance",
        "field": "Blended Brand Margin Variance",
        "colId": "Blended Brand Margin Variance",
        "width": 100,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Retail Gross Margin Variance",
        "field": "Retail Gross Margin Variance",
        "colId": "Retail Gross Margin Variance",
        "width": 100,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Wholesale Gross Margin Variance",
        "field": "Wholesale Gross Margin Variance",
        "colId": "Wholesale Gross Margin Variance",
        "width": 110,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "Blended Brand Gross Margin Variance",
        "field": "Blended Brand Gross Margin Variance",
        "colId": "Blended Brand Gross Margin Variance",
        "width": 110,
        valueFormatter: (params) => myValueFormatter(params, "percentage"),
        "resizable": true
    },
    {
        "headerName": "DTC Units Total",
        "field": "DTC Units Total",
        "colId": "DTC Units Total",
        "width": 80,
        "resizable": true,
        valueFormatter: (params) => params.value && params.value.toLocaleString('en-US')
    },
    {
        "headerName": "Wholesale Units Total",
        "field": "Wholesale Units Total",
        "colId": "Wholesale Units Total",
        "width": 110,
        "resizable": true,
        valueFormatter: (params) => params.value && params.value.toLocaleString('en-US')
    },
    {
        "headerName": "Brand Units Total",
        "field": "Brand Units Total",
        "colId": "Brand Units Total",
        "width": 80,
        "resizable": true,
        valueFormatter: (params) => params.value && params.value.toLocaleString('en-US')
    },
    {
        "headerName": "Total DTC SLS $",
        "field": "Total DTC SLS $",
        "colId": "Total DTC SLS $",
        "width": 140,
        "resizable": true,
        valueFormatter: (params) => ("$" + params.value && params.value.toLocaleString('en-US'))
    },
    {
        "headerName": "Total WHSL SLS $",
        "field": "Total WHSL SLS $",
        "colId": "Total WHSL SLS $",
        "width": 150,
        "resizable": true,
        valueFormatter: (params) => ("$" + params.value && params.value.toLocaleString('en-US'))
    },
    {
        "headerName": "Total Brand SLS $",
        "field": "Total Brand SLS $",
        "colId": "Total Brand SLS $",
        "width": 150,
        "resizable": true,
        valueFormatter: (params) => ("$" + params.value && params.value.toLocaleString('en-US'))
    }
]