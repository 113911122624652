import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
// import { Box, Grid, Paper, Typography, Select } from '@mui/material';
import { Box, Grid, Paper, Typography, MenuItem, Select } from '@mui/material';
import { AgCharts } from 'ag-charts-react';
import { toastMessage } from 'layouts/linelist/XL_Utils';
import { restApiPath } from 'utils/app.props';
import "ag-charts-enterprise";

const Competitor_Analytics_Charts = (props) => {
  const dispatch = useDispatch();
  const { row_ids } = props;
  const [tooltipRenderer, setTooltipRenderer] = useState(null);
  const [sunburstChart, setSunBurstChart] = useState(null)

  const [chartData, setChartData] = useState({
    ca_ll_column_chart_product_suggestions_subcat : null,
    ca_ll_sunburst_chart_increase_decrease_price_cat  : null,
    ca_ll_sunburst_chart_incerase_decrease_cat  : null,
    ca_ll_column_chart_product_suggestions_keyword_subcat: null,
    ca_ll_pie_chart_sku_count_cat_comp_brand:null,
    ca_ll_pie_chart_sku_count_cat_cust_brand:null

  });

  const getChartdata = async (functionName, paramBody = '') => {
    const url = `${restApiPath}callPGFunction`;
    const postBody = {
      "param_function": functionName,
      "param_body": paramBody
    };
    const requestbody = {
      method: "POST",
      headers: {
        Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postBody),
    };

    try {
      const response = await fetch(url, requestbody);
      const json = await response.json();
     
      if (json.error === "invalid_token") {
        toastMessage("Session Expired", "error");
        sessionStorage.clear();
        setTimeout(() => {
          nevigate("/login");
        }, 5000);
        return null;
      }

      if (json.response) {
     
        let resJsonData = JSON.parse(json.data);

        if (json.response) {
  
          // let resJsonData = JSON.parse(json.data);
  
          if (resJsonData.axes) {
            resJsonData.axes.forEach((axis) => {
              if (axis.label && typeof axis.label.formatter === 'string') {
                try {
                  axis.label.formatter = eval(`(${axis.label.formatter})`);
                } catch (error) {
                  console.error('Error converting formatter string to function', error);
                }
              }
            });
          }
  
            if(resJsonData?.series[0])
              {  
                if (resJsonData?.series[0]?.tooltip) {
                  if (resJsonData?.series[0]?.tooltip?.renderer && typeof resJsonData?.series[0]?.tooltip?.renderer === 'string' ) {
                    try {
                      resJsonData.series[0].tooltip.renderer = eval(`(${resJsonData?.series[0]?.tooltip?.renderer})`);
                        // sunburstChartTmp.series[0].tooltip.renderer = eval(`(${sunburstChartTmp.series[0].tooltip.renderer})`);
                    } catch (error) {
                        console.error('Error converting formatter string to function', error);
                    }
                }
              }
          }
        /*
          if(resJsonData?.series[0])
            {  
              if (resJsonData?.series[0]?.sectorLabel) {
                if (resJsonData?.series[0]?.sectorLabel?.renderer && typeof resJsonData?.series[0]?.sectorLabel?.renderer === 'string' ) {
                  try {                                    
                    resJsonData.series[0].sectorLabel.renderer = eval(`(${resJsonData?.series[0]?.sectorLabel?.renderer})`);
                      // sunburstChartTmp.series[0].tooltip.renderer = eval(`(${sunburstChartTmp.series[0].tooltip.renderer})`);
                  } catch (error) {
                      console.error('Error converting formatter string to function', error);
                  }
              }
            }
             
        } */
        }
     
        return resJsonData;
        // return JSON.parse(json.data);
     
     
      } else {
        toastMessage("There is an error while getting data.", "warning");
        return null;
      }
    } catch (exception) {
      toastMessage("An exception occurred while fetching data.", "error");
      console.log("exception : ", exception)
      return null;
    }




  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "SET_PLEASE_WAIT", data: true });
      const jsonReq = {
        "param_user_id": sessionStorage.getItem("email"),
        "param_query": sessionStorage.getItem("asRequiredDownloadQuery") || "",
        "filtered_row_ids": row_ids
      };

      const [
        ca_ll_column_chart_product_suggestions_subcat,
        ca_ll_sunburst_chart_increase_decrease_price_cat,
        ca_ll_sunburst_chart_incerase_decrease_cat,
        ca_ll_column_chart_product_suggestions_keyword_subcat,
        ca_ll_pie_chart_sku_count_cat_comp_brand,
        ca_ll_pie_chart_sku_count_cat_cust_brand
      ] = await Promise.all([
        getChartdata("ca_ll_column_chart_product_suggestions_subcat", ""),               
        getChartdata("ca_ll_sunburst_chart_increase_decrease_price_cat", ""),
        getChartdata("ca_ll_sunburst_chart_incerase_decrease_cat", ""),
        getChartdata("ca_ll_column_chart_product_suggestions_keyword_subcat",''),
        getChartdata("ca_ll_pie_chart_sku_count_cat_comp_brand",''),
        getChartdata("ca_ll_pie_chart_sku_count_cat_cust_brand",'')
      ]);




      setChartData({
        ca_ll_column_chart_product_suggestions_subcat,
        ca_ll_sunburst_chart_increase_decrease_price_cat,
        ca_ll_sunburst_chart_incerase_decrease_cat,
        ca_ll_column_chart_product_suggestions_keyword_subcat,
        ca_ll_pie_chart_sku_count_cat_comp_brand,
        ca_ll_pie_chart_sku_count_cat_cust_brand

      });

      // setSunBurstChart(createChartOptions)

      dispatch({ type: "SET_PLEASE_WAIT", data: false });
    };

    fetchData();
  }, [dispatch, row_ids]);

  const CreateAgChart = ({ optionsConfig }) => {

    return (
      <div style={{ height: '400px', width: '100%' }}>
        {optionsConfig ? <AgCharts style={{ height: '400px', width: '100%' }} options={optionsConfig}  /> : <p>Loading chart data...</p>}
      </div>
    );
  };


  return (
    <Box sx={{ padding: 1.5 }}>
      <Grid container spacing={1}>
        
        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{padding: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{ml:1, }}>Competitor's SKU Count by Category</Typography>
            <CreateAgChart optionsConfig={chartData.ca_ll_pie_chart_sku_count_cat_comp_brand} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{padding: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{ml:1, }}>SKU Count by Category</Typography>
            <CreateAgChart optionsConfig={chartData.ca_ll_pie_chart_sku_count_cat_cust_brand} />
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{padding: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{ml:1, }}>Not Playing in Category</Typography>
            <CreateAgChart optionsConfig={chartData.ca_ll_column_chart_product_suggestions_subcat} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ padding: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{ml:1, }}>Not Playing in Subcategory</Typography>
            <CreateAgChart optionsConfig={chartData.ca_ll_column_chart_product_suggestions_keyword_subcat} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ padding: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{ml:1, }}>Can Increase Price</Typography>
            <CreateAgChart optionsConfig={chartData.ca_ll_sunburst_chart_increase_decrease_price_cat} />
          </Paper>
        </Grid>


        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ padding: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{ml:1, }}>Can Increase Products</Typography>            
            <CreateAgChart optionsConfig={chartData.ca_ll_sunburst_chart_incerase_decrease_cat} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

};

export default Competitor_Analytics_Charts;
