// import { useMaterialUIController } from "context";
import { AgGridReact } from "ag-grid-react";
import AddIcon from '@mui/icons-material/Add';
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect, useMemo, useRef } from "react";
import {
    Grid,
    Autocomplete,
    Tooltip,
    TextField,

} from "@mui/material";
import { useSelector } from "react-redux";
import { toastMessage } from "../../../XL_Utils";
import { getFromMetaData } from "../../../customFunctions";
import { restApiPath } from "utils/app.props";
import InfoIcon from '@mui/icons-material/Info';
const DynamicQuery = ({ selectedComponent }) => {
    // const [controller,] = useMaterialUIController();
    // const { asRequiredDownloadQuery, } = controller;
    const mpForTabs = useSelector(s => s.mpForTabs || []);
    const columnMetaData = useSelector(s => s.ColumnMetaData || []);
    const [pvtForTabs, setPvtForTabs] = useState([]);
    const [DropDownColumn, setDropDownColumn] = useState([]);
    const [DQField, setDQField] = useState([]);
    const [DQColDropdown, setDQColDropdown] = useState({ "user_id": sessionStorage.getItem("email"), "table_name": "" });
    const DQColDropdownUpdated = useRef({});
    const [DQView, setDQView] = useState([]);
    const [DQData, setDQData] = useState([]);

    const [DDViewCol, setDDViewCol] = useState([]);
    const [DDColumn1, setDDColumn1] = useState("");
    const [DDCondition1, setDDCondition1] = useState("");
    const [TextBox1, setTextBox1] = useState("");

    const cmbTableChange = (event, newselect) => {
        let tbl = getFromMetaData("sheet_name", newselect, "table_name");
        let valuesToRemove = ["row_id", "user_inserted", "inserted_on", "user_modified", "modified_on"];
        let somefilterdata = columnMetaData.filter(i => i.table_name === tbl);
        somefilterdata = somefilterdata.filter(i => !valuesToRemove.includes(i.column_name));
        let colList = [];
        somefilterdata.map(i => colList.push(i.column_name));
        setDropDownColumn(colList);
        setDQField([]);
        setDQData([]);
        setDQView([]);
        setDQColDropdown({ "user_id": sessionStorage.getItem("email"), "table_name": tbl });
        setDDViewCol([]);
        setDDColumn1("");
        setDDCondition1("");
        setTextBox1("");
    }
    const cmbChange = (event, newselect, ids) => {
        setDQData([]);
        setDQView([]);
        let val = newselect || "";
        if (ids === "select_columns")
            setDDViewCol(newselect);
        if (ids === "ddColumn1")
            setDDColumn1(newselect);
        if (ids === "ddCondition1")
            setDDCondition1(newselect);
        setDQColDropdown(prevValues => ({
            ...prevValues,
            [ids]: val,
        }));
    }
    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: "agTotalAndFilteredRowCountComponent" },
                // { statusPanel: "agTotalRowCountComponent", align: "center" },
                // { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" },
            ],
        };
    }, []);
    const txtChangeEvent = (e) => {
        setDQData([]);
        setDQView([]);
        const { name, value } = e.target;
        let val = value || "";
        if (name === 'textBox1')
            setTextBox1(val);
        setDQColDropdown(prevValues => ({
            ...prevValues,
            [name]: val,
        }));
    };
    const addFieldComponent = () => {
        let ids = DQField.length + 2;
        let newItem = <Grid container spacing={1} padding={0.5}>
            <Grid item sm={1}>
                {/* <ToggleButtonGroup
                    color="primary"
                    value={"And"}
                    exclusive
                    // onChange={handleChange}
                    aria-label="Platform"
                >
                    <ToggleButton value="And">And</ToggleButton>
                    <ToggleButton value="Or">OR</ToggleButton>
                </ToggleButtonGroup> */}
                <Autocomplete
                    onChange={(event, newselect) => cmbChange(event, newselect, `ddAndOr${ids}`)}
                    // defaultValue="and"
                    options={["and", "or"]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="And / Or"
                            size="small"
                        />
                    )}
                />
            </Grid>
            <Grid item sm={2}>
                <Autocomplete
                    options={DropDownColumn}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select column to filter."
                            size="small"
                        />
                    )}
                    onChange={(event, newselect) => cmbChange(event, newselect, `ddColumn${ids}`)}
                />
            </Grid>
            <Grid item sm={1.5}>
                <Autocomplete
                    // defaultValue="equal"
                    options={[
                        "equal",
                        "not equal",
                        "contains",
                        "not contains",
                        "less than",
                        "less than or equal",
                        "grater than",
                        "grater than or equal",
                    ]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Filter Condition"
                            size="small"
                        />
                    )}
                    onChange={(event, newselect) => cmbChange(event, newselect, `ddCondition${ids}`)}
                />
            </Grid>
            <Grid item sm={2}>
                <TextField
                    size="small"
                    placeholder="Filter Value"
                    name={`textBox${ids}`}
                    style={{ width: "100%", height: "14px" }}
                    onChange={txtChangeEvent}
                />
            </Grid>
        </Grid>;
        setDQField(prevItems => [...prevItems, newItem]);
    }
    const btnDQApplyClick = () => {
        DQColDropdownUpdated.current = DQColDropdown;
        let loop = DQField.length + 1;
        let select_columns = DQColDropdown[`select_columns`] || "";
        let process_columns = [];
        process_columns = [...process_columns, ...select_columns];
        let ddandor = "0";
        for (let i = 1; i <= loop; i++) {
            let col = DQColDropdown[`ddColumn${i}`] || "";
            let ddCondition = DQColDropdown[`ddCondition${i}`] || "";
            if (!select_columns.includes(col)) process_columns.push(col);
            DQColDropdownUpdated.current = { ...DQColDropdown, process_columns: process_columns };
            if (col === "" && ddCondition === "") { toastMessage("Please select at least one criteria condition !", "info"); return; }
            if (i > 1) ddandor = DQColDropdownUpdated.current[`ddAndOr${i}`] || "";
            if (col !== "" && ddandor === "") { toastMessage("Please select And/Or !"); return; }
            else if (col !== "" && ddCondition == "") { toastMessage("Please select Condition !"); return; }
            else if (select_columns == "" || select_columns.length === 0) { toastMessage("Please select view columns !"); return; }
            else getDyanmicDataTable();
        }
    }
    const getDyanmicDataTable = async () => {
        const postBody = {
            "param_function": "ttd_dynamic_query",
            "param_body": DQColDropdownUpdated.current
        };
        const url = `${restApiPath}callPGFunction`;
        const requestbody = {
            method: "POST",
            headers: {
                Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postBody),
        };
        try {
            const response = await fetch(url, requestbody);
            const json = await response.json();
            if (json.error === "invalid_token") {
                toastMessage("Session Expired", "error");
                sessionStorage.clear();
                setTimeout(() => {
                    nevigate("/login");
                }, 5000);
            }
            if (json.response) {
                const resJsonData = JSON.parse(json.data);
                setDQData(resJsonData);
                if (resJsonData.length === 0)
                    toastMessage("No Data found for this criteria !", "info");
            }
        } catch (exception) { }
    };
    useEffect(() => {
        if (mpForTabs && typeof mpForTabs[Symbol.iterator] === 'function') {
            let lst = new Array()
            for (let [k, v] of mpForTabs) {
                if (lst.includes(k) === false) {
                    lst.push(k)
                }
            }
            lst.sort()
            setPvtForTabs(lst)
        }
    }, []);
    useEffect(() => {
        if (DQData.length > 0) {
            let colDef = Object.keys(DQData[0]).map(key => ({ field: key, filter: true }));
            let sadsf = <>
                <div className="ag-theme-quartz" id="agGridStl" style={{ height: "450px" }}>
                    <AgGridReact
                        columnDefs={colDef}
                        rowData={DQData}
                        statusBar={statusBar}
                        enableCharts={true}
                        enableRangeSelection={true}
                        rowSelection="multiple"
                    />
                </div>
            </>;
            setDQView(sadsf);
        }
        else setDQView([]);
    }, [DQData]);
    return (<>
        {/* <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span style={{ textAlign: "center", color: "purple", fontWeight: "600", width: "100%" }}>
                    🚀Create Your Own Reports🚀
                </span>
            </AccordionSummary>
            <AccordionDetails>
            </AccordionDetails>
        </Accordion> */}
        {/* <Grid container spacing={1} padding={0.4}>
            <MDAlert color="info" dismissible>
                <MDTypography variant="body2" color="white">
                    You can create a chart by right-clicking. Just be sure to have at least two columns and one numeric column, and select multiple cells as needed to generate the chart.
                </MDTypography>
            </MDAlert>
        </Grid> */}
        <Grid container spacing={1} padding={0.8}>
            <Grid item sm={2}>
                <Autocomplete
                    options={pvtForTabs}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Tab"
                            size="small"
                        />
                    )}
                    onChange={(event, newselect) => cmbTableChange(event, newselect)}
                />
            </Grid>
            <Grid item sm={4.5}>
                <Tooltip title="You can select multiple items by holding down the Ctrl key while selecting.">
                    <Autocomplete
                        multiple
                        options={DropDownColumn}
                        value={DDViewCol}
                        renderInput={(params) => (<TextField {...params} label="View Column(s)" size="small" />)}
                        onChange={(event, newselect) => cmbChange(event, newselect, `select_columns`)}
                    />
                </Tooltip>
            </Grid>
        </Grid>
        <Grid container spacing={1} padding={0.5}>
            <Grid item sm={1} style={{ textAlign: "center" }}>
                <Tooltip title="Please enter the date in the format yyyy-mm-dd when selecting a date column.">
                    <InfoIcon />
                </Tooltip>
            </Grid>
            <Grid item sm={2}>
                <Autocomplete
                    options={DropDownColumn}
                    value={DDColumn1}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select column to filter."
                            size="small"
                        />
                    )}
                    onChange={(event, newselect) => cmbChange(event, newselect, `ddColumn1`)}
                />
            </Grid>
            <Grid item sm={1.5}>
                <Autocomplete
                    // defaultValue="equal"
                    options={[
                        "equal",
                        "not equal",
                        "contains",
                        "not contains",
                        "less than",
                        "less than or equal",
                        "grater than",
                        "grater than or equal",
                    ]}
                    value={DDCondition1}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Filter Condition"
                            size="small"
                        />
                    )}
                    onChange={(event, newselect) => cmbChange(event, newselect, `ddCondition1`)}
                />
            </Grid>
            <Grid item sm={2}>
                <TextField
                    size="small"
                    value={TextBox1}
                    name="textBox1"
                    onChange={txtChangeEvent}
                    placeholder="Filter Value"
                    style={{ width: "100%", height: "14px" }} />
            </Grid>
        </Grid>
        {DQField}
        <Grid container spacing={1} padding={0.5}>
            <Grid item sm={1}>
                <Tooltip title={"Add new condition"}>
                    <MDButton
                        startIcon={<AddIcon />}
                        size="small"
                        color="info"
                        variant="contained"
                        onClick={() => { addFieldComponent() }}
                    >
                        add
                    </MDButton>
                </Tooltip>
            </Grid>
            {/* <Grid item sm={4.5}>
                                    <Tooltip title="You can select multiple items by holding down the Ctrl key while selecting.">
                                        <Autocomplete
                                            multiple
                                            options={DropDownColumn}
                                            // defaultValue={DQselectCol}
                                            renderInput={(params) => (<TextField {...params} label="View Column(s)" size="small" />)}
                                            onChange={(event, newselect) => cmbChange(event, newselect, `select_columns`)}
                                        />
                                    </Tooltip>
                                </Grid> */}
            <Grid item sm={0.6}>
                <MDButton
                    size="small"
                    color="info"
                    variant="contained"
                    onClick={() => { btnDQApplyClick() }}
                >
                    Apply
                </MDButton>
            </Grid>
            {/* <Grid item sm={1.4}>
                                    <MDButton
                                        size="small"
                                        color="success"
                                        variant="contained"
                                        disabled
                                    // onClick={() => { btnDQApplyClick() }}
                                    >
                                        Save This Query
                                    </MDButton>
                                </Grid> */}
        </Grid>
        <Grid container mt={0.5} spacing={1} padding={0.5}>
            <Grid item sm={12}>
                {DQView}
            </Grid>
        </Grid>
    </>)
}

export default DynamicQuery;