import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { restApiPath } from "utils/app.props";
import { CH1, cstFilterOptions, getColumnIndex, toastMessage } from "../../XL_Utils";

const ManagePermissionColsDef =
    [
        { field: "row_id", headerName: "row_id", width: 140, hide: true },
        { field: "user_id", headerName: "user_id", width: 140, hide: true },
        {
            field: "Email", headerName: "Email", width: 250, cellStyle: { 'background-color': '#F0F0F0' }, autoHeaderHeight: true, autoHeight: true, autoWidth: true,
            filter: 'agMultiColumnFilter',
            colId: "Email",
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        // filterParams: {}
                    }
                ]
            }
        },
        {
            field: "Department", headerName: "Department", width: 140, cellStyle: { 'background-color': '#F0F0F0' }, autoHeaderHeight: true, autoHeight: true, autoWidth: true,
            filter: 'agMultiColumnFilter',
            colId: "Department",
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        // filterParams: {}
                    }
                ]
            }
        },
        {
            field: "Category", headerName: "Category", width: 180, cellStyle: { 'background-color': '#F0F0F0' }, autoHeaderHeight: true, autoHeight: true, autoWidth: true,
            filter: 'agMultiColumnFilter',
            colId: "Category",
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        // filterParams: {}
                    }
                ]
            }
        },
        {
            field: "Sub Category", headerName: "Sub Category", width: 140, cellStyle: { 'background-color': '#F0F0F0' }, autoHeaderHeight: true, autoHeight: true, autoWidth: true,
            filter: 'agMultiColumnFilter',
            colId: "Sub Category",
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        // filterParams: {}
                    }
                ]
            }
        },
        {
            field: "Factory", headerName: "Factory", width: 140, cellStyle: { 'background-color': '#F0F0F0' }, autoHeaderHeight: true, autoHeight: true, autoWidth: true,
            filter: 'agMultiColumnFilter',
            colId: "Factory",
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        // filterParams: {}
                    }
                ]
            }
        },
        {
            field: "Location", headerName: "Location", width: 140, cellStyle: { 'background-color': '#F0F0F0' }, autoHeaderHeight: true, autoHeight: true, autoWidth: true,
            filter: 'agMultiColumnFilter',
            colId: "Location",
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        // filterParams: {}
                    }
                ]
            }
        },
        {
            field: "Style Activity", headerName: "Style Activity", width: 140, cellStyle: { 'background-color': '#F0F0F0' }, autoHeaderHeight: true, autoHeight: true, autoWidth: true,
            filter: 'agMultiColumnFilter',
            colId: "Style Activity",
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                        // filterParams: {}
                    }
                ]
            }
        }

    ]
const tbl_name = "xl_user_data_permission";

const ManagePermissionTab = forwardRef(({ sendDataChangesBackend, setCurrentGridApi }) => {
    const dispatch = useDispatch();
    const ManagePermissionData = useSelector((s) => s.ManagePermissionData);
    const ManageUserData = useSelector((s) => s.ManageUserData);
    const ManagePermissionShorting = useSelector((state) => state.ManagePermissionShorting ? state.ManagePermissionShorting : []);
    const ManagePermissionFilters = useSelector((state) => state.ManagePermissionFilters ? state.ManagePermissionFilters : []);

    const nevigate = useNavigate();
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState("");

    const MasterLovData = useSelector(state => state.MasterLovData);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");

    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState("");

    const [locations, setLocations] = useState(["DTC", "Wholesale"]);
    const [selectedLocation, setSelectedLocation] = useState("");

    const FbFtyDatabaseData = useSelector(s => s.FbFtyDatabaseData);
    const [factories, setFactories] = useState([]);
    const [selectedFactory, setSelectedFactory] = useState("");
    const [isFactoryVisible, setIsFactoryVisible] = useState(false);

    const [isAddNewUser, setIsAddNewUser] = useState(false);
    const [isUpdateUser, setIsUpdateUser] = useState(false)
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

    const [row_id, setRowId] = useState('');
    const [email, setEmail] = useState('');

    const [emailHelperText, setEmailHelterText] = useState('');
    const [showError, setShowError] = useState(false);

    const gridApi = useRef({});
    const gridColumnApi = useRef({});

    const filterByCellValue = (p) => {
        let column = p.column.colDef.field;
        let value = `${p.value}`;
        let newFilterItem = {
            filterType: "multi",
            filterModels: [
                null,
                {
                    values: [value],
                    filterType: "set"
                }
            ]
        };
        ManagePermissionFilters[column] = newFilterItem;
        gridApi.current.setFilterModel(ManagePermissionFilters);
        gridApi.current.onFilterChanged();
    };
    const clearFilterByCellValue = (p) => {
        let column = p.column.colDef.field;
        delete ManagePermissionFilters[column];
        gridApi.current.setFilterModel(ManagePermissionFilters);
        gridApi.current.onFilterChanged();
    };
    const clearFilterBtnCall = () => {
        dispatch({ type: "SET_ManagePermission_FILTERS", data: [] });
        gridApi.current.setFilterModel(null);
    };
    const onManagePermissionGridReady = (params) => {
        gridApi.current = params.api;
        gridColumnApi.current = params.columnApi;

        gridApi.current.autoSizeAllColumns()
        setCurrentGridApi(params)

        if (ManagePermissionShorting !== null && ManagePermissionShorting !== undefined)
            gridApi.current.applyColumnState({ state: ManagePermissionShorting })
        if (ManagePermissionFilters !== null && ManagePermissionFilters !== undefined)
            gridApi.current.setFilterModel(ManagePermissionFilters);

        try {
            const cols = ManagePermissionColsDef.filter((col) => !col.hide);
            const firstColumnm = cols[0];
            
            gridApi.current.setFocusedCell(0, firstColumnm.colId);
            gridApi.current.ensureColumnVisible(firstColumnm.colId);
        } catch (e) { console.log(e) }
    };
    const addNewUser = () => {
        if (email === null || email === undefined || email.trim() == "") {
            toastMessage("Please select atleast 1 user row.");


            return
        }

        const dt = ManagePermissionData.filter((data) => data["Email"] === email)
        if (dt && dt.length > 0) {
            const myData = dt[0];

            const filterUser = ManageUserData.filter((user) => user.email === myData["Email"])
            let location = "";

            if (filterUser && filterUser.length > 0) {
                if (filterUser[0].user_type?.toLowerCase() === "wholesale") location = "Wholesale"
                else if (filterUser[0].user_type?.toLowerCase() === "planning") location = "DTC"
            }
            setSelectedLocation(location)
            setEmail(myData["Email"])

            setSelectedDepartment("")
            setSelectedCategory("")
            setSelectedSubCategory("")
            setSelectedFactory("")
        }
        setIsAddNewUser(true)
    };
    const updateUser = () => {
        // console.log("updateUser")
        if (email === null || email === undefined || email.trim() == "") {
            toastMessage("Please select atleast 1 user row.");


            return
        }

        const dt = ManagePermissionData.filter((mp) => mp.row_id === row_id)

        if (dt && dt.length > 0) {
            const myData = dt[0]

            setEmail(myData["Email"])
            setSelectedDepartment(myData["Department"])
            setSelectedCategory(myData["Category"])
            setSelectedSubCategory(myData["Sub Category"])
            setSelectedFactory(myData["Factory"])
            setSelectedLocation(myData["Location"])
            setIsUpdateUser(true)
        }
    };
    const openDeleteConfirmation = () => {
        if (row_id === null || row_id === undefined || row_id.trim() == "") {
            toastMessage("Please select atleast 1 user row.");


            return
        }
        setOpenDeleteConfirm(true)
    }
    const deleteUser = async () => {
        // console.log("deleteUser")
        if (row_id === null || row_id === undefined || row_id.trim() == "") {
            toastMessage("Please select atleast 1 user row.");


            return
        }

        const dt = ManagePermissionData.filter((mp) => mp.row_id !== row_id)

        if (dt && dt.length > 0) {
            dispatch({ type: "SET_ManagePermission_DATA", data: JSON.parse(JSON.stringify(dt)) });

            dispatch({ type: "SET_PLEASE_WAIT", data: true });
            const res = await sendDataChangesBackend(tbl_name)
            dispatch({ type: "SET_PLEASE_WAIT", data: false });

            if (res !== false) {
                toastMessage("User permission deleted successfully !");


            }
            setOpenDeleteConfirm(false)
        }
    };

    const verifyEmail = (value) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };
    const onEmailChange = (evt) => {
        const txt = (evt.target.value).toLowerCase();
        if (verifyEmail(txt)) {
            setEmailHelterText("");
            setShowError(false);
        } else {
            setEmailHelterText("Please include @ and . in email address");
            setShowError(true);
        }
        setEmail(txt);
    };

    const closeCreateProfile = () => {
        setIsAddNewUser(false)
        setIsFactoryVisible(false)
    };
    const checkUpdatePermissionCriteria = () => {
        // check for existing criteria
        // console.log("inside update criteria")
        const myData = ManagePermissionData.filter((mp) => mp.row_id === row_id)
        let checkCriteria = true;
        if (myData && myData.length > 0) {
            myData.map((data) => {
                if (data["Department"] === selectedDepartment && data["Category"] === selectedCategory && data["Sub Category"] === selectedSubCategory && data["Factory"] === selectedFactory) {
                    toastMessage("This same criteria has already been assigned to this user.", "warning");


                    checkCriteria = false;
                    return;
                }
                if ((data["Department"] !== "" && data["Department"]) && (selectedDepartment === "" || !selectedDepartment)) {
                    console.log("inside condi 1")
                    toastMessage("User can not have full and partial permission at the same time. Please update existing permission criteria.", "warning");


                    checkCriteria = false;
                    return;
                }
                if (data["Department"] === selectedDepartment && data["Category"] !== "" && (selectedCategory === "" || !selectedSubCategory)) {
                    console.log("inside condi 2")
                    toastMessage("User can not have full and partial permission at the same time. Please update existing permission criteria.", "warning");


                    checkCriteria = false;
                    return;
                }
                if (data["Department"] === selectedDepartment && data["Category"] === selectedCategory && data["Sub Category"] !== "" && (selectedSubCategory === "" || !selectedSubCategory)) {
                    console.log("inside condi 3")
                    toastMessage("User can not have full and partial permission at the same time. Please update existing permission criteria.", "warning");

                    checkCriteria = false;
                    return;
                }
                if (data["Factory"] !== "") {
                    if ((data["Department"] === "" || !data["Department"]) && selectedDepartment !== "") {
                        toastMessage("User can not have full and partial permission at the same time. Please update existing permission criteria.", "warning");
                        checkCriteria = false;
                        return;
                    }
                    if ((data["Category"] === "" || !data["Category"]) && selectedCategory !== "") {
                        toastMessage("User can not have full and partial permission at the same time. Please update existing permission criteria.", "warning");

                        checkCriteria = false;
                        return;
                    }
                    if ((data["Sub Category"] === "" || !data["Sub Category"]) && selectedSubCategory !== "") {
                        toastMessage("User can not have full and partial permission at the same time. Please update existing permission criteria.", "warning");

                        checkCriteria = false;
                        return;
                    }
                }
            })

        }
        return checkCriteria;
    }
    const updateUserProfile = async () => {
        // varify user input
        if (!verifyEmail(email)) {
            setEmailHelterText("Please include @ and . in email address");
            setShowError(true);
            return
        }

        const filteredUser = ManageUserData.filter((user) => user.email === email)
        if (filteredUser && filteredUser.length > 0) {
            if (filteredUser[0].user_type === "vendor" && (selectedFactory === "" || !selectedFactory)) {
                toastMessage("Please Select Factory For Vender User", "warning");

                return
            }
        }

        // check permission criteria
        const check = checkUpdatePermissionCriteria();
        if (!check) {
            return
        }

        let userData = ManagePermissionData.map((dt) => {
            if (dt.row_id === row_id) {
                dt["Department"] = selectedDepartment,
                    dt["Category"] = selectedCategory,
                    dt["Sub Category"] = selectedSubCategory,
                    dt["Factory"] = selectedFactory,
                    dt["Location"] = selectedLocation
            }
            return dt
        });
        dispatch({ type: "SET_ManagePermission_DATA", data: JSON.parse(JSON.stringify(userData)) });
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        const res = await sendDataChangesBackend(tbl_name)
        dispatch({ type: "SET_PLEASE_WAIT", data: false });

        if (res !== false) {
            toastMessage("User permission updated successfully !");


        }

        setIsUpdateUser(false)
    };
    const checkAddPermissionCriteria = () => {
        // check for existing criteria
        const myData = ManagePermissionData.filter((mp) => mp["Email"] === email)
        let checkCriteria = true;
        if (myData && myData.length > 0) {

            myData.map((data) => {
                if (data["Department"] === selectedDepartment && data["Category"] === selectedCategory && data["Sub Category"] === selectedSubCategory && data["Factory"] === selectedFactory) {
                    toastMessage("This same criteria has already been assigned to this user.", "warning");

                    checkCriteria = false;
                    return;
                }

                if ((data["Department"] === "" || !data["Department"]) && selectedDepartment !== "") {
                    toastMessage("User can not have full and partial permission at the same time. Please update existing permission criteria.", "warning");
                    checkCriteria = false;
                    return;
                }
                if ((data["Department"] !== "" && data["Department"]) && (selectedDepartment === "" || !selectedDepartment)) {
                    toastMessage("User can not have full and partial permission at the same time. Please update existing permission criteria.", "warning");
                    checkCriteria = false;
                    return;
                }
                if (data["Department"] === selectedDepartment && (data["Category"] === "" || !data["Category"]) && selectedCategory !== "") {
                    toastMessage("User can not have full and partial permission at the same time. Please update existing permission criteria.", "warning");
                    checkCriteria = false;
                    return;
                }
                if (data["Department"] === selectedDepartment && data["Category"] === selectedCategory && (data["Sub Category"] === "" || !data["Sub Category"]) && selectedSubCategory !== "") {
                    toastMessage("User can not have full and partial permission at the same time. Please update existing permission criteria.", "warning");
                    checkCriteria = false;
                    return;
                }
                if (data["Department"] === selectedDepartment && (data["Factory"] === "" || !data["Factory"]) && selectedFactory !== "") {
                    toastMessage("User can not have full and partial permission at the same time. Please update existing permission criteria.", "warning");
                    checkCriteria = false;
                    return;
                }
            })

        }
        return checkCriteria;
    }
    const addUserPermission = async () => {
        // varify user input
        if (!verifyEmail(email)) {
            setEmailHelterText("Please include @ and . in email address");
            setShowError(true);
            return
        }

        const filteredUser = ManageUserData.filter((user) => user.email === email)
        if (filteredUser && filteredUser.length > 0) {
            if (filteredUser[0].user_type === "vendor" && (selectedFactory === "" || !selectedFactory)) {
                toastMessage("Please Select Factory For Vender User", "warning");

                return
            }
        }

        // check permission criteria
        const check = checkAddPermissionCriteria();
        if (!check) {
            return
        }

        let userData = ManagePermissionData;
        const myJson = {
            "row_id": null,
            "user_id": email,
            "Email": email,
            "Department": selectedDepartment,
            "Category": selectedCategory,
            "Sub Category": selectedSubCategory,
            "Factory": selectedFactory,
            "Location": selectedLocation
        }
        userData = [myJson, ...userData];
        dispatch({ type: "SET_ManagePermission_DATA", data: JSON.parse(JSON.stringify(userData)) });
        closeCreateProfile()

        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        const res = await sendDataChangesBackend(tbl_name)
        dispatch({ type: "SET_PLEASE_WAIT", data: false });

        if (res !== false) {
            toastMessage("User permission added successfully !");


        }
    };

    const onCellFocused = (event) => {
        if (event.rowIndex !== null && event.rowIndex !== undefined) {
            let row = event.api.getDisplayedRowAtIndex(event.rowIndex);
            if (row !== null && row !== undefined) {
                if (row.data !== null && row.data !== undefined) {
                    setEmail(row.data["Email"])
                    setRowId(row.data.row_id)
                }
            }
        }
    }
    useEffect(() => {
        // console.log("ManagePermissionData", ManagePermissionData)
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        // get factories list from fb_fty_database
        if (FbFtyDatabaseData) {
            const fs = [];
            FbFtyDatabaseData.map((fact) => {
                if (!fs.includes(fact)) fs.push(fact["Factory"])
            })
            setFactories(fs);
        }
        if (MasterLovData) {
            const cat = []
            const subCat = []
            MasterLovData.map((lov) => {
                if (!cat.includes(lov["Category"])) cat.push(lov["Category"])
                if (!subCat.includes(lov["Sub Category"])) subCat.push(lov["Sub Category"])
            })
            setCategories(cat)
            setSubCategories(subCat)
        }

        fetch(restApiPath + 'getDepartments', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'is-excel-app-supported': "yes"
            },
        })
            .then(response => response.json())
            .then(result => {
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
                if (result.error === "invalid_token") {
                    toastMessage("Session Expired", "warning");
                    sessionStorage.clear()
                    setTimeout(() => {
                        nevigate("/login");
                    }, 5000)
                } else {
                    const { data } = result;
                    if (data) {
                        const tmp = data.split(CH1);
                        setDepartments(tmp);
                    }
                }
            });
    }, [])
    const getContextMenuItems = (params) => {
        if (params.node !== null && params.node !== undefined) {
            var result = [
                'copy',
                'copyWithHeaders',
                'copyWithGroupHeaders',
                'separator',
                {
                    name: "Filter By Value",
                    action: () => filterByCellValue(params),
                    icon: '<i class="fa fa-filter" aria-hidden="true"></i>'
                },
                {
                    name: `Clear Filter "${params.column.colDef.field}"`,
                    action: () => clearFilterByCellValue(params),
                    icon: '<i class="fa fa-times" aria-hidden="true"></i>'
                },
                {
                    name: "Clear All Filter",
                    action: () => clearFilterBtnCall(),
                    icon: '<i class="fa fa-times" aria-hidden="true"></i>'
                },
                'separator',
                {
                    name: 'Add User Data Permission',
                    action: addNewUser,
                    icon: '<i class="fas fa-user-plus"></i>'
                },
                {
                    name: 'Update User Data Permission',
                    action: updateUser,
                    icon: '<i class="fas fa-user-edit"></i>'
                },
                {
                    name: 'Delete User Data Permission',
                    action: openDeleteConfirmation,
                    icon: '<i class="fas fa-user-minus"></i>'
                },
            ]
            return result
        }
    };
    const suppressHeaderKeyboardEvent = ({ event }) => {
        if (!event) return false
        if (event.altKey && event.key === "ArrowDown") {
            const column = ManagePermissionColsDef.filter((col) => event.srcElement.innerText === col.headerName);
            const colIndex = getColumnIndex(column[0], gridApi) + 1;
            const colElement1 = document.querySelector("div[aria-colindex='" + (colIndex) + "']");

            const wrapper = colElement1.querySelector(".ag-header-cell-comp-wrapper");
            if (wrapper) {
                const container = wrapper.querySelector(".ag-cell-label-container");
                const colElement = container.querySelector('[data-ref="eFilterButton"]');
                colElement?.dispatchEvent(new Event("click"));
            } else {
                const colElement1 = document.querySelector(".ag-header-container");
                const colElement2 = colElement1.querySelector(".ag-header-row.ag-header-row-column");
                const colElement3 = colElement2.querySelector("div[aria-colindex='" + (colIndex) + "']");

                const wrapper = colElement3.querySelector(".ag-header-cell-comp-wrapper");
                const container = wrapper.querySelector(".ag-cell-label-container");
                const colElement = container.querySelector('[data-ref="eFilterButton"]');
                colElement?.dispatchEvent(new Event("click"));
            }
            return true;
        } else if (event.ctrlKey && event.key === "ArrowLeft") {
            const cols = ManagePermissionColsDef.filter((col) => !col.hide);
            const firstColumnm = cols[0];
            gridApi.current.setFocusedCell(0, firstColumnm.colId)
            gridApi.current.ensureColumnVisible(firstColumnm.colId);
            const element = document.querySelector("div[col-id='" + firstColumnm.colId + "']");
            if (element) {
                element.focus()
            }
            return true;
        } else if (event.ctrlKey && event.key === "ArrowRight") {
            const cols = ManagePermissionColsDef.filter((col) => !col.hide);
            const lastColumn = cols[cols.length - 1];
            // gridApi.current.setFocusedCell(0,lastColumn.colId,false)
            gridApi.current.ensureColumnVisible(lastColumn.colId);
            const element = document.querySelector("div[col-id='" + lastColumn.colId + "']");
            if (element) {
                element.focus()
                return true;
            }
        } else if (event.ctrlKey && event.key === "ArrowDown") {
            const column = ManagePermissionColsDef.filter((col) => col.headerName === event.srcElement.innerText);
            gridApi.current.ensureIndexVisible(ManagePermissionData.length - 1);
            gridApi.current.setFocusedCell(ManagePermissionData.length - 1, column[0].colId);
            return true;
        }
        return false;
    };
    return (
        <>
            <div className="ag-theme-quartz" id="agGridStl">
                <AgGridReact
                    onGridReady={(event) => onManagePermissionGridReady(event)}
                    rowSelection="single"
                    enableColResize={true}
                    columnDefs={ManagePermissionColsDef}
                    rowData={ManagePermissionData}
                    suppressFieldDotNotation
                    getRowHeight={25}
                    enableSorting={true}
                    enableFilter={true}
                    getContextMenuItems={getContextMenuItems}
                    suppressMenuHide={true}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                        menuTabs: ['filterMenuTab', 'generalMenuTab'],
                        suppressHeaderKeyboardEvent
                    }}
                    onCellFocused={onCellFocused}
                    enableRangeSelection={true}
                />
            </div>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={isAddNewUser}
                onClose={closeCreateProfile}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogContent id="classic-modal-slide-description">
                    <DialogTitle sx={{ pl: '0px', pt: '4px', pb: '4px', fontSize: '16px', backgroundColor: '#fafafb' }}>Add User Data Permission</DialogTitle>
                    <MDBox>
                        <InputLabel style={{ marginTop: "15px", height: "15px" }}>Email</InputLabel>
                        <MDInput
                            margin="normal"
                            fullWidth
                            placeholder="name@domain.com"
                            value={email}
                            error={showError}
                            success={!showError}
                            helperText={emailHelperText}
                            onChange={onEmailChange}
                            disabled
                        />
                        <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                            <Autocomplete
                                disablePortal
                                id="multi-combo-box-demo"
                                fullWidth

                                options={departments}
                                defaultValue={selectedDepartment}
                                onChange={(event, newValue) => setSelectedDepartment(newValue)}
                                renderInput={(params) => <TextField {...params} label="Departments" size="small" />}
                            />
                        </div>
                        <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                            <Autocomplete
                                disablePortal
                                id="multi-combo-box-demo"
                                fullWidth
                                options={categories}
                                defaultValue={selectedCategory}
                                onChange={(event, newValue) => setSelectedCategory(newValue)}
                                renderInput={(params) => <TextField {...params} label="Categories" size="small" />}
                            />
                        </div>
                        <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                            <Autocomplete
                                disablePortal
                                id="multi-combo-box-demo"
                                fullWidth
                                options={subCategories}
                                defaultValue={selectedSubCategory}
                                onChange={(event, newValue) => setSelectedSubCategory(newValue)}
                                renderInput={(params) => <TextField {...params} label="Sub Categories" size="small" />}
                            />
                        </div>
                        <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                fullWidth
                                options={factories}
                                defaultValue={selectedFactory}
                                onChange={(event, newValue) => setSelectedFactory(newValue)}
                                renderInput={(params) => <TextField {...params} label="Factory" size="small" />}
                            />
                        </div>
                        <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                            <Autocomplete
                                disablePortal
                                id="multi-combo-box-demo"
                                fullWidth
                                options={locations}
                                disabled
                                defaultValue={selectedLocation}
                                onChange={(event, newValue) => setSelectedLocation(newValue)}
                                renderInput={(params) => <TextField {...params} label="Location" size="small" />}
                            />
                        </div>
                        <DialogActions style={{ justifyContent: "left", paddingBottom: "0px" }}>
                            <MDButton
                                size="small"
                                style={{ backgroundColor: 'black', textTransform: 'none', color: "white" }}
                                onClick={addUserPermission}
                            >
                                OK
                            </MDButton>
                            <MDButton
                                size="small"
                                style={{ backgroundColor: 'black', textTransform: 'none', color: "white" }}
                                onClick={closeCreateProfile}
                            >
                                Close
                            </MDButton>
                        </DialogActions>
                    </MDBox>
                </DialogContent>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={isUpdateUser}
                onClose={() => setIsUpdateUser(false)}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogContent id="classic-modal-slide-description">
                    <DialogTitle sx={{ pl: '0px', pt: '4px', pb: '4px', fontSize: '16px', backgroundColor: '#fafafb' }}>Update User Data Permission</DialogTitle>
                    <MDBox>
                        <InputLabel style={{ marginTop: "15px", height: "15px" }}>Email</InputLabel>
                        <MDInput
                            margin="normal"
                            fullWidth
                            disabled={true}
                            value={email}
                            placeholder="name@domain.com"
                            error={showError}
                            success={!showError}
                            helperText={emailHelperText}
                            onChange={onEmailChange}

                        />
                        <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                            <Autocomplete
                                disablePortal
                                id="multi-combo-box-demo"
                                fullWidth
                                options={departments}
                                defaultValue={selectedDepartment}
                                onChange={(event, newValue) => setSelectedDepartment(newValue)}
                                renderInput={(params) => <TextField {...params} label="Departments" size="small" />}
                            />
                        </div>
                        <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                            <Autocomplete
                                disablePortal
                                id="multi-combo-box-demo"
                                fullWidth
                                options={categories}
                                defaultValue={selectedCategory}
                                onChange={(event, newValue) => setSelectedCategory(newValue)}
                                renderInput={(params) => <TextField {...params} label="Categories" size="small" />}
                            />
                        </div>
                        <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                            <Autocomplete
                                disablePortal
                                id="multi-combo-box-demo"
                                fullWidth
                                options={subCategories}
                                defaultValue={selectedSubCategory}
                                onChange={(event, newValue) => setSelectedSubCategory(newValue)}
                                renderInput={(params) => <TextField {...params} label="Sub Categories" size="small" />}
                            />
                        </div>
                        <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                fullWidth
                                options={factories}
                                defaultValue={selectedFactory}
                                onChange={(event, newValue) => setSelectedFactory(newValue)}
                                renderInput={(params) => <TextField {...params} label="Factory" size="small" />}
                            />
                        </div>
                        <div style={{ display: "inline-grid", paddingTop: "2%", width: "100%" }}>
                            <Autocomplete
                                disablePortal
                                id="multi-combo-box-demo"
                                fullWidth
                                disabled
                                options={locations}
                                defaultValue={selectedLocation}
                                onChange={(event, newValue) => setSelectedLocation(newValue)}
                                renderInput={(params) => <TextField {...params} label="Location" size="small" />}
                            />
                        </div>
                        <DialogActions style={{ justifyContent: "left", paddingBottom: "0px" }}>
                            <MDButton
                                size="small"
                                style={{ backgroundColor: 'black', textTransform: 'none', color: "white" }}
                                onClick={updateUserProfile}
                            >
                                OK
                            </MDButton>
                            <MDButton
                                size="small"
                                style={{ backgroundColor: 'black', textTransform: 'none', color: "white" }}
                                onClick={() => setIsUpdateUser(false)}
                            >
                                Close
                            </MDButton>
                        </DialogActions>
                    </MDBox>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openDeleteConfirm}
                onClose={() => setOpenDeleteConfirm(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ pt: '4px', pb: '4px', fontSize: '16px', backgroundColor: '#fafafb' }}>
                    Delete User Permission
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this user permission?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size="small" style={{ backgroundColor: 'black', textTransform: 'none', color: "white" }} onClick={() => setOpenDeleteConfirm(false)}>No</Button>
                    <Button size="small" style={{ backgroundColor: 'black', textTransform: 'none', color: "white" }} onClick={deleteUser} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
});

ManagePermissionTab.propTypes = {
    sendDataChangesBackend: PropTypes.func.isRequired,
    setCurrentGridApi: PropTypes.func.isRequired,
};
export default ManagePermissionTab;
