import App from "App";
import { LicenseManager } from "ag-grid-enterprise";
import { MaterialUIControllerProvider } from "context";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { store } from "store";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-054145}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Sohum_Tech_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Analytics}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Analytics}_need_to_be_licensed___{Analytics}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{21_March_2025}____[v3]_[0102]_MTc0MjUxNTIwMDAwMA==b881bec92a58ce85bbe213d29b4d10e6"
);

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <HashRouter>
    <MaterialUIControllerProvider>
      <Provider store={store}>
          <App />
      </Provider>
    </MaterialUIControllerProvider>
  </HashRouter>
);
