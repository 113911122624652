import { Icon, Tab, Tabs } from "@mui/material";
import { useMaterialUIController } from "context";
import { functionalityAccess } from "layouts/linelist/customFunctions";
import Collapse from "@mui/material/Collapse";
import MDBox from "components/MDBox";

function DefaultNavbarMobile({
  assortBtnCall,
  btnCarryOver,
  btnColorCodeSync,
  btnSavePreference,
  calculateSizesBtnCall,
  costingViewBtnCall,
  defaultCostingViewBtnCall,
  downloadBtn,
  downloadLinelistBtnCall,
  finalCostingView,
  generateItemUpload,
  highlightStyle,
  importDataBtnCall,
  redownloadTabBtnCall,
  sendChangeMemoBtnCall,
  sendChangesBtnCall,
  ShowChangesBtnCall,
  upload_btn_click,
  summaryDlgOpen,
  generatePoBtnCall,
}) {
  const [controller] = useMaterialUIController();
  const { currentTab } = controller;
  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      <MDBox width="calc(100% + 1.625rem)" my={2} ml={-2}>
        <MDBox sx={{ height: "15rem", maxHeight: "15rem", overflowY: "scroll" }}>
          <Tabs variant="scrollable" scrollButtons={true}>
            {functionalityAccess("btnDownloadData", currentTab) ? (
              <Tab
                icon={<Icon>file_download</Icon>}
                label="Download Data"
                onClick={downloadLinelistBtnCall}
              />
            ) : null}
            {functionalityAccess("btnRedownloadTab", currentTab) ? (
              <Tab
                icon={<Icon>download_for_offline</Icon>}
                label="Redownload"
                onClick={redownloadTabBtnCall}
              />
            ) : null}
            {functionalityAccess("btnSendChanges", currentTab) ? (
              <Tab
                icon={<Icon>arrow_upward</Icon>}
                label="Send Changes"
                onClick={sendChangesBtnCall}
              />
            ) : null}
            {functionalityAccess("btnAssort", currentTab) ? (
              <Tab icon={<Icon>swap_horiz</Icon>} label="Assort" onClick={assortBtnCall} />
            ) : null}
            {functionalityAccess("btnCarryOver", currentTab) ? (
              <Tab icon={<Icon>swap_horiz</Icon>} label="Carry Over" onClick={btnCarryOver} />
            ) : null}
            {functionalityAccess("btnColorCodeSync", currentTab) ? (
              <Tab icon={<Icon>swap_horiz</Icon>} label="Color Code Sync" onClick={btnColorCodeSync} />
            ) : null}            
            {functionalityAccess("btnSummaryView", currentTab) ? (
                            <Tab
                              icon={<Icon>dashboard</Icon>}
                              label="Summary"
                              onClick={summaryDlgOpen}
                            />
                          ) : null}

            {functionalityAccess("btnDownloadTYReport", currentTab) ? (
              <Tab
                icon={<Icon>system_update_alt</Icon>}
                label="Download TY_Report"
                onClick={() => downloadBtn("TY_Report")}
              />
            ) : null}
            {functionalityAccess("btnDownloadPst", currentTab) ? (
              <Tab
                icon={<Icon>system_update_alt</Icon>}
                label="Download PST"
                onClick={() => downloadBtn("PST")}
              />
            ) : null}
            {functionalityAccess("btnDownloadPDInitialCosting", currentTab) ? (
              <Tab
                icon={<Icon>system_update_alt</Icon>}
                label="Download PD_Initial_Costing"
                onClick={() => downloadBtn("PD_Initial_Costing")}
              />
            ) : null}
            {functionalityAccess("btnDownloadBuySheetMasterSizingPercentage", currentTab) ? (
              <Tab
                icon={<Icon>system_update_alt</Icon>}
                label="Download Buy_Sheet_Master_Sizing_%"
                onClick={() => downloadBtn("Buy_Sheet_Master_Sizing_%")}
              />
            ) : null}                        
            {functionalityAccess("btnDownloadLinelist", currentTab) ? (
              <Tab
                icon={<Icon>system_update_alt</Icon>}
                label="Download Linelist"
                onClick={() => downloadBtn("Linelist")}
              />
            ) : null}
            {functionalityAccess("btnDownloadDtc", currentTab) ? (
              <Tab
                icon={<Icon>system_update_alt</Icon>}
                label="Download Dtc_Buy_Sheet"
                onClick={() => downloadBtn("DTC_Buy_Sheet")}
              />
            ) : null}
            {functionalityAccess("btnDownloadWholesale", currentTab) ? (
              <Tab
                icon={<Icon>system_update_alt</Icon>}
                label="Download Wholesale"
                onClick={() => downloadBtn("Wholesale")}
              />
            ) : null}
            {functionalityAccess("btnDownloadChase", currentTab) ? (
              <Tab
                icon={<Icon>system_update_alt</Icon>}
                label="Download Chase"
                onClick={() => downloadBtn("Chase")}
              />
            ) : null}
            {functionalityAccess("btnDownloadSizing", currentTab) ? (
              <Tab
                icon={<Icon>system_update_alt</Icon>}
                label="Download Sizing"
                onClick={() => downloadBtn("Sizing")}
              />
            ) : null}
            {functionalityAccess("btnShowChanges", currentTab) ? (
              <Tab icon={<Icon>update</Icon>} label="Show Changes" onClick={ShowChangesBtnCall} />
            ) : null}
            {functionalityAccess("btnSavePreference", currentTab) ? (
              <Tab icon={<Icon>star</Icon>} label="Preferences" onClick={btnSavePreference} />
            ) : null}
            {functionalityAccess("btnImportData", currentTab) ? (
              <Tab
                icon={<Icon>import_contacts</Icon>}
                label="Import"
                onClick={importDataBtnCall}
              />
            ) : null}
            {functionalityAccess("btnSendChangeMemo", currentTab) ? (
              <Tab
                icon={<Icon>forward_to_inbox</Icon>}
                label="Change Memo"
                onClick={sendChangeMemoBtnCall}
              />
            ) : null}
            {functionalityAccess("btnGenerateItemUpload", currentTab) ? (
              <Tab
                icon={<Icon>note_add</Icon>}
                title="Item Upload"
                onClick={generateItemUpload}
              />
            ) : null}
            {functionalityAccess("btnHighlightStyle", currentTab) ? (
              <Tab
                icon={<Icon>format_color_text</Icon>}
                title="Highlight Style"
                onClick={highlightStyle}
              />
            ) : null}
            {functionalityAccess("btnDefaultCostingView", currentTab) ? (
              <Tab
                icon={<Icon>calendar_view_month</Icon>}
                label="Default View"
                onClick={defaultCostingViewBtnCall}
              />
            ) : null}
            {functionalityAccess("btnCostingView", currentTab) ? (
              <Tab
                icon={<Icon>calendar_view_month</Icon>}
                label="Costing View"
                onClick={costingViewBtnCall}
              />
            ) : null}
            {functionalityAccess("btnFinalCostingView", currentTab) ? (
              <Tab
                icon={<Icon>calendar_view_month</Icon>}
                label="Final Costing View"
                onClick={finalCostingView}
              />
            ) : null}
            {functionalityAccess("btnCalculateSizes", currentTab) ? (
              <Tab
                icon={<Icon>calculate_outlined</Icon>}
                label="Calculate Sizes"
                onClick={calculateSizesBtnCall}
              />
            ) : null}
            {functionalityAccess("btnGeneratePO", currentTab) ? (
              <Tab
                icon={<Icon>description</Icon>}
                label="Generate PO"
                onClick={generatePoBtnCall}
              />
            ) : null}
            {functionalityAccess("btnDocumentUpload", currentTab) ? (
              <Tab
                icon={<Icon>attach_file</Icon>}
                label="Attachment"
                onClick={upload_btn_click}
              />
            ) : null}
          </Tabs>
        </MDBox>
      </MDBox>
    </Collapse>
  );
}
export default DefaultNavbarMobile;
