import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const LabelValuePair = ({ label, value }) => {
  return (
    <Paper elevation={1} sx={{ mb: 1, overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            bgcolor: 'rgba(0, 0, 0, .07)',
            color: 'white',
            py: 1,
            px: 2,
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography variant="h6" fontWeight="bold" sx={{fontSize: '12px', textAlign: "center",fontWeight: 600}}>
            {label}
          </Typography>
        </Box>
        <Box sx={{ width: "50%", py: 1, px: 2 }}>
          <Typography variant="h6" sx={{fontSize: '12px', textAlign: "center",fontWeight: 400}}>{value}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default LabelValuePair;