


import { executeDataVerification, toastMessage } from 'layouts/linelist/XL_Utils';
import { GetAutoChanges, compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_faherty_size_scales";
const rng_name = "Size_Scales_Rng";


const SizeScalesTab = forwardRef(({
    setCurrentGridApi,
    setActionItemDataFun }, ref) => {

    const [isGridReady, setIsGridReady] = useState(false);
    const dispatch = useDispatch(); //to update redux state variable
    const SizeScalesData = useSelector(state => state.SizeScalesData);
    const SSNData = useSelector((s) => s.SizeScalesDataSSN);

    const SizeScalesHighlistCell = useSelector(state => state.SizeScalesHighlistCell);
    const tmpColDef = useSelector(state => state.SizeScalesColDef ? state.SizeScalesColDef : []);
	const columnPref = useSelector((s) => s.SizeScalesColPref);    

    const SizeScalesSorting = useSelector(state => state.SizeScalesSorting ? state.SizeScalesSorting : []);
    const SizeScalesFilters = useSelector(state => state.SizeScalesFilters ? state.SizeScalesFilters : {});

    const highlightCells = useRef([]);
    const gridApi = useRef({});
    const gridColumnApi = useRef({})

    const cellValueChanged = useRef([]);
    const [autoChangeState, setAutoChangeState] = useState(false)
    const [autoGetChangeResult, setAutoGetChangeResult] = useState({});


    const setResetFilter = (setReset) => {
        let sorting = SizeScalesSorting
        let filtering = SizeScalesFilters

        if (setReset === "reset") {
            sorting = null
            filtering = null
        }
        gridApi.current.applyColumnState({
            state: sorting
        })
        gridApi.current.setFilterModel(filtering);

    }
    useImperativeHandle(ref, () => ({
        validateSendData: () => {
            if (!SizeScalesData) return false
            const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
            if (res.status === false) {
                highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
                gridApi.current.refreshCells({ force: true })
                return (res)
            }
            cellValueChanged.current = [];
            removeHeihlight();
            return ({ status: true})
        },
        getChangesFunc: async (masterData, metaData) => {
            const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, SizeScalesColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
            if (res.status) {
                highlightCells.current = res.highlightCells

                dispatch({ type: "SIZE_SCALE_DATA", data: res.data });
                gridApi.current.refreshCells({ force: true })
            } else {
                toastMessage(res.msg, "warning");

            }
            return res
        },
        getAutoChanges: (data, downloadType) => {
            setResetFilter("reset")
            const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
            setAutoChangeState(true);
            setAutoGetChangeResult(res);
        }
    }));
    const removeHeihlight = () => {
        highlightCells.current = [];
        if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true });
    };
    useEffect(() => {
        if (isGridReady) {
            if (SizeScalesSorting !== null && SizeScalesSorting !== undefined) {
                gridApi.current.applyColumnState({
                    state: SizeScalesSorting
                })
            }
            if (SizeScalesFilters !== null && SizeScalesFilters !== undefined) {
                gridApi.current.setFilterModel(SizeScalesFilters);
            }
        }
    }, [isGridReady])

    const onGridReady = (params) => {
        gridApi.current = params.api
        gridColumnApi.current = params.columnApi;
        setCurrentGridApi(params)
        setIsGridReady(true)
    }
    const onSortChanged = (event) => {
        const colState = gridApi.current.getColumnState();
        const savedSortState = colState
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            });
        dispatch({ type: "SET_SIZE_SCALE_SORTING", data: savedSortState });
    }
    const onFilterChanged = (event) => {
        const savedFilterModel = gridApi.current.getFilterModel();
        dispatch({ type: "SET_SIZE_SCALE_FILTERS", data: savedFilterModel });
    }
    const resteAutoChangeState = () => {
        setResetFilter("set");
        setAutoChangeState(false);
        setAutoGetChangeResult({});
    }
    const setCellValueChanged = (data) => {
        cellValueChanged.current = data;
    }
    return (
        <CustomAgGrid
            setCurrentGridApi={onGridReady}
            filters={SizeScalesFilters}
            setActionItemDataFun={setActionItemDataFun}
            tab_name="Size_Scales"
            tbl_name={tbl_name}
            columnDefs={tmpColDef}
            rowData={SizeScalesData}
            SSNData={SSNData}
            tblHighlightCells={SizeScalesHighlistCell}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            autoChangeState={autoChangeState}
            resteAutoChangeState={resteAutoChangeState}
            autoGetChangeResult={autoGetChangeResult}
            setCellValueChanged={setCellValueChanged}
            columnPref={columnPref}
        />
    )
})

SizeScalesTab.propTypes = {
    setCurrentGridApi: PropTypes.func.isRequired,
    settimeLineDataFun: PropTypes.func.isRequired,

};

export default SizeScalesTab;