import LineListToDo from "./LineListTodo";
import PdInitialCostingTodo from "./PdInitialCostingTodo";

const ToDo = ({ selectedComponent, closeDialog }) => {
    const renderToDo = () => {
        if (selectedComponent.includes("Linelist")) return <LineListToDo closeDialog={closeDialog} />;
        else if (selectedComponent.includes("PD_Intial_Costing")) return <PdInitialCostingTodo closeDialog={closeDialog} />;
        else return <div>No Item Found</div>;
    }
    return (
    <div>
        {renderToDo()}
    </div>)
}

export default ToDo;