import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

// const columnDefs = [
//     { headerName: "Column Name", field: "Column Name" },
//     { headerName: "New Value", field: "New Value" },
//     { headerName: "Updated By", field: "Updated By" }
// ];

const AutoGetChangesNotification = ({ selectedComponent }) => {
    console.log("AutoGetChangesNotification -> selectedComponent -> ", selectedComponent);

    const defaultColDef = useMemo(() => ({
        editable: false,
        sortable: true,
        filter: true
    }), []);

    return (
        <>
            <div style={{ fontSize: '14px', marginBottom: '5px' }}>{selectedComponent.msg}</div>
            <div className="ag-theme-quartz" style={{ height: 400, width: 600 }}>
                <AgGridReact
                    columnDefs={selectedComponent.columnDefs}
                    rowData={selectedComponent?.cell_changes || []}
                    defaultColDef={defaultColDef}
                />
            </div>
        </>
    );
};

export default AutoGetChangesNotification;