


import { xlRound } from 'layouts/linelist/XL_Calculation';
import { executeDataVerification, toastMessage } from 'layouts/linelist/XL_Utils';
import { GetAutoChanges, compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_faherty_freight_by_category";
const rng_name = "Freight_by_Category_Rng";

const FreightByCategoryTab = forwardRef(({ setCurrentGridApi, settimeLineDataFun, setActionItemDataFun, clearFilterBtnCall }, ref) => {
  const dispatch = useDispatch(); //to update redux state variable
  const FormulaCalcColLst = useSelector(s => s.formulaCalcColLst[0][tbl_name]);
  const FreightByCategoryData = useSelector(s => s.FreightByCategoryData);
  const SSNData = useSelector((s) => s.FreightByCategoryDataSSN);

  const columnPref = useSelector((s) => s.FreightbyCategoryColPref);

  const rdxHighlightCell = useSelector(s => s.FreightByCategoryHighlistCell);
  const rdxSorting = useSelector(s => s.FreightByCategorySorting ? s.FreightByCategorySorting : []);
  const rdxFilters = useSelector(s => s.FreightByCategoryFilters ? s.FreightByCategoryFilters : {});
  const tmpColDef = useSelector(s => s.FreightByCategoryColDef ? s.FreightByCategoryColDef : []);

  const highlightCells = useRef([]);
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const cellValueChanged = useRef([]);

  const [isGridReady, setIsGridReady] = useState(false);
  const [autoChangeState, setAutoChangeState] = useState(false)
  const [autoGetChangeResult, setAutoGetChangeResult] = useState({});

  const setResetFilter = (setReset) => {
    let sorting = rdxSorting
    let filtering = rdxFilters
    if (setReset === "reset") {
      sorting = null
      filtering = null
    }
    gridApi.current.applyColumnState({ state: sorting });
    gridApi.current.setFilterModel(filtering);
  }
  useImperativeHandle(ref, () => ({
    validateSendData: () => {
      if (!FreightByCategoryData) return false
      const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
      if (res.status === false) {
        highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
        return (res)
      }
      removeHeihlight();
      cellValueChanged.current = [];
      return ({ status: true })
    },
    getChangesFunc: async (masterData, metaData) => {
      const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, FreightByCategoryColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
      if (res.status) {
        highlightCells.current = res.highlightCells

        dispatch({ type: "FRIGHT_BY_CAT_DATA", data: res.data });
        gridApi.current.refreshCells({ force: true })
      } else {
        toastMessage(res.msg, "warning");

      }
      return res
    },
    getAutoChanges: (data, downloadType) => {
      setResetFilter("reset")
      const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
      setAutoChangeState(true);
      setAutoGetChangeResult(res);
    }
  }));
  const removeHeihlight = () => {
    highlightCells.current = [];
    if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true })
  };
  useEffect(() => {
    if (isGridReady) {
      if (rdxSorting !== null && rdxSorting !== undefined) {
        gridApi.current.applyColumnState({
          state: rdxSorting
        })
      }
      if (rdxFilters !== null && rdxFilters !== undefined) {
        gridApi.current.setFilterModel(rdxFilters);
      }
    }
  }, [isGridReady])

  const onGridReady = (params) => {
    gridApi.current = params.api
    gridColumnApi.current = params.columnApi;
    setCurrentGridApi(params)
    calculateAllFormulas();
    setIsGridReady(true)
  };

  const calculateCBMPerCartonUnitsKg = (rowNode) => {
    let cbmCartons = "0";
    let cbmPerUnits = "0";
    let volKgPerUnit = "0";
    let finalKG = "0";
    let kg = "0";
    cbmCartons = (parseFloat((parseFloat(rowNode.data["L"] ? rowNode.data["L"] : 0) * parseFloat(rowNode.data["H"] ? rowNode.data["H"] : 0) * parseFloat(rowNode.data["W"] ? rowNode.data["W"] : 0))) / 1000000)
    if (cbmCartons !== 0) { cbmCartons = parseFloat(cbmCartons).toFixed(10) }
    cbmCartons = xlRound(cbmCartons, 6);

    if (rowNode.data["Pieces Per Carton"] === undefined || parseInt(rowNode.data["Pieces Per Carton"]) === 0 || rowNode.data["Pieces Per Carton"] === null || rowNode.data["Pieces Per Carton"] === NaN) {
      cbmPerUnits = "0.0000";
      volKgPerUnit = "0.0000";
    } else
      volKgPerUnit = parseFloat(parseFloat(parseFloat(cbmCartons) * 168) / parseFloat(rowNode.data["Pieces Per Carton"]));
    if (volKgPerUnit !== 0) { volKgPerUnit = parseFloat(volKgPerUnit).toFixed(10) }
    volKgPerUnit = xlRound(volKgPerUnit, 6);

    if (rowNode.data["KG"] === undefined || rowNode.data["KG"] === null || rowNode.data["KG"] === NaN)
      kg = "0";
    else
      finalKG = parseFloat(parseFloat(rowNode.data["KG"]) + parseFloat(volKgPerUnit)) / 2;
    if (finalKG !== 0) { finalKG = parseFloat(finalKG).toFixed(10) }

    finalKG = xlRound(finalKG, 6);


    if (cbmCartons === undefined || cbmCartons === null || isNaN(cbmCartons))
      cbmCartons = "0";
    if (volKgPerUnit === undefined || volKgPerUnit === null || isNaN(volKgPerUnit))
      volKgPerUnit = "0";
    if (finalKG === undefined || finalKG === null || isNaN(finalKG))
      finalKG = "0";

    return { cbmCartons: parseFloat(cbmCartons), finalKG: finalKG, volKgPerUnit: volKgPerUnit };

  };
  const fxcalc = (colnm) => {
    let retval = false;
    if (FormulaCalcColLst.includes(colnm)) retval = true;
    return retval;
  };
  const calculateAllFormulas = () => {
    if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
      gridApi.current.forEachNode((event) => { calculateReactFormula(event) });
      gridApi.current.refreshCells({ force: true });
    }
    dispatch({ type: "SET_PLEASE_WAIT", data: false });
  };
  const calculateReactFormula = (event) => {
    let calc = calculateCBMPerCartonUnitsKg(event);
    fxcalc("CBM") ? event.data["CBM"] = calc.cbmCartons : null;
    fxcalc("Final KG") ? event.data["Final KG"] = calc.finalKG : null;
    fxcalc("Volumetric KG") ? event.data["Volumetric KG"] = calc.volKgPerUnit : null;
  };

  const onSortChanged = (event) => {
    const colState = gridApi.current.getColumnState();
    const savedSortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch({ type: "SET_FRIGHT_BY_CAT_SORTING", data: savedSortState });
  };
  const onFilterChanged = (event) => {
    const savedFilterModel = gridApi.current.getFilterModel();
    dispatch({ type: "SET_FRIGHT_BY_CAT_FILTERS", data: savedFilterModel });
  };
  const resteAutoChangeState = () => {
    setResetFilter("set");
    setAutoChangeState(false);
    setAutoGetChangeResult({});
  }
  const setCellValueChanged = (data) => {
    cellValueChanged.current = data;
  }
  return (
    <CustomAgGrid
      setCurrentGridApi={onGridReady}
      filters={rdxFilters}
      setActionItemDataFun={setActionItemDataFun}
      tab_name="Freight_by_Category"
      tbl_name={tbl_name}
      columnDefs={tmpColDef}
      rowData={FreightByCategoryData}
      SSNData={SSNData}
      tblHighlightCells={rdxHighlightCell}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      autoChangeState={autoChangeState}
      resteAutoChangeState={resteAutoChangeState}
      autoGetChangeResult={autoGetChangeResult}
      setCellValueChanged={setCellValueChanged}
      calculateFormula={calculateReactFormula}
      columnPref={columnPref}
    />
  );
})

FreightByCategoryTab.propTypes = {
  setCurrentGridApi: PropTypes.func.isRequired,
  settimeLineDataFun: PropTypes.func.isRequired,

};

export default FreightByCategoryTab;
