const defaultState = {
  appMetaData: [],
  pleaseWait: false,
  isFromSendData: false,
  isAuditTrailAction: false,
  testColLst: [],
  formulaCalcColLst: [],
  SeasonData: [],
  SeasonDataSSN: [],
  SeasonColDef: [],
  SeasonHighlistCell: [],
  SeasonUnsentCount: 0,

  LinelistData: [],
  LinelistDataSSN: [],
  LinelistColDef: [],
  LineListHighlistCell: [],
  LineListUnsentCount: 0,

  TYReportData: [],
  TYReportDataSSN: [],
  TYReportColDef: [],
  TYReportHighlistCell: [],
  TYReportUnsentCount: 0,  

  PstData: [],
  PstDataSSN: [],
  PstColDef: [],
  PstHighlistCell: [],
  PstUnsentCount: 0,

  PDInitialCostingData: [],
  PDInitialCostingDataSSN: [],
  PDInitialCostingColDef: [],
  PDInitialCostingHighlistCell: [],
  PDInitialCostingUnsentCount: 0,
  PDInitialCostingFilters: [],
  PDInitialCostingSorting: [],

  MerchAssortmentSandboxData: [],
  MerchAssortmentSandboxDataSSN: [],
  MerchAssortmentSandboxColDef: [],
  MASHighlistCell: [],
  MerchAssortmentSandboxUnsentCount: 0,

  ChaseData: [],
  ChaseDataSSN: [],
  ChaseHighlistCell: [],
  ChaseColDef: [],
  ChaseUnsentCount: 0,

  DtcBuySheetData: [],
  DtcBuySheetDataSSN: [],
  DtcBuySheetColDef: [],
  DtcButSheetHighlistCell: [],
  DtcButSheetUnsentCount: 0,

  WhslBuySheetData: [],
  WhslBuySheetDataSSN: [],
  WhslBuySheetColDef: [],
  WhslBuySheetHighlistCell: [],
  WhslBuySheetUnsentCount: 0,

  WhslBuySheetNordRpData: [],
  WhslBuySheetNordRpDataSSN: [],
  WhslBuySheetNordRpColDef: [],
  WhslBuySheetNordHighlistCell: [],
  WhslBuySheetNordUnsentCount: 0,

  ActionItemData: [],
  ActionItemDataSSN: [],
  ActionItemColDef: [],
  ActionItemUnsentCount: 0,
  ActionItemHighlightCell: [],
  ActionItemFilters: {},
  ActionItemSorting: [],

  SizingData: [],
  SizingDataSSN: [],
  SizingColDef: [],
  SizingHighlistCell: [],
  SizingUnsentCount: 0,

  BuySheetMasterSizingPercentageData: [],
  BuySheetMasterSizingPercentageDataSSN: [],
  BuySheetMasterSizingPercentageColDef: [],
  BuySheetMasterSizingPercentageHighlistCell: [],
  BuySheetMasterSizingPercentageUnsentCount: 0,

  BuyShtMasterDataChartsData: [],
  BuyShtMasterDataChartsDataSSN: [],
  BuyShtMasterDataChartsColDef: [],
  BuyShtMasterDataChartsHighlistCell: [],
  BuyShtMasterDataChartsUnsentCount: 0,

  CompApsMasterStoreEcomData: [],
  CompApsMasterStoreEcomDataSSN: [],
  CompApsMasterStoreEcomColDef: [],
  CompApsMasterStoreEcomHighlistCell: [],
  CompApsMasterStoreEcomUnsentCount: 0,

  FreightMasterData: [],
  FreightMasterDataSSN: [],
  FreightMasterColDef: [],
  FreightMasterHighlistCell: [],
  FreightMasterUnsentCount: 0,

  FreightByCategoryData: [],
  FreightByCategoryDataSSN: [],
  FreightByCategoryColDef: [],
  FreightByCategoryHighlistCell: [],
  FreightByCategoryUnsentCount: 0,

  DeliveryMasterData: [],
  DeliveryMasterDataSSN: [],
  DeliveryMasterColDef: [],
  DeliveryMasterHighlistCell: [],
  DeliveryMasterUnsentCount: 0,

  FbFtyDatabaseData: [],
  FbFtyDatabaseDataSSN: [],
  FbFtyDatabaseColDef: [],
  FbFtyDatabaseHighlistCell: [],
  FbFtyDatabaseUnsentCount: 0,

  SizeScalesData: [],
  SizeScalesDataSSN: [],
  SizeScalesColDef: [],
  SizeScalesHighlistCell: [],
  SizeScalesUnsentCount: 0,

  ShopifyProductTypeMappingData: [],
  ShopifyProductTypeMappingDataSSN: [],
  ShopifyProductTypeMappingColDef: [],
  ShopifyProductTypeMappingHighlistCell: [],
  ShopifyProductTypeMappingUnsentCount: 0,

  SeasonInquiryData: [],
  SeasonInquiryDataSSN: [],
  SeasonInquiryColDef: [],
  SeasonInquiryHighlistCell: [],
  SeasonInquiryUnsentCount: 0,

  MasterLovData: [],
  MasterLovDataSSN: [],
  MasterLovColDef: [],
  MasterLovHighlistCell: [],
  MasterLovUnsentCount: 0,

  AirtableMappingData: [],
  AirtableMappingDataSSN: [],
  AirtableMappingColDef: [],
  AirtableMappingHighlistCell: [],
  AirtableMappingUnsentCount: 0,

  MilestonesData: [],
  MilestonesDataSSN: [],
  MilestonesColDef: [],
  MilestonesHighlistCell: [],
  MilestonesUnsentCount: 0,
  MilestonesColPref: [],
  MilestonesSorting: [],
  MilestonesFilters: {},
  MilestonesGrpColPref: [],

  MatrixColDef: [],
  MatrixData: [],
  MatrixDataSSN: [],
  MatrixSorting: [],
  MatrixFilters: [],
  MatrixHighlightCell: [],
  MatrixColPref: [],
  MatrixGrpColPref: [],
  MatrixUnsentCount: 0,

  ManageUserColDef: [],
  ManageUserShorting: [],
  ManageUserFilters: [],
  ManageUserColDropDown: new Map(),
  ManageUserData: [],
  ManageUserDataSSN: [],
  ManageUserHighlightCell: [],
  ManageUserUnsentCount: 0,

  ManagePermissionColDef: [],
  ManagePermissionShorting: [],
  ManagePermissionFilters: [],
  ManagePermissionColDropDown: new Map(),
  ManagePermissionData: [],
  ManagePermissionDataSSN: [],
  ManagePermissionHighlightCell: [],
  ManagePermissionUnsentCount: 0,

  LinelistImportColDef: [],
  PdInitialCostingImportColDef: [],
  SizingImportColDef: [],
  CompApsMasterStoreEcomImportColDef: [],
  MerchAssortmentSandboxImportColDef: [],
  dataTransferRules: [],

  dataValidationRules: [],
  mpAllTblsColsDataSource: new Map(),
  settingsForDataTransfer: [],
  SeasonSorting: [],
  LinelistSorting: [],
  MasterLovSorting: [],
  TYReportSorting: [],
  PstSorting: [],
  AirtableMappingSorting: [],
  SeasonInquirySorting: [],
  ShopifyProductTypeMappingSorting: [],
  SizeScalesSorting: [],
  FbFtyDatabaseSorting: [],
  ChaseSorting: [],
  DtcBuySheetSorting: [],
  WhslBuySheetSorting: [],
  WhslBuySheetNordRpSorting: [],
  SizingSorting: [],
  BuySheetMasterSizingPercentageSorting: [],
  DeliveryMasterSorting: [],
  FreightByCategorySorting: [],
  FreightMasterSorting: [],
  CompApsMasterStoreEcomSorting: [],
  BuyShtMasterDataChartsSorting: [],
  SeasonFilters: {},
  LinelistFilters: {},
  MasterLovFilters: {},
  TYReportFilters: {},
  PstFilters: {},
  AirtableMappingFilters: {},
  SeasonInquiryFilters: {},
  ShopifyProductTypeMappingFilters: {},
  SizeScalesFilters: {},
  FbFtyDatabaseFilters: {},
  MerchAssortmentSandboxSorting: [],
  MerchAssortmentSandboxFilters: {},
  ChaseFilters: {},
  DtcBuySheetFilters: {},
  WhslBuySheetFilters: {},
  WhslBuySheetNordRpFilters: {},
  SizingFilters: {},
  BuySheetMasterSizingPercentageFilters: {},
  DeliveryMasterFilters: {},
  FreightByCategoryFilters: {},
  FreightMasterFilters: {},
  CompApsMasterStoreEcomFilters: {},
  BuyShtMasterDataChartsFilters: {},
  SummaryReportAggDataSSN: [],
  SummaryReportSrcDataSSN: [],
  pvtForTabsData: [],
  DropDownMapping: [],
  mpForTabs: {},
  pvtsData: [],
  llTabPvtData: [],
  SeasonColPref: [],
  LinelistColPref: [],
  LinelistGrpColPref: [],
  PDInitialCostingColPref: [],
  ChaseColPref: [],
  DTCBuySheetColPref: [],
  WhslBuySheetColPref: [],
  WhslBuySheetNordRPColPref: [],
  SizingColPref: [],
  BuySheetMasterSizingColPref: [],
  BuyShtMasterDataChartsColPref: [],
  CompAPSMasterStoreEcomColPref: [],
  FreightMasterColPref: [],
  FreightbyCategoryColPref: [],
  DeliveryMasterColPref: [],
  FBFtyDatabaseColPref: [],
  SizeScalesColPref: [],
  ShopifyProductTypeMapColPref: [],
  SeasonInquiryColPref: [],
  MasterLOVColPref: [],
  TYReportColPref: [],
  PstColPref: [],
  AirtableMappingColPref: [],
  ActionColPref: [],
  ManageUserColPref: [],
  ManagePermissionColPref: [],
  MerchAssortmentSandboxColPref: [],
  ChaseGrpColPref: [],
  DTCBuySheetGrpColPref: [],
  WhslBuySheetGrpColPref: [],
  WhslBuySheetNordRPGrpColPref: [],
  SizingGrpColPref: [],
  BuySheetMasterSizingGrpColPref: [],
  BuyShtMasterDataChartsGrpColPref: [],
  FreightMasterGrpColPref: [],
  MasterLOVGrpColPref: [],
  TYReportGrpColPref: [],
  PstGrpColPref: [],
  ColumnMetaData: [],
  MyAvatar: "",
};
export default function rootReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case "setMyAvatar":
      return { ...state, MyAvatar: action.data };
    case "setColumnMetaData":
      return { ...state, ColumnMetaData: action.data };
    case "SeasonColPref":
      return { ...state, SeasonColPref: action.data };
    case "LinelistColPref":
      return { ...state, LinelistColPref: action.data };
    case "LinelistGrpColPref":
      return { ...state, LinelistGrpColPref: action.data };
    case "PDInitialCostingColPref": return { ...state, PDInitialCostingColPref: action.data };
    case "ChaseColPref": return { ...state, ChaseColPref: action.data };
    case "DTCBuySheetColPref": return { ...state, DTCBuySheetColPref: action.data };
    case "WhslBuySheetColPref": return { ...state, WhslBuySheetColPref: action.data };
    case "WhslBuySheetNordRPColPref": return { ...state, WhslBuySheetNordRPColPref: action.data };
    case "SizingColPref": return { ...state, SizingColPref: action.data };
    case "BuySheetMasterSizingColPref": return { ...state, BuySheetMasterSizingColPref: action.data };
    case "BuyShtMasterDataChartsColPref": return { ...state, BuyShtMasterDataChartsColPref: action.data };
    case "CompAPSMasterStoreEcomColPref": return { ...state, CompAPSMasterStoreEcomColPref: action.data };
    case "FreightMasterColPref": return { ...state, FreightMasterColPref: action.data };
    case "FreightbyCategoryColPref": return { ...state, FreightbyCategoryColPref: action.data };
    case "DeliveryMasterColPref": return { ...state, DeliveryMasterColPref: action.data };
    case "FBFtyDatabaseColPref": return { ...state, FBFtyDatabaseColPref: action.data };
    case "SizeScalesColPref": return { ...state, SizeScalesColPref: action.data };
    case "ShopifyProductTypeMapColPref": return { ...state, ShopifyProductTypeMapColPref: action.data };
    case "SeasonInquiryColPref": return { ...state, SeasonInquiryColPref: action.data };
    case "MasterLOVColPref": return { ...state, MasterLOVColPref: action.data };
    case "TYReportColPref": return { ...state, TYReportColPref: action.data };
    case "PstColPref": return { ...state, PstColPref: action.data };
    case "AirtableMappingColPref": return { ...state, AirtableMappingColPref: action.data };
    case "MilestonesColPref": return { ...state, MilestonesColPref: action.data };
    case "MilestonesGrpColPref": return { ...state, MilestonesGrpColPref: action.data };
    case "ActionColPref": return { ...state, ActionColPref: action.data };
    case "ManageUserColPref": return { ...state, ManageUserColPref: action.data };
    case "ManagePermissionColPref": return { ...state, ManagePermissionColPref: action.data };
    case "MerchAssortmentSandboxColPref": return { ...state, MerchAssortmentSandboxColPref: action.data };

    case "ChaseGrpColPref": return { ...state, ChaseGrpColPref: action.data };
    case "DTCBuySheetGrpColPref": return { ...state, DTCBuySheetGrpColPref: action.data };
    case "WhslBuySheetGrpColPref": return { ...state, WhslBuySheetGrpColPref: action.data };
    case "WhslBuySheetNordRPGrpColPref": return { ...state, WhslBuySheetNordRPGrpColPref: action.data };
    case "SizingGrpColPref": return { ...state, SizingGrpColPref: action.data };
    case "BuySheetMasterSizingGrpColPref": return { ...state, BuySheetMasterSizingGrpColPref: action.data };
    case "BuyShtMasterDataChartsGrpColPref": return { ...state, BuyShtMasterDataChartsGrpColPref: action.data };
    case "FreightMasterGrpColPref": return { ...state, FreightMasterGrpColPref: action.data };
    case "MasterLOVGrpColPref": return { ...state, MasterLOVGrpColPref: action.data };
    case "TYReportGrpColPref": return { ...state, TYReportGrpColPref: action.data };
    case "PstGrpColPref": return { ...state, PstGrpColPref: action.data };

    case "MatrixColDef": return { ...state, MatrixColDef: action.colDef };
    case "MatrixData": return { ...state, MatrixData: action.data };
    case "MatrixSsnData": return { ...state, MatrixSsnData: action.data };
    case "MatrixSorting": return { ...state, MatrixSorting: action.data };
    case "MatrixFilters": return { ...state, MatrixFilters: action.data };
    case "MatrixHighlightCell": return { ...state, MatrixHighlightCell: action.data };
    case "MatrixColPref": return { ...state, MatrixColPref: action.data };
    case "MatrixGrpColPref": return { ...state, MatrixGrpColPref: action.data };
    case "MatrixUnsentCount": return { ...state, MatrixUnsentCount: action.count };

    case "SET_APP_METADATA":
      return { ...state, appMetaData: action.data };
    case "setTestColLst":
      return {
        ...state,
        testColLst: action.data
      };
    case "setFormulaCalcColLst":
      return {
        ...state,
        formulaCalcColLst: action.data
      };
    case "setDropDownMapping":
      return {
        ...state,
        DropDownMapping: action.data
      };
    case "SET_PLEASE_WAIT":
      return {
        ...state,
        pleaseWait: action.data
      };
    case "SET_AUDIT_TRAIL_ACTION":
      return {
        ...state,
        isAuditTrailAction: action.isAuditTrailAction
      };
    case "SET_DATA_VAL_RULES":
      return {
        ...state,
        dataValidationRules: action.data,
      };
    case "SET_ALL_TABS_COLUMN_DS":
      return {
        ...state,
        mpAllTblsColsDataSource: action.data,
      };
    case "SET_SEASON_DATA":
      return {
        ...state,
        SeasonData: action.data,
      };
    case "SET_SEASON_SSN_DATA":
      return {
        ...state,
        SeasonDataSSN: action.data,
      };
    case "SET_SEASON_COL_DEF":
      return {
        ...state,
        SeasonColDef: action.colDef,
      };
    case "TY_REPORT_DATA":
      return {
        ...state,
        TYReportData: action.data,
      };
    case "TY_REPORT_SSN_DATA":
      return {
        ...state,
        TYReportDataSSN: action.data,
      };
    case "TY_REPORT_COL_DEF":
      return {
        ...state,
        TYReportColDef: action.colDef,
      };      
    case "PST_DATA":
      return {
        ...state,
        PstData: action.data,
      };
    case "PST_SSN_DATA":
      return {
        ...state,
        PstDataSSN: action.data,
      };
    case "PST_COL_DEF":
      return {
        ...state,
        PstColDef: action.colDef,
      };      
    case "SET_LINELIST_DATA":
      return {
        ...state,
        LinelistData: action.data,
      };
    case "SET_LINELIST_SSN_DATA":
      return {
        ...state,
        LinelistDataSSN: action.data,
      };
    case "SET_LINELIST_COL_DEF":
      return {
        ...state,
        LinelistColDef: action.colDef,
      };
    case "SET_CHASE_DATA":
      return {
        ...state,
        ChaseData: action.data,
      };
    case "SET_CHASE_SSN_DATA":
      return {
        ...state,
        ChaseDataSSN: action.data,
      };
    case "SET_CHASE_COL_DEF":
      return {
        ...state,
        ChaseColDef: action.colDef,
      };
    case "SET_DTC_BY_SHT_DATA":
      return {
        ...state,
        DtcBuySheetData: action.data,
      };
    case "SET_DTC_BY_SHT_SSN_DATA":
      return {
        ...state,
        DtcBuySheetDataSSN: action.data,
      };
    case "SET_DTC_BY_SHT_COL_DEF":
      return {
        ...state,
        DtcBuySheetColDef: action.colDef,
      };
    case "SET_WHSL_BY_SHT_DATA":
      return {
        ...state,
        WhslBuySheetData: action.data,
      };
    case "SET_WHSL_BY_SHT_SSN_DATA":
      return {
        ...state,
        WhslBuySheetDataSSN: action.data,
      };
    case "SET_WHSL_BY_SHT_COL_DEF":
      return {
        ...state,
        WhslBuySheetColDef: action.colDef,
      };
    case "SET_WHSL_BY_SHT_NRD_DATA":
      return {
        ...state,
        WhslBuySheetNordRpData: action.data,
      };
    case "SET_WHSL_BY_SHT_NRD_SSN_DATA":
      return {
        ...state,
        WhslBuySheetNordRpDataSSN: action.data,
      };
    case "SET_WHSL_BY_SHT_NRD_COL_DEF":
      return {
        ...state,
        WhslBuySheetNordRpColDef: action.colDef,
      };
    case "SET_SIZING_DATA":
      return {
        ...state,
        SizingData: action.data,
      };
    case "SET_SIZING_SSN_DATA":
      return {
        ...state,
        SizingDataSSN: action.data,
      };
    case "SET_SIZING_COL_DEF":
      return {
        ...state,
        SizingColDef: action.colDef,
      };
    case "SET_PDInitialCosting_DATA":
      return {
        ...state,
        PDInitialCostingData: action.data,
      };
    case "SET_PDInitialCosting_SSN_DATA":
      return {
        ...state,
        PDInitialCostingDataSSN: action.data,
      };
    case "SET_PDInitialCosting_COL_DEF":
      return {
        ...state,
        PDInitialCostingColDef: action.colDef,
      };
    case "BUY_SHT_SIZE_PER_DATA":
      return {
        ...state,
        BuySheetMasterSizingPercentageData: action.data,
      };
    case "ACTION_ITEM_DATA":
      return {
        ...state,
        ActionItemData: action.data,
      };
    case "ACTION_ITEM_SSN_DATA":
      return {
        ...state,
        ActionItemDataSSN: action.data,
      };
    case "ACTION_ITEM_COL_DEF":
      return {
        ...state,
        ActionItemColDef: action.colDef,
      };
    case "BUY_SHT_SIZE_PER_SSN_DATA":
      return {
        ...state,
        BuySheetMasterSizingPercentageDataSSN: action.data,
      };
    case "BUY_SHT_SIZE_PER_COL_DEF":
      return {
        ...state,
        BuySheetMasterSizingPercentageColDef: action.colDef,
      };
    case "BUY_SHT_SIZE_CHART_DATA":
      return {
        ...state,
        BuyShtMasterDataChartsData: action.data,
      };
    case "BUY_SHT_SIZE_CHART_SSN_DATA":
      return {
        ...state,
        BuyShtMasterDataChartsDataSSN: action.data,
      };
    case "BUY_SHT_SIZE_CHART_COL_DEF":
      return {
        ...state,
        BuyShtMasterDataChartsColDef: action.colDef,
      };
    case "COMP_APS_MASTER_DATA":
      return {
        ...state,
        CompApsMasterStoreEcomData: action.data,
      };
    case "COMP_APS_MASTER_SSN_DATA":
      return {
        ...state,
        CompApsMasterStoreEcomDataSSN: action.data,
      };
    case "COMP_APS_MASTER_COL_DEF":
      return {
        ...state,
        CompApsMasterStoreEcomColDef: action.colDef,
      };
    case "FRIGHT_MASTER_DATA":
      return {
        ...state,
        FreightMasterData: action.data,
      };
    case "FRIGHT_MASTER_SSN_DATA":
      return {
        ...state,
        FreightMasterDataSSN: action.data,
      };
    case "FRIGHT_MASTER_COL_DEF":
      return {
        ...state,
        FreightMasterColDef: action.colDef,
      };
    case "FRIGHT_BY_CAT_DATA":
      return {
        ...state,
        FreightByCategoryData: action.data,
      };
    case "FRIGHT_BY_CAT_SSN_DATA":
      return {
        ...state,
        FreightByCategoryDataSSN: action.data,
      };
    case "FRIGHT_BY_CAT_COL_DEF":
      return {
        ...state,
        FreightByCategoryColDef: action.colDef,
      };
    case "DELIVERY_MASTER_DATA":
      return {
        ...state,
        DeliveryMasterData: action.data,
      };
    case "DELIVERY_MASTER_SSN_DATA":
      return {
        ...state,
        DeliveryMasterDataSSN: action.data,
      };
    case "DELIVERY_MASTER_COL_DEF":
      return {
        ...state,
        DeliveryMasterColDef: action.colDef,
      };
    case "FB_FTY_DATA":
      return {
        ...state,
        FbFtyDatabaseData: action.data,
      };
    case "FB_FTY_SSN_DATA":
      return {
        ...state,
        FbFtyDatabaseDataSSN: action.data,
      };
    case "FB_FTY_COL_DEF":
      return {
        ...state,
        FbFtyDatabaseColDef: action.colDef,
      };
    case "Set_Merch_Assortment_Sandbox_DATA":
      return {
        ...state,
        MerchAssortmentSandboxData: action.data,
      };
    case "Set_Merch_Assortment_Sandbox_SSN_DATA":
      return {
        ...state,
        MerchAssortmentSandboxDataSSN: action.data,
      };
    case "Set_Merch_Assortment_Sandbox_COL_DEF":
      return {
        ...state,
        MerchAssortmentSandboxColDef: action.colDef,
      };
    case "SIZE_SCALE_DATA":
      return {
        ...state,
        SizeScalesData: action.data,
      };
    case "SIZE_SCALE_SSN_DATA":
      return {
        ...state,
        SizeScalesDataSSN: action.data,
      };
    case "SIZE_SCALE_COL_DEF":
      return {
        ...state,
        SizeScalesColDef: action.colDef,
      };
    case "SHOPIFY_TYPE_MAP_DATA":
      return {
        ...state,
        ShopifyProductTypeMappingData: action.data,
      };
    case "SHOPIFY_TYPE_MAP_SSN_DATA":
      return {
        ...state,
        ShopifyProductTypeMappingDataSSN: action.data,
      };
    case "SHOPIFY_TYPE_MAP_COL_DEF":
      return {
        ...state,
        ShopifyProductTypeMappingColDef: action.colDef,
      };
    case "SEASON_INQUIRY_DATA":
      return {
        ...state,
        SeasonInquiryData: action.data,
      };
    case "SEASON_INQUIRY_SSN_DATA":
      return {
        ...state,
        SeasonInquiryDataSSN: action.data,
      };
    case "SEASON_INQUIRY_COL_DEF":
      return {
        ...state,
        SeasonInquiryColDef: action.colDef,
      };
    case "MASTER_LOV_DATA":
      return {
        ...state,
        MasterLovData: action.data,
      };
    case "MASTER_LOV_SSN_DATA":
      return {
        ...state,
        MasterLovDataSSN: action.data,
      };
    case "MASTER_LOV_COL_DEF":
      return {
        ...state,
        MasterLovColDef: action.colDef,
      };
    case "AIR_TABLE_DATA":
      return {
        ...state,
        AirtableMappingData: action.data,
      };
    case "MILESTONES_DATA":
      return {
        ...state,
        MilestonesData: action.data,
      };
    case "MILESTONES_SSN_DATA":
      return {
        ...state,
        MilestonesDataSSN: action.data,
      };
    case "MILESTONES_COL_DEF":
      return {
        ...state,
        MilestonesColDef: action.colDef,
      };
    case "SET_MILESTONES_FILTERS":
      return {
        ...state,
        MilestonesFilters: action.data,
      };
    case "SET_MILESTONES_SORTING":
      return {
        ...state,
        MilestonesSorting: action.data,
      };
    case "SET_MILESTONES_UNSENT_COUNT":
      return {
        ...state,
        MilestonesUnsentCount: action.count
      };
    case "SET_MILESTONES_HIGHLIGHT_CELL":
      return {
        ...state,
        MilestonesHighlistCell: action.data
      };
    case "SET_ACTIONITEM_HIGHLIGHT_CELL":
      return {
        ...state,
        ActionItemHighlightCell: action.data
      };
    case "AIR_TABLE_SSN_DATA":
      return {
        ...state,
        AirtableMappingDataSSN: action.data,
      };
    case "AIR_TABLE_COL_DEF":
      return {
        ...state,
        AirtableMappingColDef: action.colDef,
      };
    case "SET_SEASON_SORTING":
      return {
        ...state,
        SeasonSorting: action.data,
      };
    case "SET_SEASON_FILTERS":
      return {
        ...state,
        SeasonFilters: action.data,
      };
    case "SET_LINELIST_SORTING":
      return {
        ...state,
        LinelistSorting: action.data,
      };
    case "SET_LINELIST_FILTERS":
      return {
        ...state,
        LinelistFilters: action.data,
      };
    case "SET_MASTER_LOV_SORTING":
      return {
        ...state,
        MasterLovSorting: action.data,
      };
    case "SET_MASTER_LOV_FILTERS":
      return {
        ...state,
        MasterLovFilters: action.data,
      };

    case "SET_TY_REPORT_SORTING":
      return {
        ...state,
        TYReportSorting: action.data,
      };
    case "SET_TY_REPORT_FILTERS":
      return {
        ...state,
        TYReportFilters: action.data,
      };

    case "SET_PST_SORTING":
      return {
        ...state,
        PstSorting: action.data,
      };
    case "SET_PST_FILTERS":
      return {
        ...state,
        PstFilters: action.data,
      };

    case "SET_AIR_TABLE_SORTING":
      return {
        ...state,
        AirtableMappingSorting: action.data,
      };
    case "SET_AIR_TABLE_FILTERS":
      return {
        ...state,
        AirtableMappingFilters: action.data,
      };
    case "SET_SEASON_INQUIRY_SORTING":
      return {
        ...state,
        SeasonInquirySorting: action.data,
      };
    case "SET_SEASON_INQUIRY_FILTERS":
      return {
        ...state,
        SeasonInquiryFilters: action.data,
      };
    case "SET_SHOPIFY_TYPE_MAP_SORTING":
      return {
        ...state,
        ShopifyProductTypeMappingSorting: action.data,
      };
    case "SET_SHOPIFY_TYPE_MAP_FILTERS":
      return {
        ...state,
        ShopifyProductTypeMappingFilters: action.data,
      };
    case "SET_SIZE_SCALE_SORTING":
      return {
        ...state,
        SizeScalesSorting: action.data,
      };
    case "SET_SIZE_SCALE_FILTERS":
      return {
        ...state,
        SizeScalesFilters: action.data,
      };
    case "SET_FB_FTY_SORTING":
      return {
        ...state,
        FbFtyDatabaseSorting: action.data,
      };
    case "SET_FB_FTY_FILTERS":
      return {
        ...state,
        FbFtyDatabaseFilters: action.data,
      };
    case "SET_Merch_Assortment_Sandbox_SORTING":
      return {
        ...state,
        MerchAssortmentSandboxSorting: action.data,
      };
    case "SET_Merch_Assortment_Sandbox_FILTERS":
      return {
        ...state,
        MerchAssortmentSandboxFilters: action.data,
      };
    case "SET_CHASE_SORTING":
      return {
        ...state,
        ChaseSorting: action.data,
      };
    case "SET_CHASE_FILTERS":
      return {
        ...state,
        ChaseFilters: action.data,
      };
    case "SET_DTC_BY_SHT_SORTING":
      return {
        ...state,
        DtcBuySheetSorting: action.data,
      };
    case "SET_DTC_BY_SHT_FILTERS":
      return {
        ...state,
        DtcBuySheetFilters: action.data,
      };
    case "SET_WHSL_BY_SHT_SORTING":
      return {
        ...state,
        WhslBuySheetSorting: action.data,
      };
    case "SET_WHSL_BY_SHT_FILTERS":
      return {
        ...state,
        WhslBuySheetFilters: action.data,
      };
    case "SET_WHSL_BY_SHT_NRD_SORTING":
      return {
        ...state,
        WhslBuySheetNordRpSorting: action.data,
      };
    case "SET_WHSL_BY_SHT_NRD_FILTERS":
      return {
        ...state,
        WhslBuySheetNordRpFilters: action.data,
      };
    case "SET_SIZING_SORTING":
      return {
        ...state,
        SizingSorting: action.data,
      };
    case "SET_SIZING_FILTERS":
      return {
        ...state,
        SizingFilters: action.data,
      };
    case "SET_PDInitialCosting_SORTING":
      return {
        ...state,
        PDInitialCostingSorting: action.data,
      };
    case "SET_PDInitialCosting_FILTERS":
      return {
        ...state,
        PDInitialCostingFilters: action.data,
      };
    case "SET_BUY_SHT_SIZE_PER_SORTING":
      return {
        ...state,
        BuySheetMasterSizingPercentageSorting: action.data,
      };
    case "SET_BUY_SHT_SIZE_PER_FILTERS":
      return {
        ...state,
        BuySheetMasterSizingPercentageFilters: action.data,
      };
    case "SET_DELIVERY_MASTER_SORTING":
      return {
        ...state,
        DeliveryMasterSorting: action.data,
      };
    case "SET_DELIVERY_MASTER_FILTERS":
      return {
        ...state,
        DeliveryMasterFilters: action.data,
      };
    case "SET_FRIGHT_BY_CAT_SORTING":
      return {
        ...state,
        FreightByCategorySorting: action.data,
      };
    case "SET_FRIGHT_BY_CAT_FILTERS":
      return {
        ...state,
        FreightByCategoryFilters: action.data,
      };
    case "SET_FRIGHT_MASTER_SORTING":
      return {
        ...state,
        FreightMasterSorting: action.data,
      };
    case "SET_FRIGHT_MASTER_FILTERS":
      return {
        ...state,
        FreightMasterFilters: action.data,
      };
    case "SET_COMP_APS_MASTER_SORTING":
      return {
        ...state,
        CompApsMasterStoreEcomSorting: action.data,
      };
    case "SET_COMP_APS_MASTER_FILTERS":
      return {
        ...state,
        CompApsMasterStoreEcomFilters: action.data,
      };
    case "SET_BUY_SHT_SIZE_CHART_SORTING":
      return {
        ...state,
        BuyShtMasterDataChartsSorting: action.data,
      };
    case "SET_BUY_SHT_SIZE_CHART_FILTERS":
      return {
        ...state,
        BuyShtMasterDataChartsFilters: action.data,
      };
    case "SET_IS_FROM_SEND_DATA":
      return {
        ...state,
        isFromSendData: action.data,
      };
    case "SET_CHASE_HIGHLIGHT_CELL":
      return {
        ...state,
        ChaseHighlistCell: action.data
      };
    case "SET_SEASON_HIGHLIGHT_CELL":
      return {
        ...state,
        SeasonHighlistCell: action.data
      };
    case "SET_LINELIST_HIGHLIGHT_CELL":
      return {
        ...state,
        LineListHighlistCell: action.data
      };
    case "SET_DTC_BUY_SHT_HIGHLIGHT_CELL":
      return {
        ...state,
        DtcButSheetHighlistCell: action.data
      };
    case "SET_WHSL_BUY_SHT_HIGHLIGHT_CELL":
      return {
        ...state,
        WhslBuySheetHighlistCell: action.data
      };
    case "SET_WHSL_BUY_SHT_NRD_HIGHLIGHT_CELL":
      return {
        ...state,
        WhslBuySheetNordHighlistCell: action.data
      };
    case "SET_SIZING_HIGHLIGHT_CELL":
      return {
        ...state,
        SizingHighlistCell: action.data
      };
    case "SET_PDInitialCosting_HIGHLIGHT_CELL":
      return {
        ...state,
        PDInitialCostingHighlistCell: action.data
      };
    case "SET_BUYSHT_MASTER_PER_HIGHLIGHT_CELL":
      return {
        ...state,
        BuySheetMasterSizingPercentageHighlistCell: action.data
      };
    case "SET_BUYSHT_MASTER_CHART_HIGHLIGHT_CELL":
      return {
        ...state,
        BuyShtMasterDataChartsHighlistCell: action.data
      };
    case "SET_COMP_MASTER_HIGHLIGHT_CELL":
      return {
        ...state,
        CompApsMasterStoreEcomHighlistCell: action.data
      };
    case "SET_FRIEGHT_MASTER_HIGHLIGHT_CELL":
      return {
        ...state,
        FreightMasterHighlistCell: action.data
      };
    case "SET_FRIEGHT_BY_CAT_HIGHLIGHT_CELL":
      return {
        ...state,
        FreightByCategoryHighlistCell: action.data
      };
    case "SET_DELIVERY_MASTER_HIGHLIGHT_CELL":
      return {
        ...state,
        DeliveryMasterHighlistCell: action.data
      };
    case "SET_FBFTY_DATABASE_HIGHLIGHT_CELL":
      return {
        ...state,
        FbFtyDatabaseHighlistCell: action.data
      };
    case "SET_SIZE_SCALE_HIGHLIGHT_CELL":
      return {
        ...state,
        SizeScalesHighlistCell: action.data
      };
    case "SET_SHOPIFY_PRODUCT_HIGHLIGHT_CELL":
      return {
        ...state,
        ShopifyProductTypeMappingHighlistCell: action.data
      };
    case "SET_SEASON_INQUIRY_HIGHLIGHT_CELL":
      return {
        ...state,
        SeasonInquiryHighlistCell: action.data
      };
    case "SET_MASTER_LOV_HIGHLIGHT_CELL":
      return {
        ...state,
        MasterLovHighlistCell: action.data
      };
    case "SET_TY_REPORT_HIGHLIGHT_CELL":
      return {
        ...state,
        TYReportHighlistCell: action.data
      };      
    case "SET_PST_HIGHLIGHT_CELL":
      return {
        ...state,
        PstHighlistCell: action.data
      };      
    case "SET_AIR_TABLE_HIGHLIGHT_CELL":
      return {
        ...state,
        AirtableMappingHighlistCell: action.data
      };
    case "Set_Merch_Assortment_Sandbox_UNSENT_COUNT":
      return {
        ...state,
        MerchAssortmentSandboxUnsentCount: action.count
      };
    case "Set_Merch_Assortment_Sandbox_HIGHLIGHT_CELL":
      return {
        ...state,
        MASHighlistCell: action.data
      };
    case "SET_CHASE_UNSENT_COUNT":
      return {
        ...state,
        ChaseUnsentCount: action.count
      };
    case "SET_SEASON_UNSENT_COUNT":
      return {
        ...state,
        SeasonUnsentCount: action.count
      };
    case "SET_LINELIST_UNSENT_COUNT":
      return {
        ...state,
        LineListUnsentCount: action.count
      };
    case "SET_DTC_BUY_SHT_UNSENT_COUNT":
      return {
        ...state,
        DtcButSheetUnsentCount: action.count
      };
    case "SET_WHSL_BUY_SHT_UNSENT_COUNT":
      return {
        ...state,
        WhslBuySheetUnsentCount: action.count
      };
    case "SET_WHSL_BUY_SHT_NRD_UNSENT_COUNT":
      return {
        ...state,
        WhslBuySheetNordUnsentCount: action.count
      };
    case "SET_PDInitialCosting_UNSENT_COUNT":
      return {
        ...state,
        PDInitialCostingUnsentCount: action.count
      };
    case "SET_SIZING_UNSENT_COUNT":
      return {
        ...state,
        SizingUnsentCount: action.count
      };
    case "SET_BUYSHT_MASTER_PER_UNSENT_COUNT":
      return {
        ...state,
        BuySheetMasterSizingPercentageUnsentCount: action.count
      };
    case "SET_BUYSHT_MASTER_CHART_UNSENT_COUNT":
      return {
        ...state,
        BuyShtMasterDataChartsUnsentCount: action.count
      };
    case "SET_COMP_MASTER_UNSENT_COUNT":
      return {
        ...state,
        CompApsMasterStoreEcomUnsentCount: action.count
      };
    case "SET_FRIEGHT_MASTER_UNSENT_COUNT":
      return {
        ...state,
        FreightMasterUnsentCount: action.count
      };
    case "SET_FRIEGHT_BY_CAT_UNSENT_COUNT":
      return {
        ...state,
        FreightByCategoryUnsentCount: action.count
      };
    case "SET_DELIVERY_MASTER_UNSENT_COUNT":
      return {
        ...state,
        DeliveryMasterUnsentCount: action.count
      };
    case "SET_FBFTY_DATABASE_UNSENT_COUNT":
      return {
        ...state,
        FbFtyDatabaseUnsentCount: action.count
      };
    case "SET_SIZE_SCALE_UNSENT_COUNT":
      return {
        ...state,
        SizeScalesUnsentCount: action.count
      };
    case "SET_SHOPIFY_PRODUCT_UNSENT_COUNT":
      return {
        ...state,
        ShopifyProductTypeMappingUnsentCount: action.count
      };
    case "SET_SEASON_INQUIRY_UNSENT_COUNT":
      return {
        ...state,
        SeasonInquiryUnsentCount: action.count
      };
    case "SET_MASTER_LOV_UNSENT_COUNT":
      return {
        ...state,
        MasterLovUnsentCount: action.count
      };
    case "SET_TY_REPORT_UNSENT_COUNT":
        return {
          ...state,
          TYReportUnsentCount: action.count
      };      
    case "SET_PST_UNSENT_COUNT":
        return {
          ...state,
          PstUnsentCount: action.count
      };      
    case "SET_AIR_TABLE_UNSENT_COUNT":
      return {
        ...state,
        AirtableMappingUnsentCount: action.count
      };
    case "SET_ACTION_ITEM_UNSENT_COUNT":
      return {
        ...state,
        ActionItemUnsentCount: action.count
      };
    case "SET_SETTINGS_FOR_DATA_TRANSFER":
      return {
        ...state,
        settingsForDataTransfer: action.data
      }
    case "SUMMARY_REPORT_AGG_SSN_DATA":
      return {
        ...state,
        SummaryReportAggDataSSN: action.data
      }
    case "SET_ManageUser_DATA": return { ...state, ManageUserData: action.data, };
    case "SET_ManageUser_SSN_DATA": return { ...state, ManageUserDataSSN: action.data, };
    case "SET_ManageUser_COL_DEF": return { ...state, ManageUserColDef: action.colDef, };
    case "SET_ManageUser_COL_DROPDOWN": return { ...state, ManageUserColDropDown: action.colDropDown, };
    case "SET_ManageUser_HIGHLIGHT_CELL": return { ...state, ManageUserHighlightCell: action.data, };
    case "SET_ManageUser_SHORTING": return { ...state, ManageUserShorting: action.data, };
    case "SET_ManageUser_FILTERS": return { ...state, ManageUserFilters: action.data, };

    case "SET_ManagePermission_DATA": return { ...state, ManagePermissionData: action.data, };
    case "SET_ManagePermission_SSN_DATA": return { ...state, ManagePermissionDataSSN: action.data, };
    case "SET_ManagePermission_COL_DEF": return { ...state, ManagePermissionColDef: action.colDef, };
    case "SET_ManagePermission_COL_DROPDOWN": return { ...state, ManagePermissionColDropDown: action.colDropDown, };
    case "SET_ManagePermission_HIGHLIGHT_CELL": return { ...state, ManagePermissionHighlightCell: action.data, };
    case "SET_ManagePermission_SHORTING": return { ...state, ManagePermissionShorting: action.data, };
    case "SET_ManagePermission_FILTERS": return { ...state, ManagePermissionFilters: action.data, };
    case "SET_Linelist_Import_COL_DEF": return { ...state, LinelistImportColDef: action.colDef };
    case "SET_MAS_Import_COL_DEF": return { ...state, MerchAssortmentSandboxImportColDef: action.colDef };
    case "SET_PD_Initial_Costing_Import_COL_DEF": return { ...state, PdInitialCostingImportColDef: action.colDef };
    case "SET_Sizing_Import_COL_DEF": return { ...state, SizingImportColDef: action.colDef };
    case "SET_Comp_APS_Master_StoreEco_Import_COL_DEF": return { ...state, CompApsMasterStoreEcomImportColDef: action.colDef };
    case "dataTransferRules": return { ...state, dataTransferRules: action.data };
    case "pvtForTabs":
      return {
        ...state,
        pvtForTabsData: action.data
      }
    case "mpForTabs":
      return {
        ...state,
        mpForTabs: action.data
      }
    case "pvtsData":
      return {
        ...state,
        pvtsData: action.data
      }
    case "llTabPvtData": return {
      ...state,
      llTabPvtData: action.data
    }
    case "clearChase":
      return {
        ...state,
        ChaseData: [],
        ChaseDataSSN: [],
        ChaseHighlistCell: [],
        ChaseUnsentCount: 0,
        ChaseFilters: [],
        ChaseSorting: []
      }
    case "clearSizing":
      return {
        ...state,
        SizingData: [],
        SizingDataSSN: [],
        SizingHighlistCell: [],
        SizingUnsentCount: 0,
        SizingFilters: [],
        SizingSorting: []
      }
    case "clearTYReport":
      return {
        ...state,
        TYReportData: [],
        TYReportDataSSN: [],
        TYReportHighlistCell: [],
        TYReportUnsentCount: 0,
        TYReportFilters: [],
        TYReportSorting: []
      }      
    case "clearPst":
      return {
        ...state,
        PstData: [],
        PstDataSSN: [],
        PstHighlistCell: [],
        PstUnsentCount: 0,
        PstFilters: [],
        PstSorting: []
      }
    case "clearPDInitialCosting":
      return {
        ...state,
        PDInitialCostingData: [],
        PDInitialCostingDataSSN: [],
        PDInitialCostingHighlistCell: [],
        PDInitialCostingUnsentCount: 0,
        PDInitialCostingFilters: [],
        PDInitialCostingSorting: []
      }
    case "clearBuySheetMasterSizingPercentage":
      return {
        ...state,
        BuySheetMasterSizingPercentageData: [],
        BuySheetMasterSizingPercentageDataSSN: [],
        BuySheetMasterSizingPercentageHighlistCell: [],
        BuySheetMasterSizingPercentageUnsentCount: 0,
        BuySheetMasterSizingPercentageFilters: [],
        BuySheetMasterSizingPercentageSorting: []
      }                  
    case "clearLinelist":
      return {
        ...state,
        LinelistData: [],
        LinelistDataSSN: [],
        LineListHighlistCell: [],
        LineListUnsentCount: 0,
        LinelistFilters: [],
        LinelistSorting: []
      }
    case "clearDtc":
      return {
        ...state,
        DtcBuySheetData: [],
        DtcBuySheetDataSSN: [],
        DtcButSheetHighlistCell: [],
        DtcButSheetUnsentCount: 0,
        DtcBuySheetFilters: [],
        DtcBuySheetSorting: []
      }
    case "clearWholesale":
      return {
        ...state,
        WhslBuySheetData: [],
        WhslBuySheetDataSSN: [],
        WhslBuySheetHighlistCell: [],
        WhslBuySheetUnsentCount: 0,
        WhslBuySheetFilters: [],
        WhslBuySheetSorting: [],
        WhslBuySheetNordRpData: [],
        WhslBuySheetNordRpDataSSN: [],
        WhslBuySheetNordHighlistCell: [],
        WhslBuySheetNordUnsentCount: 0,
        WhslBuySheetNordRpFilters: [],
        WhslBuySheetNordRpSorting: []
      }
    case "clearManageUser":
      return {
        ...state,
        ManageUserData: [],
        ManageUserDataSSN: [],
        ManagePermissionData: [],
        ManagePermissionDataSSN: [],
        ManageUserFilters: [],
        ManageUserShorting: [],
        ManagePermissionFilters: [],
        ManagePermissionShorting: []
      }
    case "SET_ALL_CLEAR":
      return defaultState;
    default:
      return state;
  }
}
