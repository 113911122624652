import { Card, Dialog, DialogContent, Grid, Icon, Tooltip } from "@mui/material";
import { AgCharts } from "ag-charts-react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { restApiPath } from "utils/app.props";

const agdivstyl2 = {
  width: "87%",
  fontFamily: "Inter",
  position: "relative",
  height: "300px",
};
function renderer({ datum, xKey, yKey }) {
  return {
    title: datum[xKey],
    content: '$' + datum[yKey].toLocaleString(),
  };
}
function Ag_Charts({ chartOptions, title, formatTooltip }) {

  const [reloadChart, setReloadChart] = useState(true);
  const [chartDataSource, setChartData] = useState([]);

  const [PopupChart, setPopupChart] = useState([]);
  const [ZoomOpen, setZoomOpen] = useState(false);

  const fetchData = async () => {
    const url1 = `${restApiPath}callPGFunction`;
    setReloadChart(false);
    const jsonReq = {
      "param_body": {
        "param_user_id": sessionStorage.getItem("email"),
        "param_query": sessionStorage.getItem("asRequiredDownloadQuery") ? sessionStorage.getItem("asRequiredDownloadQuery") : ""
      },
      param_function: url
    };
    const requestbody = {
      method: "POST",
      headers: {
        'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
        'Accept': "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonReq),
    };
    try {
      const response = await fetch(url1, requestbody);
      const json = await response.json();
      if (json.response) {
        const mydata = JSON.parse(json.data);
        if (mydata) {
          if (formatTooltip) {
            mydata.series.map((sr) => {
              sr["tooltip"] = { renderer: renderer }
              return sr;
            })
          }
          console.log("option ag chart", mydata)
          setChartData(mydata);
        }
        setReloadChart(true);
      }
    } catch (error) {
      setChartData([]);
      setReloadChart(true);
    }
  };
  useEffect(() => {
    // fetchData();
    if (chartOptions && chartOptions.series) {
      if (formatTooltip) {
        chartOptions.series.map((sr) => {
          sr["tooltip"] = { renderer: renderer }
          return sr;
        })
      }
      // console.log("option ag chart", chartOptions)
      setChartData(chartOptions);
    }
    setReloadChart(true);
  
  }, [chartOptions]);

  const zoomPopupOpen = () => {
    let tmp = JSON.stringify(chartDataSource);
    tmp = tmp.replaceAll("10", "14")

    setPopupChart(JSON.parse(tmp));
    setZoomOpen(true);
  };
  const zoomPopupClose = () => {
    setZoomOpen(false);
  };
  return (
    <div>
      <Dialog open={ZoomOpen} onClose={zoomPopupClose} fullScreen>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={2}>
          <MDTypography variant="h6" fontWeight="medium">
            {title}
          </MDTypography>
          <Tooltip title="Close" placement="bottom" arrow>
            <MDButton iconOnly onClick={zoomPopupClose}>
              <Icon>close</Icon>
            </MDButton>
          </Tooltip>
        </MDBox>
        <DialogContent>
          <div style={{ display: 'grid', width: '100%', height: '100%' }}>
            <AgCharts options={PopupChart} />
          </div>
        </DialogContent>
      </Dialog>
      <Card sx={{ margin: "5px" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={0.5} px={0.5}>
          <MDTypography sx={{ fontSize: "12px", width: "95%", whiteSpace: "initial" }} align="center">
            {title}
          </MDTypography>
          <Tooltip title="Fullscreen" placement="bottom" arrow sx={{ width: "5%" }}>
            <MDButton iconOnly onClick={() => zoomPopupOpen()}>
              <Icon>open_in_full</Icon>
            </MDButton>
          </Tooltip>
        </MDBox>
        <div style={agdivstyl2} id="minimumTLDiv">
          {reloadChart ? (
            <AgCharts options={chartDataSource} />
          ) : null}
        </div>
      </Card>
    </div>
  );
}
Ag_Charts.defaultProps = {
  formatTooltip: false
}
Ag_Charts.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
export default Ag_Charts;
