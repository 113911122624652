import { xlRound } from "layouts/linelist/XL_Calculation";

export const calculateCurrentFOB = (data) => {
    let resultVal = "";
    let FinalFOB = parseFloat(data["Final FOB"] || 0);
    let WorkingCost = parseFloat(data["Working Cost"] || 0);
    if (FinalFOB > 0) resultVal = FinalFOB;
    else if (WorkingCost > 0) resultVal = WorkingCost;
    return resultVal;
  };

  export const calculateLDP = (data) => {
    let CurrentFOB = parseFloat(data["Current FOB"] || 0);
    let BlendedFreight = parseFloat(data["Blended Freight"] || 0);
    let DutyTotal = parseFloat(data["Duty Total"] || 0);
    let AgentCommissionRate = parseFloat(data["Agent Commission Rate"] || 0);
    let resultVal = (CurrentFOB + BlendedFreight + (CurrentFOB * DutyTotal) + (CurrentFOB * AgentCommissionRate)).toFixed(10);
    return xlRound(resultVal, 6);
  };

  export const calculateRTLIMU = (data) => {
    let MSRP = parseFloat(data["MSRP"] || 0);
    let LDP = parseFloat(data["LDP"] || 0);
    let resultVal = "";
    if (MSRP !== 0) resultVal = xlRound(((MSRP - LDP) / MSRP).toFixed(10), 6);
    return resultVal;
  };

  export const calculateWHLSIMU = (data) => {
    let WHLS = parseFloat(data["WHLS"] || 0);
    let LDP = parseFloat(data["LDP"] || 0);
    let resultVal = "";
    if (WHLS !== 0) resultVal = xlRound(((WHLS - LDP) / WHLS).toFixed(10), 6);
    return resultVal;
  };