import React, { useState, useEffect } from 'react';
import {
    Accordion, AccordionSummary, AccordionDetails,
    Typography, Grid, Paper, Divider, Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { prepareMilestonesData } from './prepareMilestonesData';
import { setCurrentTab } from "context";
import { useMaterialUIController } from "context";
import { CH1, getHighlightByTblName } from 'layouts/linelist/XL_Utils';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_faherty_milestones";
const rng_name = "Milestones_Rng";
const tab_name = "Milestones";

const MilestoneNotification = () => {
    const [controller, dispatch] = useMaterialUIController();
    const [mlsnData, setMlsnData] = useState([]);
    const MilestonesData = useSelector((s) => s.MilestonesData);
    const LinelistData = useSelector(state => state.LinelistData);
    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        const mlsnData = prepareMilestonesData(LinelistData, MilestonesData);
        // console.log("MilestoneNotification -> mlsnData -> ", mlsnData);
        setMlsnData(mlsnData);
        setExpanded(mlsnData.map((_, index) => `panel${index}`))
    }, [LinelistData, MilestonesData]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(prev =>
            isExpanded
                ? [...prev, panel]
                : prev.filter(p => p !== panel)
        );
    };

    const isExpanded = (panel) => expanded.includes(panel);

    const getColor = (daysRemaining) => {
        if (daysRemaining < -9) return '#000000';
        if (daysRemaining > 9) return '#006100';
        if (daysRemaining >= 2 && daysRemaining <= 9) return '#9C5700';
        if (daysRemaining < 1) return '#9C0006';
        return 'black';
    };

    const getBgColor = (daysRemaining) => {
        if (daysRemaining < -9) return '#D9D9D9';
        if (daysRemaining > 9) return '#C6EFCE';
        if (daysRemaining >= 2 && daysRemaining <= 9) return '#FFEB9C';
        if (daysRemaining < 1) return '#FFC7CE';
        return 'white';
    };

    const gotoMilestone = (row_id) => {
        if (!row_id || row_id === "") {
            return
        }

        setCurrentTab(dispatch, tab_name);

        // console.log("gotoMilestone -> row_id -> ", row_id);
        let highlightCells = getHighlightByTblName(tbl_name) || [];
        highlightCells = highlightCells.filter(obj => {
            const value = Object.values(obj)[0]; // Get the value of the object
            return value !== 5; // Keep objects where value is not 5
        });
        // console.log("gotoMilestone -> highlightCells -> ", highlightCells);
        const key = tbl_name + "|" + 'Milestone' + "|" + row_id;
        // console.log("gotoMilestone -> key -> ", key);
        highlightCells.push({ [key]: 5 });
        setHighlightByTblName(tbl_name, highlightCells);
    };

    if (sessionStorage.getItem("isDownloadLinelistPerformed") === false || sessionStorage.getItem("isDownloadLinelistPerformed") === 'false') {
        return (
            <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 400, pl: 4, whiteSpace: 'pre-wrap' }}>
                Please perform download data from Season tab.
            </Typography>
        );
    }

    if (!LinelistData || LinelistData.length === 0) {
        return (
            <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 400, pl: 4, whiteSpace: 'pre-wrap' }}>
                Please download Linelist tab data.
            </Typography>
        );
    }

    if (!mlsnData || mlsnData.length === 0) {
        return (
            // <Paper elevation={3} sx={{ maxWidth: 600, marginTop: '10px', marginBottom: '5px', padding: '5px', textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            //     <Typography variant="h6" style={{ fontWeight: 400, fontSize: '14px' }}>
            //         No milestone found for selected season-department.
            //     </Typography>
            // </Paper>
            <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 400, pl: 4, whiteSpace: 'pre-wrap' }}>
                No milestone found for selected season-department.
            </Typography>
        );
    }

    return (
        <Paper elevation={3} sx={{ maxWidth: 600, marginTop: '10px', marginBottom: '5px' }}>
            {mlsnData.map((category, index) => (
                <Accordion
                    key={index}
                    expanded={isExpanded(`panel${index}`)}
                    onChange={handleChange(`panel${index}`)}
                    disableGutters
                    sx={{
                        '&:before': {
                            display: 'none',
                        },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}bh-content`}
                        id={`panel${index}bh-header`}
                        sx={{
                            minHeight: 36,
                            '& .MuiAccordionSummary-content': {
                                margin: '6px 0',
                            },
                            // backgroundColor: '#f0f0f0',
                            backgroundColor: '#cae2f9',
                            fontSize: '14px',
                            color: 'white',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)', // Add shadow
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: '500', fontSize: '14px' }}>
                            {category.name}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                        <Grid container sx={{ width: '100%', borderBottom: '2px solid lightgray' }}>
                            <Grid item xs={8} sx={{ padding: '4px 8px', fontSize: '13px', fontWeight: '500' }}>
                                Milestone
                            </Grid>
                            <Grid item xs={4} sx={{ padding: '4px 8px', fontSize: '13px', fontWeight: '500', textAlign: 'right' }}>
                                Days Remaining
                            </Grid>
                        </Grid>
                        {category.milestones.map((milestone, mIndex) => (
                            <Grid container key={mIndex} sx={{ borderBottom: '0.0825rem solid lightgray' }}>
                                <Grid item xs={9} sx={{
                                    padding: '4px 8px',
                                    fontSize: '0.75rem',
                                    fontWeight: '500',
                                    borderRight: '0.0825rem solid lightgray',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#dcecfb',
                                    },
                                }}
                                    onClick={() => gotoMilestone(milestone.row_id)}
                                >
                                    <Tooltip title={milestone.milestone} arrow>
                                        <div style={{
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            width: '100%',
                                        }}>
                                            {milestone.milestone}
                                        </div>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={3} sx={{
                                    padding: '4px 8px',
                                    fontSize: '0.75rem',
                                    fontWeight: '500',
                                    color: getColor(milestone.daysRemaining),
                                    bgcolor: getBgColor(milestone.daysRemaining),
                                    textAlign: 'center',
                                }}>
                                    {milestone.daysRemaining}
                                </Grid>
                            </Grid>
                        ))}
                    </AccordionDetails>
                    <Divider sx={{ marginTop: '7px', marginBottom: '7px' }} />
                </Accordion>
            ))}
        </Paper>
    );
};

export default MilestoneNotification;