import React, { useMemo } from 'react';
import { createTheme } from '@mui/material/styles';

const CustomThemeCreator = ({ darkMode,transparentSidenav, whiteSidenav, }) => {
  const theme = useMemo(() => 
    createTheme({
      palette: {
        mode: !transparentSidenav && !whiteSidenav ? 'dark' : 'light',
        background: {
          default: !transparentSidenav && !whiteSidenav ? '#303030' : '#FFFFFF',
          paper: !transparentSidenav && !whiteSidenav ? '#424242' : '#F5F5F5',
        },
        text: {
          primary: !transparentSidenav && !whiteSidenav ? '#FFFFFF' : '#000000',
        },
      },
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              fontSize: "12px",
              '& fieldset': {
                borderColor: !transparentSidenav && !whiteSidenav ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: !transparentSidenav && !whiteSidenav ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
              },
              '&.Mui-focused fieldset': {
                borderColor: !transparentSidenav && !whiteSidenav ? '#90CAF9' : '#1976D2',
                borderWidth: '2px',
              },
              '& input': {
                fontSize: "12px",
                padding: '8px 14px',
              },
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              fontSize: "12px",
              '&.Mui-focused': {
                color: !transparentSidenav && !whiteSidenav ? '#90CAF9' : '#1976D2',
              },
              transform: 'translate(14px, 9px) scale(1)',
              '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -9px) scale(0.75)',
              },
            },
          },
        },
      },
    }),
    [!transparentSidenav && !whiteSidenav]
  );

  return theme;
};

export default CustomThemeCreator;